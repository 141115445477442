import { createUseStyles } from "react-jss";

export const memberListUsestyles = createUseStyles(() => ({
  memberListWrapper: {
    margin: "32px 0 64px 0",
    width: "1288px",
    boxShadow: "0px 0px 4px #E8E8E8",
    borderRadius: "8px",
    background: "#FFFFFF",
    padding: "24px 32px 0 32px",
  },
  table: {
    borderCollapse: "collapse",
    marginRight: "0",
    width: "100%",
  },
  tableRow: {
    borderBottom: "1px solid #D9D9D9",
  },
  tableBodyRow: {
    position: "relative",
    borderBottom: "1px solid #D9D9D9",
    "&:hover $deactivatedTooltip ": {
      display: "block",
    },
    "&:last-child": {
      borderBottom: "none",
    },
    // "&:hover": {
    //   background: "#FAFAFA",
    // },
  },
  tableHeaderItem: {
    padding: "0 0 24px 8px",
    textAlign: " start",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "1.5",
    whiteSpace: "nowrap",
    color: "#444444",
  },
  tableBodyItem: {
    padding: "24px 0 24px 8px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    whiteSpace: "nowrap",
    "&:last-child": {
      paddingRight: "24px",
      position: "relative",
    },
    "&:nth-child(2)": {
      position: "relative",
    },
  },
  deactivatedTooltip: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "18px",
    display: "none",
    position: "absolute",
    left: "-138px",
    top: "50%",
    transform: "translateY(-50%)",
    background: "#FFFFFF",
    borderRadius: "8px",
    color: "#B3261E",
    padding: "8px 16px",
    boxShadow: "0px 4px 20px rgba(95, 95, 95, 0.15)",
    zIndex: "999",

    "&::before": {
      content: "''",
      position: "absolute",
      right: "-13px",
      top: "calc(50% - 6px)",
      transform: "translate(-50%) rotate(45deg)",
      width: "12px",
      height: "12px",
      backgroundColor: "#ffffff",
      borderRadius: "1px",
    },
  },
  setingActionWrapper: {
    position: "absolute",
    top: "calc(50% - 18px)",
    right: "-8px",
  },
}));
