import React, { useEffect, useRef, CSSProperties, useState } from 'react';
import { IconButton, InputAdornment } from '@mui/material';
import attentionBlue from 'assets/icons/attentionBlue.svg';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Flex from '../Flex/Flex';
import { EyeIconPassword } from '../Icons/EyeIconPassword';
import { CloseIcon } from '../Icons/Close.icon';
import { EnterIcon } from '../Icons/Enter.icon';
import errorIcon from 'assets/icons/errorIcon.svg';
import { inputFieldUseStyles, requiredFieldStyles } from './InputField.styles';

export enum InputFieldVariant {
  Input = 'Input',
  Search = 'Search',
  FloatingLabeledInput = 'FloatingLabeledInput',
}

interface InputFieldProps {
  errorMessage?: string;
  errorStyles?: CSSProperties;
  className?: string;
  hasDelete?: boolean;
  label?: string;
  floatingLabel?: string;
  value: string
      | number;
  placeholder?: string;
  variant?: InputFieldVariant;
  isRequired?: boolean;
  style?: CSSProperties;
  placeHolderStyles?: CSSProperties;
  htmlForName?: string;
  inputSearchStyle?: CSSProperties;
  hasError: boolean;
  errorText?: string;
  labelStyle?: CSSProperties;
  inputType?: string;
  hintText?: string | any;
  name?: string;
  isReadonly?: boolean;
  hasEnter?: boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete?: () => void;
  handleBlur?: () => void;
  handleKeyDown?: (event: any) => void;
  handleFocus?: (() => void) | undefined;
  hanleClearClick?: () => void;
  handleSearch?: () => void;
}

export const InputField = ({
  variant = InputFieldVariant.Input,
  errorMessage,
  label,
  floatingLabel,
  placeholder,
  hasDelete = true,
  placeHolderStyles: placeholderStyles,
  value,
  isRequired,
  className,
  style,
  htmlForName,
  inputSearchStyle,
  handleKeyDown,
  handleFocus,
  handleChange,
  hanleClearClick,
  handleSearch,
  hasError,
  errorText,
  labelStyle,
  inputType,
  hintText,
  name,
  isReadonly = false,
  hasEnter = true,
}: InputFieldProps) => {
  const inputTxt = useRef<any>(null);

  useEffect(() => {
    inputTxt.current?.focus();
  });

  const [isActive, setInputIsActive] = useState<boolean>(false);
  const [isBlured, setIsBlured] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputFieldStyles = inputFieldUseStyles({
    placeHolderStyles: placeholderStyles,
    hasDelete: Boolean(hasDelete),
    isActive,
    value,
    hasError,
    hasHint: hintText,
    isBlured,
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div style={{ width: '100%' }}>
      {label && (
        <div className={inputFieldStyles.inputLabel} style={labelStyle}>
          <label htmlFor={htmlForName}>
            {label}
            {isRequired && (
              <RequiredIndicator isEmpty={Boolean(!value || errorMessage)} />
            )}
          </label>
        </div>
      )}

      <div style={{ position: 'relative' }}>
        <>
          <Flex alignItems="center" style={{ position: 'relative' }}>
            <div className={inputFieldStyles.inputWrapper}>
              {(() => {
                switch (variant) {
                  case InputFieldVariant.Input:
                    return (
                      <>
                        <input
                          type={
                            inputType === 'password' &&
                                                        !showPassword &&
                                                        value
                              ? 'password'
                              : 'text'
                          }
                          onChange={handleChange}
                          className={
                            className
                              ? `${className} ${inputFieldStyles.inputField}`
                              : inputFieldStyles.inputField
                          }
                          name={name}
                          placeholder={placeholder}
                          style={style}
                          onBlur={() => {
                            setInputIsActive(false);
                            setIsBlured(true);
                          }}
                          onFocus={() => {
                            setInputIsActive(true);
                            setIsBlured(false);
                            handleFocus && handleFocus();
                          }}
                          value={value}
                          readOnly={isReadonly}
                        />
                        <span
                          className={inputFieldStyles.inputErrorText}
                          style={{
                            margin: '8px 0 0 4px',
                            width: '100%',
                            position: 'relative',
                          }}
                        >
                          <img src={errorIcon} alt="info" />
                          {errorText}
                        </span>
                        {!isBlured && isActive && hintText && (
                          <p
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              marginTop: '4px',
                              color: '#0F314D',
                              fontWeight: '400',
                              fontSize: '11px',
                              marginLeft: '8px',
                              marginBottom: 0,
                            }}
                          >
                            <img
                              width="15px"
                              height="15px"
                              style={{ marginRight: '6px' }}
                              src={attentionBlue}
                              alt="info"
                            />
                            {hintText}
                          </p>
                        )}
                        {inputType === 'password' && (
                          <div
                            className={inputFieldStyles.passwordEyeIcon}
                          >
                            <div
                              className={
                                inputFieldStyles.passwordEyeIconFrame
                              }
                            >
                              <InputAdornment
                                position="end"
                                className="input-adornement"
                              >
                                <IconButton
                                  style={{
                                    width: '40px',
                                    height: '40px',
                                  }}
                                  aria-label="toggle password visibility"
                                  onClick={
                                    handleClickShowPassword
                                  }
                                  onMouseDown={
                                    handleMouseDownPassword
                                  }
                                >
                                  <EyeIconPassword
                                    showPass={showPassword}
                                  />
                                </IconButton>
                              </InputAdornment>
                            </div>
                          </div>
                        )}
                      </>
                    );
                  case InputFieldVariant.Search:
                    return (
                      <Flex
                        className={inputFieldStyles.searchWrapper}
                        style={style}
                      >
                        <div className={inputFieldStyles.inputContainer}>
                          <input
                            value={value}
                            placeholder={placeholder}
                            onBlur={() => {
                              setInputIsActive(false);
                            }}
                            onFocus={() => {
                              setInputIsActive(true);
                            }}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            className={inputFieldStyles.searchInput}
                            style={inputSearchStyle}
                          />
                          {value && (
                            <div
                              className={
                                inputFieldStyles.clearIconWrapper
                              }
                            >
                              <Flex
                                alignItems="center"
                                justifyContent="center"
                                className={
                                  inputFieldStyles.clearIcon
                                }
                                onClick={hanleClearClick}
                              >
                                <CloseIcon />
                              </Flex>
                            </div>
                          )}
                        </div>
                        {hasEnter && (
                          <button
                            className={inputFieldStyles.enterBtn}
                            onClick={value ? handleSearch : () => {}}
                          >
                            <EnterIcon />
                          </button>
                        )}
                      </Flex>
                    );
                  case InputFieldVariant.FloatingLabeledInput:
                    return (
                      <div className={inputFieldStyles.floatingInputWrapper}>
                        <input
                          style={style}
                          onChange={handleChange}
                          type={inputType}
                          className={inputFieldStyles.floatingInput}
                          required
                          onBlur={() => {
                            setInputIsActive(false);
                            setIsBlured(true);
                          }}
                          onFocus={() => {
                            setInputIsActive(true);
                            setIsBlured(false);
                            handleFocus && handleFocus();
                          }}
                          value={value}
                        />
                        <span className={inputFieldStyles.floatingLabel}>
                          {floatingLabel}
                        </span>
                        {isBlured && value && hasError && (
                          <ErrorMessage
                            text={errorText ?? ''}
                            margin="8px 0 0 4px"
                            position="relative"
                            width="100%"
                          />
                        )}
                      </div>
                    );
                  default:
                    return (
                      <input
                        disabled={!isActive}
                        className={
                          className
                            ? `${inputFieldStyles.inputField} ${className}`
                            : inputFieldStyles.inputField
                        }
                        placeholder={placeholder}
                        style={style}
                      />
                    );
                }
              })()}
            </div>
          </Flex>
        </>
      </div>
    </div>
  );
};

export const RequiredIndicator = ({ isEmpty }: { isEmpty: boolean }) => {
  const styles = requiredFieldStyles({ isEmpty });

  return <span className={styles.requiredText}> *</span>;
};
