import { createUseStyles } from 'react-jss';
import { AppTheme } from 'types/styles.types';

export const useStyles = createUseStyles((theme: AppTheme) => ({
  modalTitle: {
    fontSize: '32px',
    fontWeight: '700',
    lineHeight: '48px',
    color: theme.primaryColor,
    margin: '0',
    width: '100%',
  },
  namingConventionWrap: {
    background: '#F3F4F6',
    padding: '12px 16px',
    borderRadius: '4px',
    marginTop: '24px',
  },
  namingConventionTitle: {
    color: '#0F314D',
    fontSize: '18px',
  },
  namingConventionList: {
    paddingLeft: '22px',
  },
  namingConventionItem: {
    maxWidth: '550px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  dividerStyle: {
    margin: '16px 0 !important',
  },
}));
