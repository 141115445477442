import { createUseStyles } from "react-jss";

export const cardUseStyles = createUseStyles(() => ({
  cardContainer: {
    padding: "12px 24px",
    background: "#FFFFFF",
    border: "1px solid #D9D9D9",
    borderRadius: "16px",
    marginRight: "16px",
    // "&:last-child": {
    //   margin: "0",
    // },
  },
  fsmName: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "#0F314D",
  },
  divider: {
    width: "100%",
    height: "1px",
    background: "#D9D9D9",
  },
  counts: {
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "27px",
    color: "#F69C2C",
  },
  status: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#444444",
  },
  statisticsWrapper: {
    height: "152px",
    width: "100%",
    margin: "24px 0 8px",
  },
  timePeriod: {
    "&>span": {
      fontSize: "16px",
      fontWeight: "400",
      lineHeight: "1.5",
      color: "#B7B7B7",
      "&:last-child": {
        color: "#0F314D",
      },
    },
  },
}));
