import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

interface CheckboxStylesProps {
  styles: any;
  disabled: boolean;
}

export const checkboxUseStyles: any = createUseStyles((theme: AppTheme) => ({
  input: {
    display: "none",
    "&:checked + label:after": {
      display: "block",
      content: ({ styles }) => `url(${styles.check})`,
      borderRadius: "2px",
      position: "absolute",
      width: ({ styles }: CheckboxStylesProps) => styles.checkboxWidth,
      height: ({ styles }: CheckboxStylesProps) => styles.checkboxHeight,
      left: ({ styles }: CheckboxStylesProps) => styles.left,
      top: ({ styles }: CheckboxStylesProps) => styles.top,
      transform: "translateY(-100%)",
    },
    "&:checked + label:before": {
      background: theme.deepBlueColor,
      border: `1px solid ${theme.deepBlueColor}`,
    },
  },
  label: {
    position: "relative",
    cursor:({ disabled }) => (disabled ? "auto" : "pointer"),
    display: "block",
    boxSizing: "border-box",

    "&:before": {
      content: "\"\"",
      display: "block",
      backgroundColor: ({ styles }: CheckboxStylesProps) =>
        styles.backgroundColor,
      border: ({ disabled }) =>
        (disabled ? "1px solid #D9D9D9" : `1px solid ${theme.deepBlueColor}`),
      borderRadius: "4px",
      width: ({ styles }: CheckboxStylesProps) => styles.beforeWidth,
      height: ({ styles }: CheckboxStylesProps) => styles.beforeHeight,
      cursor:({ disabled }) => (disabled ? "auto" : "pointer"),
    },
  },
  description: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.textColor,
    paddingLeft: "12px",
    cursor: "pointer",
  },
}));
