import { createUseStyles } from 'react-jss';

export const useStylesCard = createUseStyles({
  root: {
    borderRadius: '12px',
    position: 'absolute',
    width: '576px',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    boxShadow: '0px 2px 20px rgba(228, 228, 228, 0.8)',
    padding: '64px',
  },
  cardContent: {
    padding: '0',
    '&:last-child': {
      padding: '0',
    },
  },
});

