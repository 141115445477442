import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import deleteIconBlue from "assets/icons/deleteIconBlue.svg";
import deleteIconGray from "assets/icons/deleteIconGray.svg";
import { retryReportCreation } from "services/reporting.services";
import { useScrollTop } from "hooks/useScrollTop";
import Flex from "modules/shared/components/general/Flex/Flex";
import CustomTooltipForReports from "modules/shared/components/general/CustomTooltip/CustomTooltipForReports";
import DeleteReport from "modules/BrandsReporting/components//DeleteReport/DeleteReport";
import BasicModal from "modules/shared/components/general/BasicModal/BasicModal";
import { servicesNames } from "constants/servicesNames.constants";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import FailedReport from "./FailedReport";
import CompletedReport from "./CompletedReport";
import InProgressReport from "./InProgressReport";
import "./styles.scss";

const { useTable } = require("react-table");

const ReportTable:React.FC<any> = ({
  columns,
  data,
  isFetchingNextPage,
  isFetchingReports,
}) => {
  const queryClient = useQueryClient();
  const scrollTopComponent = useScrollTop();
  const { mutate, isLoading } = useMutation(retryReportCreation);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  const [open, setOpen] = useState(false);
  const [id, setId] = useState<number>(0);

  const handleClick = (id:any) => {
    setOpen(true);
    setId(id);
  };

  const handleRetryClick = (id:any) => {
    setId(id);
    mutate(id, {
      onSuccess: () => {
        queryClient.invalidateQueries(servicesNames.getBrandsReports);
      },
    });
  };

  const renderConversionStatus = (value:any) => (
    <span className="is-conversion">{value ? "Conversion" : "Lead"}</span>
  );

  const generateReportNameTooltip = (cell:any) => {
    if (cell.value[0].length > 26) {
      return (
        <Flex direction="column" style={{ paddingTop: "33px" }}>
          <CustomTooltipForReports isOneRow={!(rows.length > 1)} value={cell.render("Cell")} />
          {renderConversionStatus(cell.value[1])}
        </Flex>
      );
    }
    return (
      <Flex direction="column">
        {cell.render("Cell")}
        {renderConversionStatus(cell.value[1])}
      </Flex>
    );
  };

  const generateDeleteIcon = (isOwner:any, id:any) => {
    if (isOwner) {
      return (
        <img
          onClick={() => handleClick(id)}
          src={deleteIconBlue}
          alt="delete report"
          className="icon-button"
        />
      );
    }
    return (
      <div className="delete-wrapper">
        <img
          src={deleteIconGray}
          alt=""
          style={{
            padding: "12px",
            cursor: "pointer",
            boxShadow: "0px 0px 9px rgba(169, 169, 169, 0.25)",
            borderRadius: "100%",
          }}
        />
        <div className="delete-tooltip">
          Deleting others' reports is not allowed.
        </div>
      </div>
    );
  };
  
  const generateReportTable = (row:any, index:any) => {
    switch (row.values.state) {
      case "Failed":
        return (
          <FailedReport
            row={row}
            rowIndex={index}
            data={data}
            handleRetryClick={handleRetryClick}
            generateReportNameTooltip={generateReportNameTooltip}
            generateDeleteIcon={generateDeleteIcon}
            isLoading={isLoading || isFetchingReports}
            reportId={id}
          />
        );
      case "Completed":
        return (
          <CompletedReport
            row={row}
            rowIndex={index}
            data={data}
            handleRetryClick={handleRetryClick}
            generateReportNameTooltip={generateReportNameTooltip}
            generateDeleteIcon={generateDeleteIcon}
          />
        );

      default:
        return (
          <InProgressReport
            row={row}
            generateReportNameTooltip={generateReportNameTooltip}
          />
        );
    }
  };

  return (
    <>
      <div className="reports-wrapper">
        <BasicModal
          open={open}
          handleClose={() => setOpen(false)}
          style={{ width: "617px" }}
        >
          <DeleteReport handleClose={() => setOpen(false)} id={id} />
        </BasicModal>
        <table
          {...getTableProps()}
          style={{ overflow: rows.length > 20 ? "scroll" : "hidden" }}
        >
          <thead>
            {headerGroups.map((headerGroup:any) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column:any) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row:any, index:any) => {
              prepareRow(row, index);
              return generateReportTable(row, index);
            })}
          </tbody>
        </table>
        {isFetchingNextPage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              height: "52px",
            }}
          >
            <Spinner style={{ margin: "0" }} />
          </div>
        )}
      </div>
      {scrollTopComponent}
    </>
  );
};
export default ReportTable;
