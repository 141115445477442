import { createUseStyles } from "react-jss";

export const emptyStateUseStyles = createUseStyles(() => ({
  emptyStateInfo: {
    margin: "0",
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "1.5",
    color: "#0F314D",
  },
  message: {
    margin: "0",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1,5",
    color: "#444",
  },
}));
