import { createUseStyles } from 'react-jss';

export const useStylesTextField = createUseStyles({
  root: {
    "& .MuiInputBase-root": {
      background: "#fff",
    },
    "& .MuiInputBase-input": {
      fontWeight: 400,
      fontSize: "16px",
      "&::placeholder": {
        color: "#444444",
        fontSize: "16px",
        fontWeight: "400",
      },
      "&:-webkit-autofill": {
        transition: "background-color 5000s ease-in-out 0s",
      },
    },

    "& label.Mui-focused": {
      color: "#444444",
    },

    "& .MuiInput-underline:after": {
      borderBottomColor: "#0F314D",
      borderWidth: "1px",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DBDBDB",
        borderWidth: "1px",
      },
      "&:hover fieldset": {
        borderWidth: "1px",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0F314D",
        borderWidth: "1px",
      },
    },
  },
});


export const useStyles = createUseStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {},
  form: {
    width: "100%",
  },
  submit: {
    margin: "10px",
  },
  cardBody: {},
}));
