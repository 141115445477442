import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BasicModal from 'modules/shared/components/general/BasicModal/BasicModal';
import ReportRequest from 'modules/BrandsReporting/components/ReportRequest/ReportRequest';
import ROUTES from 'constants/routes';
import Spinner from 'modules/shared/components/general/Spinner/Spinner';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import { getBrandsLogosService } from 'services/reporting.services';
import { servicesNames } from 'constants/servicesNames.constants';
import InfiniteScroll from '../../../shared/components/general/InfiniteScroll/InfiniteScroll';
import { getPageFromUrl } from '../../../../helpers/PageFromUrl';

import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { IRootStore } from '../../../../types/store.types';
import { setShowSuccessModal } from '../../../../store/brandsReporting/brandsReportingSlice';

const BrandsLogos = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    brandsReporting: { showSuccessModal },
  } = useSelector((state: IRootStore) => state);

  const {
    data: brandsLogosRsponse,
    fetchNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
  } =
        useInfiniteQuery(
          [servicesNames.getBrandsLogo],
          ({ pageParam = 1 }) => getBrandsLogosService(pageParam),
          {
            getNextPageParam: ({ data: { next = '' } = {} }) => {
              if (next) {
                const res = getPageFromUrl(next);
                return res ? Number(res) : undefined;
              }
            },
          },
        );

  const brandsLogosData: any = brandsLogosRsponse?.pages.map(page => page.data?.results?.map((duplicationItem: any) => duplicationItem));

  const flattenedArray = [].concat.apply([], brandsLogosData);

  const handleClick = (name: any, logo: any, id: any) => {
    navigate(ROUTES.BRAND_REPORTS, { state: { name, logo, id } });
  };

  const closeSuccessModal = () => {
    dispatch(setShowSuccessModal(false));
    setOpen(false);
    queryClient.invalidateQueries(servicesNames.getBrandsReports);
  };


  return (
    <InfiniteScroll
      scroll
      next={() => fetchNextPage()}
      maxHeight={'calc(100vh - 100px)'}
      hasMore={!isFetching}
    >
      <div className="brands-wrapper">
        <div className="brands-header">
          <h1>Reports</h1>
          <button
            className="auth-button"
            style={{ width: 'unset', margin: '0' }}
            onClick={() => {
              setOpen(true);
            }}
            disabled={isLoading}
          >
            Request report
          </button>
        </div>
        <BasicModal
          open={open}
          handleClose={
            showSuccessModal ? closeSuccessModal :
              () => setOpen(false)}
          style={{ width: '620px', paddingRight: '0' }}
        >
          <ReportRequest
            handleClose={
              showSuccessModal ? closeSuccessModal :
                () => setOpen(false)}
            brandsLogosData={flattenedArray}
          />
        </BasicModal>
        <div className="brands-container">
          {isLoading ? (
            <Flex style={{ width: '100%' }} justifyContent="center">
              <Spinner />
            </Flex>
          ) :
            <>
              {
                flattenedArray?.map((item: any) => (
                  <div
                    key={item.id}
                    className="brands"
                    onClick={() => handleClick(item.name, item.logo_url, item.id)}
                  >
                    <img src={item.logo_url} alt="Brand Logo"
                      style={{ maxHeight: '120px', maxWidth: '120px', objectFit: 'cover' }}
                    />
                  </div>
                ))
              }
              {
                isFetchingNextPage &&
                                <Flex style={{ width: '100%' }} justifyContent="center">
                                  <Spinner />
                                </Flex>
              }
            </>
          }
        </div>
      </div>
    </InfiniteScroll>
  );
};
export default BrandsLogos;
