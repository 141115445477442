import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import arrowOpenedGray from 'assets/icons/arrowOpenedGray.svg';
import { useStyles, useStylesAccordionChild } from '../styles';

import '../styles.scss';

const AdSetItem: React.FC<any> = ({
  adSetName,
  ads,
  adSetExpandedCount,
  setAdSetExpandedCount,
}) => {
  const classes = useStyles();
  const styles = useStylesAccordionChild();

  const expandItemHeight = (expanded: any) => {
    setAdSetExpandedCount(expanded ? adSetExpandedCount + 1 : adSetExpandedCount - 1);
  };

  return (
    <Accordion
      className={styles.root}
      key={adSetName}
      onChange={(_, expanded) => expandItemHeight(expanded)}
    >
      <AccordionSummary
        expandIcon={<img src={arrowOpenedGray} alt="arrow" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="ad-set-name-wrapper">
          <span>Ad set name </span>
          <Tooltip
            title={adSetName}
            arrow
            classes={{
              arrow: classes.arrow,
              tooltip: classes.tooltip,
            }}
            placement={'left'}
          >
            <span>
              {adSetName}
            </span>
          </Tooltip>
        </div>
      </AccordionSummary>
      {ads?.map(({ name }: any) => (
        <AccordionDetails key={name}>
          <div className="ad-name-wrapper">
            <span>Ad Name</span>
            {name?.length > 27 ? (
              <Tooltip
                title={name}
                arrow
                classes={{
                  arrow: classes.arrow,
                  tooltip: classes.tooltip,
                }}
                placement={'left'}
              >
                <span className="ad-name">
                  {name}
                </span>
              </Tooltip>
            ) : (
              <span>{name}</span>
            )}
          </div>
        </AccordionDetails>
      ))}
    </Accordion>
  );
};

export default AdSetItem;
