import React, { useRef } from 'react';

import './style.scss';

interface IInfiniteScroll {
  children: React.ReactNode;
  className?: string;
  next: () => void;
  hasMore?: boolean;
  maxHeight?: number | string;
  scroll?: boolean;
  isOverflowHide?: boolean
}

const InfiniteScroll:React.FC<IInfiniteScroll> = ({
  children,
  className,
  next,
  maxHeight,
  hasMore,
  scroll,
  isOverflowHide = false,
},
) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const onScroll =  () => {
    const list = ref.current;
    const isInTheBottom =
        list?.scrollTop &&
        Math.ceil(list?.scrollTop) >= Math.floor(list?.scrollHeight - list?.clientHeight);

    if (isInTheBottom && hasMore) {
      next();
    }
  };


  return (
    <div
      style={{
        overflow: isOverflowHide ? 'hidden' : scroll ? 'auto' : 'hidden',
        maxHeight,
        WebkitOverflowScrolling: 'touch',
        scrollbarWidth: 'thin',
        scrollbarColor: 'transparent transparent',
        msOverflowStyle: 'none',
      }}
      ref={ref}
      className={className}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};

export default React.memo(InfiniteScroll);
