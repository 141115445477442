// @ts-nocheck
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import { store } from './store';
import { AppThemeConstants } from 'constants/style.constants';

import App from './App';
import { FlagProvider } from '@unleash/proxy-client-react';
import { unleashProxyConfig } from './libs/unleashProxy.config';
import { UpdateContext } from './context/update.context';
import { ClientCommunicationContext } from './context/clientCommunication.context';
import { queryClientConfig } from './libs/reactQuery.config';
import { QueryClientProvider } from 'react-query';


const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);

  root.render(
    <FlagProvider config={unleashProxyConfig}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={AppThemeConstants}>
            <QueryClientProvider client={queryClientConfig}>
              <UpdateContext>
                <ClientCommunicationContext>
                  <App />
                </ClientCommunicationContext>
              </UpdateContext>
            </QueryClientProvider>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
    </FlagProvider>,
  );
}
