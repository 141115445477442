import { instance } from 'api/ApiRequest';
import {
  setBrandAdAccounts,
  setSearchingCampaignLoading,
  setErrorMessageCampaignIdSearch,
  setDisableEnterBtn,
  setDuplicationNamingConvention,
  setDuplicationWarnings, resetDuplicationNamingConvention,
} from './brandAdAccountsSlice';
import { serverUrls } from '../../variables';

export const getSearchedCampaigns = (campaignId?: string, signal?: any) => async (dispatch: any) => {
  try {
    if (campaignId) {
      dispatch(setDisableEnterBtn(true));
      dispatch(setSearchingCampaignLoading(true));


      const { data } = await instance.get(
        `${serverUrls.campaigns}${campaignId}/brand/ad-accounts/`,
        { signal },
      );
      dispatch(setBrandAdAccounts(data));
      dispatch(setSearchingCampaignLoading(false));
      dispatch(setDisableEnterBtn(false));
      dispatch(setErrorMessageCampaignIdSearch(''));
    }
  } catch (err:any) {
    dispatch(setDisableEnterBtn(false));
    dispatch(setSearchingCampaignLoading(false));

    // handle duplication naming convention error
    if (err.response.status === 400) {
      dispatch(setDuplicationNamingConvention(err.response.data));
      return;
    }

    const error =  err.response.data.message ?
      err.response.data.message.toLowerCase() : err.response.data.detail.toLowerCase();


    if (error.includes('dynamic')) {
      dispatch(
        setErrorMessageCampaignIdSearch(
          'The campaign has a Dynamic Creative and cannot be duplicated.',
        ),
      );
    } else if (err.response.data.detail) {
      dispatch(setErrorMessageCampaignIdSearch(err.response.data.detail));
    } else {
      dispatch(setErrorMessageCampaignIdSearch('Invalid campaign ID'));
    }
  }
};

export const getWarningsDuplication =  (franchisorId:string) => async (dispatch:any) => {
  try {
    const { data } =  await instance
      .get(`${serverUrls.reportingBrand}brands/warnings/?franchisor_id=${franchisorId}&page=duplication`);

    if (data?.warnings.length) {
      dispatch(setDuplicationWarnings(data.warnings));
    }
  } catch (error: any) {
    throw new Error(error);
  }
};


export const resyncDuplication =  (franchisorId?:string, setResyncLoaded?:any) => async (dispatch:any) => {
  try {
    const { data } = await instance
      .get(
        `${serverUrls.reportingBrand}brands/resync/?franchisor_id=${franchisorId}&page=duplication`,
      );

    if (data?.warnings.length === 0) {
      dispatch(resetDuplicationNamingConvention());
      setResyncLoaded(false);
      return;
    }

    if (data?.warnings.length) {
      dispatch(setDuplicationWarnings(data.warnings));
    }

    setResyncLoaded(false);
  } catch (error: any) {
    throw new Error(error);
  }
};


export const startCampaignDuplication =
    (campaignId: any, preparedDataForDuplication: any, cb: any) => async () => {
      try {
        await instance.post(
          `${serverUrls.campaigns}${campaignId}/data-preparation/`,
          preparedDataForDuplication,
        );
        cb();
      } catch (error: any) {
        throw new Error(error);
      }
    };
