import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useForm = ({ initialState, callback, validator }: any) => {
  const {
    authentication: { loginFailure, loggedIn },
  } = useSelector((state:any) => state);
  const [state, setState] = useState(initialState);
  const [errors, setErrors] = useState<any>({});
  const [isSubmited, setIsSubmited] = useState(false);

  useEffect(() => {
    if (!loginFailure && !loggedIn) {
      setErrors({});
    }
    // eslint-disable-next-line
    }, [loginFailure]);

  useEffect(() => {
    const isValidErrors = () =>
      Object.values(errors).filter(error => typeof error !== "undefined")
        .length > 0;
    if (isSubmited && !isValidErrors()) {
      callback();
    }
    // eslint-disable-next-line
    }, [errors]);

  const handleInputChange = (event:any) => {
    const { name, value } = event?.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleNavBarLinkClick = () => {
    setState(() => ({
      ...state,
      email: "test",
    }));
  };

  const handleBlur = (event:any) => {
    const { name: fieldName } = event?.target;
    const failedFields = validator(state, fieldName);

    setErrors(() => ({
      ...errors,
      [fieldName]: Object.values(failedFields)[0],
    }));
  };

  const handleSubmit = (event:any) => {
    event.preventDefault();
    const { name: fieldName } = event?.target;
    const failedFields = validator(state, fieldName);
    setErrors(() => ({
      ...errors,
      email: Object.values(failedFields)[0],
    }));
    setIsSubmited(true);
  };

  return {
    handleInputChange,
    handleSubmit,
    handleBlur,
    handleNavBarLinkClick,
    state,
    errors,
  };
};

export default useForm;
