import React, { useState } from 'react';
import { Button } from '../../../shared/components/general/Button/Button';
import { ButtonVariants } from '../../../shared/components/general/Button/Button.types';
import Flex from '../../../shared/components/general/Flex/Flex';
import { useMutation } from 'react-query';
import { skipEmail } from '../../../../services/clientCommunication';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';
import { useStyles } from './SkippingReasonModal.styles';

const SkippingReasonModal = ({ onClose, emailId }: any) => {
  const styles = useStyles();
  const [reasonText, setReasonText] = useState<string>('');
  const { setEmailStatus, setUpdatedStatus } = useClientCommunicationContext();

  const { mutate: mutateSkipEmail, isLoading } = useMutation(
    () => skipEmail(emailId, { reason: reasonText }),
    {
      onSuccess: (response) => {
        setUpdatedStatus(response.data.status);
        setEmailStatus({
          [emailId]: 'skip',
        });
        onClose();
      },
    },
  );

  const handleChange = (event: any) => {
    setReasonText(event.target.value);
  };

  return (
    <div className={styles.contentWrapper}>
      <h2 className={styles.title}>Please provide a reason for skipping this email</h2>
      <textarea
        value={reasonText}
        className={styles.reasontext}
        placeholder="Reason"
        onChange={event => handleChange(event)}
        maxLength={100}
        minLength={2}
      />
      <Flex
        justifyContent="flex-end"
        style={{ gap: '16px', width: '100%', marginTop: '32px' }}
      >
        <Button variant={ButtonVariants.Secondary} handleClick={onClose}>
                    Cancel
        </Button>
        <Button
          handleClick={() => mutateSkipEmail()}
          disabled={!reasonText || reasonText.length < 2}
          style={{
            textAlign: 'left',
            width: '107px',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {isLoading ? (
            <Spinner
              white={true}
              style={{
                width: '16px',
                margin: '0',
                left: '50%',
                position: 'initial',
              }}
            />
          ) : (
            <span>Save </span>
          )}
        </Button>
      </Flex>
    </div>
  );
};
export default SkippingReasonModal;
