import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import ROUTES from "constants/routes";
import { LocaleStorageTokenList, LocalStorage } from 'helpers/Storage';
import SignIn from "modules/Authentication/SignIn/SignIn";
import RequestResetPassword from "modules/Authentication/RequestResetPassword/RequestResetPassword";
import ResetPassword from "../modules/Authentication/ResetPassword/ResetPassword";
import BrandAdAccounts from "modules/BrandAdAccounts/pages/BrandAdAccounts";
import SignUp from "modules/Authentication/SignUp/SignUp";
import jwt_decode from "jwt-decode";
import RequestDuplicationPreview from "modules/RequestDuplicationPreview/pages/RequestDuplicationPreview";
import AllDuplications from "modules/AllDuplications/pages/AllDuplications";
import InvitationSignUp from "modules/InvitationSignUp/pages/InvitationSignUp/InvitationSignUp";
import BrandsLogos from "modules/BrandsReporting/pages/BrandsLogos/BrandsLogos";
import BrandReportTypes from "modules/BrandsReporting/pages/BrandReportTypes";
import TeamMemberList from "modules/TeamManagement/pages/TeamMemberList/TeamMemberList";
import PageNotFound from "modules/PageNotFound/PageNotFound";
import { useEffect, useState } from "react";
import Flex from "../modules/shared/components/general/Flex/Flex";
import Spinner from "../modules/shared/components/general/Spinner/Spinner";
import { PERMISSIONS } from "../constants/permissions";
import { variables } from "../variables";
import ClientCommunication from "../modules/ClientCommunication/pages/ClientCommunication";
import UpdateAirtable from "../modules/UpdateAirtable/pages/UpdateAirtable";
import PrivateLayout from "../layout/PrivateLayout ";
import SuggestionWrapper from '../modules/Suggestions/pages/SuggestionWrapper';
import ServerError from '../modules/ServerError/ServerError';


const { REACT_APP_GOOGLE_CLIENT_ID } =
    variables;


const useRoutesData = () => {
  const { role, permissions = [] } =
    LocalStorage.get(LocaleStorageTokenList.access_token) &&
    jwt_decode<any>(LocalStorage.get(LocaleStorageTokenList.access_token));

  const [isLoading, setIsLoading] = useState(false);
  const {
    authentication: { redirectionUrl },
  } = useSelector((state:any) => state);

  useEffect(() => {
    if (!role) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line
    }, [role, permissions, LocalStorage.get(LocaleStorageTokenList.access_token)]);


  const generateRouteElement = (route: any) => {
    switch (route) {
      case ROUTES.DUPLICATION:
        return <BrandAdAccounts/>;
      case ROUTES.ALL_DUPLICATIONS:
        return <AllDuplications/>;
      case ROUTES.REPORTING_UPDATE:
        return <UpdateAirtable/>;
      case ROUTES.REPORTING:
        return <BrandsLogos/>;
      case ROUTES.BRAND_REPORTS:
        return <BrandReportTypes/>;
      case ROUTES.TEAM_MANAGEMENT:
        return <TeamMemberList/>;
      case ROUTES.SUGGESTIONS:
        return <SuggestionWrapper/>;
      case ROUTES.CLIENT_COMUNICATION:
        return <ClientCommunication/>;
      default:
        break;
    }
  };

  const generateRoutesData = (route:any, permission:any) => {
    if (role === "admin" || permissions.includes(permission)) {
      return generateRouteElement(route);
    } else if (isLoading) {
      return (
        <Flex justifyContent="center">
          <Spinner/>
        </Flex>
      );
    } else {
      return <PageNotFound/>;
    }
  };

  return [
    {
      path: ROUTES.SIGNIN,
      element: LocalStorage.get(LocaleStorageTokenList.access_token) ? (
        <Navigate to={redirectionUrl}/>
      ) : (
        <SignIn/>
      ),
    },
    {
      path: ROUTES.RESET_PASSWORD,
      element: <ResetPassword/>,
    },
    {
      path: ROUTES.SIGNUP,
      element: !LocalStorage.get(LocaleStorageTokenList.access_token) ? (
        <SignUp/>
      ) : (
        <Navigate to={ROUTES.SIGNIN}/>
      ),
    },
    {
      path: ROUTES.REQUEST_RESET_PASSWORD,
      element: <RequestResetPassword/>,
    },
    {
      path: ROUTES.RESET_PASSWORD_CONFIRM,
      element: <ResetPassword/>,
    },
    {
      path: ROUTES.DUPLICATION,
      element: (
        <PrivateLayout>
          {generateRoutesData(ROUTES.DUPLICATION, PERMISSIONS.ad_management)}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.DUPLICATION_REQUEST,
      element: (
        <PrivateLayout>
          <RequestDuplicationPreview/>
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.ALL_DUPLICATIONS,
      element: (
        <PrivateLayout>
          {generateRoutesData(
            ROUTES.ALL_DUPLICATIONS,
            PERMISSIONS.ad_management,
          )}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.REPORTING_UPDATE,
      element: (
        <PrivateLayout>
          {generateRoutesData(
            ROUTES.REPORTING_UPDATE,
            PERMISSIONS.ad_management,
          )}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.INVITATION,
      element: <InvitationSignUp/>,
    },
    {
      path: ROUTES.REPORTING,
      element: (
        <PrivateLayout>
          {generateRoutesData(ROUTES.REPORTING, PERMISSIONS.reporting)}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.BRAND_REPORTS,
      element: (
        <PrivateLayout>
          {generateRoutesData(ROUTES.BRAND_REPORTS, PERMISSIONS.reporting)}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.TEAM_MANAGEMENT,
      element: (
        <PrivateLayout>
          {generateRoutesData(
            ROUTES.TEAM_MANAGEMENT,
            PERMISSIONS.TEAM_MANAGEMENT,
          )}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.SUGGESTIONS,
      element: (
        <PrivateLayout>
          {generateRoutesData(ROUTES.SUGGESTIONS, PERMISSIONS.ad_management)}
        </PrivateLayout>
      ),
    },
    {
      path: ROUTES.CLIENT_COMUNICATION,
      element: (
        <GoogleOAuthProvider
          clientId={REACT_APP_GOOGLE_CLIENT_ID}
        >
          <PrivateLayout>
            {generateRoutesData(ROUTES.CLIENT_COMUNICATION, PERMISSIONS.fsm)}
          </PrivateLayout>
        </GoogleOAuthProvider>
      ),
    },
    {
      path: "/server_error",
      element:
          <PrivateLayout>
            <ServerError/>
          </PrivateLayout>,
    },
    {
      path: "*",
      element: (
        <PrivateLayout>
          <PageNotFound/>
        </PrivateLayout>
      ),
    },
  ];
};

export default useRoutesData;
