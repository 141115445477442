import React from "react";
import csvFileIconBlue from "assets/icons/csvFileIconBlue.svg";
import inProgressIcon from "assets/icons/inProgressIcon.svg";

const InProgressReport:React.FC<any> = ({ row, generateReportNameTooltip }) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell:any) => {
      if (cell.column.id === 'reportName') {
        return (
          <td
            {...cell.getCellProps()}
            style={{ display: "flex", gap: "16px" }}
          >
            <img src={csvFileIconBlue} alt="file" />
            {generateReportNameTooltip(cell)}
          </td>
        );
      }
      if (cell.column.id === 'state') {
        return (
          <td {...cell.getCellProps()}>
            <div style={{ display: "flex", gap: "8px" }}>
              {cell.render("Cell")}
              <img src={inProgressIcon} alt="in progress" height="24px" />
            </div>
          </td>
        );
      }
      return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
    })}
  </tr>
);

export default InProgressReport;
