import { ImageLoader } from '../shared/components/general/ImageLoader/ImageLoader';
import serverError from "../../assets/icons/serverError.svg";
import Flex from "../../modules/shared/components/general/Flex/Flex";

const ServerError = () => (
  <Flex
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ width: "100%", height: "95vh" }}
  >
    <ImageLoader
      src={serverError}
      alt="page not found"
      style={{ height: "500px", width: "500px" }}
    />
    <h1
      style={{
        fontWeight: "600",
        fontSize: "32px",
        lineHeight: "48px",
        marginTop: "38px",
        color:'#0F314D',
      }}
    >
        Something went wrong.
    </h1>
    <p
      style={{
        fontWeight: "400",
        fontSize: "18px",
        color: "#444",
        marginTop: "8px",
        marginBottom: 0,
        maxWidth: '600px',
        textAlign: "center",
      }}
    >
        Although we are monitoring these errors, please don't hesitate to reach out to us if refreshing the page doesn't resolve the issue.
    </p>
  </Flex>
);

export default ServerError;
