import React from 'react';

interface ICustomTooltipForReports {
    value: string;
    isOneRow?: boolean;
}

const CustomTooltipForReports: React.FC<ICustomTooltipForReports> = ({ value, isOneRow }) => (
  <div
    className="custom-tooltip-container"
  >
    <span
      className="custom-tooltip-text"
      style={{ maxWidth: '250px', width: '100%' }}
    >
      {value}
    </span>
    <span style={isOneRow ? { bottom: 0 } : {}}
      className="custom-tooltip">{value}</span>
  </div>
);

export default CustomTooltipForReports;
