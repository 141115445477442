import { setConfirmedDuplication } from './requestedDuplicationsSlice';
import { serverUrls } from '../../variables';
import { instance } from 'api/ApiRequest';

export const duplicationRequest =  (url: any, cb: any) => async () => {
  try {
    const response = await instance.get(url);
    cb(response);
  } catch (error: any) {
    throw new Error(error);
  }
};

export const confirmDuplication =
    (requestId: any, payload: any, cb: any) => async (dispatch: any) => {
      try {
        const response = await instance.post(
          `${serverUrls.campaigns}duplication-request/${requestId}/confirm/`,
          payload,
        );
        cb();
        dispatch(setConfirmedDuplication(response));
      } catch (error: any) {
        throw new Error(error);
      }
    };
