import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCampaignIdSearchedValue,
  setErrorMessageCampaignIdSearch,
} from '../../store/brandAdAccounts/brandAdAccountsSlice';
import BurgerIcon from "assets/icons/burgerIcon.svg";
import logoHorizontal from "assets/icons/logoHorizontal.svg";
import SideBar from "../../modules/shared/components/general/SideBar/SideBar";
import { setIsHoveringLead } from '../../store/suggestions/suggestionSlice';

import "./styles.scss";

interface IHeaderNavbarContent {
    children: React.ReactNode
}

const HeaderNavbarContent: React.FC<IHeaderNavbarContent> = ({ children }) => {
  const sideBarRef = useRef<HTMLElement | null>(null);
  const burgerIconRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [sideNavBarWidth, setSideNavBarWidth] = useState<string>("");

  const {
    authentication: { redirectionUrl },
  } = useSelector((state:any) => state);

  const {  suggestion: { isEscalateModalOpen } } = useSelector((state:any) => state);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        burgerIconRef.current &&
                !burgerIconRef.current.contains(event.target as Node) &&
                sideBarRef.current &&
                !sideBarRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sideBarRef, burgerIconRef]);


  useEffect(() => {
    setSideNavBarWidth("72px");
  }, []);

  useEffect(() => {
    setSideNavBarWidth(open ? "271px" : "72px");
  }, [open]);

  return (
    <div
      className="header-navbar-wrapper"
      onMouseOver={() => {
        isEscalateModalOpen && setIsHoveringLead(() => true);
      }}
    >
      <header>
        <div
          className="burger-item"
          onClick={() => setOpen(!open)}
          ref={burgerIconRef}
        >
          <img src={BurgerIcon} width="20px" height="14px" alt="burger-menu"/>
        </div>
        <div
          onClick={() => {
            navigate(redirectionUrl);
            dispatch(setCampaignIdSearchedValue(""));
            dispatch(setErrorMessageCampaignIdSearch(""));
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={logoHorizontal} alt="logo-horizontal"/>
        </div>
      </header>
      <div className="children-wrapper">
        <div style={{ display: "flex", width: "100%", height: "inherit" }}>
          <div
            id="sidebar-wrapper"
            style={{
              width: "72px",
            }}
          >
            <SideBar
              open={open}
              sideNavBarWidth={sideNavBarWidth}
              innerRef={sideBarRef}
            />
          </div>
          <div id="content-wrapper">
            <div className="container-wrapper">
              <div className="container">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavbarContent;
