export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.6574 17.657L6.34367 6.34334"
      stroke="#B7B7B7"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M17.6563 6.34334L6.34262 17.657"
      stroke="#B7B7B7"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
);
