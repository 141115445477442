import { createUseStyles } from "react-jss";

export const dateRangeSelectUseStyles = createUseStyles(() => ({
  selectWrapper: {
    position: "relative",
    zIndex: "10",

    "&>div>.react-datepicker": {
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      position: "absolute",
      top: "58px",
      left: "0",
      zIndex: "999",
      width: "100%",
      border: "1px solid rgb(217, 217, 217)",
      "&>.react-datepicker__month-container": {
        width: "100%",
        "&>.react-datepicker__header": {
          background: "#F2F2F2",
        },
        "& .react-datepicker__day-names": {
          "& .react-datepicker__day-name": {
            width: "calc((100% - 38px) / 7)",
          },
        },
        "&>.react-datepicker__month": {
          "& .react-datepicker__day": {
            width: "calc((100% - 38px) / 7)",
          },
          "& .react-datepicker__day--in-range": {
            backgroundColor: "#0F314D",
          },
          "& .react-datepicker__day--keyboard-selected": {
            backgroundColor: "#0F314D",
            color: "#fff",
          },
        },
      },
    },
    "&>input": {
      width: "304px",
      height: "42px",
      padding: "8px 16px 8px 44px",
      border: `1px solid #D9D9D9`,
      borderRadius: "8px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#444",
      margin: "8px 0 0",
      zIndex: "9",
      boxSizing: "border-box",
      "&:hover": {
        boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      },
      "&:focus": {
        border: "none",
      },
    },
  },
  arrowIcon: {
    position: "absolute",
    top: "16px",
    right: "16px",
  },
  dateRangeIcon: {
    position: "absolute",
    top: "19px",
    left: "16px",
  },
}));
