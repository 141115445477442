import { createUseStyles } from "react-jss";
import { AppTheme } from "../../../../types/styles.types";

interface Props {
  isEmpty: boolean;
}

export const useStyles = createUseStyles((theme: AppTheme) => ({
  signatureWrapper: {
    border: (props: Props) =>
      (props.isEmpty ? "1px solid #0F314D" : "1px solid #D9D9D9"),
    marginTop: "16px",
    padding: "16px",
    borderRadius: "8px",
    minHeight: "195px",
    position: "relative",
    backgroundColor: "#F9F9F9",
    maxWidth: "1006px",
    overflowX: "hidden",
  },
  emptyStateTitle: {
    color: theme.deepBlueColor,
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "36px",
    margin: "8px 0 4px 0",
  },
  description: {
    color: theme.deepBlueColor,
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: 0,
  },
  emptyStateWrapper: {
    margin: "auto",
    textAlign: "center",
  },
  signaturehtml: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  tooltip: {
    width: "304px",
    display: "none",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    background: "#ffffff",
    borderRadius: "8px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    padding: "12px 16px",
    boxShadow: "0px 4px 20px rgba(95, 95, 95, 0.15)",
    color: "#444",
    right: "71px",
    "&:before": {
      content: "''",
      position: "absolute",
      left: "100%",
      top: "50%",
      transform: "translate(-50%, -50%) rotate(45deg)",
      width: "18px",
      height: "18px",
      backgroundColor: "#ffffff",
      borderRadius: "1px",
    },
  },
  tooltipWrapper: {
    position: "absolute",
    right: "11px",
    top: "9px",
    "&:hover $tooltip": {
      display: "block",
    },
  },
  retryWrapper: {
    cursor: "pointer",
    padding: "14px",
    background: "#fff",
    filter: "drop-shadow(0px 0px 9px rgba(169, 169, 169, 0.25))",
    borderRadius: "100%",
  },
}));
