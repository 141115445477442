export const ArrowDownIcon = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.07595 0.914551L4.09145 4.63355C3.82595 4.97755 3.38845 4.97755 3.12245 4.63355L0.137946 0.914551C-0.127554 0.570551 -0.002554 0.289551 0.450446 0.289551H6.76295C7.21595 0.289551 7.34145 0.571051 7.07595 0.914551Z"
      fill="#444444"
    />
  </svg>
);
