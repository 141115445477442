import { CSSProperties } from "react";
import errorIcon from "assets/icons/errorIcon.svg";
import "./styles.scss";

const ErrorMessage = ({
  text,
  width,
  margin,
  position,
  style,
  iconStyle = { width: "15px", height: "15px" },
}: {
  text: string;
  width?: any;
  margin?: any;
  position?: any;
  style?: CSSProperties;
  iconStyle?: CSSProperties;
}) => (
  <span
    className="error-message"
    style={{
      margin,
      width,
      position,
      ...style,
    }}
  >
    <img style={iconStyle} src={errorIcon} alt="info" />
    {text}
  </span>
);

export default ErrorMessage;
