import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderRadius: 2,
  border: "1px dashed rgba(68, 68, 68, 0.25)",
  backgroundColor: "#fafafa",
  outline: "none",
  transition: "border .24s ease-in-out",
};

export const focusedStyle = {
  borderColor: '#0F314C',
};

export const acceptStyle = {
  borderColor: "#0F314C",
  backgroundColor: 'rgba(101, 123, 141, 0.20)',
};

export const rejectStyle = {
  borderColor: "#B3261E",
};

export const dropBoxUseStyles = createUseStyles((theme: AppTheme) => ({
  description: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.textColor,
    marginLeft: "11px",
    "& > u": {
      color: theme.primaryColor,
    },
  },
  validationText: {
    marginTop: '4px',
    fontSize:' 14px',
    fontWeight: '400',
    color: '#959595',
    cursor: 'pointer',
  },
  closeIconWrapper: {
    marginLeft: "32px",
    height: "24px",
    cursor: "pointer",
  },
  spinnerAndCloseWrapper: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    right: "30px",
  },
  fileWrapper: {
    position: "relative",
    borderRadius: "8px",
    backgroundColor: theme.whiteColor,
    padding: "12px 30px",
  },
  fileNameWrapper: {
    marginLeft: "14px",
  },
  fileName: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.textColor,
    width: "380px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileSize: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#C0C0C0",
    marginTop: "4px",
  },
  label: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.textColor,
    "& > i": {
      color: theme.grey4,
    },
  },
}));
