import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  facebookLocationsData: {},
  facebookLeadGenForm: {},
  loading: false,
  isSuccess: null,
  requestSuccess: null,
  errorMessage: "",
  showUpdateTab: null,
  showPreview: null,
  showSuccess: null,
  showGeoPreview: null,
  showLeadPreview: null,
  showErrorContent: null,
  showSuccessContent: null,
  type: "",
  disableEnterBtn: false,
};

export const updateContent = createSlice({
  name: "UpdateContent",
  initialState,
  reducers: {
    setFacebookLocations: (state, action) => {
      state.facebookLocationsData = action.payload;
    },
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setRequestSuccess: (state, action) => {
      state.requestSuccess = action.payload;
    },

    setFacebookLeadGenForm: (state, action) => {
      state.facebookLeadGenForm = action.payload;
    },

    setDisableBtn: (state, action) => {
      state.disableEnterBtn = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    },
    setShowUpdateTab: (state, action) => {
      state.showUpdateTab = action.payload;
    },
    setShowPreview: (state, action) => {
      state.showPreview = action.payload;
    },
    setShowSuccess: (state, action) => {
      state.showSuccess = action.payload;
    },
    setShowGeoPreview: (state, action) => {
      state.showGeoPreview = action.payload;
    },
    setShowLeadPreview: (state, action) => {
      state.showLeadPreview = action.payload;
    },
    setShowErrorContent: (state, action) => {
      state.showErrorContent = action.payload;
    },
    setShowSuccessContent: (state, action) => {
      state.showSuccessContent = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
  },
});

export const {
  setFacebookLocations,
  setFacebookLeadGenForm,
  setDisableBtn,
  setLoading,
  setErrorMessage,
  setRequestSuccess,
  setShowUpdateTab,
  setShowPreview,
  setShowGeoPreview,
  setShowLeadPreview,
  setShowErrorContent,
  setShowSuccessContent,
  setType,
} = updateContent.actions;

export default updateContent.reducer;
