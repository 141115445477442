import deleteReport from "assets/icons/deleteReport.svg";
import Flex from "modules/shared/components/general/Flex/Flex";
import { DeleteReportUseStyles } from "./DeleteReport.styles";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import { useMutation, useQueryClient } from "react-query";
import { deleteReportData } from "services/reporting.services";
import { servicesNames } from "constants/servicesNames.constants";

const DeleteReport = ({
  id,
  handleClose,
}: {
  id: number;
  handleClose: any;
}) => {
  const styles = DeleteReportUseStyles();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(deleteReportData, {
    onSuccess: () => {
      queryClient.invalidateQueries(servicesNames.getBrandsReports);
    },
  });

  const handleDeleteClick = () => {
    mutate(id);
    handleClose();
  };

  return (
    <>
      <Flex
        direction="column"
        alignItems="center"
        className={styles.deleteReportWrapper}
      >
        <h1>
          Are you sure you want to delete <br /> the report?
        </h1>
        <ImageLoader
          src={deleteReport}
          alt="Delete Report"
          style={{ height: "300px" }}
        />
      </Flex>
      <Flex style={{ marginTop: "32px" }} justifyContent="flex-end">
        <button
          onClick={() => handleClose()}
          className={`${styles.cancelDelete} auth-button`}
          style={{
            width: "fit-content",
            margin: "0",
            background: "#fff",
            color: "#3f5a71",
            marginRight: "16px",
          }}
        >
          Cancel
        </button>
        <button
          className="auth-button"
          style={{ width: "unset", margin: "0" }}
          onClick={handleDeleteClick}
        >
          Delete
        </button>
      </Flex>
    </>
  );
};
export default DeleteReport;
