import { useStyles } from './Drawer.styles';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { useRef, useEffect } from 'react';

const Drawer = ({
  isOpen,
  onClose,
  children,
  headerContent,
  isEditModeOn,
  setIsSaveChangesOpen,
  height,
  drawerStyles,
  contentStyle,
  bodyStyle,
  headerStyle,
}:{
  isOpen:boolean,
  onClose:any,
  children:any,
  headerContent?:any,
  isEditModeOn?:boolean,
  setIsSaveChangesOpen?:any,
  height?:any,
  drawerStyles?:any
  contentStyle?:any
  bodyStyle?:any
  headerStyle?:any
}) => {
  const styles = useStyles();
  const drawerContentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (!isEditModeOn) {
        if (
          drawerContentRef.current &&
                    !event.target.closest('div[role="presentation"]') &&
                    !(drawerContentRef.current as any).contains(event.target)
        ) {
          onClose();
        }
      } else {
        if (
          drawerContentRef.current &&
                    !event.target.closest('div[role="presentation"]') &&
                    !(drawerContentRef.current as any).contains(event.target)
        ) {
          setIsSaveChangesOpen(true);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

    // eslint-disable-next-line
    }, [drawerContentRef, isEditModeOn]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    }
    return () => {
      document.body.style.overflowY = 'auto';
    };
  }, [isOpen]);

  return (
    <div className={`${styles.drawer} ${isOpen ? styles.open : ''}`} style={drawerStyles}>
      <div
        ref={drawerContentRef}
        className={`${styles.drawerContent} 
        ${isOpen ? styles.openContent : ''}`}
        onClick={event => event.stopPropagation()}
        style={contentStyle}
      >
        <Flex className={styles.drawerHeader} alignItems="center" style={headerStyle}>
          {headerContent}
        </Flex>
        <div className={styles.drawerBody} style={{ height,...bodyStyle }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Drawer;
