import React, { useEffect, useRef, CSSProperties } from 'react';
import close from 'assets/icons/close.svg';
import './styles.scss';
import Flex from '../Flex/Flex';
import Spinner from '../Spinner/Spinner';

const BasicModal = ({
  open,
  handleClose,
  children,
  style,
  onMouseLeave,
  basicModalStyle,
  spinerStyle,
  loading,
  isClickOutside = false,
}: {
  open: any;
  handleClose: () => void;
  children: any;
  style?: CSSProperties;
  basicModalStyle?: CSSProperties;
  onMouseLeave?: () => void;
  spinerStyle?: CSSProperties;
  loading?:boolean
  isClickOutside?: boolean;
}) => {
  const modalRef = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        modalRef.current &&
                !event.target.closest('div[role="presentation"]') &&
                !modalRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    if  (!isClickOutside) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

    // eslint-disable-next-line
    }, [modalRef]);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    }
  }, [open]);

  return (
    open && (
      <div className="basic-modal" onMouseLeave={onMouseLeave} style={basicModalStyle}>
        <div className={'modal-content'} ref={modalRef} style={style}>
          {
            loading &&
              <Flex justifyContent="center" alignItems="center" style={spinerStyle} >
                <Spinner style={{ margin: '40px' }} />
              </Flex>
          }
          <div onClick={handleClose} className="close" style={{ color: 'black' }}>
            <img src={close} alt="close" />
          </div>
          {children}
        </div>
      </div>
    )
  );
};

export default BasicModal;
