import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import { RegexConstants } from 'constants/regex.constants';
import { errorTexts } from 'constants/errorTexts';
import { facebookLocations, facebookLeadGenForms } from '../../../../store/updateContent/actions';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import { useUpdatePreview } from 'context/update.context';
import { IRootStore } from 'types/store.types';
import { AppDispatch } from 'store';

const UpdateContentBody = ({ 
  setAcitveTab, 
  activeTab, 
  handleClose,
} : {
  setAcitveTab:(a:string)=>void, 
  activeTab:string, 
  handleClose:()=>void
 }) => {
  const dipsatch = useDispatch<AppDispatch>();
  const {
    updateContent: { loading },
  } = useSelector((state:IRootStore) => state);

  const { recordId, setRecordId, adSetId, setAdSetId, leadRecordId, setLeadRecordId } =
        useUpdatePreview();

  const checkOnlyNumbers = RegexConstants.onlyNumbers.test(adSetId);
  const validateRecordId =
        recordId.startsWith('rec') &&
        RegexConstants.numbersAndLetters.test(recordId) &&
        recordId.length === 17;

  const validateLeadRecordId =
        leadRecordId.startsWith('rec') &&
        RegexConstants.numbersAndLetters.test(leadRecordId) &&
        leadRecordId.length === 17;

  const disableContinue =
        activeTab === 'geo'
          ? validateRecordId && checkOnlyNumbers && adSetId
          : validateLeadRecordId;

  const handleContinue = () => {
    activeTab === 'geo'
      ? dipsatch(facebookLocations({ ad_set_id: adSetId, record_id: recordId }))
      : dipsatch(facebookLeadGenForms({ record_id: leadRecordId }));
  };

  return (
    <>
      <h1 className="modal-title">Update Airtable</h1>
      <div className="update-tabs">
        <div
          className={`geo-tab ${activeTab === 'geo' ? 'acitve-tab' : ''} update-type`}
          onClick={() => setAcitveTab('geo')}
        >
                    Geo
        </div>
        <div
          className={`lead-tab ${activeTab === 'lead' ? 'acitve-tab' : ''} update-type`}
          onClick={() => setAcitveTab('lead')}
        >
                    Lead forms
        </div>
      </div>
      {activeTab === 'geo' ? (
        <div className="geo-content">
          <div
            style={{
              marginRight: '32px',
            }}
          >
            <label className="field-name">Record id</label>
            <input
              placeholder="Paste record id"
              value={recordId}
              onChange={event => setRecordId(event.target.value)}
              style={{
                border: recordId && !validateRecordId && '1px solid #B3261E',
              }}
            />
            {recordId && !validateRecordId && (
              <ErrorMessage
                text={errorTexts.recordId}
                margin="8px 4px"
                position="absolute"
              />
            )}
          </div>
          <div>
            <label className="field-name">Ad set id</label>
            <input
              placeholder="Paste ad set id"
              value={adSetId}
              onChange={event => setAdSetId(event.target.value)}
              style={{ border: !checkOnlyNumbers ? '1px solid #B3261E' : "default" }}
            />
            {!checkOnlyNumbers && (
              <ErrorMessage
                text={errorTexts.onlyNumbers}
                margin="8px 4px"
                position="absolute"
              />
            )}
          </div>
        </div>
      ) : (
        <div className="lead-content">
          <div
            style={{
              width: '100%',
            }}
          >
            <label className="field-name">Record id</label>
            <input
              placeholder="Paste record id"
              value={leadRecordId}
              onChange={event => setLeadRecordId(event.target.value)}
              style={{
                width: '100%',
                border: leadRecordId && !validateLeadRecordId && '1px solid #B3261E',
              }}
            />
            {leadRecordId && !validateLeadRecordId && (
              <ErrorMessage
                text={errorTexts.recordId}
                margin="8px 4px"
                position="absolute"
              />
            )}
          </div>
        </div>
      )}
      <div className="modal-footer">
        <button className="auth-button" onClick={handleClose}>
                    Cancel
        </button>
        <button
          className="auth-button"
          disabled={!disableContinue}
          onClick={!loading ? handleContinue : undefined}
          style={{
            position: 'relative',
            width: '112px',
            justifyContent: 'center',
          }}
        >
          {loading ? (
            <Spinner
              white={true}
              style={{
                width: '16px',
                margin: '0',
                left: '50%',
                position: 'initial',
              }}
            />
          ) : (
            'Continue'
          )}
        </button>
      </div>
    </>
  );
};

export default UpdateContentBody;
