import { createUseStyles } from "react-jss";
import { AppThemeConstants } from "constants/style.constants";
import { AppTheme } from "../../../../types/styles.types";

interface FiltersProps {
  selectedStatuses: string[]
}

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "275px",
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      marginTop: "8px",
      marginLeft: 0,
      borderRadius: "8px",
      padding: "8px",
      border: `1px solid ${AppThemeConstants.grey1}`,
    },
  },
};

export const useStylesFilters: any = createUseStyles((theme: AppTheme) => ({
  renderValue: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
  },
  filterValue: {
    color: theme.textColor,
  },
  selectedOption: {
    padding: "4px 8px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    borderRadius: "15px",
    backgroundColor: ({ selectedStatuses,
    }: FiltersProps) => (selectedStatuses[0] === "sent"
      ? "rgba(51, 133, 13, 0.15)"
      : selectedStatuses[0] === "skipped"
        ? "rgba(179, 38, 30, 0.15)"
        : "#EAEAEA"),
    color: ({
      selectedStatuses,
    }: FiltersProps) => (selectedStatuses[0] === "sent"
      ? "#33850D"
      : selectedStatuses[0] === "skipped"
        ? "#B3261E"
        : "#444"),
  },
  searchInputWrapper: {
    width: "304px",
    position: "relative",
    margin: "8px 24px 8px 0px",
  },
  searchInput: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid transparent",
    width: "304px",
    backgroundRepeat: "no-repeat",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#444444",
    display: "block",
    padding: "8px 16px",
    backgroundPosition: "252px center",
    backgroundSize: "20px 20px",
    height: "42px",
    "&:focus": {
      border: "1px solid #D9D9D9",
    },

    "&:hover ": {
      border: "1px solid #D9D9D9",
      boxShadow: "0px 0px 4px 0px #E8E8E8",
    },

    "&:disabled": {
      border: "1px solid transparent",
      backgroundColor: "rgba(15, 49, 77, 0.04)",
      boxShadow: "none",
    },

    "&:focus-visible": {
      outline: "none",
    },

    "&::placeholder": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#b7b7b7",
    },

    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute ",
    right: "8px",
    top: "12%",
    padding: "8px",
    cursor: "pointer",
    borderRadius: "100%",
    width: "32px",
    height: "32px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
}));
