

import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStyles = createUseStyles((theme: AppTheme) => ({
  warningCarsWrap:{
    borderTop: '4px solid #F69C2C',
    borderRadius: '8px',
    background: '#E4E4E4',
  },
  warningCarsWrapOpc:{
    filter: 'opacity(0.5)',
    userSelect: 'none',
    pointerEvents: 'none',
  },
  warningTextWrap:{
    marginTop: '16px',
    padding: '0 16px',
    marginBottom: '16px',
  },
  reSyncText:{
    textDecoration:'underline',
    cursor:'pointer',
    color:'#0F314D',
  },
  warningText:{
    color: '#444',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    background: '#DCDEE0',
    width: '100%',
    padding: '12px 16px',
  },
  warningItem:{

    '& > strong' :{
      color: 'rgba(68, 68, 68, 0.77)',
      fontSize: '16px',
      fontWeight: '700',
    },

    '& > p' :{
      color: '#6E6E6E',
      fontSize: '16px',
      fontWeight: '400',
      maxWidth: '550px',
    },
  },
}));
