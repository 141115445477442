import { createUseStyles } from "react-jss";

export const tooltipUseStyles = createUseStyles(() => ({
  tooltipContainer: {
    position: "relative",
    "&:hover $tooltip": {
      display: "block",
    },
  },
  tooltipName: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "350px",
    width: "100%",
  },
  tooltip: {
    position: "absolute",
    left: "214px",
    top: "35px",
    width: "max-content",
    background: "#ffffff",
    boxShadow: "0px 4px 94px rgba(95, 95, 95, 0.15)",
    borderRadius: "8px",
    wordWrap: "break-word",
    transform: "translateX(-50%)",
    display: "none",
    zIndex: "999",
    padding: "16px !important",
    fontWeight: "400",
    fontSize: "18px",
    "&:before": {
      content: "''",
      position: "absolute",
      left: "40px",
      top: "-9px",
      transform: "translateX(-50%) rotate(45deg)",
      width: "18px",
      height: "18px",
      borderRadius: "1px",
    },
  },
}));
