import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Checkbox  from 'modules/shared/components/general/Checkbox/Checkbox';
import { Box, MenuItem, Stack, Tooltip } from '@mui/material';
import Select from 'modules/shared/components/general/Select/Select';
import DisabledBrandAdItem from './DisabledBrandAdItem';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { CampaignInfoIcon } from 'modules/shared/components/general/Icons/CampaignInfo.icon';
import CustomTooltipForDuplication from 'modules/shared/components/general/CustomTooltip/CustomTooltipForDuplication';
import { brandAdAccountUseStyles } from './styles';
import check2 from 'assets/icons/check2.svg';
import { RegexConstants } from '../../../../constants/regex.constants';
import { checkTooltipWdh, PerformanceIndicatorList } from '../constants';

import './styles.scss';
import { setCheckedBrandAdAccounts } from '../../../../store/brandAdAccounts/brandAdAccountsSlice';


const BrandAdAccount: React.FC<any> = ({
  campaign,
  hasBorder,
  checkedCampaigns,
  handleCheckboxChange,
  lastAddedId,
  setLastAddedIdd,
  handleApplyAllSelected,
}) => {
  const dispatch = useDispatch();
  const styles = brandAdAccountUseStyles();
  const [leadId, setLeadId] = useState('');

  const {
    brandAdAccounts: {
      checkedBrandAdAccounts,
      selectedItemsLength,
      objectiveIsLead,
    },
  }: any = useSelector((state:any) => state);


  const handleChange =  (event: any) => {
    const targetValue = event.target.value;
    setLeadId(targetValue);
    handleLeadIdSelectChange(targetValue);
  };

  const {
    account_name: accountName,
    fb_account_id: fbAccountId,
    is_client_approval_required: clientApprovalRequirement,
    status,
    current_month_budget: currentMonthBudget,
    lead_gen_forms: leadGenForms,
    fb_last_3_day_performance: fbLast3Day,
    fb_last_7_day_performance: fbLast7Day,
    fb_last_30_days_performance: fbLast30Day,
  } = campaign;


  const checkRegexFn = () => {
    if (objectiveIsLead) {
      return (
        RegexConstants.Only1To100Numbers.test(
          checkedBrandAdAccounts[fbAccountId]?.percentage,
        ) && checkedBrandAdAccounts[fbAccountId]?.percentage > 24
      );
    } else {
      return RegexConstants.Only1To100Numbers.test(
        checkedBrandAdAccounts[fbAccountId]?.percentage,
      );
    }
  };
  const checkRegex = checkRegexFn();

  const handleBudgetInputChange = (event: any) => {
    setLastAddedIdd(fbAccountId);
    dispatch(setCheckedBrandAdAccounts({
      ...checkedBrandAdAccounts,
      [fbAccountId]: {
        ...checkedBrandAdAccounts[fbAccountId],
        percentage: event.target.value,
      },
    }));
  };

  const handleLeadIdSelectChange = (leadId: any) => {
    dispatch(setCheckedBrandAdAccounts({
      ...checkedBrandAdAccounts,
      [fbAccountId]: {
        ...checkedBrandAdAccounts[fbAccountId],
        lead_gen_form_id: leadId,
      },
    }));
  };

  useEffect(() => {
    if (campaign.checked) {
      setLeadId(checkedBrandAdAccounts[fbAccountId]?.lead_gen_form_id);
    }
  }, [campaign.checked, checkedBrandAdAccounts, fbAccountId]);


  const generateErrorTooltip = () => (
    <div className={styles.errorMessage}>
      {objectiveIsLead ? 'Numbers (25-100) are allowed' : 'Numbers (1-100) are allowed'}
    </div>
  );

  const generateAccountName = () => {
    if (accountName.length > 44) {
      return <CustomTooltipForDuplication value={accountName} />;
    }
    return <h3 className={styles.acountName}>{accountName}</h3>;
  };

  const generateClientApprovalRequirement = () => (
    <div className={styles.campaignInfoIcon}>
      <CampaignInfoIcon />
      <span className={styles.campaignInfo}>Needs client review</span>
    </div>
  );



  const generateBudgetAllocationFormId = () => (
    <>
      <Flex justifyContent="space-between" className={styles.campaignInfoWrapper}>
        <Flex alignItems="center" style={{ gap: '32px' }}>
          <Checkbox
            handleCheck={() => handleCheckboxChange(campaign.id, fbAccountId)}
            styles={{
              checkboxWidth: '4.67px',
              checkboxHeight: '2.67px',
              beforeWidth: '24px',
              beforeHeight: '24px',
              backgroundColor: '#fff',
              check: check2,
              top: '4px',
              left: '5px',
            }}
            checked={campaign.checked}
          />
          <div
            style={{
              fontWeight: '400',
              fontSize: '18px',
              lineHeight: '1.5',
              color: '#444444',
            }}
          >
            {generateAccountName()}
            <div className={styles.acountId}>
              <span>ID:{fbAccountId.split(':')[0]}</span>
              {clientApprovalRequirement === 'Yes' && generateClientApprovalRequirement()}
            </div>
            <Box className={styles.dateStatusWrap}>
              <Tooltip
                title={`Last 30 days: ${fbLast30Day}`}
                placement="top"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    whiteSpace: 'pre-line',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                    padding: '10px 12px',
                    borderRadius: '8px',
                    color: '#444444',
                    top: '-25px !important',
                    height: '45px',
                    left: '20px !important',
                    fontSize: '16px',
                    width: checkTooltipWdh(PerformanceIndicatorList[fbLast30Day]),
                    '&:before': {
                      content: '\'\'',
                      position: 'absolute',
                      left: '60px',
                      top: 'calc(50% - -17px)',
                      transform: 'translate(-50%) rotate(45deg)',
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#ffffff',
                      borderRadius: '1px',
                    },
                  },
                }}
                components={{ Tooltip: Stack }}
              >
                <div
                  className={styles.statusDate}
                  style={{
                    border: `1px solid var(--Status-Orange, ${fbLast30Day ?
                      PerformanceIndicatorList[fbLast30Day] : '#EAEAEA'})`,
                    color: !fbLast30Day ? '#EAEAEA' : '',
                  }}
                >
                  <span className={styles.dot}
                    style={{
                      backgroundColor: fbLast30Day ? PerformanceIndicatorList[fbLast30Day] : '#EAEAEA',
                    }} />
                  L30D
                </div>
              </Tooltip>

              <Tooltip
                title={`Last 7 days: ${fbLast7Day}`}
                placement="top"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    whiteSpace: 'pre-line',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                    padding: '10px 12px',
                    borderRadius: '8px',
                    color: '#444444',
                    top: '-25px !important',
                    height: '45px',
                    left: '20px !important',
                    fontSize: '16px',
                    width: checkTooltipWdh(PerformanceIndicatorList[fbLast7Day]),
                    '&:before': {
                      content: '\'\'',
                      position: 'absolute',
                      left: '60px',
                      top: 'calc(50% - -17px)',
                      transform: 'translate(-50%) rotate(45deg)',
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#ffffff',
                      borderRadius: '1px',
                    },
                  },
                }}
                components={{ Tooltip: Stack }}
              >
                <div
                  className={styles.statusDate}
                  style={{
                    border: `1px solid var(--Status-Orange, 
                ${fbLast7Day ? PerformanceIndicatorList[fbLast7Day] : '#EAEAEA'})`,
                    color: !fbLast7Day ? '#EAEAEA' : '',
                  }}
                ><span className={styles.dot}
                    style={{
                      backgroundColor: fbLast7Day ? PerformanceIndicatorList[fbLast7Day] : '#EAEAEA',
                    }} /> L7D
                </div>
              </Tooltip>

              <Tooltip
                title={`Last 3 days: ${fbLast3Day}`}
                placement="top"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    whiteSpace: 'pre-line',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                    padding: '10px 12px',
                    borderRadius: '8px',
                    color: '#444444',
                    top: '-25px !important',
                    height: '45px',
                    left: '20px !important',
                    fontSize: '16px',
                    width: checkTooltipWdh(PerformanceIndicatorList[fbLast3Day]),
                    '&:before': {
                      content: '\'\'',
                      position: 'absolute',
                      left: '60px',
                      top: 'calc(50% - -17px)',
                      transform: 'translate(-50%) rotate(45deg)',
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#ffffff',
                      borderRadius: '1px',
                    },
                  },
                }}
                components={{ Tooltip: Stack }}
              >
                <div
                  className={styles.statusDate}
                  style={{
                    border: `1px solid var(--Status-Orange,
                 ${fbLast3Day ? PerformanceIndicatorList[fbLast3Day] : '#EAEAEA'})`,
                    color: !fbLast3Day ? '#EAEAEA' : '',
                  }}
                ><span className={styles.dot}
                    style={{
                      backgroundColor: fbLast3Day ? PerformanceIndicatorList[fbLast3Day] : '#EAEAEA',

                    }} /> L3D
                </div>
              </Tooltip>

            </Box>
          </div>
        </Flex>
        <Flex
          style={{ gap: '24px', padding: '7px 10px' }}
          alignItems="center"
          className="form-id-item"
        >
          {objectiveIsLead && (
            <div className={styles.formIdContainer}>
              <span className={styles.budgetAllocationFormId}>Form ID</span>
              <Select
                value={leadId}
                handleChange={handleChange}
                formStyle={{ margin: '0 0 0 8px' }}
                style={{
                  margin: '0',
                  border:
                            hasBorder &&
                            campaign.checked &&
                            checkedCampaigns.includes(fbAccountId) &&
                            !leadId &&
                            '1px solid #B3261E',
                }}
                renderValue={(value: any) =>
                  (value ? (
                    <div style={{ color: '#444444' }}>{value}</div>
                  ) : (
                    'Select form name and id'
                  ))
                }
              >
                {leadGenForms?.map((optionValue: any) => (
                  <MenuItem
                    key={optionValue?.id}
                    value={optionValue?.id}
                    style={{
                      padding: '8px 10px',
                      display: 'flex',
                      marginBottom: '8px',
                      marginLeft: leadGenForms?.length > 2 ? '8px' : '0',
                    }}
                  >
                    <div
                      className="option-value-wrapper"
                      style={{
                        width: leadGenForms?.length > 2 ? '312px' : '330px',
                      }}
                    >
                      <div style={{ color: '#444444' }}>{optionValue?.name}</div>
                      <div style={{ color: '#959595' }}>
                              ID: {optionValue?.id}
                      </div>
                    </div>

                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <Flex
            alignItems="center"
            style={{
              gap: '8px',
              position: 'relative',
            }}
          >
            <span className={styles.budgetAllocationFormId}>Budget Allocation</span>
            <div className={styles.budgetAllocationPercentageWrap}>
              <div
                className={styles.budgetAllocationPercentage}
                style={{
                  border:
                          ((!checkRegex && checkedBrandAdAccounts[fbAccountId]?.percentage) ||
                              (hasBorder &&
                                  checkedCampaigns.includes(fbAccountId) &&
                                  !checkedBrandAdAccounts[fbAccountId]?.percentage &&
                                  campaign.checked)) &&
                          '1px solid #B3261E',
                }}
              >
                <input
                  name="budgetPercentage"
                  value={checkedBrandAdAccounts[fbAccountId]?.percentage || ''}
                  onChange={handleBudgetInputChange}
                />
                <span className={styles.percentage}>%</span>
              </div>
              {
                (checkRegex && selectedItemsLength > 1)
                    && lastAddedId === fbAccountId && checkedBrandAdAccounts[fbAccountId]?.checked &&
                    <span
                      className={styles.textApply}
                      onClick={() => handleApplyAllSelected(fbAccountId)}
                    >
                      Apply to selected
                    </span>
              }
            </div>

            {!checkRegex &&
                  checkedBrandAdAccounts[fbAccountId]?.percentage &&
                  generateErrorTooltip()}
          </Flex>
          <div style={{ width: '110px' }}>
            <div className={`${styles.status} ${status.replace(/\s/g, '')}`}>{status}</div>
          </div>
        </Flex>
      </Flex>
    </>
  );


  const generateContent: any = () => {
    if (
      (objectiveIsLead && leadGenForms?.length && currentMonthBudget) ||
            (!objectiveIsLead && currentMonthBudget)
    ) {
      return generateBudgetAllocationFormId();
    } else {
      return (
        <DisabledBrandAdItem
          currentMonthBudget={currentMonthBudget}
          leadGenForms={leadGenForms}
          accountName={accountName}
          status={status}
          fbAccountId={fbAccountId}
        />
      );
    }
  };

  return generateContent();
};

export default BrandAdAccount;
