import React, { useEffect, useState } from 'react';
import { useStylesFilters } from './Filters.styles';
import MultiSelect from '../../../shared/components/general/MultiSelect/MultiSelect';
import { getDates, getTypes } from '../../../../services/suggestions.services';
import { servicesNames } from '../../../../constants/servicesNames.constants';
import close from 'assets/icons/close.svg';
import search from 'assets/icons/search.svg';
import { useQuery } from 'react-query';
import SelectFilter from './SelectFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBrand,
  setDate,
  setIsSelectOpen,
  setLogicSequenceChange,
  setManager,
  setSearchValueOfCampaign,
  setType,
} from '../../../../store/suggestions/suggestionSlice';

const Filters = ({
  activeTab,
  refetch,
  isFetchingSuggestions,
  managersData,
  isFetchingManagers,
  brandsData,
  isFetchingBrands,
  refetchBrands,
  logicSequence,
  isLogicSequenceFetching,
  refetchLogicSequence,
}: {
    activeTab: string;
    refetch: () => void;
    isFetchingSuggestions: boolean;
    managersData: any
    refetchManagers: any
    isFetchingManagers: boolean
    brandsData: any
    isFetchingBrands: any
    refetchBrands: any
    logicSequence: any
    isLogicSequenceFetching: any
    refetchLogicSequence: any
}) => {
  const dispatch = useDispatch();
  const styles = useStylesFilters();
  const [inputIsActive, setInputIsActive] = useState(false);

  const selectsStyles = {
    width: '304px',
    margin: '8px 24px 8px 0',
    borderRadius: '8px',
  };

  const {
    suggestion: {
      brands,
      managers,
      managersDefaultValue,
      date,
      type,
      isSelectOpen,
      searchValueOfCampaign,
      logicSequenceData,
    },
  } = useSelector((state: any) => state);


  const { data: { data: dateData } = {}, isFetching: isFetchingDateData } = useQuery(
    [servicesNames.getDates, { managersDefaultValue, activeTab }],
    () => getDates(),
    {
      enabled:
                Boolean(managersData) &&
                (activeTab === 'changeHistory' || activeTab === 'archived'),
      refetchOnWindowFocus: false,
    },
  );

  const { data: { data: dateTypes } = {}, isFetching: isFetchingDateTypes } = useQuery(
    [servicesNames.getTypes, { managersDefaultValue, activeTab }],
    () => getTypes(),
    {
      enabled: Boolean(managersData) && activeTab === 'archived',
    },
  );

  const inputSearchStyle = {
    backgroundImage: inputIsActive || searchValueOfCampaign ? 'none' : `url(${search})`,
    padding: inputIsActive || searchValueOfCampaign ? '8px 43px 8px 16px' : '8px 50px 8px 16px',
  };

  useEffect(() => {
    !isSelectOpen && refetch();
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    refetchBrands();
    refetchLogicSequence();
    // eslint-disable-next-line
    }, []);

  return (
    <div style={{ marginBottom: '24px' }}>
      {activeTab === 'suggestions' && (
        <>
          <MultiSelect
            label="AS/AM: "
            options={managersData}
            style={selectsStyles}
            handleChange={props => dispatch(setManager(props))}
            selected={managers}
            selectedLenght={managers?.length}
            dataLength={managersData.length}
            isLoading={isFetchingManagers}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isFetchingManagers || isFetchingSuggestions}
          />
          <MultiSelect
            label="Brands: "
            options={brandsData}
            style={selectsStyles}
            handleChange={props => dispatch(setBrand(props))}
            selected={brands}
            selectedLenght={brands?.length}
            dataLength={brandsData?.length}
            isLoading={isFetchingBrands}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={Boolean(!managers?.length) || isFetchingSuggestions}
          />
          <MultiSelect
            label="Logic Sequence: "
            options={logicSequence}
            style={selectsStyles}
            handleChange={props => dispatch(setLogicSequenceChange(props))}
            selected={logicSequenceData}
            selectedLenght={logicSequenceData?.length}
            dataLength={logicSequence?.length}
            isLoading={isLogicSequenceFetching}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isLogicSequenceFetching || isFetchingSuggestions}
          />
          <div className={styles.searchInputWrapper}>
            <input
              className={styles.searchInput}
              name="campaignId"
              placeholder="Search by account name"
              value={searchValueOfCampaign}
              onChange={event => dispatch(setSearchValueOfCampaign(event.target.value))}
              style={inputSearchStyle}
              onBlur={() => {
                setInputIsActive(false);
              }}
              onFocus={() => {
                setInputIsActive(true);
              }}
              disabled={
                isFetchingDateData ||
                                isFetchingSuggestions ||
                                !managers?.length ||
                                !brands?.length
              }
            />
            {searchValueOfCampaign && (
              <div
                className={styles.closeBtn}
                onClick={() => dispatch(setSearchValueOfCampaign(''))}
              >
                <img src={close} alt="close" />
              </div>
            )}
          </div>
        </>
      )}
      {activeTab === 'changeHistory' && (
        <>
          <MultiSelect
            label="AS/AM: "
            style={selectsStyles}
            handleChange={props => dispatch(setManager(props))}
            selected={managers}
            options={managersData}
            selectedLenght={managers?.length}
            dataLength={managersData.length}
            isLoading={isFetchingManagers}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isFetchingManagers || isFetchingSuggestions}
          />

          <MultiSelect
            label="Brands: "
            style={selectsStyles}
            handleChange={props => dispatch(setBrand(props))}
            selected={brands}
            options={brandsData}
            selectedLenght={brands?.length}
            dataLength={brandsData?.length}
            isLoading={isFetchingBrands}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={Boolean(!managers.length) || isFetchingSuggestions}
          />
          <MultiSelect
            label="Logic Sequence: "
            options={logicSequence}
            style={selectsStyles}
            handleChange={props => dispatch(setLogicSequenceChange(props))}
            selected={logicSequenceData}
            selectedLenght={logicSequenceData?.length}
            dataLength={logicSequence?.length}
            isLoading={isLogicSequenceFetching}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isLogicSequenceFetching || isFetchingSuggestions}
          />
          <SelectFilter
            label="Date: "
            style={{ ...selectsStyles, marginRight: '0' }}
            optionValues={dateData?.map((element: any) => element.replaceAll('_', ' '))}
            handleChange={props => dispatch(setDate(props))}
            filterValue={date}
            disabled={
              isFetchingDateData ||
                            isFetchingSuggestions ||
                            !managers.length ||
                            !brands.length
            }
          />
          <div className={styles.searchInputWrapper}>
            <input
              className={styles.searchInput}
              name="campaignId"
              placeholder="Search by account name"
              value={searchValueOfCampaign}
              onChange={event => dispatch(setSearchValueOfCampaign(event.target.value))}
              style={inputSearchStyle}
              onBlur={() => {
                setInputIsActive(false);
              }}
              onFocus={() => {
                setInputIsActive(true);
              }}
              disabled={
                isFetchingDateData ||
                                isFetchingSuggestions ||
                                !managers.length ||
                                !brands.length
              }
            />
            {searchValueOfCampaign && (
              <div
                className={styles.closeBtn}
                onClick={() => dispatch(setSearchValueOfCampaign(''))}
              >
                <img src={close} alt="close" />
              </div>
            )}
          </div>
        </>
      )}
      {activeTab === 'archived' && (
        <>
          <MultiSelect
            label="AS/AM: "
            style={selectsStyles}
            handleChange={props => dispatch(setManager(props))}
            selected={managers}
            options={managersData}
            selectedLenght={managers?.length}
            dataLength={managersData.length}
            isLoading={isFetchingManagers}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isFetchingManagers || isFetchingSuggestions}
          />
          <MultiSelect
            label="Brands: "
            style={selectsStyles}
            handleChange={props => dispatch(setBrand(props))}
            selected={brands}
            options={brandsData}
            selectedLenght={brands?.length}
            dataLength={brandsData?.length}
            isLoading={isFetchingBrands}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={Boolean(!managers.length) || isFetchingSuggestions}
          />
          <MultiSelect
            label="Logic Sequence: "
            options={logicSequence}
            style={selectsStyles}
            handleChange={props => dispatch(setLogicSequenceChange(props))}
            selected={logicSequenceData}
            selectedLenght={logicSequenceData.length}
            dataLength={logicSequence?.length}
            isLoading={isLogicSequenceFetching}
            onCloseSelect={props => dispatch(setIsSelectOpen(props))}
            refetch={refetch}
            isSelectOpen={isSelectOpen}
            disabled={isLogicSequenceFetching || isFetchingSuggestions}
          />
          <SelectFilter
            label="Type: "
            style={{ ...selectsStyles, marginRight: '0' }}
            optionValues={dateTypes?.map((element: any) => element)}
            handleChange={props => dispatch(setType(props))}
            filterValue={type}
            disabled={
              isFetchingDateTypes ||
                            isFetchingSuggestions ||
                            !managers.length ||
                            !brands.length
            }
          />
          <SelectFilter
            label="Date: "
            style={selectsStyles}
            optionValues={dateData?.map((element: any) => element.replaceAll('_', ' '))}
            handleChange={props => dispatch(setDate(props))}
            filterValue={date}
            disabled={
              isFetchingDateData ||
                            isFetchingSuggestions ||
                            !managers.length ||
                            !brands.length
            }
          />
          <div className={styles.searchInputWrapper} style={{ display: 'inline-block' }}>
            <input
              className={styles.searchInput}
              name="campaignId"
              placeholder="Search by account name"
              value={searchValueOfCampaign}
              onChange={event => dispatch(setSearchValueOfCampaign(event.target.value))}
              style={inputSearchStyle}
              onBlur={() => {
                setInputIsActive(false);
              }}
              onFocus={() => {
                setInputIsActive(true);
              }}
              disabled={
                isFetchingDateData ||
                                isFetchingSuggestions ||
                                !managers.length ||
                                !brands.length
              }
            />
            {searchValueOfCampaign && (
              <div
                className={styles.closeBtn}
                onClick={() => dispatch(setSearchValueOfCampaign(''))}
              >
                <img src={close} alt="close" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Filters;
