import React, { CSSProperties, FC } from "react";

interface IFlexProps {
    children?: React.ReactNode;
    direction?: "row" | "column";
    alignItems?:
        | "stretch"
        | "center"
        | "flex-start"
        | "flex-end"
        | "baseline"
        | "initial"
        | "inherit";
    justifyContent?:
        | "center"
        | "flex-start"
        | "space-between"
        | "flex-end"
        | "space-around";
    flexWrap?: "nowrap" | "wrap";
    refValue?: any;
    style?: CSSProperties;
    className?: string;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    gap?: string;
}

const Flex: FC<IFlexProps> = ({
  children,
  direction = "row",
  justifyContent = "flex-start",
  alignItems = "flex-start",
  flexWrap = "nowrap",
  style = {},
  className = "",
  refValue,
  onMouseEnter,
  onMouseLeave,
  onClick,
  gap,
}) => (
  <div
    ref={refValue}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    className={className}
    style={{
      display: "flex",
      flexDirection: direction,
      alignItems,
      justifyContent,
      flexWrap,
      gap,
      ...style,
    }}
  >
    {children}
  </div>
);

export default Flex;
