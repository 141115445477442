import { createUseStyles } from "react-jss";

export const SearchedCampaignIdUseStyles = createUseStyles(() => ({
  campaignSearchWrapper: {
    background: "#f9f9f9",
    paddingTop: "32px",
    maxWidth: "1288px",
    width: "100%",
  },
  disabledToast:{
    marginTop: '10px',
    color:'#6E6E6E',
    fontSize: '16px',
    fontWeight: 400,
    margin:0,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  reSyncText:{
    cursor:'pointer',
    color:'#0F314D',
    display:"flex",
    alignItems: "center",
    gap:"8px",
  },

  '@global': {
    '@keyframes icon': {
      '100%': {
        MozTransform: 'rotate(360deg)',
        msTransform: 'rotate(360deg)',
        transform: 'rotate(360deg)',
      },
    },
  },
  updateIcon:{
    width: '16px',
    height: '16px',
  },
  updateIconAnim: {
    width: '16px',
    height: '16px',
    background: '#ffffff',
    borderRadius: '30px',
    textAlign: 'center',
    animation: '$icon 2s infinite',
    animationTimingFunction: 'linear',
    WebkitAnimation: '$icon 2s infinite',
    WebkitAnimationTimingFunction: 'linear',
    MozAnimation: '$icon 2s infinite',
    MozAnimationTimingFunction: 'linear',
    msAnimation: '$icon 2s infinite',
    msAnimationTimingFunction: 'linear',
  },

  inputContainer: {
    position: "relative",
    borderRadius: "4px",
    width: "692px",
  },
  toastText:{
    marginTop: '10px',
    color:'#6E6E6E',
    fontSize: '16px',
    fontWeight: 400,
    margin:0,
  },
  searchInput: {
    marginTop: "16px",
    backgroundColor: "#ffffff",
    boxShadow: "0 0px 4px #e8e8e8",
    borderRadius: "4px",
    border: "1px solid transparent",
    width: "100%",
    outline: "none",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#444444",
    display: "block",
    padding: "12px 94px 12px 16px",
    "&:focus": {
      border: "1px solid #D9D9D9",
    },

    "&:hover": {
      border: "1px solid #D9D9D9",
    },

    "&:focus-visible": {
      outline: "none",
    },

    "&::placeholder": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#b7b7b7",
    },

    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "4px",
    position: "absolute !important",
    top: "40px",
    transform: "translateY(-50%)",
    right: "60px",

    cursor: "pointer",
    borderRadius: "100%",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
  enterbtn: {
    border: "none",
    borderLeft: "1px solid #DBDBDB",
    height: "47px",
    width: "47px",
    padding: "16px",
    background: "#FFFFFF",
    position: "absolute",
    right: "1px",
    top: "17px",
    borderRadius: "0 4px 4px 0",
    cursor: "pointer",
  },
}));
