import { configureStore } from "@reduxjs/toolkit";
import authenticationSlice from "./authentication/authenticationSlice";
import userInvitationSlice from "./userInvitation/userInvitationSlice";
import passwordResetSlice from "./passwordReset/passwordResetSlice";
import brandAdAccountsSlice from "./brandAdAccounts/brandAdAccountsSlice";
import requestedDuplicationsSlice from "./requestedDuplications/requestedDuplicationsSlice";
import allDuplicationsSlice from "./allDuplications/allDuplicationsSlice";
import updateContentSlice from "./updateContent/updateContentSlice";
import aiFrontDeskSlice from "store/aiFrontDesk/aiFrontDeskSlice";
import brandsReportingSlice from "store/brandsReporting/brandsReportingSlice";
import suggestionSlice  from './suggestions/suggestionSlice';


export const store = configureStore({
  reducer: {
    authentication: authenticationSlice,
    userInvitation: userInvitationSlice,
    passwordReset: passwordResetSlice,
    brandAdAccounts: brandAdAccountsSlice,
    requestedDuplications: requestedDuplicationsSlice,
    allDuplications: allDuplicationsSlice,
    updateContent: updateContentSlice,
    aiFrontDesk:aiFrontDeskSlice,
    brandsReporting:brandsReportingSlice,
    suggestion: suggestionSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export type AppDispatch = typeof store.dispatch
