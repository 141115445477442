import React, { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import ROUTES from '../../../../constants/routes';
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import Flex from '../../../shared/components/general/Flex/Flex';
import { QueryConstants } from 'constants/queryConstants';
import { SortingIcon } from '../../../shared/components/general/Icons/Sorting.icon';
import { getUsers } from '../../../../services/users.services';
import { getPageFromUrl } from '../../../../helpers/PageFromUrl';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import SettingsAction from '../SettingsActions/SettingsActions';
import fullAccessIconBlue from '../../../../assets/icons/fullAccessIconBlue.svg';
import fullAccessIconGray from '../../../../assets/icons/fullAccessIconGray.svg';
import { memberListUsestyles } from './MemberList.styles';
import { PERMISSIONS } from '../../../../constants/permissions';
import { useTable } from 'react-table';

const columns:Array<any> = [
  {
    Header: "Name",
    accessor: "name",
    orderKey: "name",
  },
  {
    Header: "Email",
    accessor: "email",
    orderKey: "email",
  },
  {
    Header: "Role",
    accessor: "role",
    orderKey: "role",
  },
  {
    Header: "Permissions",
    accessor: "permissions",
    orderKey: "permissions",
    Cell: ({ value }:any) => (
      <Flex style={{ gap: "8px" }}>
        {value?.map((permission:any, index:any) =>
          (permission.name !== "Full access" ? (
            <div key={index} style={permission.style}>
              {permission.name}
            </div>
          ) : (
            <Flex alignItems="center" style={permission.style}>
              {permission.isActive ? (
                <img src={fullAccessIconBlue} alt="full access"/>
              ) : (
                <img src={fullAccessIconGray} alt="full access"/>
              )}
              <div style={{ marginLeft: "4px" }}>{permission.name}</div>
            </Flex>
          )),
        )}
      </Flex>
    ),
  },
];

const permissionsStyles = (permission: any, isActive: any) => {
  const generalStyle = {
    fontWeight: "400",
    fontSize: "14px",
    padding: "3px 7px",
    lineHeight: "1.5",
    borderRadius: "15px",
  };

  switch (permission) {
    case "ad_management":
      return {
        style: {
          ...generalStyle,
          border: isActive
            ? "1px solid #F69C2C"
            : "1px solid rgba(68, 68, 68, 0.25)",
          color: isActive ? "#F69C2C" : "rgba(68, 68, 68, 0.25)",
        },
        name: "Ad Management",
      };
    case "reporting":
      return {
        style: {
          ...generalStyle,
          border: isActive
            ? "1px solid #33850D"
            : "1px solid rgba(68, 68, 68, 0.25)",
          color: isActive ? "#33850D" : "rgba(68, 68, 68, 0.25)",
        },
        name: "Reporting",
      };
    case "fsm":
      return {
        style: {
          ...generalStyle,
          border: isActive ? "1px solid #9A004A" : "1px solid #444",
          color: isActive ? "#9A004A" : "#444",
        },
        name: "FSM",
      };
    default:
  }
};

const dataNormaliser = (data: any) => data?.map((item: any) => {
  const { name, email, role, id, is_active } = item;
  let permissions = [];
  let orderedPermissions = [];

  if (role !== "Admin") {
    orderedPermissions = Object.keys(PERMISSIONS)
      .filter(permission => item.permissions?.includes(permission))
      .map(permission => permission);
    permissions = orderedPermissions?.map(permission => permissionsStyles(permission, is_active) || "");
  } else {
    permissions = [
      {
        name: "Full access",
        style: {
          fontWeight: "400",
          fontSize: "14px",
          padding: "3px 7px",
          borderRadius: "15px",
          color: is_active ? "#0F314D" : "rgba(68, 68, 68, 0.25)",
          border: is_active
            ? "1px solid #0F314D"
            : "1px solid rgba(68, 68, 68, 0.25)",
        },
        isActive: is_active,
      },
    ];
  }

  return {
    is_active,
    id,
    name,
    email,
    role,
    permissions,
  };
});

const MemberList = () => {
  const navigate = useNavigate();
  const styles = memberListUsestyles();
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  
  const { user_id } =
    accessToken &&
    jwt_decode<any>(accessToken);

  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");

  const toggleSortOrder = (id: string) => {
    // setSortBy(sortBy === "asc" ? "desc" : "asc");
    setSortBy(sortBy === "asc" ? "desc" : "asc");
    setOrderBy(id);
  };

  const { data: usersDataResponse, isLoading } = useQuery(
    [QueryConstants.getTeamManagementUsers, { sortBy, orderBy }],
    () => getUsers({
      sort_by: sortBy,
      order_by: orderBy,
    }),
    {
      onError: (error) => {
        navigate(ROUTES.PAGE_NOT_FOUND);
      },
      getNextPageParam: ({ data: { next = "" } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
    },
  );

  const usersData = usersDataResponse?.data.map((user: any) => ({
    name: user?.name,
    email: user?.email,
    role: user?.role.charAt(0).toUpperCase() + user.role.slice(1),
    permissions: user?.permissions,
    is_active: user?.is_active,
    id: user?.id,
  }));

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
          columns,
          data: useMemo(
            () => dataNormaliser(usersData) || [],
            // eslint-disable-next-line
                [dataNormaliser(usersData)]
          ),
        });

  return isLoading ? (
    <Flex justifyContent="center">
      <Spinner style={{ margin: "40px" }}/>
    </Flex>
  ) : (
    <div className={styles.memberListWrapper}>
      <table {...getTableProps()} className={styles.table}>
        <thead>
          {headerGroups.map((headerGroup: any, index: number) => (
            <tr
              {...headerGroup.getHeaderGroupProps({
                style: {
                  minWidth: headerGroup.minWidth,
                  width: headerGroup.width,
                },
              })}
              key={index}
              className={styles.tableRow}
            >
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  className={styles.tableHeaderItem}
                  key={column.id}
                >
                  <Flex alignItems="center" style={{ gap: "4px" }}>
                    <span
                      style={{
                        cursor: column.id === "role" ? "pointer" : "initial",
                      }}
                      onClick={() =>
                        (column.id === "role"
                          ? toggleSortOrder(column?.orderKey)
                          : null)
                      }
                    >
                      {column.render("Header")}
                    </span>
                    {column.id === "role" && (
                      <Flex direction="column">
                        <button
                          style={{
                            border: "none",
                            background: "transparent",
                            display: "flex",
                            cursor: "pointer",
                            padding: "2px 4px",
                          }}
                          onClick={() =>
                            toggleSortOrder(column?.orderKey)}
                        >
                          <SortingIcon/>
                        </button>
                      </Flex>
                    )}
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={styles.tableBodyRow}
                style={{
                  color: row.original.is_active
                    ? "#444444"
                    : "rgba(68, 68, 68, 0.25)",
                }}
              >
                {row.cells.map((cell: any, index: any) => (
                  <td
                    {...cell.getCellProps({
                      style: {
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                      },
                    })}
                    className={styles.tableBodyItem}
                  >
                    {cell.render("Cell")}
                    {cell.column.orderKey === "permissions" &&
                                            user_id !== cell.row.original.id && (
                      <div className={styles.setingActionWrapper}>
                        <SettingsAction
                          isActive={cell.row.original.is_active}
                          id={cell.row.original.id}
                          style={{ top: "18px", right: "20px" }}
                        />
                      </div>
                    )}
                    {!row.original.is_active && index === 1 && (
                      <span className={styles.deactivatedTooltip}>
                          Deactivated user
                      </span>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default MemberList;
