import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import ROUTES from '../../../../constants/routes';
import check1 from 'assets/icons/check1.svg';

import warningBlueIcon from 'assets/icons/InfoBlueIconWar.svg';

import Flex from 'modules/shared/components/general/Flex/Flex';
import Checkbox from 'modules/shared/components/general/Checkbox/Checkbox';
import BrandLogosData from 'modules/BrandsReporting/components/ReportRequest/BrandsLogosData';
import DatePeriod from 'modules/BrandsReporting/components/ReportRequest/DatePeriod';
import Spinner from 'modules/shared/components/general/Spinner/Spinner';
import DropBox from './DropBox/DropBox';
import Switch from 'modules/shared/components/general/Switch/Switch';
import { IAdAccount, ReportTypes } from './types';
import { getAdAccounts } from 'services/reporting.services';
import SelectAccounts from 'modules/BrandsReporting/components/ReportRequest/SelectAccounts';
import { reportRequestUseStyles } from 'modules/BrandsReporting/components/ReportRequest/ReportRequest.styles';

const ReportRequestModalContent = ({
  location,
  brands,
  setBrands,
  setIsLoadingMemberList,
  isLoading,
  isSwitchOn,
  setIsSwitchOn,
  setReportTypes,
  reportTypes,
  handleClose,
  disabelConfirmBtn,
  handleConfirmClick,
  setActiveTab,
  setMappingsValues,
  mappingsValues,
  setUploadSuccess,
  uploadSuccess,
  setUploadedFile,
  setRanges,
  ranges,
  activeTab,
  dayBefore,
  selectedAdAccounts,
  setSelectedAdAccounts,
  keyword,
  setKeyword,
  setCheckedFB,
  setCheckedTKT,
}: {
    location: any;
    brands: any;
    setBrands: any;
    setIsLoadingMemberList: any;
    isLoading: boolean;
    isSwitchOn: boolean;
    setIsSwitchOn: any;
    setReportTypes: any;
    reportTypes: any;
    handleClose: any;
    disabelConfirmBtn: boolean;
    handleConfirmClick: any;
    setActiveTab: any;
    setMappingsValues: any;
    mappingsValues: any;
    setUploadSuccess: any;
    uploadSuccess: any;
    setUploadedFile: any;
    setRanges: any;
    ranges: any;
    activeTab: any;
    dayBefore: any;
    selectedAdAccounts: IAdAccount[];
    setSelectedAdAccounts: Dispatch<SetStateAction<any>>;
    keyword: string;
    setKeyword: (a: string) => void;
    setCheckedFB: Dispatch<SetStateAction<boolean>>
    setCheckedTKT: Dispatch<SetStateAction<boolean>>
}) => {
  const styles = reportRequestUseStyles();
  const [adAccounts,setAdAccounts] = useState<{name:string,id:number}[]>([]);

  const handleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleReportTypeCheck = (index: number) => {
    const types = [...reportTypes];
    types[index].checked = !types[index].checked;

    setReportTypes(types);
  };
  const handleChangeSelectedAccounts = (values:IAdAccount[]) => {
    if (!values.length) {
      setSelectedAdAccounts([]);
      return;
    }
    setSelectedAdAccounts(values);
  };

  const { data,isFetching } = useQuery(
    "getAdAccounts",
    () => getAdAccounts(location?.state?.id),{
      enabled: location.pathname === ROUTES.BRAND_REPORTS,
    });
  
  
  useEffect(() => {
    if ((data as any)?.data?.length) {
      setAdAccounts(data?.data);
    }
  }, [data]);

  return (
    <>
      <div className={styles.wrapper}>
        <h1>Report request</h1>
        <div className={styles.divider} />
        <div>
          <h3 className={styles.typeTitle}>Choose platform</h3>
          <Flex gap="20px">
            <Checkbox
              description={'Facebook'}
              handleCheck={(checked: boolean) => setCheckedFB(checked)}
              styles={{
                checkboxWidth: '9.62px',
                checkboxHeight: '7.62px',
                beforeWidth: '20px',
                beforeHeight: '20px',
                backgroundColor: '#fff',
                check: check1,
                left: '5px',
                top: '6px',
              }}
            />
            <Checkbox
              description={'TikTok'}
              handleCheck={(checked: boolean) => setCheckedTKT(checked)}
              styles={{
                checkboxWidth: '9.62px',
                checkboxHeight: '7.62px',
                beforeWidth: '20px',
                beforeHeight: '20px',
                backgroundColor: '#fff',
                check: check1,
                left: '5px',
                top: '6px',
              }}
            />
          </Flex>
        </div>
        <Flex alignItems="center">
          <h3 className={styles.typeTitleReport}>Report type</h3>
        </Flex>

        <p className={styles.infoToastTest}>
          <img
            width="20px"
            height="20px"
            style={{ marginRight: '16px' }}
            src={warningBlueIcon}
            alt="info" />
                  Leads report type is not available for TikTok
        </p>

        <Flex style={{ marginBottom: '16px' }}>
          {reportTypes.map((item: ReportTypes, index: number) => (
            <div className={styles.checkWrapper} key={item.name}>
              <Checkbox
                description={item.name}
                handleCheck={() => handleReportTypeCheck(index)}
                styles={{
                  checkboxWidth: '9.62px',
                  checkboxHeight: '7.62px',
                  beforeWidth: '20px',
                  beforeHeight: '20px',
                  backgroundColor: '#fff',
                  check: check1,
                  left: '5px',
                  top: '6px',
                }}
              />
            </div>
          ))}
        </Flex>
        <h3 className={styles.typeTitle}>Brand</h3>
        <div style={{ maxHeight: '348px', overflow: 'overlay' }}>
          <BrandLogosData location={location} brands={brands} setBrands={setBrands} />
        </div>
        {location.pathname === ROUTES.BRAND_REPORTS && (
          <>
            <div>
              <h3 className={styles.typeTitle}> Upload member list </h3>
              <DropBox
                setIsLoadingMemberList={setIsLoadingMemberList}
                setMappingsValues={setMappingsValues}
                mappingsValues={mappingsValues}
                setUploadSuccess={setUploadSuccess}
                uploadSuccess={uploadSuccess}
                setUploadedFile={setUploadedFile}
              />
            </div>
          </>
        )}
        {location.pathname === ROUTES.BRAND_REPORTS && (
          <>
            <SelectAccounts
              adAccounts={adAccounts}
              selectedAdAccounts={selectedAdAccounts}
              handleChangeSelectedAccounts={handleChangeSelectedAccounts}
              keyword={keyword}
              setKeyword={setKeyword}
              isFetching={isFetching}
            />
          </>
        )}
        <h3 className={styles.typeTitle}>Time period</h3>
        <Flex alignItems="center" style={{ marginBottom: '16px' }}>
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsSwitchOn(false);
            }}
          >
          Custom
          </span>
          <Switch
            isSwitchOn={isSwitchOn}
            type1="Custom"
            type2="Predefined"
            handleToggle={handleSwitch}
            handleTypeChange={() => setIsSwitchOn(!isSwitchOn)}
          />
          <span
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setIsSwitchOn(true);
            }}
          >
          Predefined
          </span>
        </Flex>
        <DatePeriod
          isSwitchOn={isSwitchOn}
          setActiveTab={setActiveTab}
          setRanges={setRanges}
          ranges={ranges}
          activeTab={activeTab}
          dayBefore={dayBefore}
        />
      </div>

      <Flex style={{ marginTop: '10px', marginRight: '32px' }} justifyContent="flex-end">
        <button
          className="auth-button report-cancel-button"
          style={{
            width: 'fit-content',
            margin: '0',
            background: '#fff',
            color: '#3f5a71',
            marginRight: '16px',
          }}
          onClick={handleClose}
        >
                  Cancel
        </button>
        <button
          className="auth-button"
          style={{
            width: '105px',
            margin: '0',
            position: 'relative',
          }}
          disabled={isLoading || disabelConfirmBtn}
          onClick={() => {
            handleConfirmClick();
          }}
        >
          {isLoading ? (
            <Spinner
              white={true}
              style={{ width: '16px', margin: '0', left: '50%', position: 'initial' }}
            />
          ) : (
            <span>Confirm</span>
          )}
        </button>
      </Flex>
    </>
  );
};

export default ReportRequestModalContent;
