import React from "react";
import { Link } from "react-router-dom";
import { DuplicationConfirmedIcon } from "../../../shared/components/general/Icons/DuplicationConfirmed.icon";
import "./DuplicationIsConfirmed.scss";
import ROUTES from "../../../../constants/routes";

const DuplicationIsConfirmed:React.FC = () =>   (
  <div className="info-about-process">
    <div>{DuplicationConfirmedIcon}</div>
    <div>
      <h1 style={{ whiteSpace: "nowrap" }}>Duplication is confirmed!</h1>
      <p>
          We will send you an email containing link to FranchiseRamp tool once{" "}
        <br /> the duplication is completed.
      </p>
      <Link
        className="auth-button"
        style={{
          width: "185px",
        }}
        to={ROUTES.DUPLICATION}
      >
          Back to duplication
      </Link>
    </div>
  </div>
);

export default DuplicationIsConfirmed;
