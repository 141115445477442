import { useDispatch } from "react-redux";
import errorIllustration from "assets/icons/errorIllustration.svg";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import { setShowFailedModal } from "store/brandsReporting/brandsReportingSlice";
import { useStylesSomethingWentWrong } from "./SomethingWentWrong.styles";

export const SomethingWentWrong = () => {
  const styles = useStylesSomethingWentWrong();
  const dispatch = useDispatch();

  return (
    <>
      <p className={styles.errorMessage}>Something went wrong!</p>
      <div className="error-image">
        <ImageLoader src={errorIllustration} alt="report success" />
      </div>
      <button
        style={{ width: "85px", margin: "8px 32px 0 auto" }}
        className="auth-button"
        onClick={() => {
          dispatch(setShowFailedModal(false));
        }}
      >
        Back
      </button>
    </>
  );
};
