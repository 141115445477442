import { createUseStyles } from "react-jss";

export const ChooseBrandAdAccountsUseStyles = createUseStyles(() => ({
  tabsWrapper: {
    gap: "16px",
    width: "100%",
    position: "sticky",
    top: "60px",
    background: "#f9f9f9",
    zIndex: "99",
  },
  checkboxAll:{
    display: 'flex',
    gap: '32px',
    alignItems: 'center',
  },
  tabsContainer: {
    display: "flex",
    marginTop: "24px",
    width: "100%",
    borderBottom: "1px solid #EAEAEA",
  },
  tabs: {
    padding: "8px 16px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#B7B7B7",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  activeTab: {
    padding: "8px 16px",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#0F314C",
    borderBottom: " 1px solid #0F314D",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  selectedItemLength: {
    background: "rgba(15, 49, 76, 0.15)",
    color: "#0F314D",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "400",
    fontSize: "14px",
    borderRadius: "24px",
  },
  searchInput: {
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid transparent",
    width: "304px",
    backgroundRepeat: "no-repeat",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#444444",
    display: "block",
    padding: "12px 16px",
    backgroundPosition: "272px center",
    backgroundSize: "20px 20px",
    "&:focus": {
      border: "1px solid #D9D9D9",
    },

    "&:hover ": {
      border: "1px solid #D9D9D9",
      boxShadow: "0px 0px 4px 0px #E8E8E8",
    },

    "&:disabled": {
      border: "1px solid transparent",
      backgroundColor: "rgba(15, 49, 77, 0.04)",
      boxShadow: "none",
    },

    "&:focus-visible": {
      outline: "none",
    },

    "&::placeholder": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "1.5",
      color: "#b7b7b7",
    },

    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  startDuplicationBtn: {
    width: "unset",
    height: "48px",
    margin: "0",
    padding: "12px 16px",
  },
  chooseAccountsWrapper: {
    marginTop: "4px",
    background: "#FFFFFF",
    width: "100%",
  },
  closeBtn: {
    padding: "4px",
    position: "absolute !important",
    top: "20px",
    transform: "translateY(-50%)",
    left: "270px",
    width: "25px",
    height: "24px",
    backgroundColor: "#fff",
  },
  btnWrapper:{
    maxWidth: '220px',
    margin: '24px auto',
    display: 'flex',
    gap: '16px',
  },
  confirmModalText:{
    color: '#0F314C',
    textAlign: 'center',
    fontSize: '32px',
    fontWight: 700,
  },
  emptyStateMessage: {
    fontWeight: "700",
    fontSize: "18px",
    color: "#0f314d",
    margin: "16px 0",
  },
  emptyStateInfo: {
    fontWeight: "400",
    fontSize: "18px",
    color: "#444444",
    margin: "0",
  },
  arrowTopIcon: {
    position: "sticky",
    bottom: "128px",
    marginLeft: "calc(100% - 120px)",
    border: "none",
    background: "#FFFFFF",
    boxShadow: "0px 0px 32px rgba(68, 68, 68, 0.25)",
    height: "auto",
    padding: "12px",
    borderRadius: "100%",
    cursor: "pointer",
    "&>img": {
      display: "block",
    },
  },
}));
