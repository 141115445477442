import React from "react";
import { Link, useLocation, useMatch, useResolvedPath } from "react-router-dom";
import classNames from "classnames";
import "./styles.scss";

const CustomLink:React.FC<any> = ({ children, to, childPath, type, isOpenAddBrandModal, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved?.pathname, end: true });

  const location = useLocation();

  return (
    <Link
      className={classNames(
        "custom-link",
        isOpenAddBrandModal && "active-custom-link",
        !Boolean(type) && match && "active-custom-link",
        !Boolean(type) && location.pathname.includes(childPath)
          ? "active-custom-link"
          : "",
      )}
      to={to}
      {...props}
    >
      {children}
    </Link>
  );
};

export default CustomLink;
