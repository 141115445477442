import React from 'react';

import { useStylesTabs } from "./Tabs.styles";

const Tab = ({
  tabName,
  activeTab,
  setActiveTab,
  children,
  itemCount,
}: {
  tabName: string;
  activeTab: string;
  setActiveTab: (a: string) => void;
  children: React.ReactNode ;
  itemCount?: number;
}) => {
  const styles = useStylesTabs();
  const handleClick = () => {
    setActiveTab(tabName);
  };

  const isActive = activeTab === tabName;
  const tabClassName = isActive ? styles.activeTab : styles.tabs;

  return (
    <div className={tabClassName} onClick={handleClick}>
      <span>{children}</span>
      {tabName === "suggestions" && (
        <span>
          {`(${itemCount || 0})`}
        </span>
      )}
    </div>
  );
};

const Tabs = ({ setActiveTab, activeTab, itemCount }:any) => {
  const styles = useStylesTabs();

  return (
    <div className={styles.tabsContainer}>
      <Tab
        tabName="suggestions"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        itemCount={itemCount}
      >
        Suggestions
      </Tab>

      <Tab
        tabName="changeHistory"
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      >
        Change history
      </Tab>
      <Tab tabName="archived" activeTab={activeTab} setActiveTab={setActiveTab}>
        Archive
      </Tab>
    </div>
  );
};

export default Tabs;
