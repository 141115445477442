import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { AppThemeConstants } from "constants/style.constants";
import { createUseStyles } from "react-jss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

interface CustomSelectProps {
  hasHover: boolean;
}


export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.8 + ITEM_PADDING_TOP,
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      marginTop: "8px",
      marginLeft: 0,
      borderRadius: "8px",
      padding: "8px",
      border: `1px solid ${AppThemeConstants.grey1}`,
    },
  },
};

export const CustomSelect = styled(Select)<CustomSelectProps>(
  ({ theme, hasHover }) => ({
    width: 300,
    "& .MuiSelect-select": {
      padding: "8px 16px !important",
    },
    "& .MuiSelect-select:hover": {
      boxShadow: hasHover
        ? "0px 1px 5px 0px rgba(173, 173, 173, 0.33)"
        : "none",
    },
    "& .Mui-disabled": {
      background: "#F6F7F8",
      borderRadius: "8px",
    },
    "& .Mui-disabled:hover": {
      boxShadow: "none",
    },
    "& .MuiSvgIcon-root.Mui-disabled": {
      border: "none",
    },
    "&.MuiOutlinedInput-root": {
      "&.Mui-focused": {
        borderColor: "#0F314D !important",
        "&:hover ": {
          borderColor: "#0F314D !important",
        },
      },
    },
  }),
);

export const stylesSx = (value?: any) => ({
  color: "#B7B7B7",
  backgroundColor: "#FFFFFF",
  fontWeight: "300",
  fontSize: "16px",
  outline: "none",
  borderRadius: "4px",
  marginTop: "16px",
  border: value
    ? "1px solid #0F314D"
    : `1px solid ${AppThemeConstants.grey1}`,
  width: "364px",
  padding: "0 !important",
  "& fieldset": {
    border: 0,
    padding: "0 !important",
    "&:focus-visible": {
      borderColor: "transparent !important",
      borderWidth: "0 !important",
      outline: 0,
      border: 0,
    },
  },
});

export const dateRangeSelectUseStyles = createUseStyles(() => ({
  selectWrapper:{
    position:"relative",
    "&>.rdrCalendarWrapper":{
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      position:"absolute",
      top:"58px",
      left:"0",
      zIndex:"999",
      width:"100%",
      "&>.rdrDateDisplayWrapper":{
        display:"none",
      },
      "&>.rdrMonths" :{
        width:"100%",
        "&>.rdrMonth":{
          width:"100%",
        },
      },
    },
    "&>input":{
      width:"304px",
      height:"42px",
      padding:"8px 16px 8px 44px",
      border: `1px solid ${AppThemeConstants.grey1}`,
      borderRadius: "8px",
      fontSize:"16px",
      fontWeight:"500",
      color:"#444",
      margin:"8px 0 0",
      "&:hover":{
        boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      },
    },
  },
  arrowIcon:{
    position:"absolute",
    top:"16px",
    right:"16px",
  },
  dateRangeIcon:{
    position:"absolute",
    top:"19px",
    left:"16px",
  },
}));

