import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';


const initialState = {
  brands: [] as Array<any>,
  managers: [] as Array<any>,
  managersDefaultValue: '',
  date: 'all time',
  type: 'all',
  searchValueOfCampaign: '',
  isSelectOpen: false,
  itemCount: 0,
  isHoveringLead: false,
  isEscalateModalOpen: false,
  logicSequenceData: [] as Array<any>,
};


export const suggestionSlice = createSlice({
  name: 'suggestionSlice',
  initialState,
  reducers: {
    setBrand: (state, action) => ({
      ...state,
      brands: cloneDeep(action.payload),
    }),
    setManager: (state, action) => ({
      ...state,
      managers: cloneDeep(action.payload),
    }),
    setSearchValueOfCampaign: (state, action) => ({
      ...state,
      searchValueOfCampaign: action.payload,
    }),
    setType: (state, action) => ({
      ...state,
      type: action.payload,
    }),
    setDate: (state, action) => ({
      ...state,
      date: action.payload,
    }),
    setLogicSequenceChange: (state, action) => ({
      ...state,
      logicSequenceData: cloneDeep(action.payload),
    }),
    setItemCount: (state, action) => ({
      ...state,
      itemCount: action.payload,
    }),
    setIsHoveringLead: (state, action) => ({
      ...state,
      isHoveringLead: action.payload,
    }),
    setIsEscalateModalOpen: (state, action) => ({
      ...state,
      isEscalateModalOpen: action.payload,
    }),
    setIsSelectOpen:(state, action) => ({
      ...state,
      isSelectOpen: action.payload,
    }),
    setManagersDefaultValue:(state, action) => ({
      ...state,
      managersDefaultValue: action.payload,
    }),
  },
});


export const {
  setBrand,
  setManager,
  setSearchValueOfCampaign,
  setType,
  setLogicSequenceChange,
  setItemCount,
  setIsHoveringLead,
  setIsSelectOpen,
  setManagersDefaultValue,
  setDate,
} = suggestionSlice.actions;

export default suggestionSlice.reducer;
