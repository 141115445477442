import React, { useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { IRootStore } from 'types/store.types';
import reportTypesEmptyState from 'assets/icons/reportTypesEmptyState.svg';
import ReportTable from '../components/ReportTable';
import { useLocation, useNavigate } from 'react-router-dom';
import BasicModal from 'modules/shared/components/general/BasicModal/BasicModal';
import ReportRequest from 'modules/BrandsReporting/components/ReportRequest/ReportRequest';
import Spinner from 'modules/shared/components/general/Spinner/Spinner';
import ROUTES from 'constants/routes';
import './styles.scss';
import { getPageFromUrl } from 'helpers/PageFromUrl';
import { getBrandReportsService } from 'services/reporting.services';
import ReportTabs from '../components/ReportTabs';
import { servicesNames } from 'constants/servicesNames.constants';
import InfiniteScroll from '../../shared/components/general/InfiniteScroll/InfiniteScroll';
import close from 'assets/icons/close.svg';
import search from 'assets/icons/search.svg';
import useDebounce from 'hooks/useDebounce';
import { brandReportTypesUseStyles } from 'modules/BrandsReporting/pages/BrandReportTypes.styles';
import { getWarnings, reSyncBrandAirTable } from '../../../services/addBrandModal.seervices';
import WarningToast from '../../shared/components/general/WarningToast/WarningToast';
import { setShowSuccessModal } from '../../../store/brandsReporting/brandsReportingSlice';
import MultiSelect from '../../shared/components/general/MultiSelect/MultiSelect';
import { Box } from '@mui/material';
import { columns, platformIcon, platforms } from './BrandsLogos/constant';


const BrandReportTypes = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const styles = brandReportTypesUseStyles();
  const location = useLocation();
  const { state } = location;
  const brandId = state && state.id;
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    brandsReporting: { activeTab, showSuccessModal },
  } = useSelector((state: IRootStore) => state);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [fileName, setFileName] = useState<string>('');
  const [inputIsActive, setInputIsActive] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<{ title: string, info: string } | null>(null);
  const [activePlatforms, setActivePlatforms] = useState<any>([]);
  const storedActiveTab = sessionStorage.getItem('activeTab');


  const inputSearchStyle = {
    backgroundImage: inputIsActive || fileName ? 'none' : `url(${search})`,
    padding: inputIsActive || fileName ? '8px 43px 8px 16px' : '8px 50px 8px 16px',
  };

  const data:Array<any> = [];
  const debouncedSearchValue = useDebounce(fileName, 1000);

  useQuery(
    ['warnings'],
    () => getWarnings(brandId, 'reporting'),
    {
      onSuccess: (resWar) => {
        if (!resWar.data?.warnings.length) {
          setWarnings(null);
          return;
        }
        setWarnings(resWar.data?.warnings[0]);
      },
    },
  );

  const { mutate: reSyncAirTable } =
        useMutation(
          () => reSyncBrandAirTable(brandId, 'reporting'),
          {
            onSuccess: (res) => {
              if (!res.data?.warnings.length) {
                setWarnings(null);
                return;
              }
              setWarnings(res.data?.warnings[0]);
            },
          },
        );

  const {
    data: reportingResponse,
    fetchNextPage,
    isFetching,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery(
    [servicesNames.getBrandsReports, { activeTab, brandId, debouncedSearchValue, activePlatforms }],
    ({ pageParam = 1 }) =>
      getBrandReportsService(activeTab, brandId, debouncedSearchValue, pageParam, activePlatforms),
    {
      getNextPageParam: ({ data: { next = '' } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
      enabled: Boolean(activeTab) || activePlatforms.length === 1,
      onSuccess: (resp) => {
        resp?.pages[0].data.results.length ? setIsEmptyState(false) : setIsEmptyState(true);
      },
    },
  );


  const handleChangePlatform = (value: Array<string>) => {
    setActivePlatforms(value);
  };


  const handleReSync = () =>
    reSyncAirTable(brandId);


  const closeSuccessModal = () => {
    dispatch(setShowSuccessModal(false));
    setOpen(false);
    queryClient.invalidateQueries(servicesNames.getBrandsReports);
  };


  useEffect(() => {
    activeTab && refetch();
    // eslint-disable-next-line
    }, [activeTab]);

  const reportingData = reportingResponse?.pages.map(page =>
    page.data?.results?.map((item:any) => item),
  );

  const flattenedArray = [].concat.apply([], reportingData as Array<any>);


  flattenedArray?.map((report:any) =>
    data.push({
      reportName: [report.name, report.is_conversion],
      platform: platformIcon[report.platform as 'Facebook' | 'TikTok'],
      timePeriod: [
        new Date(report.period_start).toUTCString()
          .slice(4, 16),
        ' - ',
        new Date(report.period_end).toUTCString()
          .slice(4, 16),
      ],
      dateCreated: new Date(report.created_at).toUTCString()
        .slice(4, 16),
      createdBy: report.created_by,
      state: report.status,
      id: report.id,
      isOwner: report.is_owner,
      file: report.file,
    }),
  );

  const generateEmptyState = () => (
    <div className="empty-state-wrapper">
      <img src={reportTypesEmptyState} alt="empty state" />
      <p>You haven't created any reports yet.</p>
      <span>Let's start!</span>
    </div>
  );

  const generateContent = () => {
    if (isFetching && !isFetchingNextPage) {
      return (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: '46px',
          }}
        >
          <Spinner />
        </div>
      );
    }

    if (isEmptyState) {
      return generateEmptyState();
    }

    if (flattenedArray.length && !isEmptyState) {
      return (
        <div>
          <ReportTable
            columns={columns}
            data={data}
            isFetchingNextPage={isFetchingNextPage}
            isFetchingReports={isFetching}
          />
        </div>
      );
    }
  };

  const generateSearchInput = () => (
    <div className={styles.searchInputWrapper}>
      <input
        className={styles.searchInput}
        placeholder="Search by file name"
        value={fileName}
        onChange={event => setFileName(event.target.value)}
        style={inputSearchStyle}
        onBlur={() => {
          setInputIsActive(false);
        }}
        onFocus={() => {
          setInputIsActive(true);
        }}
        disabled={isFetching}
      />
      {fileName && (
        <div className={styles.closeBtn} onClick={() => setFileName('')}>
          <img src={close} alt="close" />
        </div>
      )}
    </div>
  );

  useEffect(() => {
    if (!brandId) {
      navigate(ROUTES.REPORTING);
    }
    // eslint-disable-next-line
    }, [activeTab]);

  return (
    <InfiniteScroll
      scroll
      next={() => fetchNextPage()}
      maxHeight={'calc(100vh - 100px)'}
      hasMore={!hasNextPage}
    >
      <div className="report-types-wrapper">
        <div className="report-types-header">
          <h1>{location?.state?.name}</h1>
          <button
            className="auth-button"
            style={{ width: 'unset', margin: '0' }}
            onClick={() => setOpen(true)}
          >
                        Request report
          </button>
        </div>
        {
          warnings &&
                    <WarningToast
                      title={warnings?.title ?? ''}
                      info={warnings?.info ?? ''}
                      handleReSync={handleReSync} />
        }
        <ReportTabs />
        <Box display="flex" flexDirection="row" gap={'24px'} marginTop={'32px'}>
          {
            storedActiveTab !== 'Leads' &&
                        <MultiSelect
                          label="Choose platform type: "
                          options={platforms}
                          style={{
                            width: '304px',
                            margin: '0',
                            borderRadius: '8px',
                          }}
                          handleChange={handleChangePlatform}
                          selected={activePlatforms}
                          selectedLenght={activePlatforms?.length}
                          dataLength={platforms?.length}
                        />
          }

          {generateSearchInput()}
        </Box>
        <BasicModal
          open={open}
          handleClose={showSuccessModal ? closeSuccessModal :
            () => setOpen(false)
          }
          style={{ width: '620px', paddingRight: '0' }}
        >
          <ReportRequest handleClose={
            showSuccessModal ? closeSuccessModal :
              () => setOpen(false)} />
        </BasicModal>
        {generateContent()}
      </div>
    </InfiniteScroll>
  );
};
export default BrandReportTypes;
