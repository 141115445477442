import React from 'react';
import BasicModal from '../BasicModal/BasicModal';
import Flex from '../Flex/Flex';
import Earth_Moon from '../../../../../assets/icons/Earth_Moon.png';
import { ImageLoader } from '../ImageLoader/ImageLoader';
import { useStyles } from './BrandResyncModal.styles';
import ReSyncAritableCard from '../ReSyncAritableCard/ReSyncAritableCard';
import { Box } from '@mui/material';
import { ReactComponent as SuccessIcon } from 'assets/icons/successIcon.svg';


import { IBrandWarning } from '../SideBar/SideBar';


interface IBrandReSyncModal {
  isOpenModal:boolean
  brandWarning: IBrandWarning
  handleReSyncAirTable: () => void
  isLoadingReSync:boolean
  onClose: () => void
  handleDetailsDuplication: () => void
}

const BrandReSyncModal:React.FC<IBrandReSyncModal> = ({
  isOpenModal,
  brandWarning ,
  handleReSyncAirTable,
  isLoadingReSync,
  onClose,
  handleDetailsDuplication,
}) => {
  const styles = useStyles();


  return (
    <BasicModal
      open={isOpenModal}
      handleClose={onClose}
      basicModalStyle={{ zIndex: 1000 }}
      isClickOutside
    >

      <Flex direction="column" alignItems="center">
        <h3 className={styles.modalTitle}>

          Brand successfully added
        </h3>
        <p className={styles.modalDescription}>
            Brand is successfully set up but data sync will take a few minutes.
        </p>
        <ImageLoader
          src={Earth_Moon}
          alt="mail box"
          style={{ width: "400px", height: "380px" }}
        />
      </Flex>

      <Flex>
        {
          brandWarning.warnings.length ?
            <ReSyncAritableCard
              warnings={brandWarning.warnings}
              handleReSyncAirTable={handleReSyncAirTable}
              isLoadingReSync={isLoadingReSync}
              handleDetailsDuplication={handleDetailsDuplication}
            />
            :
            <Box className={styles.succesToast}>
              <SuccessIcon/>
                All features are available
            </Box>
        }
      </Flex>
    </BasicModal>
  );
};


export default BrandReSyncModal;
