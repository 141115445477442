import React from 'react';
import Flex from 'modules/shared/components/general/Flex/Flex';
import csvFileIconBlue from 'assets/icons/csvFileIconBlue.svg';
import downloadIcon from 'assets/icons/downloadIcon.svg';

const CompletedReport: React.FC<any> = ({
  row,
  rowIndex,
  data,
  generateReportNameTooltip,
  generateDeleteIcon,
}) => (
  <>
    <tr {...row.getRowProps()}>
      {row.cells.map((cell: any) => {
        if (cell.column.id === 'reportName') {
          return (
            <td
              {...cell.getCellProps()}
              style={{ display: 'flex', gap: '16px' }}
            >
              <img src={csvFileIconBlue} alt="disable file " />
              {generateReportNameTooltip(cell)}
            </td>
          );
        }
        if (cell.column.id === 'state') {
          return (
            <td {...cell.getCellProps()}>
              <Flex style={{ gap: '32px' }}>
                <a href={cell.row.original.file} download>
                  <img
                    src={downloadIcon}
                    alt="retry"
                    className="icon-button"
                  />
                </a>
                {generateDeleteIcon(
                  data[rowIndex]?.isOwner,
                  data[rowIndex]?.id,
                )}
              </Flex>
            </td>
          );
        }
        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
      })}
    </tr>
  </>
);

export default CompletedReport;
