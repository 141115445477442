export const columns = [
  {
    Header: "FSM",
    accessor: "fsm",
    width: "16%",
  },
  {
    Header: "Owner name",
    accessor: "owner",
    width: "16%",
  },
  {
    Header: "Email",
    accessor: "owner_email",
    width: "21%",
  },
  {
    Header: "Status",
    accessor: "status",
    width: "13%",
  },
  {
    Header: "Reason",
    accessor: "reason",
    width: "15%",
  },
  {
    Header: "Date created",
    accessor: "created_at",
    width: "15%",
  },
];

export const statuses = ["sent", "skipped", "expired"];

export const reportStatuses = {
  CREATED: "created",
  REGENERATING: "regenerating",
  REGENERATED: "regenerated",
  FAILED_TO_REGENERATE: "failed_to_regenerate",
  SENDING: "sending",
  SENT: "sent",
  FAILED_TO_SEND: "failed_to_send",
  SKIPPED: "skipped",
  EXPIRED: "expired",
  EMAILED: "emailed",
  TO_SEND: "to_send",
};

export const editorConfig = {
  toolbar: {
    items: [
      "undo",
      "redo",
      "|",
      "heading",
      "|",
      "bold",
      "italic",
      "strikethrough",
      "subscript",
      "superscript",
      "code",
      "|",
      "blockQuote",
      "codeBlock",
      "|",
      "bulletedList",
      "numberedList",
      "todoList",
      "outdent",
      "indent",
      "|",
      "insertTable",
    ],
  },
};
