import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStylesForwardEmails = createUseStyles((theme: AppTheme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    // height: "27px",
    marginBottom: "16px",
  },
  input: {
    border: `1px solid ${theme.grey1}`,
    padding: "16px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "1015px",
    paddingLeft: "50px",
    color: theme.textColor,
    fontSize: "18px",
    fontWeight: "400",
    "&:focus": {
      border: `1px solid ${theme.deepBlueColor}`,
    },
  },
  to: {
    color: "#F69C2C",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    position: "absolute",
    top: "50%",
    // transform: "translateY(-50%)",
    // left: "16px",
  },
  cc: {
    position: "absolute",
    right: "16px",
    color: "#F69C2C",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    top: "50%",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  showCC: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  fsmsList: {
    maxHeight: "220px",
    overflow: "auto",
    textDecoration: "none",
    position: "absolute",
    zIndex: 99,
    background: "#fff",
    listStyle: "none",
    padding: "8px",
    width: "400px",
    margin: 0,
    border: "1px solid #D9D9D9",
    boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
    borderRadius: "8px",
    top: "56px",
    "& > li": {
      borderRadius: "4px",
      padding: "8px",
      margin: "8px 0",
      cursor: "pointer",
      color: "#444",
      "&:first-child": {
        backgroundColor: "#E2E6E9",
        "&:hover": {
          backgroundColor: "#F3F4F6",
        },
      },
      "&:hover": {
        backgroundColor: "rgba(15, 49, 77, 0.03) !important",
      },
    },
  },
}));
