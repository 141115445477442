export const ArrowUpIcon = () => (
  <svg
    width="8"
    height="5"
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.137805 3.977L3.12231 0.258C3.38781 -0.086 3.82531 -0.086 4.09131 0.258L7.07581 3.977C7.34131 4.321 7.21631 4.602 6.76331 4.602H0.450805C-0.00219458 4.602 -0.127695 4.3205 0.137805 3.977Z"
      fill="#444444"
    />
  </svg>
);
