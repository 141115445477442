import { useMutation, useQuery } from "react-query";
import { useGoogleLogin } from "@react-oauth/google";
import signature from "assets/icons/signature.svg";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";
import Spinner from "../../../shared/components/general/Spinner/Spinner";
import retrySignature from "assets/icons/retrySignature.svg";
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import Flex from "../../../shared/components/general/Flex/Flex";
import { getSignature, refreshSignature } from "../../../../services/clientCommunication";
import { servicesNames } from "../../../../constants/servicesNames.constants";
import { useStyles } from "./GmailSignature.styles";
import { useEffect } from "react";

const GmailSignature = () => {
  const SCOPES = [
    "https://www.googleapis.com/auth/gmail.send",
    "https://www.googleapis.com/auth/gmail.readonly",
    "https://www.googleapis.com/auth/gmail.settings.basic",
    "https://www.googleapis.com/auth/userinfo.profile",
  ];
  const {
    googleAuthCode,
    setGoogleAuthCode,
  } = useClientCommunicationContext();

  const payloadSendEmail = LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken)
    ? {
      access_token: LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken),
    }
    : { code: googleAuthCode };

  const {
    data: {
      data: { google_signature: googleSignatureFromGetRequest = '',
      } = {},
    } = {},
    isLoading: isLoadingGet,
  } = useQuery(
    [servicesNames.getSignature],
    () => getSignature(),
  );

  const {
    data: {
      data: { google_signature: googleSignatureFromMutate = '' } = {},
    } = {},
    mutate: mutateRefreshSignature,
    isLoading: isLoadingMutate,
  } = useMutation(
    () => refreshSignature(payloadSendEmail),
    {
      onSuccess: ({ data: {
        access_token,
      } }: any) => {
        LocalStorage.set(LocaleStorageTokenList.googleAuthAccessToken, access_token);
      },
      onError: () => {
        LocalStorage.remove(LocaleStorageTokenList.googleAuthAccessToken);
      },
    },
  );

  const styles = useStyles({
    isEmpty: true,
  });

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleAuthCode(tokenResponse.code);
    },
    flow: "auth-code",
    scope: SCOPES.join(" "),
  });

  const handleLoginClick = () => {
    LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken) ?
      mutateRefreshSignature()
      : login();
  };

  useEffect(() => {
    googleAuthCode &&
      mutateRefreshSignature();

    // eslint-disable-next-line
  }, [googleAuthCode])

  const generateContent = () => {
    if (isLoadingGet || isLoadingMutate) {
      return (
        <Spinner style={{ left: "50%", transform: "translateX(-50%))" }} />
      );
    }

    if (googleSignatureFromMutate || googleSignatureFromGetRequest) {
      return (
        <div
          className={styles.signaturehtml}
          dangerouslySetInnerHTML={{ __html: googleSignatureFromMutate || googleSignatureFromGetRequest }} />
      );
    }

    if ((!isLoadingMutate || !isLoadingGet) && (!googleSignatureFromMutate || !googleSignatureFromGetRequest)) {
      return (
        <div className={styles.emptyStateWrapper}>
          <ImageLoader src={signature} alt={"signature"} />
          <h2 className={styles.emptyStateTitle}>No email signature</h2>
          <p className={styles.description}>Please configure your signature in Gmail.
          Then click on the reload button on the right to load it.
          <br />
          It is essential for us to retrieve and send emails with your designated signature.
          </p>
        </div>
      );
    }
  };

  return (
    <Flex
      justifyContent='center'
      className={styles.signatureWrapper}
      alignItems="center"
      style={{
        position: "relative",
        border:
          (googleSignatureFromMutate || googleSignatureFromGetRequest) ? "1px solid #D9D9D9" : "1px solid #0F314D",
      }}>
      {generateContent()}
      <div className={styles.tooltipWrapper}>
        <Flex className={styles.retryWrapper}
          onClick={() => handleLoginClick()}>
          <img src={retrySignature} alt={"retry signature"} />
        </Flex>
        <div className={styles.tooltip}>
          Press reload to get your latest <br /> signature from Gmail.
        </div>
      </div>
    </Flex >
  );
};

export default GmailSignature;
