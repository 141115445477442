import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  confirmedDuplications: null,
};

export const requestedDuplicationsSlice = createSlice({
  name: "RequestedDuplicationsSlice",
  initialState,
  reducers: {
    setConfirmedDuplication: (state, action) => {
      state.confirmedDuplications = action.payload;
    },
  },
});

export const { setConfirmedDuplication } = requestedDuplicationsSlice.actions;

export default requestedDuplicationsSlice.reducer;
