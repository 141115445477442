import { IAdAccount } from 'modules/BrandsReporting/components/ReportRequest/types';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { ImageLoader } from 'modules/shared/components/general/ImageLoader/ImageLoader';
import attentionBlue from 'assets/icons/attentionBlue.svg';
import React, { useEffect, useState } from 'react';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import close from 'assets/icons/close.svg';
import { reportRequestUseStyles } from 'modules/BrandsReporting/components/ReportRequest/ReportRequest.styles';
import AutoCompleteSearch from '../../../shared/components/general/MultiSelect/AutoCompleteSearch';
import { isSpecialCharacters } from '../../../../helpers/validator';

const SelectAccounts = ({
  adAccounts,
  selectedAdAccounts,
  handleChangeSelectedAccounts,
  keyword,
  setKeyword,
  isFetching,
}: {
    adAccounts:Array<any>
    selectedAdAccounts: IAdAccount[];
    handleChangeSelectedAccounts: (a: Array<any>) => void;
    keyword: string;
    setKeyword: (a: string) => void;
    isFetching:boolean;
}) => {
  const styles = reportRequestUseStyles();
  const [errorMessage,setErrorMessage] = useState<string>("");

  useEffect(() => {
    if (selectedAdAccounts.length === 0) {
      setKeyword("");
    }
    // eslint-disable-next-line
  },[selectedAdAccounts]);

  const handleKeywordChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage("");
    if  (isSpecialCharacters(event.target.value)) {
      setErrorMessage("Only letters and numbers allowed validation message.");
    }
    if (event.target.value.length > 20) {
      setErrorMessage("Field may not contain more than 20 characters.");
    } else {
      setKeyword(event.target.value);
    }
  };


  return (
    <Flex direction="column" gap="16px">
      <h3 className={styles.typeTitle}>Select accounts</h3>
      <Flex className={styles.infoMessageContainer} gap="16px" alignItems="center">
        <ImageLoader src={attentionBlue} alt="attention" />
        <span className={styles.infoMessage}>
          The custom keyword will be added to the Report name and the Filter word will be
          added by default.
        </span>
      </Flex>
      <div style={{ width: '100%' }}>
        <Flex justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          <span className={styles.inputLabel}>Choose accounts</span>
          <AutoCompleteSearch
            options={adAccounts}
            label="Choose from list or search by name or ID"
            handleChange={handleChangeSelectedAccounts}
            isFetching={isFetching}
          />
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          <span className={styles.inputLabel}>Custom keyword</span>
          <Flex direction='column' >
            <div style={{ position:"relative" }}>
              <input
                className={styles.keywordInput}
                placeholder="Add custom keyword"
                disabled={!selectedAdAccounts.length}
                value={keyword}
                onChange={handleKeywordChange}
              />
              {keyword && (
                <div
                  className={styles.closeBtn}
                  onClick={() => {
                    setKeyword('');
                    setErrorMessage("");
                  }}
                >
                  <img src={close} alt="close" />
                </div>
              )}
            </div>
            {errorMessage && <ErrorMessage text={errorMessage}/>}
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};

export default SelectAccounts;
