import { createUseStyles } from "react-jss";

export const duplicationIsInProgressUseStyles = createUseStyles(() => ({
  duplicationInProgressWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    justifyContent: "center",
    height: "100vh",
  },
  duplicationImgContainer: {
    width: "500px",
    height: "500px",
  },
}));
