export const servicesNames = {
  getLocations: "getLocations",
  getSelectLocations:"getSelectLocations",
  getSelectLocationsInDrawer:"getSelectLocationsInDrawer",
  getMetricsLocations:"getMetricsLocations",
  getSuggestions: "getSuggestions",
  getManagers: "getManagers",
  getManagersByBrands: "getManagersByBrands",
  getBrandNames: "getBrandNames",
  getDates: "getDates",
  getTypes: "getTypes",
  acceptSuggestion: "acceptSuggestion",
  dismissSuggestion: "dismissSuggestion",
  locationLeads: "locationLeads",
  escalate: "escalate",
  getBrandsLogo: "getBrandsLogo",
  getBrandsReports: "getBrandsReports",
  retryReportCreation: "retryReportCreation",
  getWeeklyBoardEmailReports: "getWeeklyBoardEmailReports",
  getFsms: "getFsms",
  getEmailReportById: "getEmailReportById",
  getToSendEmailReportsData: "getToSendEmailReportsData",
  getSentEmailReportsData: "getSentEmailReportsData",
  getSkippedEmailReportsData: "getSkippedEmailReportsData",
  getAllEmails: "getAllEmails",
  getEmailsStatistics: "getEmailsStatistics",
  getPauseAdData: "getPauseAdData",
  getCommunication: "getCommunication",
  searchLocations: "searchLocations",
  getBrands: "getBrands",
  getLocationsStatistics: "getLocationsStatistics",
  getSignature: "getSignature",
  refreshSignature: "refreshSignature",
  getLocationById: "getLocationById",
  getLogicSequenceData:"getLogicSequenceData",
};
