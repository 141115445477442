import { createUseStyles } from "react-jss";

export const useStylesSomethingWentWrong = createUseStyles(() => ({
  errorMessage: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: "32px",
    lineHeight: "1.5",
    color: "#0F314D",
    margin: "24px 0 16px",
  },
}));
