import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStylesWeeklyBoard = createUseStyles((theme: AppTheme) => ({
  title: {
    color: theme.deepBlueColor,
    fontSize: "2rem",
    fontWeight: 700,
    lineHeight: "48px",
    textAlign: "center",
  },
  date: {
    color: theme.textColor,
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
}));
