import { CSSProperties } from "react";
import { createUseStyles } from "react-jss";
import search from "assets/icons/search.svg";
import { AppTheme } from "../../../../../types/styles.types";

const errorTextFontSize = 13;

interface InputFieldStylesProps {
  isError?: boolean;
  placeHolderStyles?: CSSProperties;
  hasDelete: boolean;
  isActive: boolean;
  value: string | number;
  hasError: boolean;
  hasHint: string;
  isBlured: boolean;
}

export const inputFieldUseStyles = createUseStyles((theme: AppTheme) => ({
  inputLabel: {
    color: theme.textColor,
    marginBottom: "1rem",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "27px",
  },

  inputWrapper: {
    position: "relative",
    width: "100%",
    display: "",
    alignItems: "",
  },

  inputField: {
    color: theme.textColor,
    background: theme.inputBackground,
    height: "59px",
    width: "100%",
    padding: "1rem",
    outline: "0",
    fontSize: "1rem",
    lineHeight: "1.25rem",
    borderRadius: theme.primaryRadius,
    border: "1px solid #F2F2F2",
    boxSizing: "border-box",
    "&:read-only": {
      color: "rgba(68, 68, 68, 0.3)",
    },
    "&::placeholder": {
      color: theme.inputPlaceholder,
      fontSize: ({ placeHolderStyles = "16px" }: any) =>
        placeHolderStyles?.fontSize ?? "inherit",
    },
    "&:focus": {
      "&::placeholder": {
        color: "transparent",
      },
      border: ({ hasHint }) => hasHint && "1px solid #0F314D",
    },
    "&:not(:focus)": {
      border: ({ hasError, value }) =>
        (value && hasError ? "1px solid #B3261E" : "1px solid #F2F2F2"),
    },
    "&:not(:focus) ~ $inputErrorText": {
      display: ({ hasError, value }) => (value && hasError ? "flex" : "none"),
    },
  },

  inputErrorText: {
    color: "#b3261e",
    display: "none",
    alignItems: "center",
    gap: "6px",
    fontWeight: "400",
    fontSize: "11px",
    margin: "0",
  },

  searchWrapper: {
    width: "100%",
    marginLeft: "0",
    marginRight: "32px",
  },
  searchInputWrapper: {
    width: "100%",
  },
  inputContainer: {
    position: "relative",
    width: "100%",
  },
  searchInput: {
    backgroundColor: "#ffffff",
    boxShadow: "-1px 0px 4px #e8e8e8",
    borderRadius: "4px 0 0px 4px",
    border: "1px solid transparent",
    width: "100%",
    outline: "none",
    fontWeight: "400",
    fontSize: "16px",
    color: "#444444",
    display: "block",
    backgroundPosition: "32px center",
    backgroundRepeat: "no-repeat",
    padding: ({ isActive, value }: InputFieldStylesProps) =>
      (isActive || value ? "19px 32px" : "19px 16px 19px 70px"),
    backgroundImage: ({ isActive, value }: InputFieldStylesProps) =>
      (isActive || value ? "none" : `url(${search})`),
    "&:focus-visible": {
      outline: "none",
    },
    "&:hover": {
      border: "1px solid #dbdbdb",
    },
    "&::placeholder": {
      fontWeight: "400",
      fontSize: "18px",
      color: "#B7B7B7",
      marginLeft: "10px",
      fontFamily: "Roboto",
    },
    "&:focus": {
      backgroundImage: "none",
      border: "1px solid #dbdbdb",
    },
    "&:focus::placeholder": {
      color: "transparent",
    },
  },
  enterBtn: {
    borderRadius: "0px 4px 4px 0px",
    height: "58px",
    width: "unset",
    marginTop: "0",
    backgroundColor: theme.deepBlueColor,
    border: "none",
    padding: "0 22px",
    cursor: "pointer",
    "&:hover": {
      background: "#0f314d",
    },
  },

  errorIconWrapper: {
    position: "absolute",
    right: "10px",
    top: "50%",
    transform: "translateY(-50%)",
  },

  errorWrapper: {
    position: "relative",
    marginTop: "0.25rem",
  },

  errorText: {
    color: theme.dangerColor,
    fontSize: `${errorTextFontSize}px`,
  },

  optionsWrapper: {
    position: "absolute",
  },

  deleteIconWrapper: {
    marginLeft: "19px",
  },

  deleteIconBtn: {
    background: "transparent",
    border: "unset",
    cursor: "pointer",
    color: theme.textColor,
    transition: "0.1s",
  },
  clearIconWrapper: {
    background: "#fff",
    cursor: "pointer",
    padding: "4px",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    borderRadius: "50%",
    right: "20px",
  },
  clearIcon: {
    padding: "4px",
    borderRadius: "50%",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },

  floatingInputWrapper: {
    position: "relative",
  },

  floatingLabel: {
    lineHeight: "24px",
    fontWeight: "400",
    fontSize: "16px",
    position: "absolute",
    pointerEvents: "none",
    left: "16px",
    top: "50%",
    transform: "translateY(-50%)",
    transition: "all 0.2s",
    touchAction: "manipulation",
    color: "#444444",
  },
  floatingInput: {
    background: "#FFFFFF",
    borderRadius: "4px",
    fontSize: "16px",
    height: "56px",
    fontWeight: "400",
    lineHeight: "24px",
    padding: "14px 16px",
    color: "#444444",
    "&:hover": {
      border: ({ hasError, value, isBlured }) =>
        (value && hasError && isBlured
          ? "1px solid #B3261E"
          : `1px solid ${theme.deepBlueColor}`),
    },
    "&:focus": {
      border: "1px solid #0F314D",
    },
    "&:focus + $floatingLabel": {
      top: "0",
      background: "#fff",
      padding: "0 4px",
      color: ({ hasError, value, isBlured }) =>
        (value && hasError && isBlured ? "#B3261E" : "#444444"),
      fontSize: "12px",
      fontWeight: "400",
      left: "12px",
    },
    "&:not(:focus):valid ~ $floatingLabel": {
      top: "0",
      background: "#fff",
      padding: "0 4px",
      color: ({ hasError, value, isBlured }) =>
        (value && hasError && isBlured ? "#B3261E" : "#444444"),
      fontSize: "12px",
      fontWeight: "400",
      left: "12px",
    },
  },
  passwordEyeIcon: {
    position: "absolute",
    right: "4px",
    top: "18px",
  },
  passwordEyeIconFrame: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    background: "#fff",
  },
}));

export const requiredFieldStyles = createUseStyles((theme: AppTheme) => ({
  requiredText: {
    color: ({ isEmpty }: { isEmpty: boolean }) => isEmpty && theme.dangerColor,
  },
}));
