export const AlienIcon = (
  <svg
    width="457"
    height="305"
    viewBox="0 0 457 305"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M256.725 58.6332C251.511 56.3085 245.83 55.2253 240.127 55.4688C234.424 55.7122 228.855 57.2756 223.858 60.0362C213.341 65.9593 206.187 76.8539 195.372 82.2097C183.1 88.3097 168.61 86.1747 154.918 86.4187C141.226 86.6627 125.438 91.1401 120.399 103.883C116.134 114.65 121.338 127.264 117.7 138.257C114.653 147.364 106.324 153.409 99.8707 160.509C89.1708 172.295 83.1994 188.758 85.7403 204.478C88.2812 220.198 100.035 234.533 115.47 238.4C132.245 242.597 150.738 234.777 166.916 240.883C177.804 245 185.525 254.681 195.365 260.897C211.927 271.352 234.783 270.706 250.729 259.329C258.882 253.516 265.183 245.299 273.665 239.98C295.601 226.182 326.183 234.832 348.473 221.583C366.21 211.06 374.071 186.66 365.875 167.713C359.867 153.854 346.876 143.826 341.502 129.717C336.962 117.87 338.345 104.707 336.944 92.0856C335.543 79.4647 329.699 65.6116 317.604 61.8357C309.622 59.3469 301.085 61.9272 292.798 63.013C279.801 64.7149 268.601 64.233 256.75 58.6332"
      fill="#E0E0E0"
    />
    <path
      d="M66.1014 24.2841C55.822 27.6574 44.8601 35.8741 42.4959 46.4637C40.1316 57.0533 42.9589 68.4542 49.1985 77.2931C53.9757 84.0458 61.6411 89.6883 69.8488 88.7489C75.9422 88.0413 81.1093 83.8689 87.0137 82.1426C92.4551 80.5444 98.3413 81.069 103.783 79.4769C115.183 76.1463 122.325 63.2753 120.765 51.484C119.205 39.6927 110.144 29.6887 99.1395 25.2174C88.1349 20.7461 75.5461 21.2951 64.2125 24.8392"
      fill="#E0E0E0"
    />
    <path
      d="M379.207 233.709C370.719 232.026 361.493 235.619 356.101 242.396C350.708 249.173 349.228 258.762 351.988 266.973C354.748 275.183 361.554 281.838 369.695 284.76C375.648 286.895 382.631 287.011 387.975 283.65C392.582 280.765 395.446 275.72 397.005 270.48C399.4 262.495 399.041 253.498 395.019 246.196C390.997 238.894 384.581 233.124 376.252 233.295"
      fill="#EBEBEB"
    />
    <path
      d="M404.555 169.342L404.847 169.873C405.174 170.469 405.668 170.956 406.267 171.276L406.797 171.569L406.267 171.855C405.666 172.176 405.172 172.666 404.847 173.264L404.555 173.789L404.262 173.264C403.934 172.668 403.441 172.179 402.843 171.855L402.312 171.569L402.843 171.276C403.439 170.953 403.932 170.466 404.262 169.873L404.555 169.342Z"
      fill="#EBEBEB"
    />
    <path
      d="M66.2289 195.237L66.5153 195.761C66.8438 196.357 67.3366 196.847 67.935 197.17L68.4713 197.457L67.935 197.75C67.3383 198.073 66.8458 198.56 66.5153 199.153L66.2289 199.683L65.9364 199.153C65.6059 198.56 65.1135 198.073 64.5167 197.75L63.9805 197.457L64.5167 197.17C65.1151 196.847 65.608 196.357 65.9364 195.761L66.2289 195.237Z"
      fill="#EBEBEB"
    />
    <path
      d="M416.175 229.031L416.669 229.946C417.23 230.977 418.076 231.824 419.106 232.386L420.02 232.886L419.106 233.38C418.076 233.941 417.23 234.789 416.669 235.82L416.175 236.735L415.676 235.82C415.115 234.789 414.268 233.941 413.238 233.38L412.324 232.886L413.238 232.386C414.268 231.824 415.115 230.977 415.676 229.946L416.175 229.031Z"
      fill="#EBEBEB"
    />
    <path
      d="M53.6535 253.845L54.1531 254.76C54.7141 255.792 55.5604 256.639 56.5905 257.2L57.5045 257.701L56.5905 258.201C55.5604 258.762 54.7141 259.61 54.1531 260.641L53.6535 261.55L53.1599 260.641C52.599 259.61 51.7527 258.762 50.7226 258.201L49.8086 257.701L50.7226 257.2C51.7527 256.639 52.599 255.792 53.1599 254.76L53.6535 253.845Z"
      fill="#EBEBEB"
    />
    <path
      d="M193.092 45.5792L193.592 46.4942C194.153 47.5254 194.999 48.3727 196.029 48.9342L196.943 49.4344L196.029 49.9346C194.999 50.4961 194.153 51.3434 193.592 52.3746L193.092 53.2835L192.593 52.3746C192.032 51.3434 191.186 50.4961 190.155 49.9346L189.26 49.41L190.168 48.9098C191.198 48.3483 192.044 47.501 192.605 46.4698L193.092 45.5792Z"
      fill="#EBEBEB"
    />
    <path
      d="M323.599 127.368L324.093 128.283C324.654 129.314 325.5 130.161 326.53 130.723L327.444 131.223L326.53 131.717C325.5 132.279 324.654 133.126 324.093 134.157L323.599 135.072L323.099 134.157C322.538 133.126 321.692 132.279 320.662 131.717L319.748 131.223L320.662 130.723C321.692 130.161 322.538 129.314 323.099 128.283L323.599 127.368Z"
      fill="#FAFAFA"
    />
    <path
      d="M141.518 177.174C140.951 179.456 170.193 203.655 239.486 198.677C299.03 194.407 328.193 167.109 328.193 167.109L141.518 177.174Z"
      fill="#455A64"
    />
    <path
      opacity="0.2"
      d="M141.518 177.174C140.951 179.456 170.193 203.655 239.486 198.677C299.03 194.407 328.193 167.109 328.193 167.109L141.518 177.174Z"
      fill="black"
    />
    <path
      d="M234.926 183.902C286.641 181.436 328.299 173.85 327.97 166.958C327.642 160.067 285.451 156.479 233.735 158.945C182.02 161.411 140.362 168.997 140.691 175.889C141.019 182.78 183.21 186.368 234.926 183.902Z"
      fill="#6A7B83"
    />
    <path
      d="M191.452 168.226C191.452 168.226 188.722 125.825 230.718 120.451C272.713 115.077 276.454 159.625 276.454 159.625C276.454 159.625 249.272 180.365 191.452 168.226Z"
      fill="#F5F5F5"
    />
    <path
      d="M213.114 130.54C213.254 130.741 211.797 131.87 209.671 133.895C209.147 134.401 208.568 134.956 207.983 135.591C207.398 136.225 206.764 136.902 206.155 137.628C203.442 140.821 201.186 144.377 199.452 148.193C199.068 149.084 198.685 149.92 198.398 150.725C198.112 151.53 197.789 152.287 197.582 152.982C196.656 155.764 196.259 157.563 196.016 157.526C195.772 157.49 195.833 155.629 196.503 152.646C196.674 151.908 196.875 151.103 197.155 150.261C197.435 149.42 197.765 148.523 198.112 147.583C199.75 143.537 202.101 139.818 205.052 136.603C205.747 135.884 206.393 135.188 207.063 134.597C207.661 134.03 208.287 133.495 208.94 132.992C211.316 131.15 213.016 130.363 213.114 130.54Z"
      fill="white"
    />
    <path
      d="M320.906 166.28C320.833 166.395 320.747 166.502 320.65 166.597C320.38 166.88 320.089 167.141 319.778 167.378C318.609 168.213 317.33 168.88 315.976 169.36C307.171 172.778 297.92 174.907 288.507 175.68C286.679 175.82 285.241 175.887 284.242 175.912H283.078C282.942 175.921 282.806 175.921 282.67 175.912C282.803 175.879 282.941 175.862 283.078 175.863L284.236 175.784C285.247 175.717 286.704 175.619 288.501 175.448C297.879 174.557 307.098 172.432 315.921 169.129C317.268 168.672 318.55 168.042 319.736 167.256C320.142 166.951 320.533 166.625 320.906 166.28Z"
      fill="white"
    />
    <path
      d="M223.241 199.47C223.037 199.375 222.845 199.256 222.668 199.116C222.161 198.77 221.679 198.389 221.224 197.976C220.54 197.369 219.905 196.711 219.323 196.005C218.611 195.142 217.973 194.219 217.416 193.248C216.869 192.271 216.402 191.251 216.021 190.198C215.716 189.336 215.476 188.454 215.302 187.557C215.181 186.953 215.1 186.341 215.058 185.727C215.033 185.502 215.033 185.275 215.058 185.05C215.119 185.05 215.186 186.001 215.527 187.49C215.729 188.368 215.988 189.231 216.301 190.076C216.695 191.106 217.162 192.107 217.696 193.071C218.249 194.024 218.87 194.935 219.555 195.798C220.119 196.501 220.73 197.165 221.383 197.786C222.492 198.86 223.278 199.415 223.241 199.47Z"
      fill="#263238"
    />
    <path
      d="M220.364 189.783C220.364 189.826 220.212 189.899 220.163 190.125C220.144 190.183 220.139 190.245 220.149 190.305C220.159 190.366 220.182 190.423 220.218 190.472C220.264 190.517 220.321 190.549 220.383 190.565C220.445 190.581 220.51 190.58 220.572 190.563C220.633 190.546 220.689 190.512 220.734 190.467C220.779 190.421 220.811 190.364 220.827 190.302C220.832 190.241 220.823 190.179 220.801 190.122C220.778 190.065 220.744 190.014 220.699 189.972C220.535 189.801 220.358 189.832 220.346 189.783C220.334 189.734 220.541 189.606 220.839 189.783C220.926 189.839 220.997 189.916 221.046 190.006C221.095 190.097 221.12 190.199 221.12 190.302C221.114 190.435 221.065 190.562 220.98 190.664C220.895 190.767 220.78 190.839 220.65 190.869C220.539 190.9 220.421 190.898 220.311 190.865C220.201 190.831 220.102 190.767 220.027 190.679C219.951 190.592 219.902 190.484 219.885 190.37C219.869 190.256 219.885 190.139 219.931 190.033C220.108 189.71 220.376 189.74 220.364 189.783Z"
      fill="#263238"
    />
    <path
      d="M223.094 194.669C223.094 194.712 222.948 194.779 222.893 195.011C222.876 195.069 222.872 195.131 222.882 195.191C222.891 195.251 222.914 195.308 222.948 195.359C222.991 195.411 223.05 195.448 223.115 195.465C223.181 195.483 223.25 195.48 223.313 195.456C223.38 195.443 223.441 195.41 223.488 195.361C223.535 195.311 223.565 195.249 223.575 195.182C223.579 195.122 223.569 195.062 223.547 195.006C223.525 194.95 223.491 194.9 223.447 194.858C223.283 194.688 223.106 194.712 223.094 194.669C223.082 194.627 223.289 194.492 223.587 194.669C223.674 194.725 223.745 194.802 223.794 194.892C223.843 194.983 223.869 195.085 223.868 195.188C223.852 195.268 223.821 195.344 223.775 195.412C223.73 195.48 223.671 195.538 223.603 195.583C223.535 195.627 223.458 195.658 223.378 195.673C223.297 195.687 223.215 195.685 223.135 195.667C223.056 195.649 222.981 195.615 222.914 195.568C222.848 195.52 222.792 195.46 222.749 195.39C222.707 195.32 222.679 195.243 222.667 195.162C222.655 195.081 222.659 194.999 222.68 194.919C222.838 194.59 223.106 194.627 223.094 194.669Z"
      fill="#263238"
    />
    <path
      d="M140.908 175.479C141.067 175.408 141.232 175.353 141.402 175.314L142.84 174.905C144.058 174.558 145.886 174.088 148.159 173.545C152.662 172.459 158.944 171.166 165.952 170.16C172.959 169.153 179.357 168.659 183.988 168.439C186.304 168.33 188.18 168.275 189.472 168.269H190.965C191.139 168.253 191.315 168.253 191.489 168.269C191.318 168.298 191.145 168.314 190.971 168.317L189.478 168.384C188.186 168.433 186.31 168.519 183.994 168.659C179.369 168.933 172.99 169.476 165.988 170.489C158.987 171.502 152.717 172.746 148.202 173.777C145.941 174.289 144.119 174.722 142.864 175.04L141.414 175.393C141.248 175.435 141.079 175.464 140.908 175.479Z"
      fill="#263238"
    />
    <path
      d="M277.106 180.255C276.832 180.12 276.571 179.96 276.326 179.779L274.248 178.401C272.493 177.236 270.05 175.662 267.295 174.009C264.541 172.356 262 170.959 260.148 169.946L257.954 168.763C257.678 168.63 257.413 168.475 257.162 168.299C257.454 168.393 257.737 168.511 258.009 168.653C258.545 168.897 259.313 169.263 260.251 169.745C262.134 170.696 264.7 172.081 267.46 173.734C270.22 175.387 272.651 177.004 274.376 178.212C275.235 178.822 275.93 179.322 276.399 179.676C276.651 179.848 276.887 180.042 277.106 180.255Z"
      fill="#263238"
    />
    <path
      d="M260.033 172.252C260.069 172.288 259.887 172.441 259.899 172.758C259.902 172.928 259.972 173.091 260.094 173.21C260.174 173.263 260.266 173.295 260.362 173.303C260.458 173.312 260.554 173.296 260.642 173.257C260.731 173.218 260.807 173.157 260.866 173.081C260.924 173.004 260.963 172.914 260.977 172.819C260.977 172.734 260.959 172.65 260.925 172.572C260.89 172.494 260.839 172.425 260.776 172.368C260.551 172.148 260.319 172.179 260.313 172.13C260.307 172.081 260.563 171.953 260.922 172.191C261.023 172.26 261.106 172.351 261.167 172.456C261.228 172.562 261.265 172.679 261.276 172.801C261.275 172.96 261.227 173.115 261.138 173.246C261.049 173.378 260.923 173.479 260.776 173.539C260.635 173.607 260.476 173.631 260.321 173.608C260.166 173.586 260.021 173.517 259.905 173.411C259.818 173.323 259.752 173.216 259.713 173.099C259.674 172.981 259.663 172.856 259.679 172.734C259.734 172.319 260.027 172.215 260.033 172.252Z"
      fill="#263238"
    />
    <path
      d="M181.185 169.891C181.185 169.928 181.039 170.08 181.051 170.391C181.051 170.476 181.068 170.56 181.101 170.638C181.135 170.715 181.184 170.785 181.246 170.843C181.326 170.898 181.418 170.932 181.515 170.941C181.611 170.95 181.708 170.934 181.797 170.895C181.886 170.856 181.963 170.794 182.021 170.717C182.079 170.64 182.117 170.548 182.13 170.452C182.129 170.368 182.11 170.285 182.076 170.209C182.041 170.132 181.991 170.063 181.929 170.007C181.703 169.781 181.472 169.818 181.466 169.769C181.459 169.72 181.715 169.592 182.075 169.83C182.176 169.899 182.26 169.989 182.321 170.095C182.382 170.201 182.419 170.318 182.428 170.44C182.428 170.598 182.38 170.753 182.291 170.883C182.202 171.014 182.076 171.114 181.929 171.172C181.786 171.241 181.627 171.265 181.47 171.242C181.314 171.22 181.168 171.151 181.051 171.044C180.966 170.955 180.902 170.848 180.864 170.731C180.826 170.613 180.815 170.489 180.832 170.367C180.887 169.958 181.179 169.848 181.185 169.891Z"
      fill="#263238"
    />
    <path
      d="M166.64 171.727C166.64 171.764 166.494 171.91 166.5 172.227C166.502 172.312 166.521 172.396 166.556 172.473C166.59 172.551 166.64 172.621 166.701 172.679C166.779 172.738 166.871 172.775 166.968 172.784C167.065 172.794 167.162 172.776 167.25 172.734C167.341 172.698 167.42 172.638 167.479 172.559C167.537 172.48 167.572 172.386 167.579 172.288C167.579 172.204 167.562 172.12 167.529 172.042C167.495 171.964 167.446 171.895 167.384 171.837C167.158 171.617 166.927 171.648 166.921 171.599C166.915 171.55 167.17 171.428 167.53 171.666C167.63 171.735 167.714 171.825 167.774 171.931C167.834 172.037 167.87 172.155 167.877 172.276C167.88 172.434 167.834 172.589 167.746 172.72C167.658 172.851 167.531 172.952 167.384 173.008C167.241 173.077 167.082 173.101 166.925 173.079C166.769 173.056 166.623 172.987 166.506 172.88C166.421 172.791 166.357 172.684 166.319 172.567C166.281 172.449 166.27 172.325 166.287 172.203C166.348 171.794 166.634 171.685 166.64 171.727Z"
      fill="#263238"
    />
    <path
      d="M150.054 174.289C150.085 174.326 149.902 174.478 149.914 174.796C149.914 174.881 149.932 174.965 149.967 175.043C150.002 175.12 150.052 175.19 150.115 175.247C150.193 175.307 150.285 175.343 150.382 175.351C150.48 175.36 150.577 175.341 150.664 175.296C150.755 175.262 150.834 175.203 150.893 175.125C150.951 175.047 150.986 174.954 150.993 174.857C150.99 174.686 150.92 174.524 150.798 174.405C150.572 174.186 150.335 174.216 150.335 174.167C150.335 174.118 150.584 173.99 150.944 174.234C151.044 174.303 151.127 174.394 151.188 174.499C151.248 174.605 151.283 174.723 151.291 174.844C151.292 175.002 151.245 175.156 151.157 175.286C151.069 175.417 150.944 175.518 150.798 175.576C150.655 175.645 150.496 175.67 150.339 175.647C150.183 175.624 150.037 175.555 149.92 175.448C149.836 175.359 149.772 175.252 149.733 175.135C149.694 175.018 149.681 174.894 149.695 174.771C149.756 174.356 150.048 174.253 150.054 174.289Z"
      fill="#263238"
    />
    <path
      d="M266.674 176.741C266.711 176.778 266.528 176.931 266.54 177.242C266.54 177.326 266.557 177.41 266.59 177.488C266.624 177.566 266.673 177.635 266.735 177.693C266.812 177.754 266.904 177.791 267.001 177.801C267.099 177.81 267.196 177.792 267.284 177.748C267.376 177.713 267.456 177.652 267.515 177.574C267.574 177.495 267.61 177.401 267.619 177.303C267.618 177.219 267.599 177.136 267.565 177.059C267.53 176.982 267.48 176.914 267.418 176.857C267.192 176.632 266.961 176.668 266.955 176.619C266.948 176.571 267.204 176.443 267.564 176.68C267.665 176.749 267.749 176.84 267.81 176.945C267.871 177.051 267.908 177.169 267.917 177.29C267.925 177.404 267.909 177.517 267.87 177.624C267.831 177.73 267.769 177.827 267.69 177.908C267.611 177.99 267.515 178.053 267.41 178.095C267.304 178.136 267.191 178.155 267.078 178.15C266.965 178.145 266.854 178.116 266.753 178.065C266.651 178.013 266.562 177.942 266.491 177.853C266.419 177.765 266.367 177.663 266.338 177.554C266.309 177.444 266.303 177.329 266.321 177.217C266.376 176.809 266.668 176.699 266.674 176.741Z"
      fill="#263238"
    />
    <path
      d="M237.225 125.227C235.492 124.643 233.654 124.438 231.835 124.626C230.016 124.813 228.258 125.388 226.68 126.313C225.102 127.238 223.741 128.491 222.687 129.987C221.633 131.483 220.912 133.188 220.572 134.987L220.517 135.322C220.307 136.552 220.307 137.808 220.517 139.037C220.91 141.325 221.737 143.516 222.955 145.491C226.446 151.158 228.317 151.341 228.658 154.574C228.999 157.807 229.487 158.496 227.836 159.875C227.836 159.875 225.569 160.015 224.332 160.772C223.095 161.528 222.059 162.077 221.925 165.865C221.791 169.653 221.626 171.965 221.626 171.965C228.224 172.353 234.845 172.098 241.393 171.203L240.565 161.65C240.432 161.207 240.183 160.808 239.845 160.494C239.506 160.18 239.09 159.962 238.639 159.863C237.128 159.588 234.794 159.655 234.654 158.832C234.514 158.008 234.861 157.728 234.861 157.728C234.861 157.728 237.609 158.832 239.395 158.002C241.18 157.173 243.179 156.081 243.52 154.702C243.861 153.323 244.94 148.645 244.738 148.437C244.537 148.23 247.426 145.894 247.547 140.507C247.669 135.121 244.099 127.978 239.352 125.983C238.617 125.707 237.908 125.455 237.225 125.227Z"
      fill="#F69C2C"
    />
    <path
      d="M228.445 137.396C228.445 137.396 238.657 134.291 237.134 146.113C237.134 146.113 226.215 147.486 228.445 137.396Z"
      fill="#263238"
    />
    <path
      d="M241.534 145.857C241.534 145.857 237.232 135.585 244.775 136.61C244.775 136.64 248.87 144.045 241.534 145.857Z"
      fill="#263238"
    />
    <path
      d="M255.34 158.746C255.34 158.746 253.512 159.356 253.061 159.857C252.61 160.357 251.927 162.175 251.178 162.394C250.541 162.496 249.89 162.435 249.283 162.217C249.283 162.217 248.887 160.009 248.064 159.027C247.242 158.045 247.034 158.277 247.034 158.277C247.034 158.277 244.969 158.228 245.078 157.057C245.078 157.057 245.938 156.05 247.394 157.453C247.603 157.658 247.879 157.78 248.171 157.798C248.463 157.816 248.752 157.728 248.984 157.551C249.801 156.886 249.923 155.251 249.837 154.159C249.837 154.159 249.015 152.25 250.3 151.866C250.3 151.866 251.379 151.945 250.788 154.177C250.788 154.177 249.843 156.55 251.147 157.49C251.147 157.49 252.208 157.953 253.402 155.892L253.798 155.135C253.798 155.135 253.865 153.5 255.285 153.458C255.367 153.455 255.448 153.481 255.513 153.533C255.578 153.584 255.622 153.657 255.638 153.738C255.712 154.126 255.641 154.527 255.437 154.865C255.234 155.203 254.912 155.453 254.535 155.568C254.535 155.568 252.908 156.788 253.122 158.197C253.122 158.197 253.615 158.728 254.84 158.167L255.309 157.99C255.309 157.99 256.851 156.672 257.076 157.276C257.308 158.02 256.43 158.801 255.34 158.746Z"
      fill="#F69C2C"
    />
    <path
      d="M249.278 161.9L247.285 170.391L249.01 170.117L250.789 169.763L251.819 161.876L249.278 161.9Z"
      fill="#F69C2C"
    />
    <g opacity="0.2">
      <path
        d="M227.182 128.307C226.809 128.307 226.446 128.434 226.155 128.668C225.863 128.902 225.66 129.229 225.58 129.594C225.526 129.973 225.599 130.358 225.789 130.69C225.979 131.022 226.274 131.28 226.628 131.424C226.991 131.548 227.386 131.543 227.747 131.413C228.108 131.282 228.414 131.032 228.614 130.705C228.795 130.366 228.853 129.975 228.779 129.598C228.704 129.221 228.502 128.881 228.206 128.637C228.016 128.492 227.796 128.39 227.562 128.34C227.328 128.289 227.086 128.291 226.853 128.344"
        fill="white"
      />
    </g>
    <path
      d="M230.046 139.769C229.892 139.755 229.737 139.793 229.608 139.879C229.478 139.964 229.381 140.091 229.333 140.239C229.287 140.395 229.297 140.563 229.363 140.713C229.43 140.862 229.547 140.982 229.694 141.052C229.842 141.122 230.009 141.137 230.166 141.094C230.324 141.052 230.461 140.954 230.552 140.819C230.64 140.685 230.68 140.525 230.663 140.366C230.646 140.207 230.575 140.058 230.461 139.946C230.388 139.879 230.301 139.829 230.206 139.799C230.111 139.77 230.011 139.761 229.912 139.775"
      fill="#455A64"
    />
    <path
      d="M230.327 142.283C230.249 142.275 230.17 142.294 230.105 142.337C230.039 142.381 229.991 142.445 229.967 142.52C229.948 142.599 229.956 142.682 229.991 142.755C230.025 142.828 230.084 142.888 230.156 142.923C230.229 142.958 230.312 142.966 230.39 142.946C230.468 142.926 230.537 142.879 230.583 142.813C230.623 142.76 230.646 142.695 230.649 142.629C230.652 142.562 230.635 142.496 230.6 142.439C230.565 142.382 230.515 142.337 230.454 142.309C230.393 142.281 230.326 142.272 230.26 142.283"
      fill="#455A64"
    />
    <g opacity="0.2">
      <path
        d="M224.747 132.102C224.605 132.155 224.486 132.256 224.411 132.387C224.336 132.519 224.31 132.673 224.336 132.822C224.363 132.971 224.442 133.106 224.558 133.204C224.674 133.301 224.821 133.354 224.972 133.354C225.123 133.354 225.27 133.301 225.386 133.204C225.502 133.106 225.581 132.971 225.608 132.822C225.635 132.673 225.608 132.519 225.533 132.387C225.458 132.256 225.339 132.155 225.198 132.102"
        fill="white"
      />
    </g>
    <path
      d="M234.081 147.236C233.922 147.268 233.758 147.268 233.599 147.236C233.159 147.221 232.721 147.155 232.295 147.041C230.864 146.678 229.583 145.877 228.629 144.749C227.675 143.622 227.097 142.224 226.976 140.751C226.93 140.311 226.93 139.868 226.976 139.428C226.981 139.265 227.018 139.106 227.085 138.958C227.146 138.958 227.031 139.641 227.177 140.721C227.353 142.128 227.934 143.454 228.85 144.537C229.765 145.62 230.975 146.413 232.332 146.821C233.374 147.175 234.087 147.181 234.081 147.236Z"
      fill="#263238"
    />
    <path
      d="M237.152 139.641C236.393 138.652 235.462 137.809 234.404 137.152C233.297 136.583 232.098 136.216 230.863 136.067C231.23 135.974 231.612 135.962 231.984 136.03C232.891 136.138 233.768 136.426 234.562 136.878C235.356 137.329 236.052 137.935 236.609 138.659C236.861 138.942 237.047 139.277 237.152 139.641Z"
      fill="#263238"
    />
    <path
      d="M240.272 140.91C240.161 140.611 240.103 140.295 240.102 139.977C240.014 139.206 240.082 138.426 240.303 137.683C240.422 137.305 240.615 136.955 240.87 136.653C241.125 136.35 241.438 136.101 241.79 135.92C241.929 135.832 242.084 135.774 242.246 135.747C242.409 135.721 242.575 135.728 242.734 135.768C242.447 135.867 242.167 135.983 241.893 136.115C241.282 136.506 240.826 137.097 240.601 137.787C240.395 138.496 240.303 139.233 240.327 139.971C240.361 140.285 240.342 140.602 240.272 140.91Z"
      fill="#263238"
    />
    <path
      d="M245.153 144.735C245.034 145.058 244.848 145.352 244.608 145.598C244.367 145.844 244.078 146.036 243.758 146.162C243.463 146.338 243.134 146.45 242.792 146.49C242.451 146.53 242.105 146.497 241.777 146.394C242.403 146.287 243.015 146.115 243.605 145.882C244.161 145.555 244.679 145.171 245.153 144.735Z"
      fill="#263238"
    />
    <path
      d="M239.255 150.67C239.255 150.707 239.133 150.835 238.84 150.908C238.646 150.956 238.444 150.965 238.247 150.934C238.049 150.903 237.86 150.833 237.69 150.728C237.52 150.622 237.372 150.484 237.256 150.322C237.139 150.159 237.057 149.975 237.012 149.779C236.951 149.48 237.012 149.304 237.049 149.304C237.085 149.304 237.122 149.474 237.225 149.706C237.352 150.009 237.563 150.267 237.835 150.45C238.118 150.618 238.444 150.701 238.773 150.688C239.072 150.67 239.236 150.621 239.255 150.67Z"
      fill="#263238"
    />
    <path
      d="M237.475 158.118C237.116 158.21 236.742 158.222 236.378 158.155C235.49 158.045 234.635 157.752 233.866 157.294C233.097 156.836 232.431 156.224 231.911 155.495C231.674 155.21 231.507 154.874 231.424 154.513C231.665 154.781 231.889 155.064 232.094 155.361C233.194 156.695 234.724 157.603 236.42 157.929C236.776 157.968 237.128 158.031 237.475 158.118Z"
      fill="#263238"
    />
    <path
      d="M225.825 172.057C225.613 170.112 225.613 168.151 225.825 166.207C226.036 168.151 226.036 170.112 225.825 172.057Z"
      fill="#263238"
    />
    <path
      d="M232.095 169.794C231.232 170.124 230.302 170.243 229.383 170.141C228.707 170.174 228.041 169.974 227.494 169.574C227.092 169.202 227.098 168.824 227.135 168.83C227.172 168.836 227.263 169.129 227.635 169.397C228.168 169.706 228.78 169.855 229.396 169.824C230.876 169.903 232.077 169.702 232.095 169.794Z"
      fill="#263238"
    />
    <path
      d="M238.06 171.569C237.837 170.774 237.712 169.954 237.688 169.129C237.563 168.309 237.543 167.477 237.627 166.652C237.986 168.267 238.132 169.922 238.06 171.575V171.569Z"
      fill="#263238"
    />
    <path
      d="M237.761 167.628C237.58 168.182 237.203 168.65 236.702 168.946C236.2 169.241 235.608 169.343 235.037 169.232C235.553 169.086 236.056 168.898 236.542 168.671C237.304 168.232 237.676 167.579 237.761 167.628Z"
      fill="#263238"
    />
    <path
      d="M243.782 124.586C243.098 124.893 242.452 125.278 241.857 125.733C241.331 126.269 240.871 126.865 240.486 127.508C240.449 127.508 240.486 127.228 240.656 126.837C240.889 126.326 241.227 125.87 241.646 125.497C242.066 125.124 242.559 124.843 243.093 124.672C243.508 124.55 243.776 124.55 243.782 124.586Z"
      fill="#455A64"
    />
    <path
      d="M244.525 123.934C244.336 123.84 244.118 123.821 243.916 123.881C243.714 123.941 243.541 124.075 243.434 124.257C243.364 124.388 243.336 124.538 243.352 124.685C243.368 124.833 243.429 124.972 243.526 125.085C243.623 125.198 243.751 125.279 243.895 125.317C244.038 125.355 244.19 125.35 244.33 125.3C244.449 125.256 244.556 125.184 244.643 125.092C244.73 124.999 244.794 124.887 244.83 124.766C244.867 124.644 244.875 124.516 244.854 124.39C244.832 124.265 244.782 124.146 244.708 124.044C244.659 123.973 244.591 123.917 244.513 123.881C244.435 123.846 244.348 123.832 244.263 123.842"
      fill="#455A64"
    />
    <path
      d="M240.985 123.476C240.3 123.778 239.654 124.161 239.06 124.617C238.535 125.153 238.074 125.748 237.689 126.392C237.652 126.392 237.689 126.118 237.853 125.721C238.09 125.209 238.43 124.752 238.853 124.379C239.273 124.006 239.767 123.725 240.303 123.556C240.687 123.434 240.979 123.44 240.985 123.476Z"
      fill="#455A64"
    />
    <path
      d="M241.729 122.817C241.539 122.724 241.321 122.706 241.119 122.767C240.916 122.828 240.744 122.964 240.638 123.147C240.567 123.278 240.537 123.427 240.553 123.575C240.569 123.724 240.63 123.863 240.727 123.976C240.824 124.089 240.954 124.169 241.098 124.206C241.242 124.243 241.394 124.236 241.534 124.184C241.653 124.14 241.76 124.07 241.847 123.977C241.934 123.885 241.998 123.774 242.034 123.653C242.071 123.532 242.079 123.404 242.058 123.279C242.036 123.154 241.986 123.036 241.912 122.933C241.864 122.861 241.797 122.804 241.718 122.768C241.64 122.733 241.552 122.72 241.467 122.732"
      fill="#455A64"
    />
    <path
      d="M162.625 132.431L162.918 132.962C163.251 133.554 163.743 134.042 164.337 134.371L164.868 134.658L164.337 134.95C163.741 135.273 163.248 135.76 162.918 136.353L162.625 136.884L162.333 136.353C162.006 135.759 161.515 135.272 160.919 134.95L160.383 134.658L160.919 134.371C161.514 134.045 162.005 133.556 162.333 132.962L162.625 132.431Z"
      fill="#FAFAFA"
    />
    <path
      d="M208.392 217.508L208.685 218.038C209.011 218.633 209.502 219.12 210.098 219.441L210.635 219.734L210.098 220.021C209.505 220.345 209.018 220.834 208.697 221.43L208.404 221.955L208.112 221.43C207.784 220.834 207.291 220.345 206.692 220.021L206.162 219.734L206.692 219.441C207.289 219.118 207.781 218.632 208.112 218.038L208.392 217.508Z"
      fill="#EBEBEB"
    />
    <path
      d="M117.529 270.742L117.822 271.273C118.152 271.866 118.645 272.353 119.242 272.676L119.778 272.969L119.242 273.256C118.643 273.579 118.15 274.068 117.822 274.665L117.529 275.189L117.237 274.665C116.913 274.067 116.421 273.578 115.823 273.256L115.287 272.969L115.823 272.676C116.42 272.355 116.911 271.867 117.237 271.273L117.529 270.742Z"
      fill="#EBEBEB"
    />
    <path
      d="M313.655 34.8371L313.947 35.3678C314.272 35.9605 314.758 36.4491 315.349 36.7769L315.879 37.0636L315.349 37.3503C314.758 37.6781 314.272 38.1667 313.947 38.7594L313.655 39.2901L313.368 38.7594C313.048 38.1675 312.566 37.6789 311.979 37.3503L311.455 37.0636L311.979 36.7769C312.564 36.4516 313.046 35.9674 313.368 35.38L313.655 34.8371Z"
      fill="#455A64"
    />
    <path
      d="M232.912 240.999L233.204 241.529C233.527 242.122 234.014 242.609 234.606 242.932L235.136 243.225L234.606 243.512C234.012 243.837 233.526 244.326 233.204 244.921L232.912 245.446L232.625 244.921C232.303 244.324 231.814 243.834 231.218 243.512L230.688 243.225L231.218 242.932C231.812 242.611 232.301 242.123 232.625 241.529L232.912 240.999Z"
      fill="#455A64"
    />
    <path
      d="M64.2551 68.5579L64.5476 69.0886C64.873 69.683 65.3614 70.1719 65.9551 70.4977L66.4791 70.7844L65.9551 71.0711C65.3614 71.3969 64.873 71.8858 64.5476 72.4802L64.2551 73.0109L63.9687 72.4802C63.6432 71.8858 63.1549 71.3969 62.5611 71.0711L62.0371 70.7844L62.5611 70.4977C63.1549 70.1719 63.6432 69.683 63.9687 69.0886L64.2551 68.5579Z"
      fill="#455A64"
    />
    <path
      d="M341.403 257.56L341.696 258.091C342.021 258.684 342.507 259.172 343.097 259.5L343.628 259.787L343.097 260.08C342.506 260.403 342.019 260.89 341.696 261.483L341.403 262.013L341.117 261.483C340.793 260.889 340.304 260.402 339.71 260.08L339.186 259.787L339.71 259.5C340.303 259.174 340.792 258.685 341.117 258.091L341.403 257.56Z"
      fill="#455A64"
    />
    <path
      d="M310.761 201.245L311.048 201.776C311.375 202.367 311.863 202.854 312.455 203.179L312.985 203.472L312.455 203.758C311.861 204.084 311.373 204.573 311.048 205.167L310.761 205.698L310.469 205.167C310.147 204.572 309.66 204.083 309.067 203.758L308.537 203.472L309.067 203.179C309.659 202.855 310.145 202.368 310.469 201.776L310.761 201.245Z"
      fill="#455A64"
    />
    <path
      d="M115.287 167.53L115.579 168.061C115.904 168.654 116.39 169.142 116.981 169.47L117.511 169.757L116.981 170.044C116.39 170.371 115.904 170.86 115.579 171.453L115.287 171.983L115 171.453C114.67 170.862 114.183 170.374 113.593 170.044L113.062 169.757L113.593 169.47C114.183 169.14 114.67 168.652 115 168.061L115.287 167.53Z"
      fill="#455A64"
    />
    <path
      d="M374.955 153.403L375.101 153.665C375.263 153.961 375.506 154.205 375.802 154.367L376.07 154.513L375.802 154.659C375.506 154.821 375.263 155.065 375.101 155.361L374.955 155.629L374.815 155.361C374.652 155.063 374.407 154.819 374.108 154.659L373.846 154.513L374.108 154.367C374.407 154.207 374.652 153.963 374.815 153.665L374.955 153.403Z"
      fill="#455A64"
    />
    <path
      d="M168.609 216.398L168.743 216.666C168.892 216.969 169.128 217.221 169.419 217.392L169.675 217.55L169.407 217.685C169.105 217.836 168.854 218.071 168.682 218.362L168.523 218.618L168.389 218.35C168.24 218.045 168.005 217.79 167.713 217.618L167.457 217.465L167.725 217.331C168.028 217.179 168.282 216.945 168.456 216.654L168.609 216.398Z"
      fill="#455A64"
    />
    <path
      d="M352.379 86.6871L352.525 86.9494C352.683 87.2481 352.927 87.4925 353.226 87.6509L353.488 87.7973L353.226 87.9437C352.927 88.102 352.683 88.3464 352.525 88.6452L352.379 88.9075L352.232 88.6452C352.074 88.3464 351.83 88.102 351.532 87.9437L351.27 87.7973L351.532 87.6509C351.83 87.4925 352.074 87.2481 352.232 86.9494L352.379 86.6871Z"
      fill="#455A64"
    />
    <path
      d="M173.662 94.1291L173.808 94.3975C173.973 94.6915 174.215 94.9342 174.509 95.099L174.777 95.2454L174.509 95.3857C174.214 95.5521 173.972 95.7971 173.808 96.0933L173.662 96.38L173.522 96.1177C173.358 95.8195 173.113 95.574 172.815 95.4101L172.553 95.2698L172.815 95.1234C173.111 94.9595 173.355 94.7167 173.522 94.4219L173.662 94.1291Z"
      fill="#455A64"
    />
    <path
      d="M95.5867 45.2437L95.7269 45.5121C95.8931 45.8069 96.1378 46.0497 96.4337 46.2136L96.6957 46.36L96.4337 46.5003C96.1358 46.6642 95.8906 46.9097 95.7269 47.2079L95.5867 47.4702L95.4405 47.2079C95.2768 46.9117 95.0342 46.6667 94.7397 46.5003L94.4473 46.36L94.7154 46.2136C95.009 46.0488 95.2515 45.806 95.4161 45.5121L95.5867 45.2437Z"
      fill="#455A64"
    />
    <path
      d="M246.562 64.721L247.055 65.6299C247.616 66.6611 248.463 67.5084 249.493 68.0699L250.407 68.5701L249.493 69.0703C248.463 69.6319 247.616 70.4791 247.055 71.5103L246.562 72.4253L246.062 71.5103C245.501 70.4791 244.655 69.6319 243.625 69.0703L242.711 68.5701L243.625 68.0699C244.655 67.5084 245.501 66.6611 246.062 65.6299L246.562 64.721Z"
      fill="#455A64"
    />
    <path
      d="M398.505 207.308L399.004 208.223C399.565 209.255 400.412 210.102 401.442 210.663L402.35 211.164L401.442 211.664C400.412 212.225 399.565 213.073 399.004 214.104L398.505 215.013L398.017 214.11C397.456 213.079 396.61 212.232 395.58 211.67L394.666 211.17L395.58 210.67C396.61 210.108 397.456 209.261 398.017 208.23L398.505 207.308Z"
      fill="#455A64"
    />
    <path
      d="M89.7865 241.609L90.2801 242.524C90.841 243.555 91.6873 244.402 92.7174 244.964L93.6314 245.458L92.7174 245.958C91.6873 246.52 90.841 247.367 90.2801 248.398L89.7865 249.313L89.2869 248.398C88.726 247.367 87.8796 246.52 86.8495 245.958L85.9355 245.458L86.8495 244.964C87.8796 244.402 88.726 243.555 89.2869 242.524L89.7865 241.609Z"
      fill="#455A64"
    />
    <path
      d="M399.172 117.579C415.13 117.013 427.608 103.603 427.042 87.6278C426.477 71.6522 413.081 59.1605 397.123 59.7267C381.165 60.293 368.687 73.7028 369.253 89.6784C369.818 105.654 383.213 118.146 399.172 117.579Z"
      fill="#455A64"
    />
    <path
      opacity="0.4"
      d="M389.626 93.4337C389.051 94.1693 388.271 94.7177 387.385 95.0095C386.499 95.3013 385.546 95.3234 384.647 95.0729C383.748 94.8225 382.944 94.3108 382.335 93.6025C381.727 92.8943 381.342 92.0213 381.23 91.0941C381.117 90.1669 381.282 89.2271 381.703 88.3936C382.124 87.5601 382.783 86.8704 383.595 86.4117C384.408 85.9529 385.338 85.7459 386.269 85.8166C387.199 85.8873 388.088 86.2327 388.822 86.8091C389.805 87.5812 390.443 88.7126 390.593 89.9548C390.744 91.1969 390.396 92.4482 389.626 93.4337Z"
      fill="#263238"
    />
    <path
      opacity="0.4"
      d="M392.758 78.5802C392.423 79.0075 391.97 79.3259 391.455 79.4952C390.94 79.6646 390.386 79.6772 389.864 79.5316C389.341 79.3859 388.874 79.0885 388.52 78.6769C388.167 78.2653 387.943 77.758 387.878 77.2192C387.812 76.6804 387.907 76.1342 388.152 75.6497C388.396 75.1652 388.779 74.7641 389.251 74.4971C389.723 74.2302 390.264 74.1094 390.804 74.1499C391.345 74.1905 391.862 74.3906 392.289 74.725C392.572 74.9472 392.81 75.2232 392.987 75.5373C393.164 75.8513 393.278 76.1972 393.321 76.5553C393.365 76.9133 393.338 77.2764 393.241 77.6239C393.144 77.9713 392.98 78.2963 392.758 78.5802Z"
      fill="#263238"
    />
    <path
      d="M163.229 286.089C170.458 285.777 176.065 279.657 175.753 272.421C175.441 265.184 169.329 259.571 162.1 259.883C154.872 260.195 149.264 266.315 149.576 273.551C149.888 280.788 156.001 286.401 163.229 286.089Z"
      fill="#455A64"
    />
    <path
      opacity="0.2"
      d="M157.142 269.4C156.721 269.447 156.296 269.368 155.92 269.173C155.544 268.978 155.235 268.676 155.031 268.305C154.827 267.934 154.737 267.511 154.774 267.088C154.81 266.666 154.971 266.265 155.235 265.934C155.5 265.603 155.856 265.359 156.26 265.231C156.663 265.103 157.096 265.098 157.502 265.217C157.908 265.335 158.271 265.572 158.543 265.896C158.815 266.221 158.984 266.619 159.03 267.04C159.092 267.603 158.928 268.169 158.574 268.611C158.219 269.054 157.704 269.338 157.142 269.4Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M161.516 264.496C161.278 264.517 161.039 264.467 160.829 264.353C160.619 264.239 160.447 264.066 160.335 263.855C160.222 263.644 160.174 263.405 160.197 263.166C160.219 262.928 160.311 262.702 160.461 262.516C160.61 262.33 160.811 262.191 161.039 262.119C161.266 262.046 161.51 262.041 161.74 262.106C161.97 262.171 162.176 262.302 162.332 262.483C162.488 262.664 162.588 262.886 162.619 263.124C162.64 263.287 162.628 263.453 162.583 263.611C162.539 263.769 162.463 263.917 162.36 264.045C162.257 264.174 162.129 264.28 161.984 264.357C161.839 264.434 161.679 264.482 161.516 264.496Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M165.524 265.643C165.514 265.553 165.531 265.462 165.572 265.382C165.614 265.301 165.678 265.235 165.757 265.191C165.836 265.147 165.927 265.127 166.017 265.135C166.107 265.142 166.193 265.176 166.264 265.233C166.335 265.289 166.387 265.365 166.415 265.451C166.442 265.538 166.444 265.63 166.419 265.717C166.394 265.804 166.344 265.882 166.275 265.94C166.206 265.999 166.121 266.035 166.031 266.045C165.971 266.053 165.91 266.049 165.852 266.032C165.794 266.016 165.74 265.989 165.693 265.951C165.645 265.913 165.606 265.867 165.577 265.814C165.548 265.761 165.53 265.703 165.524 265.643Z"
      fill="black"
    />
    <path
      d="M60.5491 163.864C74.5721 163.864 85.94 152.484 85.94 138.446C85.94 124.407 74.5721 113.027 60.5491 113.027C46.5261 113.027 35.1582 124.407 35.1582 138.446C35.1582 152.484 46.5261 163.864 60.5491 163.864Z"
      fill="#455A64"
    />
    <path
      opacity="0.2"
      d="M51.9022 147.352C51.9022 148.172 51.659 148.974 51.2034 149.657C50.7479 150.339 50.1004 150.871 49.3429 151.184C48.5855 151.498 47.752 151.58 46.9481 151.419C46.1442 151.259 45.4059 150.863 44.8267 150.283C44.2474 149.702 43.8533 148.962 43.6941 148.157C43.5349 147.352 43.6178 146.518 43.9324 145.76C44.2469 145.002 44.779 144.355 45.4611 143.9C46.1433 143.445 46.945 143.202 47.7648 143.204C48.3086 143.204 48.8471 143.311 49.3495 143.519C49.8519 143.728 50.3082 144.034 50.6925 144.419C51.0768 144.804 51.3814 145.261 51.589 145.765C51.7965 146.268 51.903 146.807 51.9022 147.352Z"
      fill="white"
    />
    <path
      opacity="0.2"
      d="M46.0288 135.383C46.0348 135.867 45.897 136.342 45.6329 136.747C45.3688 137.152 44.9903 137.47 44.5455 137.659C44.1007 137.849 43.6097 137.901 43.1349 137.811C42.6601 137.72 42.223 137.49 41.879 137.15C41.5351 136.81 41.2999 136.375 41.2034 135.901C41.1069 135.427 41.1533 134.935 41.3369 134.487C41.5204 134.04 41.8328 133.657 42.2342 133.387C42.6357 133.118 43.1081 132.974 43.5914 132.974C44.2326 132.974 44.848 133.227 45.3042 133.678C45.7604 134.129 46.0208 134.742 46.0288 135.383Z"
      fill="white"
    />
    <path
      d="M35.9502 144.631C36.0599 145.07 14.2153 166.054 19.8456 170.519C25.4758 174.985 49.1667 163.523 69.36 147.199C89.5533 130.875 107.967 111.02 102.916 105.798C97.8646 100.577 72.6382 116.089 72.6382 116.089L75.6849 118.011C75.6849 118.011 90.9182 108.214 95.7929 112.014C100.668 115.815 86.8661 130.674 67.4467 144.863C48.0272 159.051 29.211 169.013 26.2984 165.127C23.3858 161.241 37.1262 148.273 37.1262 148.273L35.9502 144.631Z"
      fill="#0F314D"
    />
    <path
      d="M308.373 119.074C311.713 115.577 311.589 110.032 308.097 106.689C304.604 103.345 299.065 103.469 295.725 106.965C292.385 110.462 292.509 116.007 296.002 119.35C299.494 122.694 305.033 122.57 308.373 119.074Z"
      fill="#455A64"
    />
    <path
      d="M304.905 109.672C304.912 109.39 305.003 109.117 305.165 108.886C305.327 108.656 305.554 108.478 305.817 108.377C306.08 108.276 306.367 108.254 306.642 108.316C306.917 108.378 307.167 108.519 307.362 108.723C307.556 108.927 307.686 109.185 307.735 109.462C307.783 109.74 307.749 110.026 307.635 110.284C307.521 110.542 307.334 110.761 307.096 110.913C306.859 111.064 306.582 111.142 306.3 111.136C306.112 111.132 305.927 111.091 305.755 111.015C305.583 110.939 305.428 110.83 305.299 110.694C305.169 110.558 305.067 110.398 305 110.222C304.932 110.047 304.9 109.86 304.905 109.672Z"
      fill="#263238"
    />
    <path
      d="M307.756 113.009C307.758 112.843 307.81 112.682 307.904 112.545C307.999 112.409 308.131 112.304 308.286 112.243C308.44 112.183 308.608 112.169 308.77 112.205C308.932 112.241 309.079 112.324 309.194 112.443C309.308 112.563 309.385 112.714 309.413 112.878C309.441 113.041 309.421 113.209 309.353 113.361C309.286 113.512 309.175 113.64 309.035 113.728C308.895 113.817 308.732 113.861 308.566 113.856C308.347 113.85 308.139 113.758 307.987 113.599C307.836 113.44 307.753 113.228 307.756 113.009Z"
      fill="#263238"
    />
    <path
      d="M306.647 115.558C306.717 115.573 306.78 115.611 306.826 115.666C306.872 115.722 306.896 115.792 306.896 115.863C306.896 115.935 306.872 116.005 306.826 116.061C306.78 116.116 306.717 116.154 306.647 116.168C306.565 116.165 306.488 116.13 306.432 116.071C306.376 116.012 306.346 115.933 306.348 115.851C306.354 115.774 306.387 115.702 306.442 115.649C306.497 115.595 306.57 115.563 306.647 115.558Z"
      fill="#263238"
    />
  </svg>
);
