import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

interface WeeklyBoardProps {
  color: string;
  weekday: string;
  title: string;
}

export const useStylesEmailBlock = createUseStyles((theme: AppTheme) => ({
  emailHeader: {
    flexBasis: "32%",
  },
  titleContainer: {
    background: theme.whiteColor,
    padding: "24px 24px 24px 44px",
    marginBottom: "8px",
    borderBottom: `1px solid ${theme.deepBlueColor}`,
    position: "relative",
    "&>h4": {
      color: ({ weekday, title }: WeeklyBoardProps) =>
        (weekday !== "Monday" && title === "To send"
          ? "#B3261E"
          : theme.textColor),
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "27px",
      margin: 0,
      width: "100%",
      "&::before": {
        content: "''",
        width: "12px",
        height: "12px",
        position: "absolute",
        top: "50%",
        left: "24px",
        transform: "translateY(-50%)",
        borderRadius: "100%",
        backgroundColor: ({ color }: WeeklyBoardProps) => color,
      },
    },
  },
  overdue: {
    background: "rgba(179, 38, 30, 0.15)",
    padding: "4px 8px",
    borderRadius: "15px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#B3261E",
    display: ({ weekday }: WeeklyBoardProps) =>
      (weekday !== "Monday" ? "block" : "none"),
  },
  ownerName: {
    borderRadius: 0,
    backgroundColor: theme.whiteColor,
    color: theme.textColor,
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "27px",
    marginTop: "8px",
    width: "100%",
    borderBottom: `1px solid ${theme.secondaryColor}`,
    textAlign: "left",
    position: "relative",
    "&:hover": {
      backgroundColor: "rgba(15, 49, 77, 0.03) !important",
    },
  },
  statusText: {
    color: "#959595",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: "400",
    position: "absolute",
    right: "14px",
    bottom: "10px",
    fontStyle: "italic",
  },
  tooltipText: {
    display: "none",
    position: "absolute",
    left: "40px",
    top: "50%",
    transform: "translateY(-50%)",
    background: "#fff",
    borderRadius: "8px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#444",
    padding: "12px 16px",
    boxShadow: "0px 0px 20px 0px rgba(116, 116, 116, 0.15)",
    zIndex: "999",
    "&:before": {
      content: "''",
      position: "absolute",
      right: "calc(100% - 20px)",
      top: "13px",
      transform: "translate(-50%) rotate(45deg)",
      width: "20px",
      height: "20px",
      backgroundColor: "#fff",
      borderRadius: "1px",
    },
  },
  tooltipWrapper: {
    position: "relative",
    zIndex: 998,
    // top: "26px",
    // right: "24px",
    "&>img": {
      width: "40px",
      boxShadow: " 0px 0px 32px rgba(68,68,68,0.25)",
      borderRadius: "32px",
    },
    "&:hover $tooltipText": {
      display: "block",
    },
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
