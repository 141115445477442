import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disableSearchHistory: false,
  searchingCampaignLoading:false,
};

export const allDuplicationSlice = createSlice({
  name: "AllDuplicationSlice",
  initialState,
  reducers: {
    setSearchingCampaignLoading: (state, action) => {
      state.searchingCampaignLoading = action.payload;
    },
    setDisableSearchHistory: (state, action) => {
      state.disableSearchHistory = action.payload;
    },
  },
});

export default allDuplicationSlice.reducer;
