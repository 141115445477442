import React from 'react';
import { Card, CardContent, CssBaseline, Typography } from '@mui/material';
import logoVertical from 'assets/icons/logoVertical.svg';
import { useStyles } from '../../modules/Authentication/authShared.styles';
import { useStylesCard } from './auth.styles';

import './styles.scss';

interface IAuthenticationLayout {
    children: React.ReactNode
    title?: string | boolean
    description?: string | boolean
}

const AuthenticationLayout: React.FC<IAuthenticationLayout> = (
  {
    children,
    title,
    description,
  }) => {
  const classes = useStyles();
  const styles = useStylesCard();

  return (
    <div className="container-main-auth">
      <div className="auth-wrapper">
        <Card className={styles.root}>
          <CardContent className={styles.cardContent}>
            <CssBaseline/>
            <div className={classes.paper}>
              <img src={logoVertical} alt="logo"/>
              {title && <Typography className="auth-title">{title}</Typography>}
              {description && <p className="description">{description}</p>}
              {children}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default AuthenticationLayout;
