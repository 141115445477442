import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStyles = createUseStyles((theme: AppTheme) => ({
  contentWrapper: {
    padding: "32px",
    width: "634px",
  },
  title: {
    color: theme.deepBlueColor,
    fontSize: "24px",
    fontWeight: 500,
    lineHeight: "36px",
    margin: "0 0 24px",
  },
  reasontext: {
    borderRadius: "4px",
    border: "1px solid #F2F2F2",
    background: "#fff",
    width: "100%",
    height: "115px",
    padding: "16px",
    color: theme.textColor,
    outline: "none",
    fontFamily: "Roboto",
    resize: "none",
    lineHeight: "27px",
    fontSize: "18px",
    fontWeight: "400",
    "&::placeholder": {
      fontFamily: "Roboto",
      color: theme.grey4,
      fontSize: "18px",
      fontWeight: "400",
      lineHeight: "27px",
    },
    "&:focus": {
      border: "1px solid #D9D9D9",
    },
  },
}));
