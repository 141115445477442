import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  emailForResetWasSent: {
    sent: null,
    message: "",
  },
  confirmPasswordReset: {
    sent: null,
    message: "",
  },
  disableEnterBtn: false,
  loading: false,
};

export const passwordResetSlice = createSlice({
  name: "PasswordReset",
  initialState,
  reducers: {
    setEmailForResetWasSent: (state, action) => {
      state.emailForResetWasSent = action.payload;
    },
    setConfirmResetPassword: (state, action) => {
      state.confirmPasswordReset = action.payload;
    },
    setDisableEnterBtn: (state, action) => {
      state.disableEnterBtn = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  setEmailForResetWasSent,
  setConfirmResetPassword,
  setDisableEnterBtn,
  setLoading,
} = passwordResetSlice.actions;

export default passwordResetSlice.reducer;
