import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { servicesNames } from "../../../../constants/servicesNames.constants";
import { getEmailReportById } from "../../../../services/clientCommunication";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import Flex from "../../../shared/components/general/Flex/Flex";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import { reportStatuses } from "../../constants/constants";
import { useStyles } from "./UserEmailDrawer.styles";
import send from "assets/icons/ship.svg";
import emptyInbox from "assets/icons/emptyInbox.svg";
import skip from "assets/icons/skipedInbox.svg";
import regenerate from "assets/icons/regeneratedInbox.svg";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";

const SendingIsInProgress = ({
  emailId,
  setEmailId,
  setCurrentElementIndex,
  currentElementIndex,
  handleCloseDrawer,
  type,
}:any) => {
  const styles = useStyles();
  const [nextEmailId, setNextEmailId] = useState(null);
  const [nextEmailSendPending, setNextEmailSendPending] =
    useState<boolean>(false);
  const {
    updatedStatus,
    emailStatus,
    flattenedArraySkippedResult,
    flattenedArraySentResult,
    flattenedArrayToSendResult,
    toSendHasNextPage,
    toSendFetchNextPage,
    setUserEmailReport,
  } = useClientCommunicationContext();

  const emailReportsModifiedData =
    type === "skipped"
      ? flattenedArraySkippedResult
      : type === "sent"
        ? flattenedArraySentResult
        : flattenedArrayToSendResult;

  useQuery(
    [servicesNames.getEmailReportById, { nextEmailId }],
    () => getEmailReportById(nextEmailId),
    {
      onSuccess: (response) => {
        setUserEmailReport(response);
      },
      enabled: Boolean(nextEmailId),
    },
  );

  useEffect(() => {
    if (updatedStatus) {
      emailReportsModifiedData[currentElementIndex].status = updatedStatus;
    }

    // eslint-disable-next-line
  }, [updatedStatus]);

  const checkNextItem = (currentElementIndex: any) => {
    const nextItem = emailReportsModifiedData[currentElementIndex + 1];
    const checkForStatus =
      nextItem?.status === reportStatuses.CREATED ||
      nextItem?.status === reportStatuses.REGENERATED ||
      nextItem?.status === reportStatuses.FAILED_TO_SEND ||
      nextItem?.status === reportStatuses.FAILED_TO_REGENERATE;
    if (checkForStatus) {
      setNextEmailId(nextItem?.id);
      setEmailId(nextItem?.id);
      setCurrentElementIndex(emailReportsModifiedData.indexOf(nextItem));
      return;
    } else {
      checkNextItem(currentElementIndex + 1);
    }
  };

  const handleNextClick = () => {
    if (
      currentElementIndex !== null &&
      currentElementIndex < emailReportsModifiedData.length - 1
    ) {
      checkNextItem(currentElementIndex);
    } else if (toSendHasNextPage) {
      setNextEmailSendPending(true);
      toSendFetchNextPage();
    }
  };

  useEffect(() => {
    if (nextEmailSendPending) {
      const nextElementIndex = currentElementIndex + 1;
      const nextItem = emailReportsModifiedData[nextElementIndex];
      if (nextItem) {
        setCurrentElementIndex(nextElementIndex);
        setNextEmailId(() => nextItem?.id);
        setNextEmailSendPending(false);
      }
    }

    // eslint-disable-next-line
  }, [emailReportsModifiedData.length, nextEmailSendPending]);

  const generateContent = () => {
    const getContent = (imageSrc:any, title:any, description:any) => (
      <Flex
        style={{ width: "100%", height: "100%", textAlign: "center" }}
        justifyContent="center"
        alignItems="center"
      >
        <div>
          <ImageLoader
            src={imageSrc}
            style={{ width: "200px", height: "105px" }}
            alt={"imageSrc"}
          />
          <h2 className={styles.isInProgressTitle}>{title}</h2>
          <p className={styles.isInProgressDescription}>{description}</p>
          <div>
            <Button
              variant={ButtonVariants.Primary}
              handleClick={() => handleNextClick()}
              style={{ padding: "12px 16px" }}
            >
              Next email
            </Button>
          </div>
        </div>
      </Flex>
    );

    switch (emailStatus[emailId]) {
      case "send":
        return getContent(
          send,
          "Sending in progress",
          <>
            Please refresh the page a bit later to check if the email was sent.
            <br />
            Meanwhile, you can review the next email.
          </>,
        );
      case "skip":
        return getContent(
          skip,
          "The email has been skipped",
          "You can review the next email.",
        );
      case "regenerate":
        return getContent(
          regenerate,
          "Email regeneration is in progress",
          <>
            Please refresh the page a bit later to check if the email was
            regenerated.
            <br />
            Meanwhile, you can review the next email.
          </>,
        );
      default:
        break;
    }
  };

  return currentElementIndex === emailReportsModifiedData.length - 1 &&
    !toSendHasNextPage ? (
      <Flex
        style={{ width: "100%", height: "100%", textAlign: "center" }}
        justifyContent="center"
        alignItems="center"
      >
        <div>
          <ImageLoader
            src={emptyInbox}
            style={{ width: "200px", height: "150px" }}
            alt={"emptyInbox"}
          />
          <h2 className={styles.isInProgressTitle}>No more emails to send.</h2>
          <p className={styles.isInProgressDescription}>
          Please refresh the page a bit later to check if the last <br /> email
          was sent.
          </p>
          <div>
            <Button
              variant={ButtonVariants.Secondary}
              style={{
                border: "1px solid #0F314D",
                color: "#0F314D",
                padding: "12px 16px",
              }}
              handleClick={() => handleCloseDrawer()}
            >
            Close
            </Button>
          </div>
        </div>
      </Flex>
    ) : (
      generateContent()
    );
};

export default SendingIsInProgress;
