import ErrorMessage from "../modules/shared/components/general/ErrorMessage/ErrorMessage";
import { RegexConstants } from "../constants/regex.constants";
import { errorTexts } from "../constants/errorTexts";

export const validator = (values:any, fieldName:string) => {
  const errors = {};
  switch (fieldName) {
    case "email":
      validateEmail(values?.email, errors);
      break;
    case "password":
      validatePassword(values?.password, errors);
      break;
    case "campaignId":
      validateCampaignId(values?.campaignId, errors);
      break;
    case "budgetPercentage":
      validateBudgetPercentage(values?.budgetPercentage, errors);
      break;
    default:
  }
  return errors;
};

export const validateEmail = (email:any, errors:any) => {
  const  result = RegexConstants.email.test(String(email).toLowerCase());
  if (!result && email) {
    errors.email = <ErrorMessage text={errorTexts.invalidEmail} />;
  }

  return result;
};

const validatePassword = (pass:any, errors:any) => {
  let result = true;
  if (pass) {
    result = RegexConstants.password.test(pass);

    if (!result || pass.length < 10) {
      errors.password = <ErrorMessage text={errorTexts.password} />;
      result = false;
    }
  }
  return result;
};

export const validateCampaignId = (campaignId:any, errors:any) => {
  const result = !RegexConstants.onlyNumbers.test(campaignId);

  if (result) {
    errors.campaignId = <ErrorMessage text={errorTexts.onlyNumbers} />;
  }

  return result;
};

export const validateBudgetPercentage = (budgetPercentage:any, errors:any) => {
  const result =
    RegexConstants.onlyNumbers.test(budgetPercentage) &&
    budgetPercentage > 0 &&
    budgetPercentage <= 100;

  if (!result) {
    errors.budgetPercentage = <ErrorMessage text="only numbers" />;
  }
};

export const isSpecialCharacters  = (value:string) =>
  new RegExp('^(?=.*[!@#$%^&*.]).+$').test(value);
