import  React, { Fragment, useState } from "react";
import Flex from "modules/shared/components/general/Flex/Flex";
import { Button } from "modules/shared/components/general/Button/Button";
import UserEmailDrawer from "../UserEmailDrawer/UserEmailDrawer";
import { useStylesEmailBlock } from "./EmailBlock.styles";
import { reportStatuses } from "../../constants/constants";
import ErrorTryAgain from "assets/icons/error_try_again.svg";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";


interface IEmailBlock {
  title: string;
  color: string;
  weekday: string;
  type: string
  handleOpenClientCommunicationDr: () => void
  handleCloseClientCommunicationDr: () => void
  isDrawerOpen: boolean
}

const EmailBlock:React.FC<IEmailBlock> = ({
  title,
  color,
  weekday,
  type,
  handleOpenClientCommunicationDr,
  handleCloseClientCommunicationDr,
  isDrawerOpen,
}) => {
  const styles = useStylesEmailBlock({
    color,
    weekday,
    title,
  });

  const {
    flattenedArrayToSendResult,
    flattenedArraySkippedResult,
    flattenedArraySentResult,

    skippedData,
    sentData,
    toSendData,
  } = useClientCommunicationContext();

  const [isEditModeOn, setIsEditModeOn] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<number>();
  const [status, setStatus] = useState<string>("");
  const [currentElementIndex, setCurrentElementIndex] = useState<number >(0);

  let emailReportsData;
  let emailReportsModifiedData;

  switch (type) {
    case "skipped":
      emailReportsData = skippedData;
      emailReportsModifiedData = flattenedArraySkippedResult;
      break;
    case "sent":
      emailReportsData = sentData;
      emailReportsModifiedData = flattenedArraySentResult;
      break;
    default:
      emailReportsData = toSendData;
      emailReportsModifiedData = flattenedArrayToSendResult;
      break;
  }

  const emailReportsDataCount = emailReportsData?.pages[0].data.count;

  const handleBtnClick = (
    id: number,
    status: string,
    index: number,
  ) => {
    handleOpenClientCommunicationDr();
    setStatus(status);
    setEmailId(id);
    setCurrentElementIndex(index);
  };

  const handleCloseDrawer = () => {
    handleCloseClientCommunicationDr();
    setIsEditModeOn(false);
  };

  const generateBlockContentByStatus = (status: string, owner: string) => {
    let statusText = "";
    switch (status) {
      case reportStatuses.REGENERATING:
        statusText = "Regenerating";
        break;
      case reportStatuses.SENDING:
        statusText = "Sending";
        break;
      case reportStatuses.REGENERATED:
        statusText = "Regenerated";
        break;
      default:
        break;
    }

    const isErrorStatus =
      status === reportStatuses.FAILED_TO_REGENERATE ||
      status === reportStatuses.FAILED_TO_SEND;

    return (
      <Flex justifyContent="space-between" alignItems="center">
        <span>{owner}</span>
        {isErrorStatus && (
          <div className={styles.tooltipWrapper}>
            <img
              src={ErrorTryAgain}
              alt="Escalate"
              style={{ display: "block", width: "24px", height: "24px", boxShadow: "none" }}
            />
            <span className={styles.tooltipText}>
              {`Failed to ${status === reportStatuses.FAILED_TO_REGENERATE
                ? "regenerate"
                : "send"
              }. Please try again.`}
            </span>
          </div>
        )}
        {(status === reportStatuses.REGENERATING ||
          status === reportStatuses.SENDING ||
          status === reportStatuses.REGENERATED) && (
          <span className={styles.statusText}>
            {`${status === reportStatuses.REGENERATED
              ? statusText
              : `${statusText}...`
            }`}
          </span>
        )}
      </Flex>
    );
  };

  return (
    <Flex className={styles.emailHeader} direction="column">
      <Flex style={{ width: "100%" }} className={styles?.titleContainer}>
        <h4>
          {title} {emailReportsDataCount ? `(${emailReportsDataCount})` : "(0)"}
        </h4>
        {title === "To send" && Boolean(flattenedArrayToSendResult.length) && (
          <span className={styles?.overdue}>Overdue</span>
        )}
      </Flex>
      <>
        {emailReportsModifiedData?.map(({ status, owner, id }:any, index:number) => (
          <Fragment key={id}>
            <Button
              key={id}
              className={styles.ownerName}
              handleClick={() =>
                (status === reportStatuses.REGENERATING ||
                    status === reportStatuses.SENDING
                  ? null
                  : handleBtnClick(id, status, index))
              }
              style={{
                background:
                    status === reportStatuses.REGENERATING ||
                      status === reportStatuses.SENDING
                      ? "#EAEAEA"
                      : "",
              }}
            >
              {generateBlockContentByStatus(status, owner)}
            </Button>
          </Fragment>
        ))}
      </>
      {emailId && (
        <UserEmailDrawer
          setEmailId={setEmailId}
          emailId={emailId}
          drawerOpen={isDrawerOpen}
          isEditModeOn={isEditModeOn}
          setIsEditModeOn={setIsEditModeOn}
          handleCloseDrawer={handleCloseDrawer}
          status={status}
          currentElementIndex={currentElementIndex}
          setCurrentElementIndex={setCurrentElementIndex}
          type={type}
        />
      )}
    </Flex>
  );
};

export default EmailBlock;
