import React from 'react';
import { createUseStyles } from "react-jss";


// Custom styles
const useStyles = createUseStyles({
  checkboxAnimate: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'arial',
    fontSize: '25px',
    padding: '0 2px',
  },
  input: {
    opacity: 0,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -1,
  },
  inputCheck: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    borderRadius: '4px',
    border: '2px solid #ccc',
    position: 'relative',
    transition: '0.4s',
  },
  inputChecked: {
    animationName: '$inputAnimate',
    animationDuration: '0.7s',
  },
  '@keyframes inputAnimate': {
    '0%': {
      transform: 'scale(1)',
    },
    '40%': {
      transform: 'scale(1.3,0.7)',
    },
    '55%': {
      transform: 'scale(1)',
    },
    '70%': {
      transform: 'scale(1.2,0.8)',
    },
    '80%': {
      transform: 'scale(1)',
    },
    '90%': {
      transform: 'scale(1.1,0.9)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
});

interface IScaleCheckbox {
  checked: boolean
}

const ScaleCheckbox:React.FC<IScaleCheckbox> = ({ checked }) => {
  const classes = useStyles();

  return (
    <div className={classes.checkboxAnimate} >
      <span style={{ borderColor: '#0F314D', height: '20px', width: '20px' }}
        className={`${classes.inputCheck} ${checked ? classes.inputChecked : ''}`}/>
    </div>
  );
};

export default ScaleCheckbox;
