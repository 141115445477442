import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowLeftIcon } from "../../../shared/components/general/Icons/ArrowLeft.icon";
import { ArrowRightIcon } from "../../../shared/components/general/Icons/ArrowRight.icon";
import { customSliderUseStyles } from "./CustomSlider.styles";
import React, { useRef } from "react";

const CustomSlider = ({ children,style }: { children: any,style?:any }) => {
  const styles = customSliderUseStyles();
  const sliderRef = useRef<Slider | null>(null);

  const customPrevClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const customNextClick = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };
  const CustomPrevArrow = () => (
    <button
      onClick={customPrevClick}
      className={`${styles.arrowButtons} ${styles.arrowLeft}`}
    >
      <ArrowLeftIcon />
    </button>
  );
  const CustomNextArrow = () => (
    <button
      onClick={customNextClick}
      className={`${styles.arrowButtons} ${styles.arrowRight}`}
    >
      <ArrowRightIcon />
    </button>
  );
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  return (
    <Slider {...settings} ref={sliderRef}>
      {children}
    </Slider>
  );
};
export default CustomSlider;
