export const PerformanceIndicatorList: { [key: string]: string } = {
  Good: '#33850D',
  Average: '#F69C2C',
  Bad: '#FF9ACB',
  Critical: '#9A004A',
  'Critical - No Leads': '#000',
};


export enum FilterDateMode {
    InitialMode = 0,
    Last3Days = 1,
    Last7Days = 2,
    ThisMonth = 3
}

export const DateFilterIdx: { [key: number]: string } = {
  1: 'fb_last_3_day_performance',
  2: 'fb_last_7_day_performance',
  3: 'fb_last_30_days_performance',
};

export const SyncNamesPattern: { [key: string]: string } = {
  campaign_pattern: 'Campaign',
  adset_pattern: 'Ad set',
  ad_pattern: 'Ad',
};


export const checkTooltipWdh = (dateRange: string) =>
  (dateRange === PerformanceIndicatorList['Critical - No Leads'] ? '250px' : 'auto');
