import React, { useEffect, useState, useMemo } from 'react';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { AppThemeConstants } from 'constants/style.constants';
import EmailBlock from '../EmailBlock/EmailBlock';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import { useQueryClient } from 'react-query';
import { servicesNames } from '../../../../constants/servicesNames.constants';
import EmptyState from '../EmptyState/EmptyState';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';
import InfiniteScroll from '../../../shared/components/general/InfiniteScroll/InfiniteScroll';

const WeeklyBoard:React.FC = () => {
  const queryClient = useQueryClient();
  const [cstWeekday, setCSTWeekday] = useState<string>("");
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);


  const {
    activeTab,
    toSendData,
    toSendFetchNextPage,
    toSendHasNextPage,
    toSendIsFetching,
    toSendIsFetchingNextPage,

    sentData,
    sentFetchNextPage,
    sentHasNextPage,
    sentIsFetching,
    sentIsFetchingNextPage,

    skippedData,
    skippedFetchNextPage,
    skippedHasNextPage,
    skippedIsFetching,
    skippedIsFetchingNextPage,

    isFetchingFsms,
  } = useClientCommunicationContext();

  const toSendDataCount = toSendData?.pages[0].data.count;
  const sentDataCount = sentData?.pages[0].data.count;
  const skippedDataCount = skippedData?.pages[0].data.count;

  const emailTypes = [
    {
      title: "To send",
      color: AppThemeConstants.orange,

      type: "to_send",
    },
    {
      title: "Sent",
      color: AppThemeConstants.green,
      type: "sent",
    },
    {
      title: "Skipped",
      color: AppThemeConstants.statusDisabled,
      type: "skipped",
    },
  ];

  const fetchNextPageFunction = useMemo(() => {
    if (toSendHasNextPage) {
      return toSendFetchNextPage;
    } else if (sentHasNextPage) {
      return sentFetchNextPage;
    } else if (skippedHasNextPage) {
      return skippedFetchNextPage;
    }
    return () => undefined;
  }, [toSendHasNextPage, toSendFetchNextPage, sentHasNextPage, sentFetchNextPage, skippedHasNextPage, skippedFetchNextPage]);

  useEffect(() => {
    const cstTime = new Date().toLocaleString("en-US", {
      timeZone: "America/Chicago",
    });

    const cstDate = new Date(cstTime);
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const weekday = weekdays[cstDate.getDay()];

    setCSTWeekday(weekday);

    const services = [
      servicesNames.getToSendEmailReportsData,
      servicesNames.getSentEmailReportsData,
      servicesNames.getSkippedEmailReportsData,
    ];

    services.forEach((service) => {
      queryClient.removeQueries([service, { activeTab }]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenClientCommunicationDr = () => setIsDrawerOpen(true);

  const handleCloseClientCommunicationDr = () => setIsDrawerOpen(false);

  const generateContent = () => {
    if (
      (toSendIsFetching && !toSendIsFetchingNextPage) ||
      (skippedIsFetching && !skippedIsFetchingNextPage) ||
      (sentIsFetching && !sentIsFetchingNextPage) || isFetchingFsms
    ) {
      return (
        <Flex justifyContent="center">
          <Spinner style={{ margin: "40px" }} />
        </Flex>
      );
    }
    if (skippedDataCount || toSendDataCount || sentDataCount) {
      return (
        <>
          <Flex justifyContent="space-between" style={{ marginTop: "24px" }}>
            {emailTypes?.map(({ title, color, type }) => (
              <EmailBlock
                key={title}
                title={title}
                color={color}
                type={type}
                weekday={cstWeekday}
                handleCloseClientCommunicationDr={handleCloseClientCommunicationDr}
                handleOpenClientCommunicationDr={handleOpenClientCommunicationDr}
                isDrawerOpen={isDrawerOpen}
              />
            ))}
          </Flex>
          {(skippedIsFetchingNextPage ||
            sentIsFetchingNextPage ||
            toSendIsFetchingNextPage) && (
            <Flex
              justifyContent="center"
              style={{ width: "100%", marginBottom: "40px" }}
            >
              <Spinner  />
            </Flex>
          )}
        </>
      );
    }
    if (
      !skippedDataCount && !toSendDataCount && !sentDataCount
    ) {
      return <EmptyState />;
    }
    return null;
  };

  return (
    <InfiniteScroll
      scroll
      next={() => fetchNextPageFunction()}
      maxHeight={'calc(100vh - 300px)'}
      hasMore={
        !(skippedIsFetchingNextPage ||
          sentIsFetchingNextPage ||
          toSendIsFetchingNextPage)
      }
      isOverflowHide={isDrawerOpen}
    >
      {generateContent()}
    </InfiniteScroll>
  );
};

export default WeeklyBoard;
