export enum LocaleStorageTokenList {
    access_token = "access_token",
    refresh_token = "refresh_token",
    googleAuthAccessToken = "googleAuthAccessToken",
    activeTab ="activeTab"
}

type LocaleStorageTokenKey = keyof typeof LocaleStorageTokenList;

export const LocalStorage = {
  get: (key: LocaleStorageTokenKey) => localStorage.getItem(key) || "",
  set: (key: LocaleStorageTokenKey, value: any) => localStorage.setItem(key, value),
  remove: (key: LocaleStorageTokenKey) => localStorage.removeItem(key),
};






