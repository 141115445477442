import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import moment from 'moment';
import arrowOpenedWhite from 'assets/icons/arrowOpenedWhite.svg';
import pointerLeft from 'assets/icons/pointerLeft.svg';
import { CopyId } from 'modules/shared/components/general/Icons/CopyId.icon';
import { useStyles } from './style';

import './styles.scss';

interface IAllDuplicationsDataSingleItem {
    campaigns: any;
    createdAt: any;
    createdBy: any;
    index: any;
}

const AllDuplicationsDataSingleItem: React.FC<IAllDuplicationsDataSingleItem> = ({
  campaigns,
  createdAt,
  createdBy,
  index,
}) => {
  const classes = useStyles();

  const [copied, setCopied] = useState<boolean>(false);
  const firstCampaignId = campaigns[0]?.source_campaign_id;

  useEffect(() => {
    let copyTimer: number;
    if (copied) {
      copyTimer = setTimeout(() => {
        setCopied(false);
      }, 1500) as unknown as number;
    }

    return () => {
      clearTimeout(copyTimer);
    };
  }, [copied]);

  return (
    <div className="all-duplications-data">
      <div
        className="copy-id"
        onClick={(event) => {
          event.preventDefault();
          setCopied(true);
          navigator.clipboard.writeText(firstCampaignId);
        }}
      >
        {CopyId}
      </div>
      {copied && (
        <div className="tooltip-budget error-tooltip">
          <div className="pointer-left">
            <img className="tooltip-arrow" src={pointerLeft} alt="tooltip-icon" />
          </div>
          <span style={{ color: '#444444', fontWeight: 400 }}>Copied</span>
        </div>
      )}

      <Accordion
        className={classes.root}
        defaultExpanded={index === 0}
        style={{
          borderRadius: '8px',
          margin: '0',
        }}
      >
        <AccordionSummary
          style={{ paddingRight: '38px' }}
          expandIcon={<img src={arrowOpenedWhite} alt="arrow" />}
        >
          <div className="campaign-info">
            <div className="campaign-id">
              <span>Source Campaign id: {firstCampaignId}</span>
            </div>
            <div className="created-on">
              <span>
                  Created on: {moment(createdAt).format('MMMM D.Y')} by {createdBy}
              </span>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="campaign-details-wraper">
            <div className="account-name">
              <span> Account name</span>
              <span>Task in Airtable</span>
            </div>
            <div
              style={{
                height: campaigns?.length < 4 ? 'auto' : '215px',
                overflowY: 'auto',
              }}
            >
              {campaigns?.map(
                ({
                  id,
                  target_account_name,
                  target_account_fb_url,
                  airtable_task_url,
                }: any) => (
                  <div className="campaign-name" key={id}>
                    <span>
                      <a
                        href={target_account_fb_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {target_account_name}
                      </a>
                    </span>
                    {airtable_task_url ? (
                      <span>
                        <a
                          href={airtable_task_url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                            Link
                        </a>
                      </span>
                    ) : (
                      <span className="no-link">-</span>
                    )}
                  </div>
                ),
              )}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default AllDuplicationsDataSingleItem;
