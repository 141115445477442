import { instance } from "../api/ApiRequest";
import { serverUrls } from "../variables";

export const uploadMemberListService = async (payload: any) => {
  const response = await instance.post(
    `${serverUrls.reporting}file/detect-columns/`,
    payload,
    { headers: { "Content-Type": "multipart/form-data" } },
  );

  return response;
};

export const retryReportCreation = async (id: string | number) => {
  await instance.get(`${serverUrls.reporting}reports/${id}/retry/`);
};

export const getBrandReportsService = async (
  type: string,
  brandId: string,
  debouncedSearchValue:string,
  page: any = 1,
  activePlatforms: Array<string> = [],
) => {
  const paramsObj = activePlatforms.length === 1 ? {
    type,
    brand_id:brandId,
    platform:  activePlatforms[0] ,
    ...(debouncedSearchValue ? { name_contains:debouncedSearchValue } : {}),
    page,
  } :
    {
      type,
      brand_id:brandId,
      ...(debouncedSearchValue ? { name_contains:debouncedSearchValue } : {}),
      page,
    };

  const queryParams = new URLSearchParams(paramsObj);
  const response = await instance.get(
    `${serverUrls.reporting}reports/?${queryParams}`,
  );

  return response;
};

export const getBrandsLogosService = async (page:string) => {
  const queryParams = new URLSearchParams({ page });

  const response = await instance.get(`${serverUrls.reportingBrand}brands/?${queryParams}`);

  return response;
};

export const createReport = async (payload: any) => {
  const response = await instance.post(
    `${serverUrls.reporting}reports/bulk/`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    },
  );
  return response;
};

export const deleteReportData = async (id: any) => {
  await instance.delete(`${serverUrls.reporting}reports/${id}/`);
};

export const getAdAccounts = async (brand_id:any) => {
  const response = await instance.get(`${serverUrls.reportingBrand}${brand_id}/ad-accounts/`);

  return response;
};
