export const EditIconGray = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4434 5.68726L5.44501 14.6857C4.78636 15.3444 4.26633 16.1439 4.10802 17.0618C3.94817 17.9886 3.89497 19.1388 4.44346 19.6873C4.99195 20.2358 6.14213 20.1826 7.06894 20.0227C7.98686 19.8644 8.78636 19.3444 9.44501 18.6857L18.4434 9.68726M14.4434 5.68726C14.4434 5.68726 17.4434 2.68726 19.4434 4.68726C21.4434 6.68726 18.4434 9.68726 18.4434 9.68726M14.4434 5.68726L18.4434 9.68726"
      stroke="#444444"
      strokeOpacity="0.25"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
