import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useQueryClient } from 'react-query';
import Flex from '../../../shared/components/general/Flex/Flex';
import { convertDateToCst } from '../../../../helpers/dateFormater';
import { columns, reportStatuses } from '../../constants/constants';
import emailHoverIcon from '../../../../assets/icons/emailHoverIcon.svg';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import EmptyState from '../EmptyState/EmptyState';
import UserEmailDrawer from '../UserEmailDrawer/UserEmailDrawer';
import { servicesNames } from '../../../../constants/servicesNames.constants';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';
import { allEmailsUseStyles } from './AllEmails.styles';
import InfiniteScroll from '../../../shared/components/general/InfiniteScroll/InfiniteScroll';

const generateStatusStyles = (status: string) => {
  const generalStatusStyles = {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "21px",
    padding: "4px 8px",
    borderRadius: "15px",
    width: "fit-content",
  };
  let styles;
  switch (status) {
    case reportStatuses.EXPIRED:
      styles = {
        ...generalStatusStyles,
        background: "#EAEAEA",
        color: "#444444",
      };
      break;

    case reportStatuses.SKIPPED:
      styles = {
        ...generalStatusStyles,
        background: "rgba(179, 38, 30, 0.15)",
        color: "#B3261E",
      };
      break;

    case reportStatuses.SENT:
      styles = {
        ...generalStatusStyles,
        background: "rgba(51, 133, 13, 0.15)",
        color: "#33850D",
      };
      break;

    default:
      break;
  }

  return styles;
};

const AllEmails:React.FC = () => {
  const queryClient = useQueryClient();
  const styles = allEmailsUseStyles();
  const {
    selectedTimePeriod,
    activeTab,
    allEmailsResponse,
    fetchNextAllEmailsPage,
    allEmailsIsFetchingNextPage,
    allEmailsIsFetching,
    flattenedArrayAllEmailsResponse,
    debouncedSearchValue,
  } = useClientCommunicationContext();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [emailId, setEmailId] = useState<number>();
  const [isEmptyStateAllEmails, setIsEmptyStateAllEmails] =
    useState<boolean>(false);
  const countAllEmails = allEmailsResponse?.pages[0]?.count;

  useEffect(() => {
    setIsEmptyStateAllEmails(false);
    queryClient.removeQueries([
      servicesNames.getAllEmails,
      {
        activeTab,
        selectedTimePeriod,
        debouncedSearchValue,
      },
    ]);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setIsEmptyStateAllEmails(!countAllEmails);
    // eslint-disable-next-line
  }, [countAllEmails]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: flattenedArrayAllEmailsResponse,
    });

  const handleBtnClick = (id: number | undefined, owner: string) => {
    setIsDrawerOpen(true);
    setEmailId(id);
  };
  const handleCloseDrawer = () => setIsDrawerOpen(false);

  const generateDrawer = () => (
    emailId && (
      <UserEmailDrawer
        emailId={emailId}
        drawerOpen={isDrawerOpen}
        handleCloseDrawer={handleCloseDrawer}
        status={"skipped"}
      />
    )
  );

  const generateContent = () => {
    if (allEmailsIsFetching && !allEmailsIsFetchingNextPage) {
      return (
        <Flex justifyContent="center">
          <Spinner style={{ margin: "40px" }} />
        </Flex>
      );
    }
    if (isEmptyStateAllEmails) {
      return <EmptyState />;
    }

    if (flattenedArrayAllEmailsResponse.length) {
      return (
        <div>
          <table {...getTableProps()} className={styles.table}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                  className={styles.tableHeaderRow}
                >
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      className={styles.tableHeaderItem}
                      style={{ width: column.width }}
                    >
                      {column.render("Header") as React.ReactNode}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} style={{ marginBottom: "32px" }}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={styles.tableBodyRow}>
                    {row.cells.map(cell => (
                      <td
                        {...cell.getCellProps()}
                        className={styles.tableBodyItem}
                      >
                        {cell.column.Header === "Date created" ? (
                          convertDateToCst(cell.value).split(",")[0]
                        ) : cell.column.Header === "Email" ? (
                          <Flex
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <div className={styles.tableEmail}>
                              {cell.render("Cell") as React.ReactNode}
                            </div>
                            <div
                              className={styles.emailHoverIcon}
                              onClick={() =>
                                handleBtnClick(
                                  cell.row.original?.id as number,
                                  cell.row.original.fsm as string,
                                )
                              }
                            >
                              <img src={emailHoverIcon} alt="" />
                            </div>
                          </Flex>
                        ) : cell.column.Header === "Reason" ? (
                          cell.value.length > 24 ? (
                            <div className={styles.reasonTooltipWrapper}>
                              <div className={styles.reasonContainer}>
                                {cell.render("Cell") as React.ReactNode}
                              </div>
                              <div className={styles.reasonTooltip}>
                                {cell.render("Cell") as React.ReactNode}
                              </div>
                            </div>
                          ) : !cell.value.length ? (
                            "-"
                          ) : (
                            (cell.render("Cell") as React.ReactNode)
                          )
                        ) : (
                          <div
                            style={{
                              textTransform: "capitalize",
                              ...(cell.column.Header === "Status"
                                ? generateStatusStyles(cell.value)
                                : {}),
                            }}
                          >
                            {cell.render("Cell") as React.ReactNode}
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {allEmailsIsFetchingNextPage && (
            <Flex
              justifyContent="center"
              style={{ width: "100%", margin: "6px" }}
            >
              <Spinner style={{ margin: "40px" }} />
            </Flex>
          )}
          {generateDrawer()}
        </div>
      );
    }
  };

  return  (
    <InfiniteScroll
      scroll
      next={() => fetchNextAllEmailsPage()}
      maxHeight={'calc(100vh - 320px)'}
      hasMore={!allEmailsIsFetchingNextPage}
    >
      {generateContent()}
    </InfiniteScroll>
  );
};
export default AllEmails;
