import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import { updateLocations } from "../../../../store/updateContent/actions";
import CustomTooltip from "modules/shared/components/general/CustomTooltip/CustomTooltip";
import {
  setFacebookLocations,
  setShowGeoPreview,
  setShowUpdateTab,
} from "../../../../store/updateContent/updateContentSlice";
import "./styles.scss";
import { useUpdatePreview } from "../../../../context/update.context";
import { IRootStore } from "types/store.types";
import { AppDispatch } from "store";

const GeoUpdatePreview = () => {
  const { recordId, setShowSuccessErrorContent, setBackToPreview } =
    useUpdatePreview();
  const dispatch = useDispatch<AppDispatch>();
  const {
    updateContent: {
      loading,
      facebookLocationsData: { geos, ad_set_name, location_name },
    },
  } = useSelector((state:IRootStore) => state);

  const geosPreviewData = [
    {
      key: "include_geo_zips",
      title: "INCLUDE Geo - Zip Codes",
      value: geos?.include_geo_zips,
    },
    {
      key: "include_custom_locations",
      title: "INCLUDE Geo - Addresses",
      value: geos?.include_custom_locations,
    },
    {
      key: "exclude_geo_zips",
      title: "EXCLUDE Geo - Zip Codes",
      value: geos?.exclude_geo_zips,
    },
    {
      key: "exclude_custom_locations",
      title: "EXCLUDE Geo - Addresses",
      value: geos?.exclude_custom_locations,
    },
  ];

  const geosPostData = {
    include_geo_zips: geos?.include_geo_zips || null,
    include_custom_locations: geos?.include_custom_locations || null,
    exclude_geo_zips: geos?.exclude_geo_zips || null,
    exclude_custom_locations: geos?.exclude_custom_locations || null,
  };

  const handleBackClick = () => {
    dispatch(setShowGeoPreview(false));
    dispatch(setShowUpdateTab(true));
  };

  return (
    <div className="airtable-update-preview">
      <h1>Airtable update preview</h1>
      <p className="location-name modal-subtitle">Location name</p>
      <span>{location_name}</span>
      <p className="modal-subtitle">Ad set name</p>
      {ad_set_name.length > 95 ? (
        <CustomTooltip value={ad_set_name} />
      ) : (
        <span>{ad_set_name}</span>
      )}
      <p className="modal-subtitle">Geos</p>
      <div className="geos">
        {geosPreviewData.map(geoItem => (
          geoItem.value?.length && (
            <Fragment key={geoItem.key}>
              <p>{geoItem.title}</p>
              {geoItem.value?.map((item:any, index:any) =>
                (item.length > 95 ? (
                  <CustomTooltip value={item} />
                ) : (
                  <span key={index}>
                    {item}
                    <br />
                  </span>
                )),
              )}
            </Fragment>
          )
        ))}
      </div>

      <div className="modal-footer">
        <button className="auth-button" onClick={handleBackClick}>
          Back
        </button>
        <button
          className="auth-button"
          onClick={() =>
            (!loading
              ? dispatch(
                updateLocations(geosPostData, recordId, () => {
                  setShowSuccessErrorContent(true);
                  dispatch(setFacebookLocations({}));
                  setBackToPreview(true);
                }),
              )
              : undefined)
          }
          style={{
            position: "relative",
            width: "112px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Spinner
              white={true}
              style={{
                width: "16px",
                margin: "0",
                left: "50%",
                position: "initial",
              }}
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};
export default GeoUpdatePreview;
