import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import BasicModal from '../../../shared/components/general/BasicModal/BasicModal';
import Flex from '../../../shared/components/general/Flex/Flex';
import { ActivateIcon } from '../../../shared/components/general/Icons/Activate.icon';
import { DeactivateIcon } from '../../../shared/components/general/Icons/Deactivate.icon';
import { EditIconBlue } from '../../../shared/components/general/Icons/EditBlue.icon';
import { EditIconGray } from '../../../shared/components/general/Icons/EditGray.icon';
import { SettingsIcon } from '../../../shared/components/general/Icons/Settings.icon';
import ActivateOrDeactivate from '../ActivateDeactivate/ActivateDeactivate';
import InviteOrEdit from '../InviteOrEdit/InviteOrEdit';
import { useSettingsActionsStyles } from './SettingsActions.styles';

const SettingsAction = ({
  id,
  style,
  isActive,
}: {
    id?: number;
    style: CSSProperties;
    isActive: boolean;
}) => {
  const styles = useSettingsActionsStyles();
  const [toggleActions, setToggleActions] = useState<boolean>(false);
  const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  const [isOpenActivate, setIsOpenActivate] = useState<boolean>(false);

  const handleEditOpen = () => {
    setIsOpenEdit(true);
  };

  const handleEditClose = () => {
    setIsOpenEdit(false);
  };

  const handleActivateOpen = () => {
    setIsOpenActivate(true);
  };

  const handleActivateClose = () => {
    setIsOpenActivate(false);
  };

  const actionsWrapperRef = useRef<any>(null);

  const handleEdit = () => {
    setToggleActions(false);
    handleEditOpen();
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        actionsWrapperRef.current &&
                !actionsWrapperRef.current.contains(event.target)
      ) {
        setToggleActions(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionsWrapperRef]);

  return (
    <>
      <div className={styles.settings} onClick={() => setToggleActions(!toggleActions)}>
        <SettingsIcon />
        {toggleActions && (
          <Flex
            className={styles.actionsWrapper}
            direction="column"
            refValue={actionsWrapperRef}
            style={style}
          >
            <span
              className={styles.actionContainer}
              onClick={isActive ? () => handleEdit() : () => {}}
              style={{
                color: !isActive ? 'rgba(68, 68, 68, 0.25)' : '#262626',
              }}
            >
              <div className={styles.iconWrapper} style={{}}>
                {isActive ? <EditIconBlue /> : <EditIconGray />}
              </div>
                            Edit
            </span>
            <span
              className={styles.actionContainer}
              onClick={() => {
                setToggleActions(false);
                handleActivateOpen();
              }}
            >
              <div className={styles.iconWrapper}>
                {isActive ? <DeactivateIcon /> : <ActivateIcon />}
              </div>
              {isActive ? 'Deactivate' : 'Reactivate'}
            </span>
          </Flex>
        )}
      </div>

      <BasicModal
        open={isOpenEdit}
        handleClose={() => setIsOpenEdit(false)}
        style={{ padding: '0' }}
      >
        <InviteOrEdit handleCloseModal={handleEditClose} type="edit" id={id} />
      </BasicModal>
      <BasicModal
        open={isOpenActivate}
        handleClose={() => setIsOpenActivate(false)}
        style={{ width: '617px', padding: '0' }}
      >
        <ActivateOrDeactivate
          handleCloseModal={handleActivateClose}
          isActive={isActive}
          id={id}
        />
      </BasicModal>
    </>
  );
};

export default SettingsAction;
