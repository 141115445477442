import React, { useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { CustomSelect, stylesSx, MenuProps } from './styles';

const Select: React.FC<any> = ({
  children,
  renderValue,
  value,
  handleChange,
  style,
  formStyle,
  onClick,
  placeholder,
  menuProps = MenuProps,
  disabled,
  hasHover = false,
}: {
    children: any;
    renderValue?: any;
    value: any;
    handleChange: any;
    style?: any;
    onClick: any;
    formStyle: any;
    placeholder: string;
    menuProps: any;
    disabled?: boolean;
    hasHover?: boolean;
}) => {
  const [open, setOpen] = useState(false);

  const handleClose = (event: any) => {
    setOpen(false);
  };

  const handleOpen = (event: any) => {
    setOpen(true);
  };

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} style={formStyle}>
      <CustomSelect
        disabled={disabled}
        style={style}
        renderValue={renderValue}
        value={value}
        name="select-select"
        id="select-select"
        displayEmpty
        placeholder={placeholder}
        MenuProps={menuProps}
        hasHover={hasHover}
        input={
          <OutlinedInput
            sx={{
              '&:focus-visible': {
                borderColor: 'transparent !important',
                borderWidth: '0 !important',
                outline: 0,
                border: 0,
              },
            }}
          />
        }
        sx={stylesSx()}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Without label' }}
      >
        {children}
      </CustomSelect>
    </FormControl>
  );
};

export default Select;
