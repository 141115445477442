import { createUseStyles } from "react-jss";
import { AppTheme } from "../../../../types/styles.types";

export const useInviteOrEditStyles = createUseStyles((theme: AppTheme) => ({
  wrapper: {
    background: theme.grey2,
    padding: "32px",
  },
  title: {
    color: theme.primaryColor,
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "1.5",
    marginBottom: "16px",
  },
  subTitle: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "1.5",
    color: theme.textColor,
    marginTop: "0",
    marginBottom: "16px",
  },
  userRole: {
    cursor: "pointer",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "1.5",
    color: theme.textColor,
  },
  divider: {
    height: "1px",
    width: "100%",
    backgroundColor: theme.secondaryColor,
    margin: "16px 0",
  },
  permissionItem: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: theme.textColor,
  },
  hintText: {
    display: "flex",
    lineHeight: "16px",
    alignItems: "center",
    marginTop: "8px",
    marginBottom: 0,
    color: "#0F314D",
    fontWeight: "400",
    fontSize: "12px",
  },
  successMessage: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "27px",
    color: "#444444",
    textAlign: "center",
    margin: "8px 0 43px",
  },
}));
