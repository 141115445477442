import { createUseStyles } from "react-jss";
import { AppTheme } from "../../../../types/styles.types";

export const useSettingsActionsStyles = createUseStyles((theme: AppTheme) => ({
  settings: {
    cursor: "pointer",
    padding: "2px",
    width: "36px",
    height: "36px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    position: "relative",
    marginLeft: "8px",
    "&:active": {
      background: theme.grey3,
    },
    "&:hover": {
      background: theme.grey3,
    },
  },
  actionsWrapper: {
    width: "160px",
    position: "absolute",
    background: theme.whiteColor,
    boxShadow: "0px 4px 20px rgba(95, 95, 95, 0.15)",
    borderRadius: "8px",
    zIndex: "999",
    overflow: "hidden",
  },
  actionContainer: {
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    width: "100%",
    cursor: "pointer",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#262626",
    "&:hover": {
      background: "#FAFAFA",
    },
    "&:first-child": {
      borderBottom: "1px solid #D9D9D9",
    },
  },
  iconWrapper: {
    marginRight: "12px",
    "&>svg": {
      display: "block",
    },
  },
}));
