import React from "react";
import { InputAdornment, IconButton } from "@mui/material";
import { EyeIconPassword } from "../../Icons/EyeIconPassword";
import "../styles.scss";

const PasswordAdornment:React.FC<any> = ({ showPassword, setShowPassword }) => {
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  return (
    <InputAdornment position="end" className="input-adornement">
      <IconButton
        style={{ width: "40px", height: "40px" }}
        aria-label="toggle password visibility"
        onClick={handleClickShowPassword}
        onMouseDown={handleMouseDownPassword}
      >
        <EyeIconPassword showPass={showPassword} />
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordAdornment;
