import { createSlice } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";


const initialState = {
  locationListSearchValue:"",
  isPrevCredentialsValid:{},
  selectedBrands:[],
  selectedStatuses:["On", "Off"],
  brandsData:[],
  selectedLocations:[],
  selectedLocationsInDrawer:[],
  selectedLocationsIds:[],
  isSelectOpen:false,
};

export const aiFrontDeskSlice = createSlice({
  name:"aiFrontDesk",
  initialState,
  reducers:{
    setLocationListSearchValue:(state,action) => ({
      ...state,
      locationListSearchValue:action.payload,
    }),
    setIsPrevCredentialsValid:(state,action) => ({
      ...state,
      isPrevCredentialsValid:action.payload,
    }),
    setSelectedBrands:(state,action) => ({
      ...state,
      selectedBrands: cloneDeep(action.payload),
    }),
    setSelectedStatuses:(state,action) => ({
      ...state,
      selectedStatuses: cloneDeep(action.payload),
    }),
    setBrandsData:(state,action) => ({
      ...state,
      brandsData: cloneDeep(action.payload),
    }),
    setSelectedLocations:(state,action) => ({
      ...state,
      selectedLocations: cloneDeep(action.payload),
    }),
    setSelectedLocationsInDrawer:(state,action) => ({
      ...state,
      selectedLocationsInDrawer: cloneDeep(action.payload),
    }), 
    setSelectedLocationsIds:(state,action) => ({
      ...state,
      selectedLocationsIds: cloneDeep(action.payload),
    }),
    setIsSelectOpen:(state,action) => ({
      ...state,
      isSelectOpen:action.payload,
    }),
  },
});

export const {
  setLocationListSearchValue,
  setIsPrevCredentialsValid,
  setSelectedBrands,
  setSelectedStatuses,
  setBrandsData,
  setSelectedLocations,
  setSelectedLocationsInDrawer,
  setSelectedLocationsIds,
  setIsSelectOpen,
} = aiFrontDeskSlice.actions;

export default aiFrontDeskSlice.reducer;
