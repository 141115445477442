import React, { ReactNode, CSSProperties } from 'react';
import { ButtonVariants } from './Button.types';
import Spinner from '../Spinner/Spinner';
import { buttonUseStyles } from './Button.styles';

export interface ButtonProps {
  variant: ButtonVariants;
  disabled?: boolean;
  loading?: boolean;
  handleClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children: ReactNode;
  style?: CSSProperties;
  type?: any;
  className?: string;
}

const getButtonStylesByVariant = (variant: ButtonVariants, disabled: boolean) => {
  const buttonStyles = buttonUseStyles({ disabled });
  switch (variant) {
    case ButtonVariants.Primary:
      return buttonStyles.primaryButton;
    case ButtonVariants.Secondary:
      return buttonStyles.secondaryButton;
    case ButtonVariants.Warning:
      return buttonStyles.warningButton;
    case ButtonVariants.Disabled:
      return buttonStyles.disabledButton;
    case ButtonVariants.Outlined:
      return buttonStyles.outlinedButton;
    case ButtonVariants.Danger:
      return buttonStyles.dangerButton;
    default:
      return buttonStyles.primaryButton;
  }
};

export const Button: React.FC<any> = ({
  loading = false,
  variant,
  disabled = false,
  handleClick,
  children,
  style,
  type,
  className,
}: ButtonProps) => (
  <button
    onClick={handleClick}
    style={style}
    className={`${getButtonStylesByVariant(variant, Boolean(disabled))} ${className}`}
    disabled={disabled}
    type={type}
  >
    {loading && variant === ButtonVariants.Primary ? (
      <Spinner white={true} style={{ width: '16px', height: '16px', margin: 0 }} />
    ) : (
      children
    )}
  </button>
);
