import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { LocaleStorageTokenList, LocalStorage } from 'helpers/Storage';
import ROUTES from 'constants/routes';
import { logout } from 'store/authentication/actions';
import {
  setCampaignIdSearchedValue,
  setErrorMessageCampaignIdSearch,
  setStartDuplicationSuccess,
} from '../../../../../store/brandAdAccounts/brandAdAccountsSlice';
import {
  setUserInvitationInfo,
  setUserInvitationValue,
} from '../../../../../store/userInvitation/userInvitationSlice';
import logOut from 'assets/icons/logOut.svg';
import duplicationGray from 'assets/icons/duplicationGray.svg';
import duplicationBlue from 'assets/icons/duplicationBlue.svg';
import historyBlue from 'assets/icons/historyBlue.svg';
import historyGray from 'assets/icons/historyGray.svg';
import updateBlue from 'assets/icons/updateBlue.svg';
import updateGray from 'assets/icons/updateGray.svg';
import reportingBlue from 'assets/icons/reportingBlue.svg';
import reportingGray from 'assets/icons/reportingGray.svg';
import suggestionsIconGray from 'assets/icons/suggestions_menu_icon.svg';
import suggestionsIconBlue from 'assets/icons/suggestions_icon_selected.svg';
import clientCommunicationGray from 'assets/icons/clientCommunicationGray.svg';
import clientCommunicationBlue from 'assets/icons/clientCommunicationBlue.svg';
import teamManagementBlue from '../../../../../assets/icons/teamManagementBlue.svg';
import teamManagementGray from '../../../../../assets/icons/teamManagementGray.svg';
import addBoxActiveIcon from '../../../../../assets/icons/addBoxActiveIcon.svg';
import addBoxIcon from '../../../../../assets/icons/addBoxIcon.svg';
import CustomLink from '../CustomLink/CustomLink';
import { setLocationListSearchValue } from 'store/aiFrontDesk/aiFrontDeskSlice';
import './styles.scss';
import AddBrandModal from '../AddBrandModal/AddBrandModal';
import BrandReSyncModal from '../BrandResyncModal/BrandResyncModal';
import { useMutation } from 'react-query';
import { reSyncBrandAirTable } from '../../../../../services/addBrandModal.seervices';
import NamingConvetionModal from '../../../../BrandAdAccounts/components/NamingConvetionModal/NamingConvetionModal';


interface ISideBar {
    open: boolean;
    sideNavBarWidth: string | number;
    innerRef: any;
}


export interface IBrandWarning {
    id?: number;
    name?: string;
    warnings: Array<{
      title: string, info: string ,
      details?: { [key: string]: string }, errors?: Array<any>
    }>;
}

const SideBar: React.FC<ISideBar> = ({ open, sideNavBarWidth, innerRef }) => {
  const dispatch: Dispatch<any> = useDispatch();
  const location = useLocation();
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  const [permissionsForUser, setPermissionsForUser] = useState<Array<any>>([]);
  const [isOpenAddBrandModal, setOpenAddBrandModal] = useState<boolean>(false);
  const [isOpenBrandSuccesModal, setOpenBrandSuccesModal] = useState<boolean>(false);
  const [brandWarning, setBrandWarning] = useState<IBrandWarning | null>(null);
  const [isOpenDuplicationErrorModal, setOpenDuplicationErrorModal] = useState(false);

  const { mutate: reSyncAirTable, isLoading: isLoadingReSync } = useMutation(reSyncBrandAirTable, {
    onSuccess: ({ data }) => {
      setBrandWarning(data);
    },
  });

  const {
    authentication: { disableBtn },
  } = useSelector((state: any) => state);


  const {
    email,
    name,
    role: roleFromToken,
    permissions: permissionsFromToken,
  } = jwt_decode<any>(accessToken);

  useEffect(() => {
    permissionsFromToken?.length && setPermissionsForUser(permissionsFromToken);
    // eslint-disable-next-line
    }, [permissionsFromToken?.length]);

  const handleDetailsDuplication = () => {
    setOpenBrandSuccesModal(false);
    setOpenAddBrandModal(false);
    setOpenDuplicationErrorModal(true);
  };

  const handleDetailsDuplicationClose = () => {
    setOpenDuplicationErrorModal(false);
    setOpenBrandSuccesModal(true);
  };

  const handleAddBrandModal = () => setOpenAddBrandModal(true);

  const handleCloseAddBrandModal = () => setOpenAddBrandModal(false);

  const handleCloseSuccessModal = () => setOpenBrandSuccesModal(false);

  const handleReSyncAirTable = () => {
    if (brandWarning?.id) {
      reSyncAirTable(brandWarning.id);
      return;
    }
  };

  const navBarLinksDuplications = [
    {
      icon:
                location.pathname === ROUTES.DUPLICATION ? (
                  <img src={duplicationBlue} alt="duplication" />
                ) : (
                  <img src={duplicationGray} alt="duplication" />
                ),
      label: 'Duplication',
      to: ROUTES.DUPLICATION,
    },
    {
      icon:
                location.pathname === ROUTES.ALL_DUPLICATIONS ? (
                  <img src={historyBlue} alt="All duplications" />
                ) : (
                  <img src={historyGray} alt="All duplications" />
                ),
      label: 'All Duplications',
      to: ROUTES.ALL_DUPLICATIONS,
    },
    {
      icon:
                location.pathname === ROUTES.REPORTING_UPDATE ? (
                  <img src={updateBlue} alt="update" />
                ) : (
                  <img src={updateGray} alt="update" />
                ),
      label: 'Update',
      to: ROUTES.REPORTING_UPDATE,
    },
    {
      icon:
                location.pathname === ROUTES.SUGGESTIONS ||
                location.pathname.includes(ROUTES.SUGGESTIONS) ? (
                    <img src={suggestionsIconBlue} alt="Suggestions" />
                  ) : (
                    <img src={suggestionsIconGray} alt="Suggestions" />
                  ),
      label: 'Suggestions',
      to: ROUTES.SUGGESTIONS,
      childPath: ROUTES.SUGGESTIONS,
    },
  ];

  const navBarLinksEmailReports = {
    icon:
            location.pathname === ROUTES.CLIENT_COMUNICATION ||
            location.pathname.includes(ROUTES.CLIENT_COMUNICATION) ? (
                <img src={clientCommunicationBlue} alt="Suggestions" />
              ) : (
                <img src={clientCommunicationGray} alt="Suggestions" />
              ),
    label: 'Client communication',
    to: ROUTES.CLIENT_COMUNICATION,
  };

  const navBarAddBrand = {
    type: 'modal',
    icon: <img src={isOpenAddBrandModal ? addBoxActiveIcon : addBoxIcon} alt="Add Brand" />,
    label: 'Add Brand',
  };

  const navBarLinksReporting = {
    icon:
            location.pathname === ROUTES.REPORTING || location.pathname === ROUTES.BRAND_REPORTS ? (
              <img src={reportingBlue} alt="Request report" />
            ) : (
              <img src={reportingGray} alt="Request report" />
            ),
    label: 'Request report',
    to: ROUTES.REPORTING,
    childPath: ROUTES.BRAND_REPORTS,
  };

  const resetAiFrontDeskData = () => {
    dispatch(setLocationListSearchValue(''));
  };

  const handleMenuItemClick = (label: string) => {
    switch (label) {
      case 'Invite':
        dispatch(setUserInvitationValue(''));
        dispatch(setUserInvitationInfo({ isSent: null, message: '' }));
        break;
      case 'Duplication':
        dispatch(setStartDuplicationSuccess(false));
        dispatch(setCampaignIdSearchedValue(''));
        dispatch(setErrorMessageCampaignIdSearch(''));
        break;
      case 'All duplications':
        dispatch(setCampaignIdSearchedValue(''));
        dispatch(setErrorMessageCampaignIdSearch(''));
        break;
      case 'Request report':
        dispatch(setLocationListSearchValue(''));
        break;
      case 'AI Front Desk':
        resetAiFrontDeskData();
        break;
      default:
        break;
    }
  };

  const navBarLinkTeam = {
    icon:
            location.pathname === ROUTES.TEAM_MANAGEMENT ? (
              <img src={teamManagementBlue} alt="team management" />
            ) : (
              <img src={teamManagementGray} alt="team management" />
            ),
    label: 'Team Management',
    to: ROUTES.TEAM_MANAGEMENT,
  };

  const navBarLinksAllPermissions = [
    ...navBarLinksDuplications,
    navBarLinksReporting,
    navBarLinksEmailReports,
    navBarAddBrand,
  ];

  let userPermissionsLinks: Array<any> = [];


  const navigationLinksByPermission = () => {
    switch (roleFromToken) {
      case 'employee':
        if (permissionsForUser.includes('ad_management')) {
          userPermissionsLinks = [...navBarLinksDuplications];
        }
        if (permissionsForUser.includes('reporting')) {
          userPermissionsLinks.push(navBarLinksReporting);
        }
        if (permissionsForUser.includes('fsm')) {
          userPermissionsLinks.push(navBarLinksEmailReports);
        }
        break;
      case 'admin':
        userPermissionsLinks = [...navBarLinksAllPermissions];
        break;

      default:
        return null;
    }
    return userPermissionsLinks;
  };


  return (
    <>
      {
        isOpenDuplicationErrorModal &&
                <NamingConvetionModal
                  isOpenModal={isOpenDuplicationErrorModal}
                  onClose={handleDetailsDuplicationClose}
                  syncNamesPattern={brandWarning?.warnings[0].details}
                  namingConventionErrors={brandWarning?.warnings[0].errors}
                />
      }
      {
        brandWarning &&
                <BrandReSyncModal
                  isOpenModal={isOpenBrandSuccesModal}
                  brandWarning={brandWarning}
                  handleReSyncAirTable={handleReSyncAirTable}
                  isLoadingReSync={isLoadingReSync}
                  onClose={handleCloseSuccessModal}
                  handleDetailsDuplication={handleDetailsDuplication}
                />
      }

      <AddBrandModal
        setOpenBrandSuccesModal={setOpenBrandSuccesModal}
        isOpenModal={isOpenAddBrandModal}
        onClose={handleCloseAddBrandModal}
        setBrandWarning={setBrandWarning}
      />
      <aside style={{ width: sideNavBarWidth }} ref={innerRef}>
        <ul>
          {navigationLinksByPermission()?.map(({ label, icon, to, childPath, type }, index) => {
            const key = label + index;

            if (type) {
              return (
                <div className={`sidebar-item ${location.pathname === to  && `sidebar-item_dis`}`} key={key}>
                  <li onClick={handleAddBrandModal}>
                    <CustomLink type={type} isOpenAddBrandModal={isOpenAddBrandModal}>
                      {icon}
                      {sideNavBarWidth === '271px' && <span>{label}</span>}
                    </CustomLink>
                  </li>
                  {!open && <span className="sidebar-tooltip">{label}</span>}
                </div>
              );
            }

            return (
              <div className={`sidebar-item ${location.pathname === to  && `sidebar-item_dis`}`} key={key}>
                <li onClick={() => handleMenuItemClick(label)}>
                  <CustomLink  open={open} to={to} childPath={childPath}>
                    {icon}
                    {sideNavBarWidth === '271px' && <span>{label}</span>}
                  </CustomLink>
                </li>
                {!open && <span className="sidebar-tooltip">{label}</span>}
              </div>
            );
          })}
        </ul>

        <div
          className="user-info"
          style={{
            padding: open ? '0' : 'unset',
          }}
        >
          {roleFromToken === 'admin' && (
            <div
              className="sidebar-item"
              style={{ marginBottom: '8px' }}
              onClick={() => handleMenuItemClick(navBarLinkTeam.label)}
            >
              <CustomLink open={open} to={navBarLinkTeam.to}>
                {navBarLinkTeam.icon}
                {sideNavBarWidth === '271px' && (
                  <span style={{ fontSize: '18px', fontWeight: '400' }}>
                    {navBarLinkTeam.label}
                  </span>
                )}
              </CustomLink>
              {!open && <span className="sidebar-tooltip">{navBarLinkTeam.label}</span>}
            </div>
          )}
          {
            <div
              className="contents"
              style={{
                width: open ? 'calc(100%)' : '0',
                height: open ? 'inherit' : '0',
              }}
            >
              <div className="user-info-container">
                <h4>{name}</h4>
                <span>{email}</span>
                <div className="line"></div>
              </div>
            </div>
          }

          <div className="logout" onClick={() => !disableBtn && dispatch(logout())}>
            <img src={logOut} alt="logout" style={{ paddingLeft: '8px' }} />
            {open && <span>Log out</span>}
            {!open && <span className="sidebar-tooltip">Log out</span>}
          </div>
        </div>
      </aside>
    </>
  );
};

export default SideBar;
