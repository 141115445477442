import React from "react";
import { EmptyHistoryIcon } from "modules/shared/components/general/Icons/EmptyHistory.icon";
import "./styles.scss";

const EmptyHistory: React.FC = () => (
  <div className="request-confirm-campaign-duplication-wrapper">
    <div className="request-confirm-campaign-duplication-description">
      {EmptyHistoryIcon}
      <p>Looks like you don't have any duplicated campaigns</p>
    </div>
  </div>
);

export default EmptyHistory;
