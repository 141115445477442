import BasicModal from "modules/shared/components/general/BasicModal/BasicModal";
import SkippingReasonModal from "../SkippingReasonModal/SkippingReasonModal";
import RegenerateEmailModal from "../SuccessFailedModals/RegenerateEmailModal";
import SendEmailForOtherUsersModal from "../SuccessFailedModals/SendEmailForOtherUsersModal";
import AreYouSureToSendModal from "../SuccessFailedModals/AreYouSureToSendModal";
import SaveEditChangesModal from "../SuccessFailedModals/SaveEditChangesModal";

const Modals = ({
  isSkipOpen,
  setIsSkipOpen,
  emailId,
  isRegenerateModalOpened,
  setIsRegenerateModalOpened,
  sendModalForOtherUser,
  setSendModalForOtherUser,
  areYouSureSendModalOpened,
  setIsSaveChangesOpen,
  setAreYouSureSendModalOpened,
  isSaveChangesOpen,
  setShowEditButton,
  setIsEditorDisabeled,
  setIsEditModeOn,
  handleCloseDrawer,
  handleCloseWithoutChanges,
  isLoadingSave,
}:any) => (
  <>
    <BasicModal
      open={isSkipOpen}
      handleClose={() => setIsSkipOpen(false)}
      style={{ padding: "0" }}
    >
      <SkippingReasonModal
        onClose={() => setIsSkipOpen(false)}
        emailId={emailId}
      />
    </BasicModal>
    <BasicModal
      open={isRegenerateModalOpened}
      handleClose={() => setIsRegenerateModalOpened(false)}
    >
      <RegenerateEmailModal
        onClose={() => setIsRegenerateModalOpened(false)}
        emailId={emailId}
      />
    </BasicModal>
    <BasicModal
      open={sendModalForOtherUser}
      handleClose={() => setSendModalForOtherUser(false)}
    >
      <SendEmailForOtherUsersModal
        onClose={() => setSendModalForOtherUser(false)}
        emailId={emailId}
      />
    </BasicModal>
    <BasicModal
      open={areYouSureSendModalOpened}
      handleClose={() => setAreYouSureSendModalOpened(false)}
    >
      <AreYouSureToSendModal
        onClose={() => setAreYouSureSendModalOpened(false)}
        emailId={emailId}
      />
    </BasicModal>
    <BasicModal
      open={isSaveChangesOpen}
      style={{ width: "617px" }}
      handleClose={() => setIsSaveChangesOpen(false)}
    >
      <SaveEditChangesModal
        handleCancel={() => setIsSaveChangesOpen(false)}
        handleClose={() => {
          setIsSaveChangesOpen(false);
          setShowEditButton(true);
          setIsEditorDisabeled(true);
          setIsEditModeOn && setIsEditModeOn(false);
          handleCloseDrawer();
          handleCloseWithoutChanges();
        }}
        isLoadingSave={isLoadingSave}
      />
    </BasicModal>
  </>
);

export default Modals;
