import { useDispatch } from "react-redux";
import { useQueryClient } from "react-query";
import reportSuccess from "assets/icons/reportSuccess.svg";
import Flex from "modules/shared/components/general/Flex/Flex";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import { servicesNames } from "../../../../constants/servicesNames.constants";
import { setShowSuccessModal } from "store/brandsReporting/brandsReportingSlice";
import { ReportSuccessUseStyles } from "./ReportSuccess.styles";

const ReportSuccess = ({
  handleClose,
  text,
}: {
  handleClose: any;
  text: string;
}) => {
  const styles = ReportSuccessUseStyles();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return (
    <Flex
      direction="column"
      alignItems="center"
      className={styles.successWrapper}
    >
      <h1>Report requested successfully.</h1>
      <p>{text}</p>
      <ImageLoader
        src={reportSuccess}
        alt="report success"
        style={{ height: "300px", width: "300px" }}
      />
      <button
        className="auth-button"
        style={{ width: "unset" }}
        onClick={() => {
          dispatch(setShowSuccessModal(false));
          handleClose();
          queryClient.invalidateQueries(servicesNames.getBrandsReports);
        }}
      >
        Done
      </button>
    </Flex>
  );
};
export default ReportSuccess;
