import { useState } from "react";
import { InputAdornment, IconButton, TextField } from "@mui/material";
import AuthenticationLayout from "../../../../layout/Authentication/AuthenticationLayout";
import { EyeIconPassword } from "../../../shared/components/general/Icons/EyeIconPassword";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import { validator } from "../../../../helpers/validator";
import useForm from "../../../../hooks/useForm";
import { useStylesTextField } from '../../../Authentication/authShared.styles';


const InvitationSignUp = () => {
  const styles = useStylesTextField();
  const initState = {
    email: "",
    password: "",
  };

  const submitForm = () => {};
  const { handleInputChange, handleSubmit, handleBlur, state, errors } =
    useForm({
      initState,
      callback:submitForm,
      validator,
    });

  const isValidForm =
    Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;

  const [signInInfo, setSignInInfo] = useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setSignInInfo({ ...signInInfo, showPassword: !signInInfo.showPassword });
  };

  const handleMouseDownPassword = (event:any) => {
    event.preventDefault();
  };

  return (
    <AuthenticationLayout title="Sign up">
      <form  onSubmit={handleSubmit}>
        <TextField
          className={styles.root}
          required
          type="text"
          defaultValue={state?.email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          name="first-name"
          placeholder="First name"
          variant="outlined"
          margin="normal"
          fullWidth
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <TextField
          className={styles.root}
          required
          type="text"
          defaultValue={state?.email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          name="first-name"
          placeholder="First name"
          variant="outlined"
          margin="normal"
          fullWidth
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <TextField
          className={styles.root}
          required
          type="text"
          defaultValue={state?.email}
          onChange={handleInputChange}
          onBlur={handleBlur}
          disabled
          name="first-name"
          placeholder="First name"
          variant="outlined"
          margin="normal"
          fullWidth
          error={Boolean(errors.email)}
          helperText={errors.email}
        />
        <TextField
          className={styles.root}
          required
          error={Boolean(errors.password)}
          helperText={errors.password}
          name="password"
          defaultValue={state?.password}
          placeholder="Confirm password"
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={handleInputChange}
          onBlur={handleBlur}
          type={signInInfo.showPassword ? "text" : "password"}
          inputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ width: "40px", height: "40px" }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  <EyeIconPassword />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant={ButtonVariants.Primary}
          type="submit"
          style={{ width: "100%", marginTop: "16px" }}
          disabled={!isValidForm}
        >
          Sign up
        </Button>
      </form>
    </AuthenticationLayout>
  );
};

export default InvitationSignUp;
