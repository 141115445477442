import React, { CSSProperties } from 'react';
import { useStylesFilters } from './Filters.styles';
import { MenuItem } from '@mui/material';
import Select from '../../../shared/components/general/Select/Select';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';

const SelectFilter = ({
  label,
  optionValues = [],
  handleChange,
  filterValue,
  style,
  disabled,
  menuProps,
}: {
    label: string;
    optionValues?: string[];
    handleChange: (a: any) => void;
    filterValue: string;
    style: CSSProperties;
    disabled?: boolean;
    menuProps?: any;
}) => {
  const styles = useStylesFilters();

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    handleChange(value);
  };

  return (
    <Select
      className={styles.selectFilterWrapper}
      disabled={disabled}
      value={filterValue}
      handleChange={(event: any) => handleSelectChange(event)}
      menuProps={menuProps}
      style={{
        width: '304px',
        margin: '0',
        borderRadius: '8px',
      }}
      hasHover={true}
      formStyle={{ width: '304px', ...style }}
      renderValue={() =>
        (filterValue ? (
          <div className={styles.renderValue}>
            {label}{' '}
            <span className={styles.filterValue}>
              {disabled
                ? ''
                : capitalizeFirstLetter(filterValue?.replaceAll('_', ' '))}
            </span>
          </div>
        ) : (
          <div className={styles.renderValue}>{label}</div>
        ))
      }
    >
      {optionValues?.map((value, index) => (
        <MenuItem
          key={index}
          value={value}
          style={{
            padding: '8px 8px',
            borderRadius: '4px',
            background: value === filterValue ? '#E2E6E9' : '#FFF',
          }}
        >
          {capitalizeFirstLetter(value?.replaceAll('_', ' '))}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectFilter;
