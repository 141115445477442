import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './ErrorToast.styles';
import { ReactComponent as ErrorIcon } from 'assets/icons/Error.svg';


interface IErrorToast {
    title:string
    info: string
    handleSeeDetails:() => void
}

const ErrorToast:React.FC<IErrorToast> = ({ title, info, handleSeeDetails }) => {
  const styles = useStyles();

  return  (
    <Box className={styles.toastWrap} >
      <ErrorIcon  style={{ width:'32px', height:'32px' }}/>
      <Box>
        <strong className={styles.toastTitle}>{title}</strong>
        {
          info.includes('See details') ?
            <p className={styles.toastText}>{info.replace('See details', '')}
              <span
                className={styles.reSyncText}
                onClick={handleSeeDetails}
              >
                  See details
              </span>
            </p>
            :
            <p className={styles.toastText}>{info}</p>
        }
      </Box>
    </Box>
  );
};

export default ErrorToast;
