import axios from 'axios';
import { LocaleStorageTokenList, LocalStorage } from '../helpers/Storage';
import { store } from '../store';
import { push } from 'connected-react-router';
import { setLoginFailure } from '../store/authentication/authenticationSlice';
import { serverUrls } from '../variables';


export const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token =  LocalStorage.get(LocaleStorageTokenList.access_token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error),
);


let isRetrying = false;


instance.interceptors.response.use(
  (response) => {
    isRetrying = false;
    return response;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      if (err.response.status === 401 && !isRetrying) {
        isRetrying = true;
        try {
          if (LocalStorage.get(LocaleStorageTokenList.refresh_token)) {
            const response = await axios.post(
              `${serverUrls.accounts}token/refresh/`,
              {
                refresh: LocalStorage.get(LocaleStorageTokenList.refresh_token),
              },
            );
            LocalStorage.set(LocaleStorageTokenList.access_token, response.data.access);
            window.location.reload();
            return instance(originalConfig);
          }
        } catch (_error:any) {
          store.dispatch(push("/"));
          LocalStorage.remove(LocaleStorageTokenList.access_token);
          LocalStorage.remove(LocaleStorageTokenList.refresh_token);
          store.dispatch(setLoginFailure(_error.response.status));

          return Promise.reject("");
        }
      }
    }
    return Promise.reject(err);
  },
);


instance.interceptors.response.use(
  response =>
    response
  ,
  (error) => {
    if (error.response && error.response.status === 500) {
      window.location.pathname = '/server_error';
      return;
    }
    return Promise.reject(error);
  },
);

