import { createUseStyles } from "react-jss";

export const useStylesTabs: any = createUseStyles(() => ({
  tabsContainer: {
    display: "flex",
    margin: "16px 0",
    width: "100%",
    borderBottom: "1px solid #EAEAEA",
  },
  tabs: {
    padding: "8px 16px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#B7B7B7",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
  activeTab: {
    padding: "8px 16px",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#0F314C",
    borderBottom: " 1px solid #0F314D",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer",
  },
  selectedItemLength: {
    color: "#0F314D",
    width: "24px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: "500",
    fontSize: "14px",
  },
}));
