/* eslint-disable */
// @ts-ignore
export const variables = { ...process.env, ...window["variables"] };

const {
    REACT_APP_BACKEND_API_URL_AUTH,
    REACT_APP_BACKEND_API_URL_FCA,
    REACT_APP_BACKEND_API_URL_AFD,
} = variables;

export const serverUrls = {
    accounts: `${REACT_APP_BACKEND_API_URL_AUTH}accounts/`,
    campaigns: `${REACT_APP_BACKEND_API_URL_FCA}campaigns/`,
    reporting: `${REACT_APP_BACKEND_API_URL_FCA}reporting/`,
    reportingBrand: `${REACT_APP_BACKEND_API_URL_FCA}brand-management/`,
    clientCommunication: `${REACT_APP_BACKEND_API_URL_FCA}client-communication/`,
    suggestions: `${REACT_APP_BACKEND_API_URL_FCA}suggestions/`,
    locationManagement: `${REACT_APP_BACKEND_API_URL_AFD}location-management/`,
    preBooking: `${REACT_APP_BACKEND_API_URL_AFD}pre-booking/`,
};
