export const RegexConstants = {
  Only1To100Numbers: /^[1-9]\d?$|^100$/,
  email: /^[\w.+_-]+@[\w-]+(\.[\w-]+)*\.[a-zA-Z]{2,}$/,
  password:
    /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,}).*$/,
  onlyNumbers: /^\d*$/,
  numbersAndLetters: /^[a-zA-Z0-9]*$/,
  onlyLetters: /^[a-zA-Z-]+$/,
  validUrl:
    /^(https?:\/\/)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/,
};
