import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStylesClientCommunication = createUseStyles(
  (theme: AppTheme) => ({
    title: {
      color: theme.deepBlueColor,
      fontSize: "32px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "48px",
      textAlign: "center",
    },
    emailReports: {
      maxWidth: "1288px",
      margin: "32px auto 0 auto",
      width: "100%",
      "& fieldset": {
        borderColor: "transparent !important",
        borderWidth: "0 !important",
      },
    },
    statisticsWrapper: {
      height: "152px",
      width: "100%",
      margin: "24px 0 8px",
    },
    timePeriod: {
      "&>span": {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "1.5",
        color: "#B7B7B7",
        "&:last-child": {
          color: "#0F314D",
        },
      },
    },

    tooltipText: {
      display: "none",
      gap: "10px",
      alignItems: "center",
      position: "absolute",
      left: "-315px",
      top: "50%",
      transform: "translateY(-50%)",
      background: "#fff",
      borderRadius: "8px",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "21px",
      color: "#B3261E",
      padding: "8px 16px",
      boxShadow: "0px 0px 20px 0px rgba(116, 116, 116, 0.15)",
      zIndex: "999",
      "&:before": {
        content: "''",
        position: "absolute",
        right: "-14px",
        top: "12px",
        transform: "translate(-50%) rotate(45deg)",
        width: "16px",
        height: "16px",
        backgroundColor: "#fff",
        borderRadius: "1px",
      },
    },
    googleAuthContainer: {
      position: "relative",
      "&:hover $tooltipText": {
        display: "flex",
      },
    },
  }),
);
