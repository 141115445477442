import React from "react";
import { tooltipUseStyles } from "./CustomTooltipForDuplication.styles";

const CustomTooltipForDuplication = ({ value }: { value: string }) => {
  const styles = tooltipUseStyles();
  return (
    <div className={styles.tooltipContainer}>
      <span className={styles.tooltipName}>{value}</span>
      <span className={styles.tooltip}>{value}</span>
    </div>
  );
};
export default CustomTooltipForDuplication;
