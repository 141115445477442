import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateLeadGenForm } from "../../../../store/updateContent/actions";
import {
  setFacebookLeadGenForm,
  setShowLeadPreview,
  setShowUpdateTab,
} from "../../../../store/updateContent/updateContentSlice";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import CustomTooltip from "modules/shared/components/general/CustomTooltip/CustomTooltip";
import { useUpdatePreview } from "../../../../context/update.context";
import { IRootStore } from "types/store.types";
import { AppDispatch } from "store";
import "./styles.scss";

const LeadUpdatePreview = () => {
  const { setShowSuccessErrorContent, setBackToPreview, leadRecordId } =
    useUpdatePreview();
  const dispatch = useDispatch<AppDispatch>();
  const {
    updateContent: {
      loading,
      facebookLeadGenForm: { lead_gen_forms, page_name, location_name },
    },
  } = useSelector((state:IRootStore) => state);

  const handleBackClick = () => {
    dispatch(setShowLeadPreview(false));
    dispatch(setShowUpdateTab(true));
  };

  return (
    <div className="airtable-update-preview">
      <h1 className="modal-title">Airtable update preview</h1>
      <p className="location-name modal-subtitle">Location name</p>
      <span>{location_name}</span>
      <p className="modal-subtitle">Page name</p>
      <span>{page_name}</span>
      <p className="modal-subtitle">Lead forms</p>
      <div className="lead-forms">
        {lead_gen_forms?.map((lead:any, index:any) => (
          <Fragment key={index}>
            {lead.length > 95 ? <CustomTooltip value={lead} /> : <p>{lead}</p>}
          </Fragment>
        ))}
      </div>
      <div className="modal-footer">
        <button
          className="auth-button"
          onClick={() => {
            handleBackClick();
          }}
        >
          Back
        </button>
        <button
          className="auth-button"
          onClick={() =>
            (!loading
              ? dispatch(
                updateLeadGenForm(lead_gen_forms, leadRecordId, () => {
                  setShowSuccessErrorContent(true);
                  dispatch(setFacebookLeadGenForm({}));
                  setBackToPreview(true);
                }),
              )
              : undefined)
          }
          style={{ position: "relative", width: loading ? "112px" : "unset" }}
        >
          {loading ? (
            <Spinner
              white={true}
              style={{
                width: "16px",
                margin: "0",
                left: "50%",
                position: "initial",
              }}
            />
          ) : (
            "Update"
          )}
        </button>
      </div>
    </div>
  );
};
export default LeadUpdatePreview;
