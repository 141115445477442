import { instance } from "../api/ApiRequest";
import { serverUrls } from "../variables";

export const getUsers = async ({
  sort_by,
  order_by,
}: {
  sort_by?: string;
  order_by?: string;
} = {}) => {
  const paramsObj = {
    ...(order_by ? { order_by } : {}),
    ...(sort_by ? { sort_by } : {}),
  };

  const queryParams = new URLSearchParams(paramsObj);

  const response = await instance.get(
    `${serverUrls.accounts}users/?${queryParams}`,
  );
  return response;
};

export const getSingleUser = async (id: number| undefined) => {
  const response = await instance.get(`${serverUrls.accounts}users/${id}/`);

  return response;
};

export const singleUserUpdate = async (id: number | undefined, payload: any) => {
  const response = await instance.patch(
    `${serverUrls.accounts}users/${id}/`,
    payload,
  );

  return response;
};

export const inviteNewUser = async (email: string, payload: any) => {
  const response = await instance.post(
    `${serverUrls.accounts}users/invitations/`,
    {
      email,
      ...payload,
    },
  );

  return response;
};
