import React, { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { LocaleStorageTokenList, LocalStorage } from '../../../helpers/Storage';
import useDebounce from 'hooks/useDebounce';
import Tabs from '../components/Tabs/Tabs';
import WeeklyBoard from '../components/WeeklyBoard/WeeklyBoard';
import AllEmails from '../components/AllEmailsTable/AllEmails';
import Filters from '../components/Filters/Filters';
import { useClientCommunicationContext } from '../../../context/clientCommunication.context';
import { useStylesClientCommunication } from './ClientCommunication.styles';
import StatisticsContent from '../components/StatisticCards/StatisticsContent';

const ClientCommunication:React.FC = () => {
  const styles = useStylesClientCommunication();
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);

  const { role: roleFromToken } =
    accessToken &&
    jwt_decode<any>(accessToken);

  const {
    selectedTimePeriod,
    searchValue,
    setSelectedFsmsIds,
    setSelectedFsms,
    activeTab,
    setDebouncedSearchValue,
  } = useClientCommunicationContext();

  const debauncedValue = useDebounce(searchValue, 1000);

  useEffect(() => {
    setDebouncedSearchValue(debauncedValue);
    // eslint-disable-next-line
  }, [debauncedValue]);

  useEffect(() => {
    LocalStorage.set("activeTab", activeTab);

    return () => {
      setSelectedFsmsIds([]);
      setSelectedFsms([]);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    LocalStorage.set("activeTab", activeTab);
    return () => {
      LocalStorage.remove("activeTab");
    };
  }, [activeTab]);

  return (
    <div className={styles.emailReports}>
      <h1 className={styles.title}>Client communication</h1>
      <StatisticsContent roleFromToken={roleFromToken} selectedTimePeriod={selectedTimePeriod} />
      <Tabs />
      <Filters />
      {activeTab === "weeklyBoard" ? <WeeklyBoard /> : <AllEmails />}
    </div>
  );
};

export default ClientCommunication;
