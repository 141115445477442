export const EnterIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2496 4C21.6638 4 21.9996 4.33579 21.9996 4.75V11.25C21.9996 13.3211 20.3206 15 18.2496 15H4.58541L8.30474 18.7197C8.57101 18.9859 8.59522 19.4026 8.37736 19.6962L8.30474 19.7803C8.03848 20.0466 7.62181 20.0708 7.3282 19.8529L7.24408 19.7803L2.24408 14.7803C1.97782 14.5141 1.95361 14.0974 2.17147 13.8038L2.24408 13.7197L7.24408 8.71967C7.53698 8.42678 8.01185 8.42678 8.30474 8.71967C8.57101 8.98594 8.59522 9.4026 8.37736 9.69621L8.30474 9.78033L4.58541 13.5H18.2496C19.4404 13.5 20.4152 12.5748 20.4944 11.404L20.4996 11.25V4.75C20.4996 4.33579 20.8354 4 21.2496 4Z"
      fill="white"
    />
  </svg>
);
