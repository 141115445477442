import { useInfiniteQuery, useQuery } from "react-query";
import {
  getAllEmails,
  getEmailsStatistics,
  getWeeklyBoardEmailReports,
} from "../../../services/clientCommunication";
import { servicesNames } from "../../../constants/servicesNames.constants";
import { reportStatuses } from "../constants/constants";
import { getPageFromUrl } from "../../../helpers/PageFromUrl";

export const useToSendDataQuery = (
  selectedFsmsIds:any,
  fsmsData:any,
  activeTab:any,
  isSelectOpen:any,
) => {
  const {
    data: toSendData,
    refetch: refetchToSendData,
    fetchNextPage: toSendFetchNextPage,
    hasNextPage: toSendHasNextPage,
    isFetching: toSendIsFetching,
    isFetchingNextPage: toSendIsFetchingNextPage,
  } = useInfiniteQuery(
    [servicesNames.getToSendEmailReportsData, { activeTab }],
    ({ pageParam = 1 }) => getWeeklyBoardEmailReports({
      page: pageParam,
      status: reportStatuses.TO_SEND,
      fsm_ids: selectedFsmsIds,
      fsmsData,
    }),
    {
      getNextPageParam: ({ data: { next = "" } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
      enabled:
        activeTab === "weeklyBoard" &&
        selectedFsmsIds.length > 0 &&
        !isSelectOpen,
    },
  );

  return {
    toSendData,
    refetchToSendData,
    toSendFetchNextPage,
    toSendHasNextPage,
    toSendIsFetching,
    toSendIsFetchingNextPage,
  };
};

export const useSentDataQuery = (
  selectedFsmsIds:any,
  fsmsData:any,
  activeTab:any,
  isSelectOpen:any,
) => {
  const {
    data: sentData,
    fetchNextPage: sentFetchNextPage,
    hasNextPage: sentHasNextPage,
    refetch: refetchSentData,
    isFetching: sentIsFetching,
    isFetchingNextPage: sentIsFetchingNextPage,
  } = useInfiniteQuery(
    [servicesNames.getSentEmailReportsData, { activeTab }],
    ({ pageParam = 1 }) => getWeeklyBoardEmailReports({
      page: pageParam,
      status: reportStatuses.SENT,
      fsm_ids: selectedFsmsIds,
      fsmsData,
    }),
    {
      getNextPageParam: ({ data: { next = "" } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
      enabled:
        activeTab === "weeklyBoard" &&
        selectedFsmsIds.length > 0 &&
        !isSelectOpen,
    },
  );

  return {
    sentData,
    sentFetchNextPage,
    sentHasNextPage,
    refetchSentData,
    sentIsFetching,
    sentIsFetchingNextPage,
  };
};

export const useSkippedDataQuery = (
  selectedFsmsIds:any,
  fsmsData:any,
  activeTab:any,
  isSelectOpen:any,
) => {
  const {
    data: skippedData,
    fetchNextPage: skippedFetchNextPage,
    hasNextPage: skippedHasNextPage,
    refetch: refetchSkippedData,
    isFetching: skippedIsFetching,
    isFetchingNextPage: skippedIsFetchingNextPage,
  } = useInfiniteQuery(
    [servicesNames.getSkippedEmailReportsData, { activeTab }],
    ({ pageParam = 1 }) => getWeeklyBoardEmailReports({
      page: pageParam,
      status: reportStatuses.SKIPPED,
      fsm_ids: selectedFsmsIds,
      fsmsData,
    }),
    {
      getNextPageParam: ({ data: { next = "" } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
      enabled:
        activeTab === "weeklyBoard" &&
        selectedFsmsIds.length > 0 &&
        !isSelectOpen,
    },
  );

  return {
    skippedData,
    skippedFetchNextPage,
    skippedHasNextPage,
    refetchSkippedData,
    skippedIsFetching,
    skippedIsFetchingNextPage,
  };
};

export const useAllEmails = (
  activeTab:any,
  selectedTimePeriod:any,
  debouncedSearchValue:any,
  selectedStatuses:any,
  selectedFsmsIds:any,
  fsmsData:any,
  statuses:any,
) => {
  const {
    data: allEmailsResponse,
    refetch: refetchAllEmails,
    fetchNextPage: fetchNextAllEmailsPage,
    isFetchingNextPage: allEmailsIsFetchingNextPage,
    isFetching: allEmailsIsFetching,
  } = useInfiniteQuery(
    [
      servicesNames.getAllEmails,
      {
        activeTab,
        selectedTimePeriod,
        debouncedSearchValue,
      },
    ],
    ({ pageParam = 1 }) => getAllEmails({
      page: pageParam,
      status: selectedStatuses,
      fsm_ids: selectedFsmsIds,
      period: selectedTimePeriod,
      fsmsData,
      statusesData: statuses,
      ownerName: debouncedSearchValue,
    }),
    {
      onSuccess: (data) => {
        if (!data?.pages[0].count) {
          // setIsEmptyStateAllEmails(true);
        } else {
          if (selectedFsmsIds.length) {
            // setIsEmptyStateAllEmails(false);
          }
        }
      },
      getNextPageParam: (data) => {
        if (data?.next) {
          const res = getPageFromUrl(data?.next);
          return res ? Number(res) : undefined;
        }
      },
      enabled: activeTab === "allEmails",
    },
  );

  const allEmailsData:any = allEmailsResponse?.pages.map(page => page?.results?.map((item:any) => item));

  const flattenedArrayAllEmailsResponse = [].concat.apply([], allEmailsData);

  return {
    allEmailsResponse,
    refetchAllEmails,
    fetchNextAllEmailsPage,
    allEmailsIsFetchingNextPage,
    allEmailsIsFetching,
    flattenedArrayAllEmailsResponse,
  };
};

export const useStatistics = (activeTab:any, roleFromToken:any, selectedTimePeriod:any) => {
  const { data: { data: statisticsData } = {} } = useQuery(
    [
      servicesNames.getEmailsStatistics,
      { activeTab, roleFromToken, selectedTimePeriod },
    ],
    () => getEmailsStatistics({ period: selectedTimePeriod }),
    {
      enabled: activeTab === "allEmails" && roleFromToken === "admin",
    },
  );

  return { statisticsData };
};
