import React from "react";

export const ActivateIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 21.0183L3.78984 18.1458C5.0964 13.3941 8.5482 10.9863 12 10.9863"
      stroke="#0F314D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.0005 10.9902C14.7619 10.9902 17.0005 8.75166 17.0005 5.99023C17.0005 3.22881 14.7619 0.990234 12.0005 0.990234C9.23906 0.990234 7.00049 3.22881 7.00049 5.99023C7.00049 8.75166 9.23906 10.9902 12.0005 10.9902Z"
      stroke="#0F314D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="bevel"
    />
    <path
      d="M17 23.002C19.7614 23.002 22 20.7634 22 18.002C22 15.2405 19.7614 13.002 17 13.002C14.2386 13.002 12 15.2405 12 18.002C12 20.7634 14.2386 23.002 17 23.002Z"
      stroke="#33850D"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.1 16.5L16.4 19.5L14.9 18.3"
      stroke="#33850D"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
