import React from "react";
import Flex from "modules/shared/components/general/Flex/Flex";
import csvFileIconGray from "assets/icons/csvFileIconGray.svg";
import refreshIcon from "assets/icons/refreshIcon.svg";

const FailedReport:React.FC<any> = ({
  row,
  rowIndex,
  data,
  handleRetryClick,
  generateReportNameTooltip,
  generateDeleteIcon,
  isLoading,
  reportId,
}) => (
  <>
    <tr {...row.getRowProps()}>
      {row.cells.map((cell:any) => {
        if (cell.column.id === 'reportName') {
          return (
            <td
              {...cell.getCellProps()}
              style={{ display: "flex", gap: "16px" }}
            >
              <img src={csvFileIconGray} alt="disable file " />
              {generateReportNameTooltip(cell)}
            </td>
          );
        }
        if (cell.column.id === 'state') {
          return (
            <td {...cell.getCellProps()}>
              <Flex style={{ gap: "32px" }}>
                <div className="refresh-wrapper">
                  <img
                    src={refreshIcon}
                    alt="retry"
                    onClick={() => {
                      handleRetryClick(data[rowIndex]?.id);
                    }}
                    className="icon-button"
                    style={
                      isLoading && reportId === data[rowIndex]?.id
                        ? {
                          animation: "0.8s linear infinite spinner",
                          // " -webkit-animation":
                          //   "0.8s linear infinite spinner",

                          animationPlayState: "inherit",
                          transform: "translate3d(-50%, -50%, 0)",
                          willChange: "transform",
                        }
                        : undefined
                    }
                  />

                  {isLoading && reportId === data[rowIndex]?.id ? null : (
                    <div className="refresh-tooltip">
                        Failed to generate report. Please try again.
                    </div>
                  )}
                </div>
                {generateDeleteIcon(
                  data[rowIndex]?.isOwner,
                  data[rowIndex].id,
                )}
              </Flex>
            </td>
          );
        }
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  </>
);

export default FailedReport;
