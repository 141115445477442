import pauseAdIcon from 'assets/icons/pauseAdIcon.svg';
import splitTestBudgetIcon from 'assets/icons/splitEndIcon.svg';
import splitTestEnd from 'assets/icons/splitTestBudgetIcon.svg';

export enum SuggestionCardItemType {
    splitTestBudget = 'split test budget reallocation',
    pauseAd = 'pause ad',
    splitTestEnd = 'split test end'
}

export enum SuggestionValidationStsCode {
    ValidSuggestion = 200,
    SurePauseAd = 222,
    FBAtomicExc = 400,
    NotFound = 404,
    CanNotBeAccepted = 422
}

export const iconMappingSuggestionCard = {
  [SuggestionCardItemType.splitTestBudget.toLowerCase().trim()]: splitTestBudgetIcon,
  [SuggestionCardItemType.pauseAd.toLowerCase().trim()]: pauseAdIcon,
  [SuggestionCardItemType.splitTestEnd.toLowerCase().trim()]: splitTestEnd,
};


export interface IISuggestionCardSplit {
    action_type:string
    id:any
    updated_by?:string
    created_at?:string
    status?:string
    details: {
        account_last_30_days_cpl?:number
        account_daily_budget?:number
        account_remaining_budget?:number
        ad?:{
            last_7_average_cpl:number
            last_7_days_amount_spent:number
            last_7_days_impressions:number
            last_7_days_leads:number
            link:string
            name:string
        }
        campaign?: {
            link:string
            name:string
        }
        losing_campaign?: {
            last_3_days_cpl:number
            last_7_days_cpl:number
            new_daily_budget:number
            lifetime_cpl:number
            old_daily_budget:number
            link:string
            name:string
        },
        winning_campaign?: {
            last_3_days_cpl:number
            lifetime_cpl:number
            last_7_days_cpl:number
            new_daily_budget:number
            old_daily_budget:number
            link:string
            name:string
        }
    },
    reason:{
        description:string,
        type:string
    }
}
