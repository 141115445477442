import Flex from "modules/shared/components/general/Flex/Flex";
import Checkbox from "modules/shared/components/general/Checkbox/Checkbox";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import check1 from "assets/icons/check1.svg";

import { Brand } from "./types";
import { reportRequestUseStyles } from "modules/BrandsReporting/components/ReportRequest/ReportRequest.styles";
import ROUTES from "../../../../constants/routes";

const BrandLogosData = ({ location, brands, setBrands }: { location: any, brands: any, setBrands: any }) => {
  const styles = reportRequestUseStyles();

  const handleBrandsCheck = (index: number) => {
    const brandsData: any = [...brands];
    brandsData[index].checked = !brandsData[index].checked;

    setBrands(brandsData);
  };

  if (location.pathname === ROUTES.REPORTING) {
    return brands?.map((brandLogo: Brand, index: number) => (
      <Flex
        alignItems="center"
        className={styles.brandNameWrapper}
        key={brandLogo.id}
      >
        <Checkbox
          handleCheck={() => handleBrandsCheck(index)}
          styles={{
            checkboxWidth: "9.62px",
            checkboxHeight: "7.62px",
            beforeWidth: "20px",
            beforeHeight: "20px",
            backgroundColor: "#fff",
            check: check1,
            left: "5px",
            top: "6px",
          }}
        />
        <ImageLoader
          src={brandLogo.logo_url}
          alt="brand"
          style={{
            marginLeft: "20px",
            height: "40px",
            width: "100px",
            objectFit: "contain",
          }}
        />
        <div className={styles.brandNameDivider}></div>{" "}
        <div className={styles.brandName}>{brandLogo.name}</div>
      </Flex>
    ));
  } else {
    return (
      <Flex alignItems="center" className={styles.brandNameWrapper}>
        <ImageLoader
          src={location?.state?.logo}
          alt="brand"
          style={{
            height: "40px",
            width: "100px",
            objectFit: "contain",
          }}
        />
        <div className={styles.brandNameDivider}></div>{" "}
        <div className={styles.brandName}>{location?.state?.name}</div>
      </Flex>
    );
  }
};

export default BrandLogosData;
