import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

interface Props {
  isDisabled: boolean;
  isSwitchOn: boolean;
}

export const switchUseStyles = createUseStyles((theme: AppTheme) => ({
  wrapper: {
    margin: "0 8px",
    lineHeight: 0,
    height: "24px",
  },
  switchCheckbox: {
    height: 0,
    width: 0,
    visibility: "hidden",
    display: "none",
    "&:checked + $switchLabel $switchButton": {
      left: "calc(100% - 2px)",
      transform: "translateX(-100%)",
    },
  },
  switchLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: (props: Props) => (props.isDisabled ? "initial" : "pointer"),
    width: "24px",
    height: "14px",
    background: (props: Props) => (props.isSwitchOn ? theme.primaryColor : "rgba(68, 68, 68, 0.25)"),
    borderRadius: "100px",
    position: "relative",
    transition: "background-color .2s",
    "&:acitve > $switchButton": {
      width: "60px",
    },
  },
  switchButton: {
    content: "",
    position: "absolute",
    top: "2px",
    left: "2px",
    width: "10px",
    height: "10px",
    borderRadius: "45px",
    transition: "0.2s",
    background: theme.whiteColor,
    boxShadow: "0 0 2px 0 rgba(10, 10, 10, 0.29)",
  },
}));
