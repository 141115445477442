import React from "react";
import { EmptyHistoryByIdIcon } from "../../../shared/components/general/Icons/EmptyHistoryById.icon";
import "./styles.scss";

const EmptyHistoryById:React.FC = () =>  (
  <div className="request-confirm-campaign-duplication-wrapper">
    <div className="request-confirm-campaign-duplication-description">
      {EmptyHistoryByIdIcon}
      <p>No results found</p>
      <p>Looks like this campaign hasn't been duplicated yet.</p>
    </div>
  </div>
);

export default EmptyHistoryById;
