import {
  setLoginSuccess,
  setSignInInfo,
  setLoginFailure,
  setLoginLoading,
  setLoginFailureStatus,
  setDisableBtn,
} from './authenticationSlice';
import { LocaleStorageTokenList, LocalStorage } from '../../helpers/Storage';
import { instance } from 'api/ApiRequest';
import { getSearchedCampaigns } from 'store/brandAdAccounts/actions';
import { serverUrls } from '../../variables';

export const login = (email: any, password: any) =>
  instance.post(`${serverUrls.accounts}token/`, {
    email,
    password,
  });

export const checkRefreshToken = () => async (dispatch: any) => {
  try {
    await instance.post(`${serverUrls.accounts}token/verify/`, {
      token: LocalStorage.get(LocaleStorageTokenList.refresh_token),
    });
  } catch (error) {
    dispatch(logout());
    dispatch(setLoginSuccess(false));
    dispatch(setDisableBtn(false));
  }
};

export const loginAction = (email: any, password: any) => async (dispatch: any) => {
  try {
    dispatch(setDisableBtn(true));
    dispatch(setLoginLoading(true));
    const response = await login(email, password);
    const {
      data: { access, refresh },
    } = response;
    dispatch(setDisableBtn(false));
    LocalStorage.set(LocaleStorageTokenList.access_token, access);
    LocalStorage.set(LocaleStorageTokenList.refresh_token, refresh);
    dispatch(setLoginSuccess(true));
    dispatch(setLoginFailure(false));
    dispatch(setLoginLoading(false));
  } catch (error: any) {
    dispatch(setDisableBtn(true));
    dispatch(setLoginFailureStatus(error?.response.status));
    dispatch(setLoginFailure(true));
    dispatch(setLoginLoading(false));
    dispatch(setDisableBtn(false));
  }
};

export const logout = () => async (dispatch: any) => {
  dispatch(setDisableBtn(true));
  const refreshToken = LocalStorage.get(LocaleStorageTokenList.refresh_token);
  const { status } = await instance.post(`${serverUrls.accounts}logout/`, {
    refresh: refreshToken,
  });
  LocalStorage.remove(LocaleStorageTokenList.access_token);
  LocalStorage.remove(LocaleStorageTokenList.refresh_token);
  LocalStorage.remove(LocaleStorageTokenList.googleAuthAccessToken);

  dispatch(setDisableBtn(false));

  status === 205 && dispatch(setLoginSuccess(false));
  dispatch(getSearchedCampaigns());
  dispatch(setLoginFailure(null));
};

export const getSignInInfo = (payload: any) => async (dispatch: any) => {
  dispatch(setSignInInfo(payload));
};
