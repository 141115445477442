import React from "react";
import { useScrollTop } from "hooks/useScrollTop";
import Spinner from "../../../shared/components/general/Spinner/Spinner";
import { useStylesSuggestionsData } from "./SuggestionsData.styles";
import SuggestionCardItem from '../SuggestionCardItem/SuggestionCardItem';

interface ISuggestionsData {
  flattenedArray: any
  isFetchingNextPage: boolean
  activeTab: string
}

const SuggestionsData:React.FC<ISuggestionsData> = ({
  flattenedArray,
  isFetchingNextPage,
  activeTab,
}) => {
  const styles = useStylesSuggestionsData();

  const scrollToTopComponent = useScrollTop();

  return (
    <>
      {flattenedArray?.map((item:any) => (
        <div className={styles.suggestedAdItemsWrapper} key={item.id}>
          <SuggestionCardItem  item={item} activeTab={activeTab} />
        </div>
      ))}
      {isFetchingNextPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Spinner style={{ margin: "0" }} />
        </div>
      )}
      {scrollToTopComponent}
    </>
  );
};

export default SuggestionsData;
