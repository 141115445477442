import { createUseStyles } from "react-jss";


export const brandAdAccountUseStyles = createUseStyles(() => ({
  campaignInfoWrapper: {
    padding: "24px 0px 24px 24px",
    width: "100%",
    borderBottom: "1px solid #D9D9D9",
  },
  disableCampaignInfoWrapper: {
    padding: "24px 0px 24px 24px",
    width: "100%",
    background: "#F9F9F9",
    borderBottom: "1px solid #D9D9D9",
  },
  acountName: {
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#444444",
    margin: "0",
  },
  acountId: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#959595",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
  },
  campaignInfo: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "18px",
    whiteSpace: "nowrap",
    display: "none",
    position: "absolute",
    left: "35px",
    top: "14px",
    transform: "translateY(-50%)",
    background: "#ffffff",
    borderRadius: "8px",
    color: "#444444",
    padding: "12px",
    boxShadow: "0px 4px 20px rgba(95, 95, 95, 0.15)",
    zIndex: "999",

    "&::before": {
      content: "''",
      position: "absolute",
      left: "0",
      top: "calc(50% - 8px)",
      transform: "translate(-50%) rotate(45deg)",
      width: "12px",
      height: "12px",
      backgroundColor: "#ffffff",
      borderRadius: "1px",
    },
  },
  campaignInfoIcon: {
    position: "relative",
    "&:hover $campaignInfo": {
      display: "block",
    },
  },
  formIdContainer: {
    display: "flex",
    alignItems: "center",
  },
  budgetAllocationFormId: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "1.5",
    color: "#444444",
  },
  budgetAllocationPercentageWrap:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textApply: {
    marginTop:'5px',
    color:'#3F5A71',
    fontSize:'14px',
    cursor:'pointer',
    position: 'absolute',
    width: '200px',
    left: '120px',
    top: '40px',
  },
  budgetAllocationPercentage: {
    position: "relative",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    width: "75px",
    "&>input": {
      padding: "12px 16px",
      width: "72px",
      height: "38px",
      borderRadius: "4px",
      border: "none",
    },
  },

  dateStatusWrap:{
    position: "relative",
    marginTop: '10px',
    display:'flex',
    gap: '8px',
  },
  statusDate:{
    borderRadius: '15px',
    padding: '2px 8px',
    color: '#444',
    display: 'flex',
    alignItems: "center",
  },
  dot:{
    backgroundColor: '#000',
    borderRadius: '10px',
    display: 'inline-block',
    height: '10px',
    width: '10px',
    marginRight: '8px',
  },
  percentage: {
    position: "absolute",
    paddingLeft: "8px",
    borderLeft: "1px solid #D9D9D9",
    right: "8px",
    top: "10px",
    background: "#FFFFFF",
  },
  status: {
    width: "fit-content",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.5",
    padding: "4px 8px",
    borderRadius: "15px",
  },

  errorMessage: {
    position: "absolute",
    bottom: "-18px",
    right: "-43px",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "12px",
    color: "#B3261E",
  },
  disableTooltipWrapper: {
    display: "flex",
    gap: "10px",
    backgroundColor: "transparent",
    zIndex: 8,
  },
  tooltipMessage: {
    color: "#959599",
    lineHeight: "1.5",
    background: "#F9F9F9",
  },
}));
