// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import AccountItem from './AccountItem/AccountItem';
import './styles.scss';
import { confirmDuplication } from '../../../../store/requestedDuplications/actions';
import ROUTES from '../../../../constants/routes';


const DuplicationPreviewData: React.FC<any> = (
  {
    requestId,
    campaignDuplication,
    handleCampaignDuplicationConfirmRequest,
    next,
    removeById,
    setRequestPreviewStatus,
  }) => {
  const dispatch = useDispatch();

  const [disableConfirm, setDisabelConfirm] = useState(false);
  const [campaignIds, setCampaignIds] = useState([]);

  useEffect(
    () => {
      setCampaignIds(
        campaignDuplication.map(({ id }: any) => id),
      );
    },
    // eslint-disable-next-line
        [campaignDuplication?.length]
  );

  const handleConfirmDuplication = () => {
    setDisabelConfirm(true);
    dispatch<any>(
      confirmDuplication(requestId, campaignIds, () => {
        setRequestPreviewStatus("duplication is confirmed");
      }),
    );
  };

  const printDuplicationItems = () => campaignDuplication.map(
    ({ name, target_account_name, daily_budget, ad_sets, id }: any) => (
      <AccountItem
        campaignDuplication={campaignDuplication}
        name={name}
        id={id}
        targetAccountName={target_account_name}
        dailyBudget={daily_budget}
        adSets={ad_sets}
        key={id}
        removeById={removeById}
      />
    ),
  );

  return (
    <div className="confirm-campaign-duplication-wrapper">
      <div className="title-wrapper">
        <h1 className="header-title">Confirm campaign duplication</h1>
        <p className="header-description">
                    Please review the duplicated accounts info and confirm the
                    duplication.
        </p>
        <span>Check your email if there are missing duplications.</span>
      </div>
      <div>
        <InfiniteScroll
          style={{ height: 'calc(100vh - 340px)' }}
          dataLength={campaignDuplication?.length}
          next={() => {
            next && handleCampaignDuplicationConfirmRequest();
          }}
          hasMore={next}
          loader={null}
        >
          {printDuplicationItems()}
        </InfiniteScroll>
      </div>
      {Boolean(campaignDuplication.length) && (
        <div className="duplication-btn-wrapper">
          <Link className="auth-button cancel-button" to={ROUTES.DUPLICATION}>
                        Cancel
          </Link>
          <button
            className="auth-button confirm-button"
            disabled={disableConfirm}
            onClick={handleConfirmDuplication}
          >
                        Confirm
          </button>
        </div>
      )}
    </div>
  );
};

export default DuplicationPreviewData;
