import React from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import clientComunicationEmptyState from "../../../../assets/icons/clientComunicationEmptyState.svg";
import { emptyStateUseStyles } from "./EmptyState.styles";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";

const EmptyState = () => {
  const styles = emptyStateUseStyles();

  return (
    <Flex
      direction="column"
      style={{ gap: "16px", padding: "140px 0" }}
      justifyContent="center"
      alignItems="center"
    >
      <ImageLoader
        src={clientComunicationEmptyState}
        alt="client Comunication Empty State"
        style={{ width: "300px", height: "300px" }}
      />
      <span className={styles.message}>
        You don't have any emails generated with this filter.
      </span>
      <span className={styles.description}>Try another filter!</span>
    </Flex>
  );
};

export default EmptyState;
