import { createUseStyles } from "react-jss";
import { AppTheme } from "../../../../types/styles.types";

export const useActivateOrDeactivateStyles = createUseStyles(
  (theme: AppTheme) => ({
    wrapper: {
      background: theme.grey2,
      padding: "56px 32px 32px 32px",
      height: "572px",
    },
  }),
);
