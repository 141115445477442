import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import close from 'assets/icons/close.svg';
import enterBlue from 'assets/icons/enterBlue.svg';
import {
  resetAllDuplicationData,
  setCampaignIdSearchedValue,
  setErrorMessageCampaignIdSearch,
} from '../../../../store/brandAdAccounts/brandAdAccountsSlice';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import Flex from 'modules/shared/components/general/Flex/Flex';
import { RegexConstants } from '../../../../constants/regex.constants';
import {
  getSearchedCampaigns,
  getWarningsDuplication,
  resyncDuplication,
} from '../../../../store/brandAdAccounts/actions';
import { errorTexts } from '../../../../constants/errorTexts';
import { SearchedCampaignIdUseStyles } from './SearchCampaignId.styles';
import { AppDispatch } from 'store';
import ErrorToast from '../../../shared/components/general/ErrorToast/ErrorToast';
import NamingConventionModal from '../NamingConvetionModal/NamingConvetionModal';
import { Box, Grid } from '@mui/material';

import { ReactComponent as UpdateIcon } from 'assets/icons/updateIcon.svg';
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import jwt_decode from 'jwt-decode';


const SearchCampaignId = () => {
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  const { role: roleFromToken } = accessToken && jwt_decode<any>(accessToken);
  const styles = SearchedCampaignIdUseStyles();
  const dispatch = useDispatch<AppDispatch>();
  const controller = useRef(new AbortController());
  const [isOpenNamingDetails, setOpenNamingDetails] = useState<boolean>(false);
  const [isResyncLoaded, setResyncLoaded] = useState<boolean>(false);

  const {
    brandAdAccounts:
            {
              errorMessageCampaignIdSearch, disableEnterBtn, campaignIdSearchedValue,
              isNamingConventionError, franchisorErrorId, duplicationWarnings,
              syncNamesPattern = null, namingConventionErrors = null,
            },
  } = useSelector((state: any) => state);

  const checkOnlyNumbers = RegexConstants.onlyNumbers.test(campaignIdSearchedValue);

  const inputSearchStyle: any = {
    border: !checkOnlyNumbers && '1px solid rgb(179, 38, 30)',
  };

  const handleSearchChange = (event: any) => {
    dispatch(setErrorMessageCampaignIdSearch(''));
    dispatch(setCampaignIdSearchedValue(event.target.value));
    if (event.target.value === '') {
      handleResetSearch();
    }
  };

  const errorMessageGenerator = () => {
    if (!checkOnlyNumbers) {
      return (
        <ErrorMessage
          text={errorTexts.onlyNumbers}
          margin="8px 4px"
          style={{ position: 'absolute' }}
        />
      );
    }
    if (errorMessageCampaignIdSearch) {
      return (
        <ErrorMessage
          text={errorMessageCampaignIdSearch}
          margin="8px 4px"
          style={{ position: 'absolute' }}
        />
      );
    }
  };


  const handleResetSearch = () => {
    dispatch(setCampaignIdSearchedValue(''));
    dispatch(getSearchedCampaigns());

    dispatch(setErrorMessageCampaignIdSearch(''));
    dispatch(resetAllDuplicationData());


    controller.current.abort();
    controller.current = new AbortController();
  };

  const handleSearch = () => {
    dispatch(resetAllDuplicationData());
    !disableEnterBtn && searchCampaignsById();
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const searchCampaignsById = () => {
    if (checkOnlyNumbers) {
      dispatch(
        getSearchedCampaigns(campaignIdSearchedValue, controller.current.signal),
      );
      dispatch(setErrorMessageCampaignIdSearch(''));
    }
  };

  const handleSeeDetails = () => setOpenNamingDetails(true);

  const resyncDuplicationNamingConvention = () => {
    setResyncLoaded(true);
    if (isNamingConventionError) {
      dispatch(resyncDuplication(franchisorErrorId, setResyncLoaded));
    }
  };

  useEffect(
    () => () => {
      dispatch(setCampaignIdSearchedValue(''));
    },
    // eslint-disable-next-line
        [],
  );

  useEffect(() => {
    if (isNamingConventionError) {
      dispatch(getWarningsDuplication(franchisorErrorId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNamingConventionError, franchisorErrorId]);

  return (
    <>
      <NamingConventionModal
        isOpenModal={isOpenNamingDetails}
        onClose={() => setOpenNamingDetails(false)}
        syncNamesPattern={syncNamesPattern ?? {}}
        namingConventionErrors={namingConventionErrors ?? []}
      />
      <Flex direction="column" alignItems="center" className={styles.campaignSearchWrapper}>

        <Grid container>
          <Grid item xs={4.5} />

          <Grid item xs={7.5}>
            <Box display="flex" justifyContent="space-between">
              <h1>Campaign duplication</h1>

              {
                roleFromToken !== 'employee' && isNamingConventionError &&
                                <p className={`${isResyncLoaded ? styles.disabledToast : styles.toastText}`}>
                                  <span
                                    className={styles.reSyncText}
                                    onClick={resyncDuplicationNamingConvention}
                                  >
                                    Resync naming
                                    <UpdateIcon
                                      className={`${!isResyncLoaded ? styles.updateIcon : styles.updateIconAnim}`}
                                    />
                                  </span>
                                </p>
              }

            </Box>
          </Grid>
        </Grid>

        {
          duplicationWarnings &&
                    <ErrorToast
                      title={duplicationWarnings.title}
                      info={duplicationWarnings.info}
                      handleSeeDetails={handleSeeDetails} />
        }
        <div className={styles.inputContainer}>
          <input
            className={styles.searchInput}
            name="campaignId"
            placeholder="Your campaign ID goes here"
            value={campaignIdSearchedValue}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            style={inputSearchStyle}
          />
          <button className={styles.enterbtn} onClick={handleSearch}>
            <img src={enterBlue} alt="enter" width={16} height={16} />
          </button>
          {campaignIdSearchedValue && (
            <div className={styles.closeBtn} onClick={handleResetSearch}>
              <img src={close} alt="close" />
            </div>
          )}
          {errorMessageGenerator()}
        </div>
      </Flex>
    </>
  );
};

export default SearchCampaignId;
