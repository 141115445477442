import React, { useId } from 'react';
import Flex from '../Flex/Flex';
import { checkboxUseStyles } from './Checkbox.styles';

const Checkbox = ({
  handleCheck,
  description,
  styles,
  checked,
  disabled,
}: {
    handleCheck: (isChecked: boolean) => void;
    description?: string;
    styles: any;
    checked?: boolean;
    disabled?: any;
}) => {
  const id = useId();
  const checkboxStyles = checkboxUseStyles({
    styles,
    disabled,
  });

  const handleInnerChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    handleCheck(event.target.checked);


  const toggleCheckbox = () => {
    const checkbox = document.getElementById(id) as HTMLInputElement;
    if (checkbox) {
      checkbox.click();
    }
  };

  return checked ? (
    <Flex alignItems="center">
      <input
        onChange={handleInnerChange}
        type="checkbox"
        id={id}
        className={checkboxStyles.input}
        style={{ width: '24px', height: '24px' }}
        checked={checked}
        disabled={disabled}
      />
      <label htmlFor={id} className={checkboxStyles.label}>
        {' '}
      </label>

      {description && <span className={checkboxStyles.description}>{description}</span>}
    </Flex>
  ) : (
    <Flex alignItems="center">
      <input
        onChange={handleInnerChange}
        type="checkbox"
        id={id}
        className={checkboxStyles.input}
        style={{ width: '24px', height: '24px' }}
        disabled={disabled}
        checked={checked}
      />
      <label
        htmlFor={id}
        className={checkboxStyles.label}
        style={{ cursor: disabled ? 'auto' : 'pointer' }}
      >
        {' '}
      </label>

      {description && (
        <span className={checkboxStyles.description} onClick={toggleCheckbox}>
          {description}
        </span>
      )}
    </Flex>
  );
};

export default Checkbox;
