import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'hooks/useForm';
import { validator } from 'helpers/validator';
import { setEmailForResetWasSent } from '../../../store/passwordReset/passwordResetSlice';
import AuthenticationLayout from '../../../layout/Authentication/AuthenticationLayout';
import ErrorMessage from '../../../modules/shared/components/general/ErrorMessage/ErrorMessage';
import EmailIsSent from './EmailIsSent/EmailIsSent';
import { useStyles, useStylesTextField } from '../authShared.styles';
import { sendEmailForeResetPassword } from '../../../store/passwordReset/actions';
import { TextField } from '@mui/material';


const RequestResetPassword = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = useStylesTextField();
  const {
    passwordReset: {
      emailForResetWasSent: { sent, message },
      disableEnterBtn,
    },
  } = useSelector((state:any) => state);

  const initState = {
    email: '',
    password: '',
  };

  const submitForm = () => {};
  const { handleInputChange, handleSubmit, handleBlur, state, errors } = useForm({
    initState,
    callback: submitForm,
    validator,
  });

  const emailChangeHandler = (event:any) => {
    handleInputChange(event);
    dispatch(setEmailForResetWasSent({ sent: false, message: '' }));
  };

  useEffect(() => {
    dispatch(setEmailForResetWasSent({ sent: null, message: '' }));
    // eslint-disable-next-line
    }, []);

  const isValidForm =
        Object.values(errors).filter(error => typeof error !== 'undefined').length === 0;

  return (
    <AuthenticationLayout
      title={!sent && 'Reset password'}
      description={
        !sent &&
                "Please enter your email address and we'll send you a link to reset your password."
      }
    >
      {!sent ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            label="Email"
            defaultValue={state?.email}
            className={`${styles.root}`}
            onChange={event => emailChangeHandler(event)}
            onBlur={handleBlur}
            name="email"
            placeholder="Email"
            variant="outlined"
            margin="normal"
            id="outlined-basic"
            fullWidth
            error={errors?.email}
            helperText={errors?.email}
            style={{ marginBottom: 0 }}
          />

          {!sent && message && <ErrorMessage text={message} margin="4px 0 0 8px" />}
          <button
            className="auth-button"
            disabled={!isValidForm || !state?.email}
            onClick={() =>
              !disableEnterBtn && dispatch<any>(sendEmailForeResetPassword(state?.email))
            }
            style={{ marginTop: '32px' }}
          >
                        Get the link
          </button>
        </form>
      ) : (
        <EmailIsSent />
      )}
    </AuthenticationLayout>
  );
};

export default RequestResetPassword;
