import React, { useCallback, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, FormControl } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { isEqual, xor } from 'lodash';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { compareTextWithTextSearch } from '../../../../../helpers/compareTextWithTextSearch';

import {
  AutoCompleteItemsStyles,
  multiSelectWithSearchUseStyles,
} from 'modules/shared/components/general/MultiSelect/MultiSelect.styles';
import { ArrowUpIcon } from '../Icons/ArrowUp.icon';
import { ArrowDownIcon } from '../Icons/ArrowDown.icon';
import { ISelectionOption } from '../../../../BrandsReporting/components/ReportRequest/types';
import Flex from '../Flex/Flex';

const allOption: ISelectionOption = { name: 'Select All', value: 'ALL' };

const selectAllOption = {
  name: 'Select All',
  id: -1,
};

interface Props {
    options: Array<any>
    label?:string
    handleChange: (a: Array<any>) => void;
    isFetching?:boolean
}

const AutoCompleteSearch: React.FC<Props> = (props) => {
  const { options , label = '', handleChange } = props;

  const [inputValue, setInputValue] = useState('');
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const checkboxStyles = { color: '#0F314D', height: '24px', width: '24px' };

  const [value, setValue] = useState<ISelectionOption[]>([]);

  const styles = multiSelectWithSearchUseStyles({ selected:value });

  const getOptionLabel = useCallback((option: ISelectionOption) => option.name, []);


  const handleSelect = (_:any, newValue:Array<any>, reason:string) => {
    if (reason !== 'selectOption' && reason !== 'removeOption') {
      return;
    }

    // Not click 'ALL'
    if (newValue.findIndex(item => item.value === 'ALL') === -1) {
      setValue(newValue);
      handleChange(newValue);
      setInputValue('');
      return;
    }

    // All options are not selected
    if (!allSelected) {
      const newValueFiltered = newValue.filter(
        item => item.value !== 'ALL',
      );
      const slcItems = [...new Set([...newValueFiltered, ...filteredOptions])];

      setValue(slcItems);
      handleChange(slcItems);
      setInputValue('');
      return;
    }

    // Not filter options
    if (isEqual(filteredOptions, options)) {
      setValue([]);
      handleChange([]);
      setInputValue('');
      return;
    }

    const filteredOptionsValue = filteredOptions.map((item:any) => item.id);
    setValue(
      value.filter((item:any) => !filteredOptionsValue.includes(item.id)),
    );
    handleChange(value.filter((item:any) => !filteredOptionsValue.includes(item.id)));
    setInputValue('');
  };

  const searchInAutocomplete = useCallback(
    (
      options: Array<any>,
      state: FilterOptionsState<ISelectionOption>,
    ) => {
      const { getOptionLabel, inputValue } = state;

      const customFilterOptions = createFilterOptions({
        ignoreAccents: true,
        ignoreCase: true,
        limit: undefined,
        matchFrom: 'any',
        stringify: (option: ISelectionOption) => getOptionLabel(option),
        trim: false,
      });

      const filtered = customFilterOptions(options, state);

      if (!inputValue) {
        return filtered;
      }

      filtered.push(
        ...options.filter(option => compareTextWithTextSearch(getOptionLabel(option), inputValue)),
      );

      return [...new Set(filtered)];
    },
    [],
  );

  const filteredOptions = useMemo(
    () => searchInAutocomplete(options, { inputValue, getOptionLabel }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputValue, options]);

  const allSelected = useMemo(() => {
    const currValue = value.map((item:any) => item.id);
    const filteredOptionsValue = filteredOptions.map((item:any) => item.id);
    if (isEqual(filteredOptions, options)) {
      return xor(currValue, filteredOptionsValue).length === 0;
    }
    return filteredOptionsValue.every(item => currValue.includes(item));
  }, [value, filteredOptions, options]);

  return (
    <FormControl>
      <Autocomplete
        id="multi-select"
        open={isSelectOpen}
        multiple
        disablePortal
        disableClearable
        disableCloseOnSelect
        inputValue={inputValue}
        value={value}
        options={options}
        onOpen={() => setIsSelectOpen(true)}
        onClose={() => setIsSelectOpen(false)}
        onChange={handleSelect}
        onInputChange={(_, newInputValue, reason) => {
          if (reason === 'reset') {
            return;
          }
          setInputValue(newInputValue);
        }}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(opt, val) =>
          opt.name === val.name && opt.id === val.id
        }
        filterOptions={(opts, state) => {
          const filtered = searchInAutocomplete(opts, state);
          return filtered.length > 0 ? [allOption, ...filtered] : [];
        }}
        renderOption={(_props, opt, { selected }) => (
          <li {..._props} style={AutoCompleteItemsStyles}>
            <Checkbox
              style={checkboxStyles}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              size="small"
              checked={opt?.value === 'ALL' ? allSelected : selected}
              sx={{ p: 0, mr: 2 }}
            />
            <Flex direction="column" gap="4px">
              {
                opt.name === selectAllOption.name ?
                  <span>{opt.name}</span>
                  :
                  <>
                    <span>{opt.name}</span>
                    <span className={styles.optionId}>ID:{opt?.fb_id}</span>
                  </>
              }
            </Flex>
          </li>
        )}
        renderInput={params =>   (
          <div ref={params.InputProps.ref} className={styles.serchInput}>
            <input
              type="text"
              placeholder={
                value.length > 0 ? `${value.length} selected` : label
              }
              {...params.inputProps}
            />
            <div className={styles.arrowIcon}>
              {isSelectOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        )}
      />
    </FormControl>
  );
};

export default AutoCompleteSearch;
