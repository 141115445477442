// @ts-nocheck

import { useEffect, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import moment from 'moment';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EditPen from 'assets/icons/editPen.svg';
import { Button } from 'modules/shared/components/general/Button/Button';
import Flex from 'modules/shared/components/general/Flex/Flex';
import close from 'assets/icons/close.svg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { servicesNames } from '../../../../constants/servicesNames.constants';
import { editorConfig } from '../../constants/constants';
import { getEmailReportById, saveEditedEmail } from '../../../../services/clientCommunication';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';
import Spinner from '../../../shared/components/general/Spinner/Spinner';
import ForwardEmails from '../ForwardEmails/ForwardEmails';
import Drawer from '../Drawer/Drawer';
import { useStylesWeeklyBoard } from '../WeeklyBoard/WeeklyBoard.styles';
import { useStyles } from './UserEmailDrawer.styles';
import SendingIsInProgress from './SendingIsInProgress';
import UserEmailButtons from './UserEmailButtons';
import Modals from './Modals';
import GmailSignature from '../GmailSignature/GmailSignature';

interface UserEmailDrawerProps {
    drawerOpen: boolean;
    isEditModeOn?: boolean;
    setIsEditModeOn?: (val: boolean) => void;
    handleCloseDrawer: () => void;
    setEmailId?: (val: number) => void;
    emailId: number;
    status: string;
    currentElementIndex?: number;
    setCurrentElementIndex?: any;
    type?: string;
}

const UserEmailDrawer = ({
  drawerOpen,
  isEditModeOn,
  setIsEditModeOn,
  handleCloseDrawer,
  emailId,
  setEmailId,
  status,
  currentElementIndex,
  setCurrentElementIndex,
  type,
}: UserEmailDrawerProps) => {
  const styles = useStylesWeeklyBoard();
  const classes = useStyles({
    readOnly: status === 'skipped' || status === 'sent',
  });

  const {
    areYouSureSendModalOpened,
    setAreYouSureSendModalOpened,
    setSendModalForOtherUser,
    sendModalForOtherUser,
    emailStatus,
    setEmailStatus,
    setToValue,
    setToOptions,
    setCCoptions,
    setShowCCField,
    userEmailReport,
    setUserEmailReport,
  } = useClientCommunicationContext();
  const queryClient = useQueryClient();

  const refs = {
    ckEditorRef: useRef(null),
    editorRef: useRef(null),
    subjectRef: useRef(null),
  };

  const [showEditButton, setShowEditButton] = useState<boolean>(true);
  const [modalStates, setModalStates] = useState({
    isSkipOpen: false,
    isSaveChangesOpen: false,
    isRegenerateModalOpened: false,
  });

  const toggleModal = (modalName: string) => {
    setModalStates(prevState => ({
      ...prevState,
      [modalName]: !prevState[modalName],
    }));
  };

  const [editEmailPayload, setEditEmailPayload] = useState({});
  const [isEditorDisabeled, setIsEditorDisabeled] = useState<boolean>(true);
  const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(false);
  const [editRequired, setEditRequired] = useState<boolean>(false);

  const { mutate: saveMutate, isLoading: isLoadingSave } = useMutation(
    () => saveEditedEmail(emailId, editEmailPayload),
    {
      onSuccess: ({ data }) => {
        setIsEditModeOn && setIsEditModeOn(false);
        setIsEditorDisabeled(true);
        setShowEditButton && setShowEditButton(true);
        setEditRequired(data.edit_required);
      },
    },
  );

  const { isFetching } = useQuery(
    [servicesNames.getEmailReportById, { emailId }],
    () => getEmailReportById(emailId),
    {
      onSuccess: (response) => {
        setUserEmailReport(response);
      },
    },
  );

  const editMessage = () => {
    setIsEditModeOn && setIsEditModeOn(true);
    if (refs.ckEditorRef.current && isEditModeOn) {
      (refs.ckEditorRef?.current as any).editor?.editing?.view?.focus();
    }
    setShowEditButton && setShowEditButton(false);
    setIsEditorDisabeled(false);
  };

  const cancelBtnHandler = () => {
    setIsEditModeOn && setIsEditModeOn(false);
    setShowEditButton(true);
    setIsEditorDisabeled(true);
    handleCloseWithoutChanges();
    setToValue('');
    setToOptions([...userEmailReport.to_emails]);
    setCCoptions([...userEmailReport.cc_emails]);
    setShowCCField(false);
  };

  const handleCloseWithoutChanges = () => {
    if (refs.editorRef.current) {
      (refs.editorRef?.current as any)?.setData((userEmailReport as any)?.body);
    }

    if (refs.subjectRef.current) {
      setEditEmailPayload({
        ...editEmailPayload,
        subject: (userEmailReport as any)?.subject,
      });
    }
  };

  const backendTimestamp = (userEmailReport as any)?.last_regenerated;
  const date = new Date(backendTimestamp);
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userLocalTime = date.toLocaleString(undefined, {
    timeZone: userTimeZone,
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  });

  const convertedDate = userLocalTime.replace(/,(\s\d{4},)/, ' $1');


  const handleOnClose = () => {
    if (isEditModeOn) {
      toggleModal('isSaveChangesOpen');
    } else {
      queryClient.invalidateQueries([servicesNames.getToSendEmailReportsData]);
      queryClient.invalidateQueries([servicesNames.getSkippedEmailReportsData]);
      queryClient.invalidateQueries([servicesNames.getSentEmailReportsData]);
      setEmailStatus({});
      handleCloseDrawer();
      setIsEditModeOn && setIsEditModeOn(false);
    }
  };

  useEffect(() => {
    if (!showEditButton) {
      editMessage();
    }
    // eslint-disable-next-line
    }, [showEditButton]);

  useEffect(() => {
    if (userEmailReport) {
      setEditRequired(userEmailReport.edit_required);
    }
  }, [userEmailReport]);

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
  }, [
    modalStates.isSkipOpen,
    modalStates.isRegenerateModalOpened,
    modalStates.isSaveChangesOpen,
    areYouSureSendModalOpened,
    sendModalForOtherUser,
  ]);

  const headerJsx = (
    <Flex justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
      <Flex>
        <div
          onClick={handleOnClose}
          className="close"
          style={{ cursor: 'pointer', marginRight: '16px' }}
        >
          <img src={close} alt="close" />
        </div>
        {!isFetching && <h3>{(userEmailReport as any)?.owner}</h3>}
      </Flex>
      <span className={styles.date}>
        {(userEmailReport as any)?.last_regenerated && !isFetching
          ? `Regenerated on  ${moment(convertedDate).format('DD MMM, YYYY h:mm A')}`
          : null}
      </span>
    </Flex>
  );

  return (
    <Drawer
      isOpen={drawerOpen}
      onClose={handleOnClose}
      headerContent={headerJsx}
      isEditModeOn={isEditModeOn}
      setIsSaveChangesOpen={() => toggleModal('isSaveChangesOpen')}
      height={emailStatus[emailId] ? '100%' : 'auto'}
    >
      {isFetching ? (
        <Flex  alignItems='center' justifyContent="center" style={{ height: '100vh' }}>
          <Spinner style={{ top: 'calc(50% - 52px)' }} />
        </Flex>
      ) : emailStatus[emailId] ? (
        <SendingIsInProgress
          setEmailId={setEmailId}
          emailId={emailId}
          currentElementIndex={currentElementIndex}
          setCurrentElementIndex={setCurrentElementIndex}
          handleCloseDrawer={handleCloseDrawer}
          type={type}
        />
      ) : status === 'skipped' || status === 'sent' ? (
        <div
          style={{
            height: 'auto',
            overflowY: 'auto',
            padding: '24px 16px',
            borderRadius: '8px',
          }}
        >
          <ForwardEmails
            setIsSaveDisabled={setIsSaveDisabled}
            setEditEmailPayload={setEditEmailPayload}
            editEmailPayload={editEmailPayload}
            defaultToValue={userEmailReport.to_emails}
            defaultCCValue={userEmailReport.cc_emails}
            readOnly={status === 'skipped' || status === 'sent'}
          />
          <div className={classes.inputContainer}>
            <input
              className={classes.input}
              type="text"
              id="subject"
              name="subject"
              defaultValue={(userEmailReport as any)?.subject}
              readOnly={true}
            />
            <span className={classes.defaultValue}>Subject:</span>
          </div>
          <CKEditor
            ref={refs.ckEditorRef}
            editor={ClassicEditor}
            disabled={isEditorDisabeled}
            config={editorConfig}
            data={(userEmailReport as any)?.body}
            onReady={(editor) => {
              (refs.editorRef.current as any) = editor;
              const toolbarElement = (editor.ui.view.toolbar as any).element;
              toolbarElement.style.display = 'none';
            }}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              padding: '24px 16px',
              borderRadius: '8px',
              border: isEditModeOn ? '1px solid #0F314D' : '1px solid transparent',
            }}
          >
            <ForwardEmails
              setIsSaveDisabled={setIsSaveDisabled}
              setEditEmailPayload={setEditEmailPayload}
              editEmailPayload={editEmailPayload}
              defaultToValue={(userEmailReport as any)?.to_emails}
              setShowEditButton={setShowEditButton}
              defaultCCValue={(userEmailReport as any)?.cc_emails}
              readOnly={status === 'skipped' || status === 'sent'}
            />
            <div className={classes.inputContainer}>
              <input
                className={classes.input}
                style={{ width: 'calc(100% - 10px)' }}
                ref={refs.subjectRef}
                type="text"
                id="subject"
                name="subject"
                defaultValue={(userEmailReport as any)?.subject}
                value={(editEmailPayload as any).subject}
                onChange={(event: any) => {
                  setIsEditModeOn && setIsEditModeOn(true);
                  setEditEmailPayload({
                    ...editEmailPayload,
                    subject: event.target.value,
                  });
                }}
                onFocus={() => {
                  setShowEditButton && setShowEditButton(false);
                }}
              />
              <span className={classes.defaultValue}>Subject:</span>
            </div>
            <div
              style={{
                height: 'auto',
                overflowY: 'scroll',
                position: 'relative',
              }}
            >
              {showEditButton && (
                <Button
                  style={{
                    backgroundColor: '#fff',
                    color: '#0F314D',
                    position: 'absolute',
                    right: '32px',
                    top: '7px',
                    height: '24px',
                    zIndex: '9',
                    border: 'none',
                    padding: 0,
                  }}
                  handleClick={editMessage}
                >
                  {
                    <Flex alignItems="center">
                      <span style={{ marginRight: '10px' }}> Edit</span>
                      <img src={EditPen} alt="Edit pen" />
                    </Flex>
                  }
                </Button>
              )}
              <CKEditor
                ref={refs.ckEditorRef}
                editor={ClassicEditor}
                disabled={isEditorDisabeled}
                config={editorConfig}
                data={(userEmailReport as any)?.body}
                onReady={(editor) => {
                  (refs.editorRef.current as any) = editor;
                  const toolbarElement = (editor.ui.view.toolbar as any).element;
                  toolbarElement.style.visibility = isEditModeOn
                    ? 'visible'
                    : 'visible';
                }}
                onChange={(event: any, editor) => {
                  const data = editor.getData();
                  setEditEmailPayload((prevPayload) => {
                    const updatedBody = (userEmailReport as any)?.body.replace(
                      /<body>[\s\S]*<\/body>/,
                      `<body>${data}</body>`,
                    );
                    return { ...prevPayload, body: updatedBody };
                  });
                }}
              />
            </div>
            <GmailSignature />
            <UserEmailButtons
              isEditModeOn={isEditModeOn}
              isSaveDisabled={isSaveDisabled}
              setIsEditModeOn={setIsEditModeOn}
              cancelBtnHandler={cancelBtnHandler}
              saveMutate={saveMutate}
              setIsSkipOpen={() => toggleModal('isSkipOpen')}
              setIsRegenerateModalOpened={() =>
                toggleModal('isRegenerateModalOpened')
              }
              editRequired={editRequired}
            />
          </div>
          <Modals
            isSkipOpen={modalStates.isSkipOpen}
            setIsSkipOpen={() => toggleModal('isSkipOpen')}
            emailId={emailId}
            isRegenerateModalOpened={modalStates.isRegenerateModalOpened}
            setIsRegenerateModalOpened={() => toggleModal('isRegenerateModalOpened')}
            sendModalForOtherUser={sendModalForOtherUser}
            setSendModalForOtherUser={setSendModalForOtherUser}
            areYouSureSendModalOpened={areYouSureSendModalOpened}
            setIsSaveChangesOpen={() => toggleModal('isSaveChangesOpen')}
            setAreYouSureSendModalOpened={setAreYouSureSendModalOpened}
            isSaveChangesOpen={modalStates.isSaveChangesOpen}
            setShowEditButton={setShowEditButton}
            setIsEditorDisabeled={setIsEditorDisabeled}
            setIsEditModeOn={setIsEditModeOn}
            handleCloseDrawer={handleCloseDrawer}
            handleCloseWithoutChanges={handleCloseWithoutChanges}
            isLoadingSave={isLoadingSave}
          />
        </>
      )}
    </Drawer>
  );
};

export default UserEmailDrawer;
