import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Button, MenuItem, SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { CustomSelect, MenuProps, stylesSx } from '../MultiSelect/MultiSelect.styles';
import { customMultiSelectAutoCompleteStyles } from './CustomMultiSelectAutoComplete';
import { ReactComponent as FilterResetIcon } from '../../../../../assets/icons/filterResetIcon.svg';
import { FilterDateMode, PerformanceIndicatorList } from '../../../../BrandAdAccounts/components/constants';
import ScaleCheckbox from './CheckboxAnimation';
import {
  resetPerformanceFilter,
  setPerformanceStatusFilter,
} from '../../../../../store/brandAdAccounts/brandAdAccountsSlice';


interface ICustomMultiSelectAutoComplete {
    placeholder?: string;
    dateFilterModeIdx: number;
    performanceStatusList: Array<string>;
}

const CustomMultiSelectAutoComplete: React.FC<ICustomMultiSelectAutoComplete> = (
  {
    placeholder,
    performanceStatusList,
    dateFilterModeIdx,
  }) => {
  const dispatch = useDispatch();
  const styles = customMultiSelectAutoCompleteStyles();
  const [isOpen, setOpen] = useState(false);
  const [dateFilterMode, setDateFilterMode] = useState<number>(() =>
    (dateFilterModeIdx ? dateFilterModeIdx : FilterDateMode.InitialMode),
  );
  const [performanceStatus, setPerformanceStatus] = useState<Array<string>>(performanceStatusList);
  const [isTouchedSelect, setTouchSelect] = useState(false);
  const [isCheckboxValidation, setCheckboxValidation] = useState(false);

  const handleResetFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setTouchSelect(false);
    setCheckboxValidation(false);
    setDateFilterMode(FilterDateMode.InitialMode);
    setPerformanceStatus([]);
    if (performanceStatus.length || dateFilterMode) {
      dispatch(resetPerformanceFilter());
    }
  };

  const handleClose = () => {
    if (!performanceStatus.length && dateFilterMode !== FilterDateMode.InitialMode) {
      setCheckboxValidation(true);
      return;
    }
    if (Boolean(performanceStatus.length) && Boolean(dateFilterMode)) {
      dispatch(setPerformanceStatusFilter({ performanceStatus, dateFilterMode }));
      setOpen(false);
      return;
    }
    setOpen(false);
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    if (dateFilterMode === FilterDateMode.InitialMode) {
      setTouchSelect(true);
      return;
    }
    setTouchSelect(false);

    if (event.target.value[0]) {
      setPerformanceStatus(event.target.value);
      return;
    }
    setPerformanceStatus([]);
  };


  useEffect(
    () => {
      if (isCheckboxValidation) {
        setTimeout(() => setCheckboxValidation(false), 1000);
      }
    }
    , [isCheckboxValidation],
  );


  return (
    <FormControl sx={{ m: 0, width: 300, position: 'relative' }}>
      <span className={styles.placeholder}>
        {dateFilterMode === FilterDateMode.InitialMode && placeholder}
        {dateFilterMode === FilterDateMode.Last3Days && 'Last 3 Days:'}
        {dateFilterMode === FilterDateMode.Last7Days && 'Last 7 Days:'}
        {dateFilterMode === FilterDateMode.ThisMonth && 'Last 30 Days:'}
      </span>
      <CustomSelect
        labelId="mutiple-select-label"
        multiple
        value={performanceStatus}
        style={{
          width: '304px',
          margin: '8px 24px 8px 0',
          borderRadius: '8px',
        }}
        open={isOpen}
        onOpen={() => setOpen(true)}
        onClose={handleClose}
        renderValue={(value: any) => (
          <span style={value.length ? {
            paddingLeft: dateFilterMode === FilterDateMode.ThisMonth ? '100px' : '90px' ,
            color:  '#0F314D',
            fontWeight: 400,
          } : {}}>
            {value.length > 2 ? `${value.length} selected` : value.join(', ')}
          </span>
        )}
        onChange={handleChange}
        MenuProps={MenuProps}
        placeholder={placeholder}
        sx={{
          ...stylesSx(),
          '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: "none !important",
              },
        }}
      >
        <Box>
          <Box>
            <Button
              size="small"
              className={styles.resetBtn}
              onClick={handleResetFilter}
              disabled={!Boolean(dateFilterMode)}
            >
              <FilterResetIcon style={{ marginRight: '4px' }} />
                            Reset
            </Button>
          </Box>
          <Box className={styles.dateBtnWrap}>
            <Button
              size="small"
              className={`
              ${dateFilterMode === FilterDateMode.Last3Days ? styles.activeDateBtn : styles.dateBtn}
              ${isTouchedSelect && dateFilterMode === FilterDateMode.InitialMode && styles.dateChipInvalid}
              `}
              onClick={() => setDateFilterMode(FilterDateMode.Last3Days)}
            >
                            Last 3 days
            </Button>
            <Button
              size="small"
              className={`
              ${dateFilterMode === FilterDateMode.Last7Days ? styles.activeDateBtn : styles.dateBtn}
               ${isTouchedSelect && dateFilterMode === FilterDateMode.InitialMode && styles.dateChipInvalid}
              `}
              onClick={() => setDateFilterMode(FilterDateMode.Last7Days)}
            >
                            Last 7 days
            </Button>
            <Button
              size="small"
              className={`
              ${dateFilterMode === FilterDateMode.ThisMonth ? styles.activeDateBtn : styles.dateBtn}
               ${isTouchedSelect && dateFilterMode === FilterDateMode.InitialMode && styles.dateChipInvalid}
              `}
              onClick={() => setDateFilterMode(FilterDateMode.ThisMonth)}
            >
                            Last 30 days
            </Button>
          </Box>
        </Box>

        <MenuItem value={'Critical - No Leads'} className={styles.optionStyles}>
          {
            Boolean(performanceStatus.length) ?
              <Checkbox
                style={{ color: '#0F314D', height: '24px', width: '24px' }}
                checked={performanceStatus.includes('Critical - No Leads')}
              />
              :
              <ScaleCheckbox checked={isCheckboxValidation} />
          }
          <span className={styles.dot}
            style={{ backgroundColor: PerformanceIndicatorList['Critical - No Leads'] }} />
          <ListItemText primary={'Critical - No Leads'} className={styles.optionText} sx={{ fontSize: '16px' }} />
        </MenuItem>

        <MenuItem value={'Critical'} className={styles.optionStyles}>
          {
            Boolean(performanceStatus.length) ?
              <Checkbox
                style={{ color: '#0F314D', height: '24px', width: '24px' }}
                checked={performanceStatus.includes('Critical')}
              />
              :
              <ScaleCheckbox checked={isCheckboxValidation} />
          }
          <span className={styles.dot}
            style={{ backgroundColor: PerformanceIndicatorList.Critical }} />
          <ListItemText primary={'Critical'} className={styles.optionText} sx={{ fontSize: '16px' }} />
        </MenuItem>
        <MenuItem value={'Bad'} className={styles.optionStyles}>
          {
            Boolean(performanceStatus.length) ?
              <Checkbox
                style={{ color: '#0F314D', height: '24px', width: '24px' }}
                checked={performanceStatus.includes('Bad')}
              />
              :
              <ScaleCheckbox checked={isCheckboxValidation} />
          }
          <span className={styles.dot}
            style={{ backgroundColor: PerformanceIndicatorList.Bad }} />
          <ListItemText primary={'Bad'} className={styles.optionText} sx={{ fontSize: '16px' }} />
        </MenuItem>
        <MenuItem value={'Average'} className={styles.optionStyles}>
          {
            Boolean(performanceStatus.length) ?
              <Checkbox
                style={{ color: '#0F314D', height: '24px', width: '24px' }}
                checked={performanceStatus.includes('Average')}
              />
              :
              <ScaleCheckbox checked={isCheckboxValidation} />
          }

          <span className={styles.dot}
            style={{ backgroundColor: PerformanceIndicatorList.Average }} />
          <ListItemText primary={'Average'} className={styles.optionText} sx={{ fontSize: '16px' }} />
        </MenuItem>
        <MenuItem value={'Good'} className={styles.optionStyles}>
          {
            Boolean(performanceStatus.length) ?
              <Checkbox
                style={{ color: '#0F314D', height: '24px', width: '24px' }}
                checked={performanceStatus.includes('Good')}
              />
              :
              <ScaleCheckbox checked={isCheckboxValidation} />
          }
          <span className={styles.dot}
            style={{ backgroundColor: PerformanceIndicatorList.Good }} />
          <ListItemText primary={'Good'} className={styles.optionText} sx={{ fontSize: '16px' }} />
        </MenuItem>

      </CustomSelect>
    </FormControl>
  );
};

export default CustomMultiSelectAutoComplete;
