import React from 'react';

import BasicModal from '../../../shared/components/general/BasicModal/BasicModal';
import Flex from '../../../shared/components/general/Flex/Flex';

import { useStyles } from './NamingConventionModal.styles';
import { Box, Divider, Typography } from '@mui/material';
import { SyncNamesPattern } from '../constants';


interface INamingConventionModal {
    isOpenModal: boolean;
    namingConventionErrors?: Array<any>;
    onClose: () => void;
    syncNamesPattern?: { [key: string]: string };
}

const NamingConventionModal: React.FC<INamingConventionModal> = (
  {
    isOpenModal,
    onClose,
    syncNamesPattern,
    namingConventionErrors,
  }) => {
  const styles = useStyles();

  return (
    <BasicModal
      open={isOpenModal}
      handleClose={onClose}
      basicModalStyle={{ zIndex: 1000 }}
      spinerStyle={{
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 1001,
        filter: 'contrast(2)',
      }}
    >
      <Flex direction="column" alignItems="center">
        <h3 className={styles.modalTitle}>
                    Errors and details
        </h3>
      </Flex>

      <Box className={styles.namingConventionWrap}>
        <Typography
          variant="subtitle1"
          fontWeight="600"
          className={styles.namingConventionTitle}
        >
                    Synced naming conventions for this brand are
        </Typography>
        <ol type="1" className={styles.namingConventionList}>
          {
            syncNamesPattern && Object.keys(syncNamesPattern)
              .map(pattern =>
                <li key={pattern}>
                  <p
                    className={styles.namingConventionItem}
                  >
                    {SyncNamesPattern[pattern]}: {syncNamesPattern[pattern]}
                  </p>
                </li>,
              )
          }
        </ol>
      </Box>

      <Divider orientation="horizontal" variant="fullWidth" className={styles.dividerStyle} />

      {
        namingConventionErrors?.length &&
          namingConventionErrors.map((namingError, idx) => (
            <Box key={idx}>
              <Typography
                variant="subtitle1"
                fontWeight="600"
                className={styles.namingConventionTitle}
              >
                {namingError.title}
              </Typography>

              <ol type="1" className={styles.namingConventionList}>
                {
                  namingError.info
                    .map((info: string) =>
                      <li key={info}>
                        <p
                          className={styles.namingConventionItem}
                        >
                          {info}
                        </p>
                      </li>,
                    )
                }
              </ol>
              <Divider orientation="horizontal" variant="fullWidth" className={styles.dividerStyle} />
            </Box>
          ))
      }

    </BasicModal>
  );
};

export default NamingConventionModal;
