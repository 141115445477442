import { LocaleStorageTokenList, LocalStorage } from '../../../helpers/Storage';
import jwt_decode from 'jwt-decode';
import { useQuery } from 'react-query';
import { servicesNames } from '../../../constants/servicesNames.constants';
import { getBrandNames, getLogicSequenceData, getManagers } from '../../../services/suggestions.services';
import React from 'react';
import Suggestions from './Suggestions';
import {
  setBrand,
  setLogicSequenceChange,
  setManager,
  setManagersDefaultValue,
} from '../../../store/suggestions/suggestionSlice';
import { useDispatch } from 'react-redux';


const SuggestionWrapper = () => {
  const dispatch = useDispatch();
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  const { name }: { name: string } = jwt_decode(accessToken);

  const {
    data: { data: managersData } = {},
    isFetching: isFetchingManagers,
    refetch: refetchManagers,
    isFetched,
  } = useQuery([servicesNames.getManagers, {}], () => getManagers(), {
    onSuccess: ({ data }) => {
      if (!data.includes(name)) {
        dispatch(setManagersDefaultValue('All Selected'));
        dispatch(setManager(data));
      } else {
        dispatch(setManagersDefaultValue(name));
        dispatch(setManager([name]));
      }
    },
  });

  const {
    data: { data: brandsData } = {},
    isFetching: isFetchingBrands,
    refetch: refetchBrands,
    isFetched: isFetchedBrands,
  } = useQuery(
    [
      servicesNames.getBrandNames,
    ],
    () => getBrandNames(),
    {
      onSuccess: ({ data }) => {
        dispatch(setBrand(data));
      },
    },
  );

  const {
    data: { data: logicSequence } = {},
    isFetching: isLogicSequenceFetching,
    refetch: refetchLogicSequence,
    isFetched: isFetchedLogicSequence,
  } = useQuery([servicesNames.getLogicSequenceData],
    getLogicSequenceData,
    {
      onSuccess: ({ data }) => {
        dispatch(setLogicSequenceChange(data));
      },
    });

  if (!(isFetchedLogicSequence && isFetchedBrands && isFetched)) {
    return null;
  }

  return (
    <Suggestions
      data={managersData}
      isFetchingManager={isFetchingManagers}
      refetch={refetchManagers}
      brandsData={brandsData}
      isFetchingBrands={isFetchingBrands}
      refetchBrands={refetchBrands}
      logicSequence={logicSequence}
      isLogicSequenceFetching={isLogicSequenceFetching}
      refetchLogicSequence={refetchLogicSequence}
    />
  );
};

export default SuggestionWrapper;
