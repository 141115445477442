import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import jwt_decode from 'jwt-decode';
import { servicesNames } from 'constants/servicesNames.constants';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';
import MultiSelect from 'modules/shared/components/general/MultiSelect/MultiSelect';
import { getFSMs } from 'services/clientCommunication';
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import close from 'assets/icons/close.svg';
import search from 'assets/icons/search.svg';
import SelectFilter from '../../../Suggestions/components/Filters/SelectFilter';
import { capitalizeFirstLetter } from '../../../../helpers/capitalizeFirstLetter';
import { statuses } from '../../constants/constants';
import { MenuProps, useStylesFilters } from './Filters.styles';

const Filters = () => {
  const {
    setSelectedFsmsIds,
    searchValue,
    handleSearch,
    selectedStatuses,
    setSelectedStatuses,
    selectedFsms,
    setSelectedFsms,
    selectedTimePeriod,
    setSelectedTimePeriod,
    setFsmsData,
    isSelectOpen,
    setIsSelectOpen,
    activeTab,
    refetchToSendData,
    refetchSentData,
    refetchSkippedData,
    refetchAllEmails,
    setIsFetchingFsms,
  } = useClientCommunicationContext();
  const styles = useStylesFilters({
    selectedStatuses,
  });
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  const { name }: { name: string } = jwt_decode(accessToken);
  const [inputIsActive, setInputIsActive] = useState(false);
  const [fsmsNames, setFsmsNames] = useState<string[]>([]);

  const timePeriodOptions = [
    'all_time',
    'today',
    'this_week',
    'last_week',
    'this_month',
    'last_month',
  ];

  const inputSearchStyle = {
    backgroundImage: inputIsActive || searchValue ? 'none' : `url(${search})`,
    padding: inputIsActive || searchValue ? '12px 43px 12px 16px' : '12px 50px 12px 16px',
  };

  const selectsStyles = {
    width: '304px',
    margin: '8px 24px 8px 0',
    borderRadius: '8px',
  };

  const handleChangeSelectedFsms = (values: string[]) => {
    setSelectedFsms(() => values);
  };

  const handleChangeStatuses = (values: string[]) => {
    setSelectedStatuses([...values]);
  };

  useEffect(() => {
    if (!isSelectOpen && selectedFsms.length === 0) {
      setSelectedFsms(fsmsNames);
    }
    if (!isSelectOpen && selectedStatuses.length === 0) {
      setSelectedStatuses(['sent', 'skipped', 'expired']);
    }
    // eslint-disable-next-line
    }, [isSelectOpen]);

  const {
    isLoading: isLoadingFsms,
    data: fsmsData,
    isFetching: isFetchingFsms,
  } = useQuery([servicesNames.getFsms], () => getFSMs(), {
    onSuccess: (response) => {
      const fsmsNames:Array<any> = [];
      response.map((item: { id: number; full_name: string }) =>
        fsmsNames.push(item.full_name),
      );
      if (!fsmsNames.includes(name)) {
        setFsmsNames(fsmsNames);
        setSelectedFsms(fsmsNames);
        setFsmsData(response);
      } else {
        setSelectedFsms([name]);
        setFsmsNames(fsmsNames);
      }
    },
  });

  useEffect(() => {
    setIsFetchingFsms(isFetchingFsms);

    // eslint-disable-next-line
    }, [isFetchingFsms]);

  useEffect(
    () => () => {
      setSelectedStatuses(['sent', 'skipped', 'expired']);
    },
    // eslint-disable-next-line
        []
  );

  useEffect(() => {
    const updatedIds = selectedFsms.map((selectedItem: string) => {
      const matchingFsm = fsmsData?.find(
        (fsm: { id: number; full_name: string }) => fsm.full_name === selectedItem,
      );
      return matchingFsm ? matchingFsm.id : null;
    });

    setSelectedFsmsIds(() => updatedIds.filter((id:any) => id));
    return () => {
      setSelectedFsmsIds([]);
    };
    // eslint-disable-next-line
    }, [selectedFsms, fsmsData]);

  return (
    <div style={{ marginBottom: '24px', display: 'flex' }}>
      {activeTab === 'weeklyBoard' ? (
        <MultiSelect
          label="FSM: "
          options={fsmsNames}
          style={selectsStyles}
          handleChange={handleChangeSelectedFsms}
          selected={selectedFsms}
          selectedLenght={selectedFsms?.length}
          dataLength={fsmsData?.length}
          isLoading={false}
          onCloseSelect={setIsSelectOpen}
          refetch={() => {
            refetchSentData();
            refetchSkippedData();
            refetchToSendData();
          }}
          isSelectOpen={isSelectOpen}
          disabled={false || isFetchingFsms}
        />
      ) : (
        <>
          <MultiSelect
            label="FSM: "
            options={fsmsNames}
            style={selectsStyles}
            handleChange={handleChangeSelectedFsms}
            selected={selectedFsms}
            selectedLenght={selectedFsms?.length}
            dataLength={fsmsData?.length}
            isLoading={false}
            onCloseSelect={setIsSelectOpen}
            refetch={() => {
              refetchAllEmails();
            }}
            isSelectOpen={isSelectOpen}
            disabled={false || isFetchingFsms}
          />
          <div className={styles.searchInputWrapper}>
            <input
              className={styles.searchInput}
              name="owner id"
              placeholder="Search by owner name"
              value={searchValue}
              onChange={event => handleSearch(event.target.value)}
              style={inputSearchStyle}
              onBlur={() => {
                setInputIsActive(false);
              }}
              onFocus={() => {
                setInputIsActive(true);
              }}
              disabled={isLoadingFsms}
            />
            {searchValue && (
              <div className={styles.closeBtn} onClick={() => handleSearch('')}>
                <img src={close} alt="close" />
              </div>
            )}
          </div>
          <MultiSelect
            label="Status: "
            options={statuses}
            style={selectsStyles}
            handleChange={handleChangeStatuses}
            selected={selectedStatuses}
            selectedLenght={selectedStatuses?.length}
            dataLength={statuses?.length}
            isLoading={false}
            onCloseSelect={setIsSelectOpen}
            refetch={() => {
              refetchAllEmails();
            }}
            isSelectOpen={isSelectOpen}
            disabled={isFetchingFsms}
            selectedOption={
              selectedStatuses.length === 1 && (
                <span className={styles.selectedOption}>
                  {capitalizeFirstLetter(selectedStatuses[0])}
                </span>
              )
            }
          />
          <SelectFilter
            label="Time period: "
            style={{
              width: '304px',
              margin: '8px 0 8px 0',
              borderRadius: '8px',
            }}
            menuProps={MenuProps}
            optionValues={timePeriodOptions?.map(element => element)}
            handleChange={(event) => {
              setSelectedTimePeriod(() => event);
            }}
            filterValue={selectedTimePeriod}
            disabled={isFetchingFsms || false}
          />
        </>
      )}
    </div>
  );
};

export default Filters;
