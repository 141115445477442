import { useId } from "react";
import Flex from "../Flex/Flex";
import { switchUseStyles } from "./Switch.styles";

const Switch = ({
  handleToggle,
  handleClick,
  isSwitchOn,
  style,
  isDisabled,
}: any) => {
  const id = useId();
  const styles = switchUseStyles({ isSwitchOn, isDisabled });

  return (
    <Flex alignItems="center" style={style}>
      <Flex className={styles.wrapper} alignItems="center">
        <input
          checked={isSwitchOn}
          onChange={handleToggle}
          className={styles.switchCheckbox}
          id={id}
          type="checkbox"
          onClick={handleClick}
        />
        <label
          className={styles.switchLabel}
          htmlFor={id}

        >
          <span className={styles.switchButton} style={{ cursor: isDisabled ? "initial" : "pointer" }} />
        </label>
      </Flex>
    </Flex>
  );
};

export default Switch;
