import React from 'react';
import { Navigate } from 'react-router-dom';
import ROUTES from 'constants/routes';
import { LocaleStorageTokenList, LocalStorage } from 'helpers/Storage';
import HeaderNavbarContent from '../HeaderNavbarContent/HeaderNavbarContent';

interface IPrivateLayout {
  children: React.ReactNode
}

const PrivateLayout:React.FC<IPrivateLayout> = ({ children }) => {
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);

  return accessToken ? (
    <HeaderNavbarContent children={children} />
  ) : (
    <Navigate to={ROUTES.SIGNIN} />
  );
};

export default PrivateLayout;
