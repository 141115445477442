import React, { useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import MenuItem from '@mui/material/MenuItem';
import Select from 'modules/shared/components/general/Select/Select';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import Spinner from 'modules/shared/components/general/Spinner/Spinner';
import { uploadMemberListService } from 'services/reporting.services';
import { UploadIcon } from 'modules/shared/components/general/Icons/Upload.icon';
import { FileIcon } from 'modules/shared/components/general/Icons/File.icon';
import { ReactComponent as JpegFileIcon } from 'assets/icons/jpegFileIcon.svg';
import { CloseIcon } from 'modules/shared/components/general/Icons/Close.icon';
import Flex from 'modules/shared/components/general/Flex/Flex';
import {
  dropBoxUseStyles,
  baseStyle,
  focusedStyle,
  acceptStyle,
  rejectStyle,
} from './DropBox.styles';

const DropBox: React.FC<any> = ({
  setMappingsValues,
  mappingsValues,
  setUploadSuccess,
  uploadSuccess,
  setIsLoadingMemberList =  () => {},
  setUploadedFile,
  validationText,
  acceptFileType = { 'text/xlsx': ['.xlsx'] },
  isImageUpload = false,
  handeOnDrop,
  mxSiz = 1e8,
}) => {
  const styles = dropBoxUseStyles();
  const [showDropBox, setShowDropBox] = useState(true);
  const [options, setOptions] = useState([]);
  const { mutate, isLoading } = useMutation(
    uploadMemberListService,
  );
  const {
    mappings: { email, revenue, member_since },
  } = mappingsValues;

  const onDrop = (acceptedFiles: any) => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);


    if (!isImageUpload) {
      mutate(formData, {
        onSuccess: (res) => {
          setOptions(res.data.column_names);
          setUploadSuccess(true);
        },
        onError: () => setUploadSuccess(false),
      });
    }
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    fileRejections,
  } = useDropzone({
    accept: acceptFileType,
    maxFiles: 1,
    multiple: false,
    maxSize: mxSiz,
    onDrop: handeOnDrop ? handeOnDrop : onDrop,
  });

  const file: any = acceptedFiles[0] || {};
  const fileName = file?.name?.split(Object.keys(acceptFileType))[0];
  const fileSizeMB = Math.round((file.size / 1000000) * 10) / 10;
  const rejectionError = fileRejections && fileRejections[0]?.errors[0]?.code;

  useEffect(() => {
    const formData = new FormData();

    formData.append('member_list', acceptedFiles[0]);

    setUploadedFile(acceptedFiles[0]);
    // eslint-disable-next-line
    }, [acceptedFiles?.length]);

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...{
        border: fileRejections?.length
          ? '1px dashed #B3261E'
          : '1px dashed rgba(68, 68, 68, 0.25)',
      },
    }),
    // eslint-disable-next-line
        [isFocused, isDragAccept, isDragReject, fileRejections]
  );

  useEffect(() => {
    setIsLoadingMemberList(isLoading);

    // eslint-disable-next-line
    }, [isLoading]);

  const handelEmailChange = (event: any) => {
    setMappingsValues({
      mappings: {
        ...mappingsValues.mappings,
        email: event.target.value,
      },
    });
  };

  const handelMemberSinceChange = (event: any) => {
    setMappingsValues({
      mappings: {
        ...mappingsValues.mappings,
        member_since: event.target.value,
      },
    });
  };

  const handelRevenueChange = (event: any) => {
    setMappingsValues({
      mappings: {
        ...mappingsValues.mappings,
        revenue: event.target.value,
      },
    });
  };

  useEffect(() => {
    acceptedFiles?.length && setShowDropBox(false);

    // eslint-disable-next-line
    }, [acceptedFiles?.length]);

  return (
    <section style={{ borderRadius: '8px' }}>
      {showDropBox ? (
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <Flex alignItems="center" direction='column' justifyContent="center">
            <Flex >
              <UploadIcon />
              <span className={styles.description}>
                            Drag & Drop or <u style={{ cursor: 'pointer' }}>Browse</u> to Upload
              </span>
            </Flex>

            {
              validationText &&
                <span className={styles.validationText}>
                  {validationText}
                </span>
            }
          </Flex>
        </div>
      ) : (
        <div>
          <Flex key={file?.path} alignItems="center" className={styles.fileWrapper}>
            {
              Object.keys(acceptFileType)[0] === 'image/jpeg' ?
                <JpegFileIcon/> :   <FileIcon />
            }
            <Flex
              direction="column"
              alignItems="flex-start"
              className={styles.fileNameWrapper}
            >
              <span className={styles.fileName}>{fileName}</span>
              <span className={styles.fileSize}>{fileSizeMB} MB</span>
            </Flex>
            <div className={styles.spinnerAndCloseWrapper}>
              {isLoading ? (
                <div style={{ marginLeft: '50px' }}>
                  <Spinner style={{ width: '23px', margin: 0 }} />
                </div>
              ) : (
                <div
                  className={styles.closeIconWrapper}
                  onClick={() => {
                    setShowDropBox(true);
                    setUploadSuccess(false);
                    setUploadedFile('');
                  }}
                >
                  <CloseIcon />
                </div>
              )}
            </div>
          </Flex>
        </div>
      )}
      {Boolean(fileRejections?.length) && (
        <ErrorMessage
          text={
            rejectionError === 'too-many-files'
              ? 'Only 1 file should be uploaded'
              :
              rejectionError === 'file-too-large' ?
                `Upload failed: File size must be 1MB or less.`
                :
                `Only ${acceptFileType[Object.keys(acceptFileType)[0]].join(' ,')} files are allowed`
          }
          margin="4px 8px"
        />
      )}
      {Boolean(uploadSuccess) && (
        <Flex direction="column" className="select-input-wrapper">
          <Flex
            style={{ width: '100%', marginTop: '16px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <label className={styles.label}>Email</label>
            <Select
              value={email}
              handleChange={handelEmailChange}
              style={{ width: '364px', marginTop: '0' }}
              formStyle={{ width: '364px', margin: '0 0 0 0' }}
              renderValue={(value: any) =>
                (value ? (
                  <div style={{ color: '#444444' }}>{value}</div>
                ) : (
                  'Select from the list'
                ))
              }
            >
              {options?.map((value, index) => {
                const key = value + index;
                return (
                  <MenuItem
                    key={key}
                    disabled={value === member_since || value === revenue}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Flex>
          <Flex
            style={{ width: '100%', marginTop: '16px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <label className={styles.label}>Member since</label>
            <Select
              value={member_since}
              handleChange={handelMemberSinceChange}
              style={{ width: '364px', marginTop: '0' }}
              formStyle={{ width: '364px', margin: '0 0 0 0' }}
              renderValue={(value: any) =>
                (value ? (
                  <div style={{ color: '#444444' }}>{value}</div>
                ) : (
                  'Select from the list'
                ))
              }
            >
              {options?.map((value, index) => {
                const key = value + index;
                return (
                  <MenuItem
                    key={key}
                    disabled={value === email || value === revenue}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Flex>
          <Flex
            style={{ width: '100%', marginTop: '16px' }}
            justifyContent="space-between"
            alignItems="center"
          >
            <label className={styles.label}>
                            Revenue <i>(optional)</i>
            </label>
            <Select
              value={mappingsValues.mappings.revenue}
              handleChange={handelRevenueChange}
              style={{ width: '364px', marginTop: '0' }}
              formStyle={{ width: '364px', margin: '0 0 0 0' }}
              renderValue={(value: any) =>
                (value ? (
                  <div style={{ color: '#444444' }}>{value}</div>
                ) : (
                  'Select from the list'
                ))
              }
            >
              {options?.map((value, index) => {
                const key = value + index;
                return (
                  <MenuItem
                    key={key}
                    disabled={value === email || value === member_since}
                    value={value}
                  >
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </Flex>
        </Flex>
      )}
    </section>
  );
};

export default DropBox;
