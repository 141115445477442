import { instance } from 'api/ApiRequest';
import {
  setFacebookLocations,
  setFacebookLeadGenForm,
  setDisableBtn,
  setLoading,
  setErrorMessage,
  setRequestSuccess,
  setShowUpdateTab,
  setShowPreview,
  setShowGeoPreview,
  setShowLeadPreview,
  setShowSuccessContent,
  setShowErrorContent,
  setType,
} from './updateContentSlice';
import { serverUrls } from '../../variables';

export const facebookLocations = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const { data } = await instance.post(`${serverUrls.campaigns}facebook/locations/`, payload);
    dispatch(setFacebookLocations(data));
    dispatch(setDisableBtn(true));
    dispatch(setLoading(false));
    dispatch(setRequestSuccess(true));
    dispatch(setShowGeoPreview(true));
    dispatch(setShowUpdateTab(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(error?.response?.data?.detail));
    dispatch(setRequestSuccess(false));
    dispatch(setShowUpdateTab(false));
    dispatch(setShowPreview(false));
    dispatch(setShowErrorContent(true));
    dispatch(setType('locations'));
  }
};

export const facebookLeadGenForms = (payload: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    const { data } = await instance.post(
      `${serverUrls.campaigns}facebook/lead-gen-forms/`,
      payload,
    );
    dispatch(setFacebookLeadGenForm(data));
    dispatch(setLoading(false));
    dispatch(setRequestSuccess(true));
    dispatch(setShowLeadPreview(true));
    dispatch(setShowUpdateTab(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrorMessage(error?.response?.data?.detail));
    dispatch(setRequestSuccess(false));
    dispatch(setShowUpdateTab(false));
    dispatch(setShowPreview(false));
    dispatch(setShowErrorContent(true));
    dispatch(setType('leadGenForms'));
  }
};

export const updateLocations = (payload: any, recordId: any, cb: any) => async (dispatch: any) => {
  try {
    dispatch(setLoading(true));
    await instance.post(`${serverUrls.campaigns}airtable/locations/${recordId}/sync/`, payload);
    dispatch(setLoading(false));
    cb();
    dispatch(setRequestSuccess(true));
    dispatch(setShowSuccessContent(true));
    dispatch(setShowGeoPreview(false));
    dispatch(setShowErrorContent(false));
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setRequestSuccess(false));
    dispatch(setErrorMessage(error?.response?.data?.detail));
    dispatch(setType('locationsSync'));
    dispatch(setShowErrorContent(true));
    dispatch(setShowGeoPreview(false));
  }
};

export const updateLeadGenForm =
    (payload: any, leadRecordId: any, cb: any) => async (dispatch: any) => {
      try {
        dispatch(setLoading(true));
        await instance.post(
          `${serverUrls.campaigns}facebook/lead-gen-forms/${leadRecordId}/sync/`,
          payload,
        );
        dispatch(setLoading(false));
        cb();
        dispatch(setShowSuccessContent(true));
        dispatch(setRequestSuccess(true));
        dispatch(setShowLeadPreview(false));
      } catch (error: any) {
        dispatch(setLoading(false));
        dispatch(setRequestSuccess(false));
        dispatch(setErrorMessage(error?.response?.data?.detail));
        dispatch(setShowPreview(true));
        dispatch(setType('leadGenFormsSync'));
        dispatch(setShowErrorContent(true));
        dispatch(setShowLeadPreview(false));
      }
    };
