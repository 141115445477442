import BasicModal from "modules/shared/components/general/BasicModal/BasicModal";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import Flex from "modules/shared/components/general/Flex/Flex";
import chatBotBro from "assets/icons/chatBotBro.svg";
import { Button } from "modules/shared/components/general/Button/Button";
import { ButtonVariants } from "modules/shared/components/general/Button/Button.types";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import { useStyles } from "./PauseAdModal.styles";
import { useEffect } from "react";

const PauseAdModal = ({
  isPauseModalOpen,
  setIsPauseModalOpen,
  isLoading,
  acceptSuggesstionMutate,
  dismissSuggesstionMutate,
  isLoadingAccept,
  setLoadingItems,
  isLoadingDismiss }:any) => {
  const styles = useStyles();

  useEffect(() => {
    setLoadingItems({});
    // eslint-disable-next-line
  }, [isPauseModalOpen])

  return (
    <BasicModal
      open={isPauseModalOpen}
      handleClose={() => setIsPauseModalOpen(false)}
    >
      <Flex direction="column" alignItems="center" style={{ width: "553px" }}>
        <Flex direction="column" style={{ padding: "24px 0 0 0" }} alignItems='center'>
          <h3 className={styles.modalTitle}>
            Are you sure you want to <br /> pause this ad?
          </h3>
          <p className={styles.modalDescription}>This is the last running ad in the campaign.</p>
          <ImageLoader
            src={chatBotBro}
            alt="mail box"
            style={{ width: "279px", height: "272px", textAlign: "center", marginTop: "16px" }}
          />
        </Flex>
        <Flex
          justifyContent="flex-end"
          style={{ gap: "16px", width: "100%", marginTop: "16px" }}
        >
          <Button variant={ButtonVariants.Secondary}
            handleClick={() => acceptSuggesstionMutate()}
            // handleClick={() => setIsPauseModalOpen(false)}
            style={{
              textAlign: "left",
              width: "107px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isLoadingAccept ? (
              <Spinner
                white={true}
                style={{
                  width: "16px",
                  margin: "0",
                  left: "50%",
                  position: "initial",
                }}
              />
            ) : (
              <span>Accept</span>
            )}
          </Button>
          <Button
            handleClick={() => {
              dismissSuggesstionMutate();
            }}
            style={{
              textAlign: "left",
              width: "107px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isLoadingDismiss ? (
              <Spinner
                white={true}
                style={{
                  width: "16px",
                  margin: "0",
                  left: "50%",
                  position: "initial",
                }}
              />
            ) : (
              <span>Dismiss</span>
            )}
          </Button>
        </Flex>
      </Flex>
    </BasicModal >
  );
};

export default PauseAdModal;
