import React, { useState, useEffect } from 'react';
import BasicModal from '../BasicModal/BasicModal';
import Flex from '../Flex/Flex';
import { useStyles } from './AddBrandModal.styles';
import { InputField } from '../InputField/InputField';
import warningBlueIcon from '../../../../../assets/icons/InfoBlueIconWar.svg';
import { Button } from '../Button/Button';
import { ButtonVariants } from '../Button/Button.types';
import DropBox from '../../../../BrandsReporting/components/ReportRequest/DropBox/DropBox';
import { useMutation } from 'react-query';
import { createNewBrand } from '../../../../../services/addBrandModal.seervices';
import { IBrandWarning } from '../SideBar/SideBar';


interface IAddBrandModal {
    isOpenModal:boolean
    onClose: () => void
    setOpenBrandSuccesModal: React.Dispatch<React.SetStateAction<boolean>>
    setBrandWarning: React.Dispatch<React.SetStateAction<IBrandWarning | null>>
}

const AddBrandModal:React.FC<IAddBrandModal> = (
  {
    isOpenModal ,
    onClose ,
    setOpenBrandSuccesModal,
    setBrandWarning,
  }) => {
  const styles = useStyles();
  const [brandNameValue, setBrandNameValue] = useState<string>('');
  const [mappingsValues, setMappingsValues] = useState({
    mappings: { member_since: '', email: '', revenue: '' },
  });
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string | Blob>('');
  const [jpegFile, setFileJpeg] = useState<any>(null);
  const [errorName, setErrorName] = useState<string>('');

  const { mutate: createNewBrd, isLoading } = useMutation(createNewBrand, {
    onSuccess: ({ data }) => {
      setBrandWarning(data);
      setOpenBrandSuccesModal(true);
      onClose();
    },
    onError: (error:any) => {
      const key  = Object.keys(error?.response?.data)[0];
      if  (error?.response.data.detail) {
        setErrorName(error?.response.data.detail);
        return;
      }
      setErrorName(error?.response?.data[key].join(' ,'));
    },
  });

  const handleChangeBrandName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length) {
      setErrorName('');
    }
    setBrandNameValue(event.target.value);
  };


  const handleDrop = (acceptedFiles: any) =>
    setFileJpeg(acceptedFiles[0]);



  const handleCreateNewBrand = () => {
    createNewBrd({
      name: brandNameValue,
      logo_url: jpegFile,
    });
  };


  useEffect(
    () => () => {
      if  (isOpenModal) {
        setBrandNameValue('');
        setFileJpeg(null);
      }
    }
    ,[isOpenModal],
  );


  return (
    <BasicModal
      open={isOpenModal}
      handleClose={onClose}
      basicModalStyle={isLoading ?
        { zIndex: 1000 , userSelect:'none' }  : { zIndex: 1000 }}
      style={isLoading ? { filter: 'contrast(0.5)' } : {}}
      spinerStyle={{
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0  , zIndex: 1001,
        filter: 'contrast(2)',
      }}
      loading={isLoading}
      isClickOutside
    >
      <Flex direction="column" alignItems="center">
        <h3 className={styles.modalTitle}>
            Add brand
        </h3>
      </Flex>

      <p className={styles.infoToastTest}>
        <img
          width="20px"
          height="20px"
          style={{ marginRight: '16px' }}
          src={warningBlueIcon}
          alt="info" />
          Facebook data will be synced for the previous month.
      </p>
      <InputField
        labelStyle={{ marginTop: '16px' }}
        style={{ width: '570px' }}
        placeholder="Fill the brand name as in AirTable"
        label="Brand name"
        value={brandNameValue}
        handleChange={handleChangeBrandName}
        hasError={Boolean(errorName)}
        errorText={errorName}
      />

      <Flex  alignItems={'inherit'}  direction="column" >
        <h3 className={styles.typeDropBoxTitle}> Upload logo</h3>
        <DropBox
          setMappingsValues={setMappingsValues}
          mappingsValues={mappingsValues}
          setUploadSuccess={setUploadSuccess}
          uploadSuccess={uploadSuccess}
          setUploadedFile={setUploadedFile}
          acceptFileType={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
          validationText={'JPG, Max size 1MB, Max resolution 120x120'}
          isImageUpload
          handeOnDrop={handleDrop}
          mxSiz={1048576}
        />
      </Flex>

      <Flex justifyContent={'flex-end'} style={{ marginTop: '24px' }}>

        <Button
          variant={ButtonVariants.Secondary}
          style={{ marginRight: '16px' }}
          handleClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant={ButtonVariants.Primary}
          disabled={!(uploadedFile && Boolean(brandNameValue))}
          handleClick={handleCreateNewBrand}
        >
          Add
        </Button>
      </Flex>
    </BasicModal>
  );
};

export default AddBrandModal;
