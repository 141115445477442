import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setShowGeoPreview,
  setShowUpdateTab,
  setShowLeadPreview,
  setShowSuccessContent,
  setShowErrorContent,
} from "../../../../store/updateContent/updateContentSlice";
import errorIllustration from "assets/icons/errorIllustration.svg";
import "./styles.scss";
import { IRootStore } from "types/store.types";

const UpdateContentErrorSuccess:React.FC<any> = ({ handleClose }) => {
  const dispatch = useDispatch();

  const {
    updateContent: { errorMessage, showErrorContent, showSuccessContent, type },
  } = useSelector((state:IRootStore) => state);

  const checkHandleBackType = () => {
    switch (type) {
      case "locations":
        return dispatch(setShowUpdateTab(true));
      case "locationsSync":
        return dispatch(setShowGeoPreview(true));
      case "leadGenForms":
        return dispatch(setShowUpdateTab(true));
      case "leadGenFormsSync":
        return dispatch(setShowLeadPreview(true));
      default:
        return;
    }
  };

  const handleBackClick = () => {
    dispatch(setShowErrorContent(false));
    dispatch(setShowSuccessContent(false));
    return checkHandleBackType();
  };

  return (
    <>
      <div
        className={`success-error-wrapper ${
          showSuccessContent && "success-image"
        } `}
        style={{ height: showSuccessContent ? "420px" : "auto" }}
      >
        <h1
          className="modal-title"
          style={{
            textAlign: "center",
            marginBottom: "8px",
            marginTop: "32px",
          }}
        >
          {showSuccessContent
            ? "Airtable updated successfully!"
            : "Something went wrong!"}
        </h1>
        {showErrorContent && (
          <>
            <p
              style={{
                textAlign: "center",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "1.5",
                color: "#444444",
                margin: "0 32px 16px",
              }}
            >
              {errorMessage}
            </p>
            <div className="error-image">
              <img src={errorIllustration} alt="errorIllustration" />
            </div>
          </>
        )}
      </div>

      <button
        style={{ width: "85px", marginLeft: "auto", marginTop: "8px" }}
        className="auth-button"
        onClick={() => (showErrorContent ? handleBackClick() : handleClose())}
      >
        {showErrorContent ? "Back" : "Done"}
      </button>
    </>
  );
};

export default UpdateContentErrorSuccess;
