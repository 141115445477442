import { createUseStyles } from "react-jss";

export const successFailedModalUseStyles = createUseStyles(() => ({
  succesModalWrapper: {
    gap: "16px",
    height: "100vh",
  },
  failedModalWrapper: {
    gap: "16px",
  },
  successMessage: {
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "1.5",
    color: "#444444",
  },
  modalMessage: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#0F314D",
    textAlign: "center",
  },
  modalTitle: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "1.5",
    color: "#0F314D",
    textAlign: "center",
    margin: "0",
  },
}));
