import { useStylesTabs } from "./Tabs.styles";
import { LocalStorage } from "../../../../helpers/Storage";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";

const Tab = ({
  tabName,
  children,
}: {
  tabName: string;
  children: any;
}) => {
  const styles = useStylesTabs();
  const {
    activeTab,
    setActiveTab,
  } = useClientCommunicationContext();
  const handleClick = () => {
    setActiveTab(tabName);
    LocalStorage.set("activeTab", tabName);
  };

  const isActive = activeTab === tabName;
  const tabClassName = isActive ? styles.activeTab : styles.tabs;

  return (
    <div className={tabClassName} onClick={handleClick}>
      <span>{children}</span>
    </div>
  );
};

const Tabs = () => {
  const styles = useStylesTabs();
  return (
    <div className={styles.tabsContainer}>
      <Tab tabName="weeklyBoard">
        Weekly board
      </Tab>

      <Tab tabName="allEmails" >
        All emails
      </Tab>
    </div>
  );
};

export default Tabs;
