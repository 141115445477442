import { useEffect, useState } from "react";
import UpdateSpace from "assets/icons/updateSpace.svg";
import BasicModal from "../../shared/components/general/BasicModal/BasicModal";
import GeoUpdatePreview from "../components/GeoUpdatePreview/GeoUpdatePreview";
import { useDispatch, useSelector } from "react-redux";
import {
  setFacebookLeadGenForm,
  setFacebookLocations,
  setShowErrorContent,
  setShowSuccessContent,
  setShowUpdateTab,
} from "../../../store/updateContent/updateContentSlice";
import LeadUpdatePreview from "../components/LeadUpdatePreview/LeadUpdatePreview";
import UpdateContentErrorSuccess from "../components/UpdateContentErrorOrSuccess/UpdateContentErrorOrSuccess";
import "./styles.scss";
import { useUpdatePreview } from "../../../context/update.context";
import UpdateContentBody from "../components/UpdateContentBody /UpdateContentBody";
import { IRootStore } from "types/store.types";

const UpdateAirtable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [activeTab, setAcitveTab] = useState("geo");
  const { setRecordId, setAdSetId, setLeadRecordId } = useUpdatePreview();

  const {
    updateContent: {
      showUpdateTab,
      showGeoPreview,
      showLeadPreview,
      showErrorContent,
      showSuccessContent,
    },
  } = useSelector((state:IRootStore) => state);

  const handleOpen = () => {
    setOpen(true);
    dispatch(setShowSuccessContent(false));
    dispatch(setShowErrorContent(false));
    dispatch(setShowUpdateTab(true));
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(setFacebookLocations({}));
    dispatch(setFacebookLeadGenForm({}));
    setAcitveTab("geo");
    setAdSetId("");
    setRecordId("");
    setLeadRecordId("");
  };

  useEffect(() => {
    dispatch(setShowUpdateTab(true));

    // eslint-disable-next-line
  }, []);

  const generateContent = () => {
    if (showUpdateTab) {
      return (
        <UpdateContentBody
          handleClose={handleClose}
          setAcitveTab={setAcitveTab}
          activeTab={activeTab}
        />
      );
    }
    if (showGeoPreview) {
      return <GeoUpdatePreview/>;
    }
    if (showLeadPreview) {
      return <LeadUpdatePreview/>;
    }
    if (showErrorContent) {
      return <UpdateContentErrorSuccess />;
    }
    if (showSuccessContent) {
      return <UpdateContentErrorSuccess handleClose={handleClose} />;
    }
  };

  return (
    <div className="update-wrapper">
      <div className="update-img">
        <img src={UpdateSpace} alt="update spaceship" />
      </div>
      <div>
        <h1 style={{ color: "#0F314D", fontWeight: "600", fontSize: "32px" }}>
          Update Airtable
        </h1>
        <p>
          You can update Airtable geo and lead forms information. Change <br />{" "}
          history feature will come soon!
        </p>
        <button
          className="auth-button"
          style={{ width: "unset", padding: "16px 24px", textAlign: "center" }}
          onClick={() => {
            handleOpen();
          }}
        >
          Update
        </button>
      </div>
      <BasicModal
        open={open}
        handleClose={handleClose}
        style={{
          width: showErrorContent || showSuccessContent ? "617px" : "866px",
        }}
      >
        <div className="update-modal-content">{generateContent()}</div>
      </BasicModal>
    </div>
  );
};

export default UpdateAirtable;
