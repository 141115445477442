import React, { useEffect } from "react";
import CampaignIdSearch from "../components/SearchCampaignId";
import SearchedBrandAdAccountsById from "../components/SearchedBrandAdAccountsById/SearchedBrandAdAccountsById";
import DataPreparation from "../components/DataPreparation/DataPreparation";
import { useDispatch, useSelector } from 'react-redux';
import { setStartDuplicationSuccess } from '../../../store/brandAdAccounts/brandAdAccountsSlice';

const BrandAdAccounts:React.FC = () => {
  const dispatch = useDispatch();

  const {
    brandAdAccounts: {
      startDuplicationSuccess,
    },
  }: any = useSelector(state => state);


  useEffect(() => {
    const rootElement = document.getElementById('root');

    if (rootElement) {
      rootElement.classList.add('root_overFlow');
    }

    dispatch(setStartDuplicationSuccess(false));

    return () => {
      document.body.style.overflow = 'unset';
      if (rootElement) {
        rootElement.classList.add('root_overFlow');
      }
    };
    // eslint-disable-next-line
  }, []);

  return startDuplicationSuccess ? (
    <DataPreparation />
  ) : (
    <div
      style={{
        maxWidth: "1288px",
        // margin: "0 auto 64px",
        margin: "auto",
        marginBottom: "64px",
      }}
    >
      <CampaignIdSearch />
      <SearchedBrandAdAccountsById />
    </div>
  );
};

export default BrandAdAccounts;
