import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useStylesForwardEmails } from './ForwardEmails.styles';
import { getUsers } from 'services/users.services';
import { QueryConstants } from 'constants/queryConstants';
import closeSvg from 'assets/icons/close.svg';
import { RegexConstants } from '../../../../constants/regex.constants';
import ErrorMessage from '../../../shared/components/general/ErrorMessage/ErrorMessage';
import { useClientCommunicationContext } from '../../../../context/clientCommunication.context';

const ForwardEmails = ({
  setEditEmailPayload,
  editEmailPayload,
  setIsSaveDisabled,
  defaultToValue,
  setShowEditButton,
  defaultCCValue,
  readOnly,
}: {
    setEditEmailPayload: (param: any) => any;
    editEmailPayload: any;
    setIsSaveDisabled: (param: boolean) => any;
    defaultToValue: string[];
    setShowEditButton: (param: boolean) => any;
    defaultCCValue: string;
    readOnly: boolean;
}) => {
  const styles = useStylesForwardEmails();
  const {
    toValue,
    setToValue,
    toOptions,
    setToOptions,
    CCoptions,
    setCCoptions,
    showCCField,
    setShowCCField,
  } = useClientCommunicationContext();
  const [ccValue, setCCValue] = useState<string>('');
  const [notOurUserTo, setNotOurUserTo] = useState<string>('');
  const [notOurUserCC, setNotOurUserCC] = useState<string>('');
  const [isToBlurred, setIsToBlurred] = useState<boolean>(false);
  const [isCCBlurred, setIsCCBlurred] = useState<boolean>(false);
  const [showToEmailValidation, setShowToEmailValidation] = useState<boolean>(false);
  const [showCCEmailValidation, setShowCCEmailValidation] = useState<boolean>(false);
  const toRef = useRef<any>(null);
  const ccRef = useRef<any>(null);
  const ccShowRef = useRef<any>(null);
  const optionsContainerRefTo = useRef<any>(null);
  const optionsContainerRefCC = useRef<any>(null);
  const emailRegex = RegexConstants.email;
  const isValidEmailTo = emailRegex.test(String(toValue).toLowerCase());
  const isValidEmailCC = emailRegex.test(String(ccValue).toLowerCase());

  const { data: { data: authUsersData = [] } = {} } = useQuery(
    [QueryConstants.getTeamManagementUsers],
    () => getUsers(),
  );

  const activeUsersEmails = authUsersData.reduce((emails:any, user:any) => {
    if (user.is_active) {
      emails.push(user.email);
    }
    return emails;
  }, []);

  useEffect(() => {
    setToOptions([...defaultToValue]);
    setCCoptions([...defaultCCValue]);

    return () => {
      setToOptions([]);
      setCCoptions([]);
      setToValue('');
      setShowCCField(false);
    };
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    const handleEmailValidation = (value:any, isBlurred:any, isValid:any, setShowValidation:any) => {
      if (value && isBlurred && !isValid) {
        setShowValidation(true);
      } else {
        setShowValidation(false);
      }
    };

    handleEmailValidation(toValue, isToBlurred, isValidEmailTo, setShowToEmailValidation);
    handleEmailValidation(ccValue, isCCBlurred, isValidEmailCC, setShowCCEmailValidation);
  }, [isToBlurred, toValue, isCCBlurred, ccValue, isValidEmailTo, isValidEmailCC]);

  useEffect(() => {
    if (toValue && !isValidEmailTo) {
      setIsSaveDisabled(true);
    } else if (ccValue && !isValidEmailCC) {
      setIsSaveDisabled(true);
    } else {
      setIsSaveDisabled(false);
    }

    if (!toOptions.length) {
      setIsSaveDisabled(true);
    }

    // eslint-disable-next-line
    }, [toValue, isValidEmailTo, isValidEmailCC, ccValue, toOptions]);

  useEffect(() => {
    const handleClickOutside = (event:any) => {
      if (
        toRef.current &&
                toRef.current.contains(event.target) &&
                !(event.target === ccShowRef.current)
      ) {
        setIsToBlurred(false);
      } else {
        setIsToBlurred(true);
      }

      if (ccRef.current && ccRef.current.contains(event.target)) {
        setIsCCBlurred(false);
      } else {
        setIsCCBlurred(true);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [toRef, ccRef, ccShowRef]);

  const handleToChange = (event:any) => {
    const { value } = event.target;
    setToValue(value);
  };

  const handleCCInputChange = (event:any) => {
    const { value } = event.target;
    setCCValue(value);
  };

  const handleToListClick = (email:any) => {
    setToValue('');
    setToOptions([...toOptions, email]);
    setIsToBlurred(false);
  };

  const handleCCListClick = (email:any) => {
    setCCValue('');
    setCCoptions([...CCoptions, email]);
  };

  useEffect(() => {
    setEditEmailPayload({
      ...editEmailPayload,
      cc_emails: [...CCoptions],
    });
    // eslint-disable-next-line
    }, [CCoptions?.length]);

  useEffect(() => {
    setEditEmailPayload({
      ...editEmailPayload,
      to_emails: [...toOptions],
    });
    // eslint-disable-next-line
    }, [toOptions?.length]);

  const filterTOEmails = (inputValue:any) =>
    activeUsersEmails.filter(
      (email:any) =>
        email?.toLowerCase().includes(inputValue.toLowerCase()) &&
                !toOptions.includes(email) &&
                !CCoptions.includes(email),
    );

  const filterCCEmails = (inputValue:any) =>
    activeUsersEmails.filter(
      (email:any) =>
        email.toLowerCase().includes(inputValue.toLowerCase()) &&
                !CCoptions.includes(email) &&
                !toOptions.includes(email),
    );

  const deleteEmailFromListTo = (email:any) => {
    const filteredOptions = toOptions.filter((option:any) => option !== email);
    setToOptions(filteredOptions);
    !readOnly && setShowEditButton(false);
  };

  const deleteEmailFromListCC = (email:any) => {
    const filteredOptions = CCoptions.filter((option:any) => option !== email);
    setCCoptions(filteredOptions);
  };

  const detectNameByEmail = (email:any) => {
    const user = authUsersData
      .filter((user:any) => user.is_active)
      .find((user:any) => user.email === email);

    return user?.name || '';
  };

  const detectNameByEmailCC = (email:any) => {
    const user = authUsersData
      .filter((user:any) => user.is_active)
      .find((user:any) => user.email === email);

    return user?.name || '';
  };

  useEffect(() => {
    if (toValue && !filterTOEmails(toValue).length && isValidEmailTo) {
      setNotOurUserTo(toValue);
    } else {
      setNotOurUserTo('');
    }

    // eslint-disable-next-line
    }, [toValue, filterTOEmails(toValue).length]);

  useEffect(() => {
    if (ccValue && !filterTOEmails(ccValue).length && isValidEmailCC) {
      setNotOurUserCC(ccValue);
    } else {
      setNotOurUserCC('');
    }
    // eslint-disable-next-line
    }, [ccValue, filterTOEmails(ccValue).length]);

  const [heightTo, setHeightTo] = useState(43);

  useEffect(() => {
    const observerTo = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const divHeight = optionsContainerRefTo.current.getBoundingClientRect().height;
          setHeightTo(divHeight);
          // You can perform actions or state updates here based on the height change
        }
      }
    });

    observerTo.observe(optionsContainerRefTo.current, { childList: true, subtree: true });

    // Clean up the observer when the component unmounts
    return () => {
      observerTo.disconnect();
    };
  }, []);


  return (
    <div>
      <div
        ref={toRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          border: showToEmailValidation ? '1px solid #B3261E' : '1px solid #D9D9D9',
          position: 'relative',
          padding: '8px 16px',
          borderRadius: '8px',
          marginBottom: showToEmailValidation ? '32px' : '16px',
          paddingBottom: toOptions?.length ? 0 : '8px',
          maxWidth: '1006px',
        }}
        className={styles.to}
      >
        <span style={{ marginRight: '8px', lineHeight: '24px' }}>To:</span>
        <div
          ref={optionsContainerRefTo}
          style={{
            minWidth: '1px',
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            maxWidth: '912px',
          }}
        >
          {toOptions.map((option: any) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                borderRadius: '15px',
                border: '1px solid #D9D9D9',
                boxSizing: 'border-box',
                marginRight: '8px',
                color: '#444',
                lineHeight: '24px',
                marginBottom: '8px',
                fontSize: '16px',
                padding: '4px 8px',
              }}
              key={option}
            >
              <span style={{ lineHeight: '24px', color: '#444', fontWeight: '400' }}>
                {detectNameByEmail(option)
                  ? `${detectNameByEmail(option)} (${option})`
                  : option}
              </span>
              <span
                style={{
                  cursor: readOnly ? 'default' : 'pointer',
                  lineHeight: 0,
                  marginLeft: '4px',
                }}
                onClick={() => (readOnly ? null : deleteEmailFromListTo(option))}
              >
                <img width="16px" height="16px" src={closeSvg} alt="close" />
              </span>
            </div>
          ))}
          <div style={{ position: 'relative', flex: '1', height: '27px' }}>
            <input
              type="text"
              value={toValue}
              onChange={handleToChange}
              style={{
                flex: '1 1',
                border: '0',
                width: '100%',
                cursor: toOptions?.length >= 5 || readOnly ? 'default' : 'text',
                height: '24px',
                textDecoration: showToEmailValidation
                  ? '#B3261E dotted underline'
                  : 'none',
                color: '#444',
                fontWeight: '400',
                lineHeight: '24px',
                fontSize: '16px',
              }}
              readOnly={toOptions?.length >= 5 || readOnly}
              onFocus={() => {
                !readOnly && setShowEditButton(false);
              }}
            />
            <span
              style={{
                height: '0',
                visibility: 'hidden',
              }}
            >
              {toValue}
            </span>
          </div>
        </div>
        {Boolean(toValue && !isToBlurred && filterTOEmails(toValue).length) && (
          <ul
            className={styles.fsmsList}
            style={{ top: heightTo > 42 ? `calc(${heightTo}px + 24px)` : '60px' }}
          >
            {filterTOEmails(toValue).map((email: any, index: any) => (
              <li
                style={{ color: '#444' }}
                key={index}
                onClick={() => handleToListClick(email)}
              >
                <div>
                  {detectNameByEmail(email) && (
                    <span>
                      <strong>
                        {detectNameByEmail(email).substring(0, 1)}
                      </strong>
                      {detectNameByEmail(email).substring(1)}
                    </span>
                  )}
                </div>
                <span>
                  <strong>{email.substring(0, 1)}</strong>
                  {email.substring(1)}
                </span>
              </li>
            ))}
          </ul>
        )}
        {notOurUserTo && (
          <ul className={styles.fsmsList}>
            <li
              style={{ color: '#444' }}
              onClick={() => handleToListClick(notOurUserTo)}
            >
              {notOurUserTo}
            </li>
          </ul>
        )}
        {!showCCField && !readOnly && (
          <span
            ref={ccShowRef}
            style={{
              marginRight: '8px',
              position: 'absolute',
              right: '16px',
              cursor: 'pointer',
              backgroundColor: '#fff',
            }}
            onClick={() => {
              setShowCCField(true);
              !readOnly && setShowEditButton(false);
            }}
            className={styles.showCC}
          >
                        CC
          </span>
        )}
        <div style={{ position: 'absolute', top: '101%' }}>
          {showToEmailValidation && <ErrorMessage text="Invalid email address." />}
        </div>
      </div>
      {Boolean(showCCField) && (
        <div
          ref={ccRef}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            border:
                            ccValue && isCCBlurred && !isValidEmailCC
                              ? '1px solid #B3261E'
                              : '1px solid #D9D9D9',
            position: 'relative',
            padding: '8px 16px',
            borderRadius: '8px',
            marginBottom: ccValue && isCCBlurred && !isValidEmailCC ? '32px' : '16px',
            maxWidth: '1006px',
          }}
          className={styles.to}
        >
          <span style={{ marginRight: '8px', lineHeight: '24px' }}>CC:</span>
          <div
            className="options"
            id="your-cc-container-id"
            ref={optionsContainerRefCC}
            style={{ minWidth: '1px', display: 'flex', flexWrap: 'wrap', flex: 1 }}
          >
            {CCoptions.map((option: any) => (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '15px',
                  border: '1px solid #D9D9D9',
                  boxSizing: 'border-box',
                  marginRight: '8px',
                  color: '#444',
                  lineHeight: '24px',
                  marginBottom: '8px',
                  fontSize: '16px',
                  padding: '4px 8px',
                }}
                key={option}
              >
                <span
                  style={{ lineHeight: '24px', color: '#444', fontWeight: '400' }}
                >
                  {detectNameByEmailCC(option)
                    ? `${detectNameByEmailCC(option)} (${option})`
                    : option}
                </span>
                <span
                  style={{
                    marginLeft: '4px',
                    cursor: readOnly ? 'default' : 'pointer',
                    lineHeight: 0,
                  }}
                  onClick={() =>
                    (readOnly ? null : deleteEmailFromListCC(option))
                  }
                >
                  <img width="16px" height="16px" src={closeSvg} alt="close" />
                </span>
              </div>
            ))}
            <div style={{ position: 'relative', flex: '1', height: '27px' }}>
              <input
                type="text"
                value={ccValue}
                onChange={handleCCInputChange}
                // className={styles.input}
                style={{
                  flex: '1 1',
                  border: '0',
                  width: '100%',
                  cursor: CCoptions?.length >= 5 || readOnly ? 'default' : 'text',
                  height: '24px',
                  textDecoration:
                                        ccValue && isCCBlurred && !isValidEmailCC
                                          ? '#B3261E dotted underline'
                                          : 'none',
                  color: '#444',
                  fontWeight: '400',
                  lineHeight: '24px',
                  fontSize: '16px',
                }}
                readOnly={CCoptions?.length >= 5 || readOnly}
                onFocus={() => {
                  !readOnly && setShowEditButton(false);
                }}
              />
              <span
                style={{
                  height: '0',
                  visibility: 'hidden',
                }}
              >
                {ccValue}
              </span>
            </div>
          </div>
          {Boolean(ccValue && !isCCBlurred && filterCCEmails(ccValue).length) && (
            <ul className={styles.fsmsList}>
              {filterCCEmails(ccValue).map((email: any, index: any) => (
                <li
                  style={{ color: '#444' }}
                  key={index}
                  onClick={() => handleCCListClick(email)}
                >
                  <div>
                    {detectNameByEmail(email) && (
                      <span>
                        <strong>
                          {detectNameByEmail(email).substring(0, 1)}
                        </strong>
                        {detectNameByEmail(email).substring(1)}
                      </span>
                    )}
                  </div>
                  <span>
                    <strong>{email.substring(0, 1)}</strong>
                    {email.substring(1)}
                  </span>
                </li>
              ))}
            </ul>
          )}
          {notOurUserCC && (
            <ul className={styles.fsmsList}>
              <li
                style={{ color: '#444' }}
                onClick={() => handleCCListClick(notOurUserCC)}
              >
                {notOurUserCC}
              </li>
            </ul>
          )}
          <div style={{ position: 'absolute', top: '101%' }}>
            {showCCEmailValidation && <ErrorMessage text="Invalid email address." />}
          </div>
        </div>
      )}
    </div>
  );
};

export default ForwardEmails;
