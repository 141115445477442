import React from "react";
import { useMutation } from "react-query";
import Flex from "../../../shared/components/general/Flex/Flex";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import mailboxBro from "assets/icons/mailboxBro.svg";
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import { successFailedModalUseStyles } from "./SuccessFailedModal.styles";
import { sendEmail } from "../../../../services/clientCommunication";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";
import Spinner from "../../../shared/components/general/Spinner/Spinner";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";

const AreYouSureToSendModal = ({ onClose, emailId }:any) => {
  const styles = successFailedModalUseStyles();

  const { googleAuthCode, setEmailStatus, setUpdatedStatus } =
    useClientCommunicationContext();

  const payloadSendEmail = LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken)
    ? {
      access_token: LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken),
    }
    : { code: googleAuthCode };

  const { mutate: mutateSendEmail, isLoading } = useMutation(
    () => sendEmail(emailId, payloadSendEmail),
    {
      onSuccess: (response: any) => {
        LocalStorage.set(LocaleStorageTokenList.googleAuthAccessToken, response.data.access_token);
        setUpdatedStatus(response.data.status);
        setEmailStatus({
          [emailId]: "send",
        });
        onClose();
      },
      onError: () => {
        LocalStorage.remove(LocaleStorageTokenList.googleAuthAccessToken);
      },
    },
  );

  return (
    <Flex direction="column" alignItems="center" style={{ width: "617px" }}>
      <Flex direction="column" style={{ padding: "24px 32px 0" }} alignItems='center'>
        <h3 className={styles.modalTitle}>
          Are you sure you want to send the email?
        </h3>
        <ImageLoader
          src={mailboxBro}
          alt="mail box"
          style={{ width: "300px", height: "300px", textAlign: "center" }}
        />
      </Flex>
      <Flex
        justifyContent="flex-end"
        style={{ gap: "16px", width: "100%", marginTop: "16px" }}
      >
        <Button variant={ButtonVariants.Secondary} handleClick={onClose}>
          Cancel
        </Button>
        <Button
          handleClick={() => mutateSendEmail()}
          style={{
            textAlign: "left",
            width: "107px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Spinner
              white={true}
              style={{
                width: "16px",
                margin: "0",
                left: "50%",
                position: "initial",
              }}
            />
          ) : (
            <span>Send</span>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default AreYouSureToSendModal;
