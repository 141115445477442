import { createUseStyles } from "react-jss";

export const emptyStateUseStyles = createUseStyles(() => ({
  message:{
    fontSize:"18px",
    fontWeight:"700",
    lineHeight:"1.5",
    color:"#0F314D",
  },
  description:{
    fontSize:"18px",
    fontWeight:"400",
    lineHeight:"1.5",
    color:"#444444",
  },
}));
