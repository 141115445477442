import React from "react";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";
import Flex from "../../../shared/components/general/Flex/Flex";
import { useStatistics } from "../../queryActions/queryActions";
import CustomSlider from "../Slider/CustomSlider";

import { cardUseStyles } from "./StatisticCard.styles";

const StatisticCard = ({ cardInfo }: { cardInfo: any }) => {
  const styles = cardUseStyles();
  return (
    <Flex
      className={styles.cardContainer}
      direction="column"
      alignItems="center"
      style={{ gap: "8px" }}
    >
      <span className={styles.fsmName}>{cardInfo.full_name}</span>
      <div className={styles.divider}></div>
      <Flex style={{ gap: "16px" }}>
        <Flex direction="column" style={{ gap: "4px" }} alignItems="center">
          <span className={styles.counts}>{cardInfo.delivered}</span>
          <span className={styles.status}>Sent</span>
        </Flex>
        <Flex direction="column" style={{ gap: "4px" }} alignItems="center">
          <span className={styles.counts}>{cardInfo.skipped}</span>
          <span className={styles.status}>Skipped</span>
        </Flex>
        <Flex direction="column" style={{ gap: "4px" }} alignItems="center">
          <span className={styles.counts}>{cardInfo.expired}</span>
          <span className={styles.status}>Expired</span>
        </Flex>
      </Flex>
    </Flex>
  );
};

const StatisticsContent = ({ roleFromToken, selectedTimePeriod }:any) => {
  const styles = cardUseStyles();
  const { activeTab,
  } = useClientCommunicationContext();
  const { statisticsData } = useStatistics(activeTab, roleFromToken, selectedTimePeriod);

  const generateTimePeriod = () => {
    switch (selectedTimePeriod) {
      case "all_time":
        return "All time";
      case "today":
        return "Today";
      case "this_week":
        return "This week";
      case "last_week":
        return "Last week";
      case "this_month":
        return "This month";
      case "last_month":
        return "Last month";
      default:
        break;
    }
  };

  if (!(activeTab === "allEmails" && roleFromToken === "admin" && statisticsData?.length > 0)) {
    return null;
  }

  const timePeriod = (
    <div className={styles.timePeriod}>
      <span>Time period: {generateTimePeriod()}</span>
    </div>
  );

  const cards = statisticsData.map((card:any) => <StatisticCard cardInfo={card} key={card.id} />);

  return (
    <div className={styles.statisticsWrapper}>
      {timePeriod}
      {statisticsData.length > 5 ? (
        <CustomSlider>{cards}</CustomSlider>
      ) : (
        <div style={{ marginTop: "24px" }}>
          {timePeriod}
          <Flex style={{ gap: "16px", marginTop: "8px" }}>{cards}</Flex>
        </div>
      )}
    </div>
  );
};

export default StatisticsContent;
