import React from "react";
import { useMutation } from "react-query";
import { LocaleStorageTokenList, LocalStorage } from '../../../../helpers/Storage';
import Flex from "../../../shared/components/general/Flex/Flex";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import highFiveHandsBro from "assets/icons/highFiveHandsBro.svg";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";
import { sendEmail } from "../../../../services/clientCommunication";
import Spinner from "../../../shared/components/general/Spinner/Spinner";
import { useStyles } from "./SendModal.styles";

const SendEmailForOtherUsersModal = ({
  onClose,
  emailId,
}: {
  emailId: number;
  onClose: () => void;
}) => {
  const styles = useStyles();
  const {
    googleAuthCode,
    setEmailStatus,
    setSendModalForOtherUser,
    setUpdatedStatus,
  } = useClientCommunicationContext();

  const payloadSendEmail = LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken)
    ? {
      access_token: LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken),
    }
    : { code: googleAuthCode };

  const { mutate: mutateSendEmail, isLoading } = useMutation(
    () => sendEmail(emailId, payloadSendEmail),
    {
      onSuccess: (response: any) => {
        LocalStorage.set(LocaleStorageTokenList.googleAuthAccessToken, response.data.access_token);
        setUpdatedStatus(response.data.status);
        setEmailStatus({
          [emailId]: "send",
        });
        setSendModalForOtherUser(false);
      },
      onError: () => {
        LocalStorage.remove(LocaleStorageTokenList.googleAuthAccessToken);
      },
    },
  );

  return (
    <Flex direction="column" alignItems="center" style={{ width: "617px" }}>
      <h2 className={styles.title}>
        You're about to send another <br /> FSM's email
      </h2>
      <p
        style={{
          color: "#0F314D",
          textAlign: "center",
          fontSize: "18px",
          fontWeight: "400",
          lineHeight: "27px",
        }}
      >
        The email will be sent from your Google account. The <br />
        owner's FSM's email will be CC'ed. Please check the email <br />
        text before sending.
      </p>
      <ImageLoader
        src={highFiveHandsBro}
        alt="high five hands bro"
        style={{ width: "300px", height: "300px", textAlign: "center" }}
      />
      <Flex
        justifyContent="flex-end"
        style={{ gap: "16px", width: "100%", marginTop: "16px" }}
      >
        <Button variant={ButtonVariants.Secondary} handleClick={onClose}>
          Cancel
        </Button>
        <Button
          handleClick={() => {
            mutateSendEmail();
          }}
          style={{
            textAlign: "left",
            width: "107px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Spinner
              white={true}
              style={{
                width: "16px",
                margin: "0",
                left: "50%",
                position: "initial",
              }}
            />
          ) : (
            <span>Send</span>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};
export default SendEmailForOtherUsersModal;
