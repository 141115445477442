import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ROUTES from 'constants/routes';
import useForm from 'hooks/useForm';
import { validator } from 'helpers/validator';
import { setUserSignUpMessage } from '../../../store/userInvitation/userInvitationSlice';
import AuthenticationLayout from '../../../layout/Authentication/AuthenticationLayout';
import PasswordAdornment from '../../shared/components/general/Input/PasswordAdornment/PasswordAdornment';
import ErrorMessage from '../../shared/components/general/ErrorMessage/ErrorMessage';
import Spinner from '../../shared/components/general/Spinner/Spinner';
import { getInvitedUserEmail, signUpUser } from '../../../store/userInvitation/actions';
import { useStylesTextField } from '../authShared.styles';
import { TextField } from '@mui/material';

import './styles.scss';

const SignUp = () => {
  const dispatch = useDispatch();
  const styles = useStylesTextField();
  const navigate = useNavigate();
  const location = useLocation();
  const userInvitationToken = location.pathname.split('/')[2];

  const {
    userInvitation: {
      invitedUserEmail,
      userSignUpInfo: { isSent: signUpIsSent, message: signUpmessage },
      invitedUserEmailInfo: { emailGet, message: emailGetMessage },
      disableBtn,
      loading,
    },
  } = useSelector((state:any) => state);

  const [showPassword, setShowPassword] = useState(false);
  const [requiredFields, setRequiredFields] = useState({
    firstName: false,
    lastName: false,
    password: false,
    confirmPassword: false,
  });

  useEffect(() => {
    dispatch<any>(getInvitedUserEmail(userInvitationToken));
    dispatch(setUserSignUpMessage(''));
    // eslint-disable-next-line
    }, []);

  const submitForm = () => {};
  const {
    handleInputChange,
    handleBlur,
    state = {},
    errors,
  } = useForm({
    validator,
    callback: submitForm,
  });

  const isValidForm =
        Object.values(errors).filter(error => typeof error !== 'undefined').length === 0;

  const handleFieldBlur = (event:any, name:any) => {
    handleBlur(event);
    const isStateName = state[name];
    if (!isStateName) {
      setRequiredFields({
        ...requiredFields,
        [name]: true,
      });
    }
  };

  const handleFieldFocus = (name:any) => {
    setRequiredFields({
      ...requiredFields,
      [name]: false,
    });
    dispatch(setUserSignUpMessage(''));
  };

  useEffect(() => {
    if (signUpIsSent) {
      navigate(ROUTES.SIGNIN);
    }
    // eslint-disable-next-line
    }, [signUpIsSent]);

  const generateContent = () => {
    const isGetEmailMessage = !emailGet && emailGetMessage;

    if (isGetEmailMessage) {
      return (
        <>
          <AuthenticationLayout>
            <div className="expired-link">
              <h2>{emailGetMessage}</h2>
              <p>Please contact the account admin</p>
            </div>
          </AuthenticationLayout>
        </>
      );
    } else if (invitedUserEmail) {
      return (
        <>
          <AuthenticationLayout title="Sign up">
            <TextField
              className={styles.root}
              label={requiredFields.firstName ? 'Required' : 'First Name'}
              id="outlined-error"
              name="firstName"
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={handleInputChange}
              onBlur={event => handleFieldBlur(event, 'firstName')}
              error={requiredFields.firstName}
              type="text"
              onFocus={() => handleFieldFocus('firstName')}
            />
            <TextField
              className={styles.root}
              label={requiredFields.lastName ? 'Required' : 'Last Name'}
              name="lastName"
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={handleInputChange}
              onBlur={event => handleFieldBlur(event, 'lastName')}
              onFocus={() => handleFieldFocus('lastName')}
              error={requiredFields.lastName}
              type="text"
            />
            <TextField
              value={invitedUserEmail}
              className={`${styles.root}`}
              name="email"
              variant="outlined"
              margin="normal"
              id="invited-user-email"
              fullWidth
              disabled
              style={{ marginBottom: 0 }}
            />
            <div className="password-container pass ">
              <TextField
                className={styles.root}
                name="password"
                label={requiredFields.password ? 'Required' : 'Password'}
                helperText={errors?.password}
                defaultValue={state?.password}
                variant="outlined"
                margin="normal"
                fullWidth
                onFocus={() => {
                  handleFieldFocus('password');
                }}
                onChange={handleInputChange}
                onBlur={event => handleFieldBlur(event, 'password')}
                error={requiredFields.password}
                type={showPassword ? 'text' : 'password'}
              />
              <div className="sign-up-password-eye-icon">
                <PasswordAdornment
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </div>
            </div>
            <div className="password-container">
              <TextField
                className={styles.root}
                label={
                  requiredFields.confirmPassword ? 'Required' : 'Confirm Password'
                }
                error={requiredFields.confirmPassword}
                helperText={errors?.confirmPassword}
                name="confirmPassword"
                defaultValue={state?.confirmPassword}
                variant="outlined"
                margin="normal"
                fullWidth
                onFocus={() => {
                  handleFieldFocus('confirmPassword');
                }}
                onChange={handleInputChange}
                onBlur={event => handleFieldBlur(event, 'confirmPassword')}
                type={showPassword ? 'text' : 'password'}
              />
              <div className="sign-up-password-eye-icon">
                <PasswordAdornment
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </div>
            </div>

            {state.password &&
                            state.confirmPassword &&
                            state.password !== state.confirmPassword && (
              <ErrorMessage width={'95%'} text="Passwords do not match." />
            )}
            {!signUpIsSent && signUpmessage && (
              <ErrorMessage
                width={'95%'}
                text="User with this email already exists"
              />
            )}
            <button
              className="auth-button confirm"
              disabled={
                !isValidForm ||
                                !state.firstName ||
                                !state.lastName ||
                                !state?.confirmPassword ||
                                !state?.password ||
                                state?.password !== state?.confirmPassword ||
                                disableBtn
              }
              onClick={() =>
                dispatch<any>(
                  signUpUser({
                    email: invitedUserEmail,
                    first_name: state?.firstName,
                    last_name: state?.lastName,
                    password: state?.password,
                    password2: state?.confirmPassword,
                    token: userInvitationToken,
                  }),
                )
              }
            >
                            Confirm
            </button>
          </AuthenticationLayout>
        </>
      );
    }
    return null;
  };

  const generateSignUpContent = () => {
    if (loading) {
      return (
        <div className="container-main-auth">
          <Spinner />
        </div>
      );
    }
    return generateContent();
  };

  return generateSignUpContent();
};

export default SignUp;
