import { useEffect, useState } from 'react';
import Flex from "../../../shared/components/general/Flex/Flex";
import BasicModal from "../../../shared/components/general/BasicModal/BasicModal";
import { Button } from '../../../shared/components/general/Button/Button';
import { ButtonVariants } from '../../../shared/components/general/Button/Button.types';
import InviteOrEdit from "../InviteOrEdit/InviteOrEdit";

const InviteNewMembers = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    document.body.style.overflowY = 'auto';

    return () => {
      document.body.style.overflowY = 'unset';
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Flex
      justifyContent="space-between"
      style={{ marginTop: "32px", maxWidth: "1288px" }}
    >
      <h1>Team Management</h1>
      <Button
        variant={ButtonVariants.Primary}
        handleClick={() => setIsOpen(true)}
      >
        Invite a member
      </Button>

      <BasicModal
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        style={{ padding: "0" }}
      >
        <InviteOrEdit handleCloseModal={handleCloseModal} type="invite" />
      </BasicModal>
    </Flex>
  );
};
export default InviteNewMembers;
