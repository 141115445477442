import { createUseStyles } from "react-jss";
import { AppTheme } from "./types/styles.types";

export const globalUseStyles = createUseStyles((theme: AppTheme) => ({
  "@global": {
    h1: {
      fontWeight: "700",
      fontSize: "32px",
      lineHeight: "48px",
      color: theme.primaryColor,
      margin: 0,
    },
    hr: {
      height: "1px",
      color: `${theme.secondaryColor} !important`,
    },
    subTitle: {},
  },
}));
