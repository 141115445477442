import { useEffect, useState, useCallback } from "react";
import { createUseStyles } from "react-jss";
import arrowTop from "assets/icons/arrowTop.svg";


const scrollToTopUseStyles = createUseStyles(() => ({
  arrowTopIcon: {
    position: "fixed",
    bottom: "128px",
    right: "128px",
    border: "none",
    background: "#FFFFFF",
    boxShadow: "0px 0px 32px rgba(68, 68, 68, 0.25)",
    height: "auto",
    padding: "12px",
    borderRadius: "100%",
    cursor: "pointer",
    "&>img": {
      display: "block",
    },
  },
}));

export const useScrollTop = () => {
  const styles = scrollToTopUseStyles();
  const [showScrollBtn, setShowScrollBtn] = useState<boolean>(false);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleScrollButtonVisiblity = useCallback(() => {
    setShowScrollBtn((window.scrollY > 300));
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollButtonVisiblity);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisiblity);
    };
  }, [handleScrollButtonVisiblity]);

  return (
    showScrollBtn && (<button onClick={handleScrollToTop} className={styles.arrowTopIcon}>
      <img src={arrowTop} alt="scroll to top" />
    </button>)
  );
};
