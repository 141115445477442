import { createUseStyles } from "react-jss";

export const spinnerUseStyles = createUseStyles(() => ({
  wrapper: {
    animation: "0.8s linear infinite spinner",
    "-webkit-animation": "0.8s linear infinite spinner",
    animationPlayState: "inherit",
    willChange: "transform",
    // position: "absolute",
  },
}));
