import React from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import InvitationAstronaut from "../../../../assets/icons/InvitationAstronaut.svg";
import { useInviteOrEditStyles } from "./InviteOrEdit.styles";

const InviteOrEditSuccess = ({ handleClose }: { handleClose: any }) => {
  const styles = useInviteOrEditStyles();

  return (
    <Flex
      direction="column"
      alignItems="center"
      style={{ padding: "32px 0", width: "617px" }}
    >
      <h1>Invitation sent.</h1>
      <p className={styles.successMessage}>
        The new team member will appear in Team Management <br /> when they
        create the account via the email invitation.
      </p>
      <ImageLoader
        src={InvitationAstronaut}
        alt="Invitation Astronaut"
        style={{ height: "300px", width: "300px" }}
      />
      <Button
        variant={ButtonVariants.Primary}
        handleClick={handleClose}
        style={{ marginTop: "16px" }}
      >
        Done
      </Button>
    </Flex>
  );
};
export default InviteOrEditSuccess;
