import React from 'react';

import { ReactComponent as WarningIcon } from 'assets/icons/warningIcon.svg';

import { Box } from '@mui/material';
import { useStyles } from './ReSyncAritableCard.styles';
import { Button } from '../Button/Button';
import { ButtonVariants } from '../Button/Button.types';
import Flex from '../Flex/Flex';
import Spinner from '../Spinner/Spinner';


interface IReSyncAirtableCard {
    warnings: Array<{ title: string, info: string; }>;
    handleReSyncAirTable: () => void
    handleDetailsDuplication: () => void
    isLoadingReSync: boolean
}

const ReSyncAirtableCard: React.FC<IReSyncAirtableCard> = ({
  warnings,
  handleReSyncAirTable,
  handleDetailsDuplication,
  isLoadingReSync,
}) => {
  const styles = useStyles();


  return (
    <Box sx={{ position: 'relative' }} >
      {
        isLoadingReSync &&
              <Flex justifyContent="center" alignItems="center" style={{
                position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 , zIndex: 1001,
                filter: 'contrast(1)',
              }} >
                <Spinner style={{ margin: '40px' }} />
              </Flex>
      }
      <Box className={`${styles.warningCarsWrap} ${isLoadingReSync && styles.warningCarsWrapOpc}`}>

        <Box className={styles.warningText}>
          <WarningIcon />
                  Some features will be unavailable
        </Box>

        <Box className={styles.warningTextWrap}>
          {
            warnings
              .map(({ title, info }) => (
                <Box className={styles.warningItem}>
                  <strong>
                    {title}
                  </strong>
                  {
                    info.includes('See details') ?
                      <p>{info.replace('See details', '')}
                        <span
                          className={styles.reSyncText}
                          onClick={handleDetailsDuplication}
                        >
                            See details
                        </span>
                      </p>
                      :
                      <p>
                        {info}
                      </p>
                  }
                </Box>
              ))
          }

          <Button
            variant={ButtonVariants.Outlined}
            style={{ height: 'auto', backgroundColor: 'transparent', padding: '8px 24px' }}
            handleClick={handleReSyncAirTable}
          >
                      Resync from Airtable
          </Button>

        </Box>
      </Box>
    </Box>

  );
};

export default ReSyncAirtableCard;
