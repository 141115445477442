import { instance } from "../api/ApiRequest";
import { serverUrls } from "../variables";

export const getAllDuplications = async ({
  page = 1,
  search,
}: {
  page: any;
  search: any;
}) => {
  const queryParams = new URLSearchParams({
    page,
    search,
  });

  const response = await instance.get(
    `${serverUrls.campaigns}duplication-requests/?${queryParams}`,
  );

  return response;
};
