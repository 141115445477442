import { ImageLoader } from '../shared/components/general/ImageLoader/ImageLoader';
import pageNotFoundImg from "../../assets/icons/pageNotFoundImg.svg";
import Flex from "../../modules/shared/components/general/Flex/Flex";

const PageNotFound = () => (
  <Flex
    direction="column"
    alignItems="center"
    justifyContent="center"
    style={{ width: "100%", height: "95vh" }}
  >
    <ImageLoader
      src={pageNotFoundImg}
      alt="page not found"
      style={{ height: "500px", width: "500px" }}
    />
    <h1
      style={{
        fontWeight: "600",
        fontSize: "32px",
        lineHeight: "48px",
        marginTop: "38px",
      }}
    >
        Page not found.
    </h1>
    <p
      style={{
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "27px",
        color: "#444",
        marginTop: "8px",
        marginBottom: 0,
      }}
    >
        Apologies, but the page you are trying to access cannot be found.
    </p>
  </Flex>
);

export default PageNotFound;
