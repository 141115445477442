export const convertDateToCst = (date: any) => {
  let cstTime = new Date(date).toLocaleDateString("en-US", {
    timeZone: "America/Chicago",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  cstTime = cstTime.replace(/,/g, "");
  const parts = cstTime.split(" ");
  const day = parts[1];
  const month = parts[0];
  const year = parts[2];

  return `${day} ${month} ${year}`;
};

export const dateFormatter = (date: any) => {
  const utcTime = new Date(date);
  const localTime = new Date(utcTime.toString());
  
  const formatter = new Intl.DateTimeFormat("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const parts = formatter.formatToParts(localTime);
  return `${parts[2].value.trim()} ${parts[0].value.trim()} ${parts[4].value.trim()}, 
     ${parts[6].value.trim()}:${parts[8].value.trim()} ${parts[10].value.trim()}`;
};

export const dateFormatterFullMonth = (timestampStr: string) => {
  const dateObj = new Date(timestampStr);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = monthNames[dateObj.getUTCMonth()];
  const year = dateObj.getUTCFullYear();
  return `${month} ${day}, ${year}`;
};
