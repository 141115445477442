import React, { useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { Box, Card, CardContent, Divider, Grid, Typography, Tooltip, Stack } from '@mui/material';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-circle.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/moneyIcon.svg';
import { ReactComponent as MoneySpent } from 'assets/icons/moneySpent.svg';
import { ReactComponent as SuggestionDangerIcon } from 'assets/icons/suggestionDangerIcon.svg';

import winingCampaignIcon from 'assets/icons/winingCampainIcon.svg';
import losingCampaignIcon from 'assets/icons/losingCampainIcon.svg';

import Spinner from '../../../shared/components/general/Spinner/Spinner';
import {
  iconMappingSuggestionCard,
  IISuggestionCardSplit,
  SuggestionCardItemType,
  SuggestionValidationStsCode,
} from './constant';
import { servicesNames } from '../../../../constants/servicesNames.constants';
import { acceptSuggestionById, dismissSuggestionById, getPauseData } from '../../../../services/suggestions.services';
import PauseAdModal from '../PauseAdModal/PauseAdModal';
import DismissAdModal from '../PauseAdModal/DismissAdModal';
import { dateFormatterFullMonth } from '../../../../helpers/dateFormater';
import { ButtonVariants } from '../../../shared/components/general/Button/Button.types';
import { Button } from '../../../shared/components/general/Button/Button';

import { stylesSuggestionCardItem } from './SuggestionCardItem.styles';

interface ISuggestionCardItem {
    item: IISuggestionCardSplit,
    activeTab: string
}

const SuggestionCardItem: React.FC<ISuggestionCardItem> = ({ item, activeTab }) => {
  const suggestionRef = useRef<HTMLDivElement | null>(null);
  const styles = stylesSuggestionCardItem();
  const currentActionType = item.action_type.toLowerCase().trim();
  const queryClient = useQueryClient();
  const [showPauseAdModal, setShowPauseAdModal] = useState(false);
  const [showDismissModal, setShowDismissModal] = useState(false);
  const [loadingItems, setLoadingItems] = useState<any>({});
  const [dismissModalInfo , setDismissModalInfo] = useState<{ title:string, info:string}| null>(null);
  const [isAcceptedError , setAcceptedError] = useState(false);


  const { isFetching: isFetchingPauseAd, refetch } = useQuery(
    [servicesNames.getPauseAdData],
    () => getPauseData(item.id),
    {
      enabled: false,
    },
  );

  const { isLoading: isLoadingDismiss, mutate: dismissSuggesstionMutate } =
        useMutation(
          () =>
            dismissSuggestionById({
              id: item.id,
            }),
          {
            mutationKey: [servicesNames.dismissSuggestion],
            onSuccess: () => {
              queryClient.invalidateQueries(servicesNames.dismissSuggestion);
              setShowPauseAdModal(false);
              setShowDismissModal(false);
            },
            onError: () => {
              setAcceptedError(true);
              queryClient.invalidateQueries(servicesNames.dismissSuggestion);
            },
          },
        );

  const {
    isLoading: isLoadingAccept,
    isSuccess: isSuccessAccept,
    mutate: acceptSuggesstionMutate,
  } = useMutation(
    (id: number) =>
      acceptSuggestionById({
        id,
      }),
    {
      mutationKey: [servicesNames.acceptSuggestion],
      onSuccess: () => {
        queryClient.invalidateQueries(servicesNames.acceptSuggestion);
        setShowDismissModal(false);
        setShowPauseAdModal(false);
      },
      onError: () => {
        setAcceptedError(true);
        queryClient.invalidateQueries(servicesNames.acceptSuggestion);
      },
    },
  );

  const handleDismissClick = () => {
    if (suggestionRef.current) {
      suggestionRef.current?.classList.add('hidden');
    }
    if (isLoadingDismiss) {
      return null;
    } else {
      return dismissSuggesstionMutate(item.id);
    }
  };


  const handlePauseAd = () => {
    setLoadingItems({ ...loadingItems, [item.id]: true });

    refetch().then((
      res:any,
    ) => {
      if (res.error?.response.status === SuggestionValidationStsCode.CanNotBeAccepted) {
        setShowDismissModal(true);
        setDismissModalInfo(res.error?.response.data);
        return;
      }

      if (res.error?.response.status === SuggestionValidationStsCode.NotFound) {
        setShowDismissModal(true);
        setDismissModalInfo(res.error?.response.data);
        return;
      }

      if (res.error?.response.status === SuggestionValidationStsCode.FBAtomicExc) {
        setAcceptedError(true);
        setLoadingItems({ ...loadingItems, [item.id]: false });
        return;
      }

      if (res.data?.status === SuggestionValidationStsCode.ValidSuggestion) {
        acceptSuggesstionMutate(item.id);
        return;
      }

      if (res.data?.status === SuggestionValidationStsCode.SurePauseAd) {
        setShowPauseAdModal(true);
        return;
      }
    });
  };

  const dailyBudgetCount = useMemo(() => {
    if (
      item.details?.losing_campaign?.old_daily_budget !== undefined &&
                item.details?.losing_campaign?.new_daily_budget !== undefined) {
      const countBdg = (item.details?.losing_campaign?.old_daily_budget - item.details?.losing_campaign?.new_daily_budget);

      return parseFloat(String(countBdg)).toFixed(2);
    }
    return 0;
  }
  , [item.details?.losing_campaign],
  );

  const convertCPLValue = (value: number | undefined) => {
    if (value !== undefined) {
      return `$${value}`;
    }

    return '-';
  };
  

  return (
    <>
      <PauseAdModal
        isPauseModalOpen={showPauseAdModal}
        setIsPauseModalOpen={setShowPauseAdModal}
        isLoading={isFetchingPauseAd}
        acceptSuggesstionMutate={() => acceptSuggesstionMutate(item.id)}
        dismissSuggesstionMutate={() => dismissSuggesstionMutate(item.id)}
        isLoadingAccept={isLoadingAccept}
        setLoadingItems={setLoadingItems}
        isLoadingDismiss={isLoadingDismiss}
      />
      <DismissAdModal
        title={dismissModalInfo?.title}
        info={dismissModalInfo?.info}
        isDismissModalOpen={showDismissModal}
        setIsDismissModalOpen={setShowDismissModal}
        isLoading={isLoadingDismiss}
        setLoadingItems={setLoadingItems}
        handleDissmisClick={handleDismissClick}
      />

      <Card
        className={`${styles.suggestionCardWrap} ${isAcceptedError && styles.suggestionCardWrapFailed}`}
        ref={suggestionRef}
        sx={{
          height: currentActionType !== SuggestionCardItemType.pauseAd ?
            '313px' : '233px',
        }}
      >

        <CardContent className={styles.statusInfoWrap}>
          <Typography
            variant="h5" component="div" fontSize={'20px'}
            className={ `
            ${styles.cardTitle}
            ${
    currentActionType === SuggestionCardItemType.splitTestEnd
      ? styles.cardTitleWdtSplit  : 
      currentActionType === SuggestionCardItemType.splitTestEnd && styles.cardTitleWdtPauseAd
    } `}>
            <img
              src={iconMappingSuggestionCard[currentActionType]}
              alt={item.action_type}
            />
            {item.action_type}
            {
              currentActionType === SuggestionCardItemType.splitTestEnd &&
                  <Tooltip
                    title={'The calculations might change based on the most recent updates'}
                    placement="left"
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        whiteSpace: 'pre-line',
                        backgroundColor: '#FFFFFF',
                        boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                        padding: '10px 12px',
                        borderRadius: '8px',
                        color: '#444444',
                        top: '-4px !important',
                        left: '20px !important',
                        fontSize:'16px',
                        width: '500px',
                        '&:before': {
                          content: '\'\'',
                          position: 'absolute',
                          left:'0px',
                          top: 'calc(50% - 7px)',
                          transform: 'translate(-50%) rotate(45deg)',
                          width: '12px',
                          height: '12px',
                          backgroundColor: '#ffffff',
                          borderRadius: '1px',
                        },
                      },
                    }}
                    components={{ Tooltip: Stack }}
                  >
                    <span className={`${styles.infoIconWrapper} ${styles.infoIconWrapperOver}`}>
                      <InfoIcon style={{ width:'20px', height: '20px' }} />
                    </span>
                  </Tooltip>
            }
            {
              isAcceptedError &&
              <Tooltip
                title={'Failed to make the change, please try again.'}
                placement="left"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    whiteSpace: 'pre-line',
                    backgroundColor: '#FFFFFF',
                    boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                    padding: '10px 12px',
                    borderRadius: '8px',
                    top: currentActionType === SuggestionCardItemType.splitTestBudget ?
                      '1px !important' : '-4px !important',
                    left: currentActionType === SuggestionCardItemType.splitTestBudget ?
                      '550px !important' : '20px !important',
                    fontSize:'16px',
                    width: '350px',
                    color: '#B3261E',
                    '&:before': {
                      content: '\'\'',
                      position: 'absolute',
                      left:'0px',
                      top: 'calc(50% - 7px)',
                      transform: 'translate(-50%) rotate(45deg)',
                      width: '12px',
                      height: '12px',
                      backgroundColor: '#ffffff',
                      borderRadius: '1px',
                    },
                  },
                }}
                components={{ Tooltip: Stack }}
              >
                <SuggestionDangerIcon style={{ marginLeft: '20px', cursor: 'pointer' }}  />
              </Tooltip>
            }

          </Typography>
          <Typography
            mt={'16px'}
            variant="body2"
            color="text.secondary"
            fontSize={'14px'}
          >
            <strong> Reason:</strong> {item.reason.description}
          </Typography>
          <Box display={'flex'} alignItems={'center'} mt={'16px'}>

            {
              currentActionType !== SuggestionCardItemType.pauseAd &&
                            <Typography variant="body2" color="text.secondary" fontSize={'14px'}>
                              <strong> Account daily budget: </strong> ${item.details.account_daily_budget}
                            </Typography>
            }

            {
              currentActionType === SuggestionCardItemType.splitTestEnd &&
                  <Divider sx={{ margin:"0 16px" }} orientation="vertical" variant="middle" flexItem />
            }
            {
              currentActionType === SuggestionCardItemType.splitTestEnd &&
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              className={styles.remainingIconWrapper}
                            >
                              {
                                Math.sign(item.details?.account_remaining_budget ?? 0)  === -1 ?
                                  <Tooltip
                                    title={'Account budget is overspent'}
                                    placement="bottom"
                                    PopperProps={{
                                      disablePortal: true,
                                      sx: {
                                        whiteSpace: 'pre-line',
                                        backgroundColor: '#FFFFFF',
                                        boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                                        padding: '10px 12px',
                                        borderRadius: '8px',
                                        color: '#444444',
                                        top: '10px !important',
                                        maxWidth: '600px',
                                        '&:before': {
                                          content: '\'\'',
                                          position: 'absolute',
                                          right: '90px',
                                          top: 'calc(50% - 26px)',
                                          transform: 'translate(-50%) rotate(45deg)',
                                          width: '12px',
                                          height: '12px',
                                          backgroundColor: '#ffffff',
                                          borderRadius: '1px',
                                        },
                                      },
                                    }}
                                    components={{ Tooltip: Stack }}
                                  >
                                    <MoneyIcon style={{ cursor:'pointer' }}  />
                                  </Tooltip>
                                  : <MoneySpent />
                              }
                              <strong> Remaining budget: </strong>
                              <span
                                className={`${Math.sign(item.details?.account_remaining_budget ?? 0)  === -1 
                                && styles.accountBudgetCount}`}
                              >
                                  ${item.details.account_remaining_budget}
                              </span>
                            </Typography>
            }
          </Box>


          <Box mt={'40px'}>
            <Grid container spacing={2}>
              {
                currentActionType === SuggestionCardItemType.pauseAd ?
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      mt={'16px'}
                      fontSize={'14px'}
                      fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            Ad name
                    </Typography>

                    <Tooltip
                      title={item.details?.ad?.name}
                      placement="bottom-start"
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          whiteSpace: 'pre-line',
                          backgroundColor: '#FFFFFF',
                          boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                          padding: '10px 12px',
                          borderRadius: '8px',
                          color: '#444444',
                          maxWidth: '600px',
                        },
                      }}
                      components={{ Tooltip: Stack }}
                    >
                      <Typography
                        mt={'16px'} variant="body2" color="text.secondary"
                        className={styles.campaignLinkWrap}
                      >
                        <Link
                          to={item.details?.ad?.link ?? ''} target="_blank"
                          className={styles.campaignLink}
                        >
                          {item.details?.ad?.name}
                        </Link>
                      </Typography>
                    </Tooltip>
                  </Grid>
                  :
                  <Grid item xs={5}>
                    <Typography
                      variant="body2"
                      fontSize={'14px'}
                      fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            Campaign Name
                    </Typography>

                    <Tooltip
                      title={item.details?.losing_campaign?.name}
                      placement="bottom-start"
                      PopperProps={{
                        sx: {
                          whiteSpace: 'pre-line',
                          backgroundColor: '#FFFFFF',
                          boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                          padding: '10px 12px',
                          borderRadius: '8px',
                          color: '#444444',
                          maxWidth: '600px',
                        },
                        disablePortal: true,
                      }}
                      components={{ Tooltip: Stack }}
                    >
                      <Typography
                        mt={'16px'} variant="body2" color="text.secondary"
                        className={styles.campaignLinkWrap}
                      >
                        <img src={losingCampaignIcon} alt="losingCampainIcon" />
                        <Link
                          to={item.details?.losing_campaign?.link ?? ''} target="_blank"
                          className={styles.campaignLink}
                        >
                          {item.details?.losing_campaign?.name}
                        </Link>
                      </Typography>
                    </Tooltip>

                    <Tooltip
                      title={item.details?.winning_campaign?.name}
                      placement="bottom-start"
                      PopperProps={{
                        sx: {
                          whiteSpace: 'pre-line',
                          backgroundColor: '#FFFFFF',
                          boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                          padding: '10px 12px',
                          borderRadius: '8px',
                          color: '#444444',
                          maxWidth: '600px',
                        },
                        disablePortal: true,
                      }}
                      components={{ Tooltip: Stack }}
                    >
                      <Typography
                        mt={'16px'} variant="body2" color="text.secondary"
                        className={styles.campaignLinkWrap}
                      >
                        <img src={winingCampaignIcon} alt="winingCampainIcon" />
                        <Link
                          to={item.details?.winning_campaign?.link ?? ''} target="_blank"
                          className={styles.campaignLink}
                        >
                          {item.details?.winning_campaign?.name}
                        </Link>
                      </Typography>
                    </Tooltip>


                  </Grid>
              }
              <Grid item xs={1} />

              {
                currentActionType !== SuggestionCardItemType.pauseAd ?
                  <Grid item xs={2}>
                    <Typography
                      variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            CPL (Lifetime)
                    </Typography>
                    <Typography
                      variant="body2" color="text.secondary" mt={'16px'}
                    >
                      {convertCPLValue(item.details?.losing_campaign?.lifetime_cpl)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={'16px'}>
                      {convertCPLValue(item.details?.winning_campaign?.lifetime_cpl)}
                    </Typography>
                  </Grid>
                  :
                  <Grid item xs={2}>
                    <Typography
                      variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            Leads (L7D)
                    </Typography>
                    <Typography
                      variant="body2" color="text.secondary" mt={'16px'}
                    >
                      {item.details?.ad?.last_7_days_leads !== undefined ? item.details?.ad?.last_7_days_leads : '-'}
                    </Typography>
                  </Grid>
              }
              {
                currentActionType !== SuggestionCardItemType.pauseAd ?
                  <Grid item xs={2}>
                    <Typography variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            CPL (L7D)
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={'16px'}>
                      {convertCPLValue(item.details?.losing_campaign?.last_7_days_cpl)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={'16px'}>
                      {convertCPLValue(item.details?.winning_campaign?.last_7_days_cpl)}
                    </Typography>
                  </Grid>
                  :
                  <Grid item xs={2}>
                    <Typography
                      variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            CPL (L7D)
                    </Typography>
                    <Typography
                      variant="body2" color="text.secondary" mt={'16px'}
                    >
                      {convertCPLValue(item.details?.ad?.last_7_average_cpl)}
                    </Typography>
                  </Grid>
              }
              {
                currentActionType !== SuggestionCardItemType.pauseAd ?
                  <Grid item xs={2}>
                    <Typography variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            CPL (L3D)
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={'16px'}>
                      {convertCPLValue(item.details?.losing_campaign?.last_3_days_cpl)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" mt={'16px'}>
                      {convertCPLValue(item.details?.winning_campaign?.last_3_days_cpl)}
                    </Typography>
                  </Grid>
                  :
                  <Grid item xs={2}>
                    <Typography
                      variant="body2" mt={'16px'} fontSize={'14px'} fontStyle={'italic'}
                      fontWeight={500}
                      className={styles.textColor}
                    >
                                            Impressions (L7D)
                    </Typography>
                    <Typography
                      variant="body2" color="text.secondary" mt={'16px'}
                    >
                      {item.details?.ad?.last_7_days_impressions !== undefined ? item.details?.ad?.last_7_days_impressions : '-'}
                    </Typography>
                  </Grid>
              }
            </Grid>
          </Box>

        </CardContent>

        <Divider orientation="vertical" variant="middle" flexItem className={styles.borderStyle} />

        <CardContent className={
          `${styles.cardButtonsWrap}
         ${item?.status === 'expired' &&
                    currentActionType === SuggestionCardItemType.pauseAd ?
      styles.pauseAdPdExpired : styles.adPdExpired}`}
        >

          <Box>
            {
              activeTab === 'changeHistory' &&
                            <>
                              <div className={styles.statusAcceptWrap}>
                                <span className={styles.statusAccept}>
                                 Accepted
                                </span>
                              </div>

                              {item?.created_at && (
                                <Tooltip
                                  title={`${dateFormatterFullMonth(item?.created_at)} | By ${item?.updated_by}`}
                                  placement="bottom"
                                  PopperProps={{
                                    sx: {
                                      whiteSpace: 'pre-line',
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                                      padding: '10px 12px',
                                      borderRadius: '8px',
                                      color: '#444444',
                                      maxWidth: '600px',
                                    },
                                    disablePortal: true,
                                  }}
                                  components={{ Tooltip: Stack }}
                                >
                                  <p className={styles.createdAt}>
                                    {dateFormatterFullMonth(item?.created_at)} | By {item?.updated_by}
                                  </p>
                                </Tooltip>
                              )}
                            </>
            }

            {
              activeTab === 'archived' &&
                            <>
                              <div className={styles.statusAcceptWrap}>
                                <span
                                  className={`${styles.statusAccept}
                                 ${item?.status === 'expired' ? styles.statusAcceptExpired : styles.statusAcceptDismissed}`}
                                >
                                  {item?.status === 'dismissed' && 'Dismissed'}
                                  {item?.status === 'expired' && 'Expired'}
                                </span>
                              </div>
                              {item.created_at && item?.status !== 'expired' ? (
                                <Tooltip
                                  title={`${dateFormatterFullMonth(item?.created_at)} | By ${item?.updated_by}`}
                                  placement="bottom"
                                  PopperProps={{
                                    sx: {
                                      whiteSpace: 'pre-line',
                                      backgroundColor: '#FFFFFF',
                                      boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                                      padding: '10px 12px',
                                      borderRadius: '8px',
                                      color: '#444444',
                                      maxWidth: '600px',
                                    },
                                    disablePortal: true,
                                  }}
                                  components={{ Tooltip: Stack }}
                                >
                                  <p className={styles.createdAt}>
                                    {dateFormatterFullMonth(item.created_at)} | By {item.updated_by}
                                  </p>
                                </Tooltip>

                              ) :
                                <p className={`${styles.createdAt} ${styles.expiredBy}`}>
                                  {dateFormatterFullMonth(item?.created_at ?? '')}
                                </p>
                              }
                            </>
            }
            {
              activeTab === 'suggestions' &&
                            <Box className={styles.buttonsBox}>
                              {
                                isSuccessAccept ?
                                  <div
                                    className={`${styles.statusAcceptWrap} ${isSuccessAccept ? styles.fadeInOut : ''
                                    }`}
                                  >
                                    <span className={styles.statusAccept}>
                                         Accepted
                                    </span>
                                  </div>
                                  :
                                  <Box className={
                                    currentActionType !== SuggestionCardItemType.pauseAd ?
                                      styles.cardButtons : styles.cardButtonsPaused
                                  }
                                  >
                                    <Button
                                      disabled={loadingItems[item.id]}
                                      className={styles.acceptBtn} handleClick={() => handlePauseAd()}
                                    >
                                      {loadingItems[item.id] ?
                                        <Spinner
                                          white
                                          style={{
                                            width: '16px',
                                            margin: '0',
                                            left: '50%',
                                            position: 'initial',
                                          }}
                                        />
                                        :
                                        <>Accept</>
                                      }

                                    </Button>
                                    <Button
                                      disabled={loadingItems[item.id]}
                                      variant={ButtonVariants.Outlined}
                                      className={styles.closeIcon}
                                      handleClick={() => handleDismissClick()}
                                    >
                                      <CloseIcon className={styles.icon} />
                                    </Button>
                                  </Box>
                              }
                            </Box>
            }
            {
              currentActionType !== SuggestionCardItemType.pauseAd ?
                <Box className={item?.status === 'expired' ? styles.dailyBdgExpired :
                  item.reason.description.length > 145 ? styles.dailyBdgBigReason : styles.dailyBdg
                }>
                  <Typography variant="body2" mt={'30px'} fontSize={'14px'} fontStyle={'italic'}
                    fontWeight={500}
                    className={styles.textColor}
                  >
                                        Daily budget reallocation
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mt={'16px'}
                    className={styles.infoIconBdg}>
                                        ${item.details?.losing_campaign?.old_daily_budget} {'-> '}
                                        ${item.details?.losing_campaign?.new_daily_budget}
                    <span className={styles.losingCamp}>
                      {' '} (-{dailyBudgetCount})
                    </span>

                    {
                      currentActionType !== SuggestionCardItemType.splitTestBudget &&
                                            <Tooltip
                                              title={activeTab === 'changeHistory' ? 'This campaign is paused' : 'This campaign will be paused'}
                                              placement="left"
                                              PopperProps={{
                                                disablePortal: true,
                                                sx: {
                                                  whiteSpace: 'pre-line',
                                                  backgroundColor: '#FFFFFF',
                                                  boxShadow: '0px 0px 20px 0px rgba(116, 116, 116, 0.15)',
                                                  padding: '10px 12px',
                                                  borderRadius: '8px',
                                                  color: '#444444',
                                                  right: '15px !important',
                                                  maxWidth: '600px',
                                                  '&:before': {
                                                    content: '\'\'',
                                                    position: 'absolute',
                                                    right: '-12px',
                                                    top: 'calc(50% - 7px)',
                                                    transform: 'translate(-50%) rotate(45deg)',
                                                    width: '12px',
                                                    height: '12px',
                                                    backgroundColor: '#ffffff',
                                                    borderRadius: '1px',
                                                  },
                                                },
                                              }}
                                              components={{ Tooltip: Stack }}
                                            >
                                              <span className={styles.infoIconWrapper}>
                                                <InfoIcon />
                                              </span>
                                            </Tooltip>
                    }

                  </Typography>
                  <Typography variant="body2" color="text.secondary" mt={'16px'} position={'relative'} >
                        ${item.details?.winning_campaign?.old_daily_budget} {'-> '}
                        ${item.details?.winning_campaign?.new_daily_budget}
                    <span className={styles.winingCamp}>
                      {' '} (+{dailyBudgetCount})
                    </span>
                    {
                      currentActionType === SuggestionCardItemType.splitTestEnd &&
                          <span
                            className={`${styles.accountBudgetCountDaily} 
                      ${Math.sign(item.details?.account_remaining_budget ?? 0) === -1 ? styles.losingCamp : styles.winingCamp}`}
                          >
                            {
                              Math.sign(item.details?.account_remaining_budget ?? 0) === -1 ?
                                <>
                                  ({item.details?.account_remaining_budget})
                                </>
                                :
                                <>
                                  (+{item.details?.account_remaining_budget})
                                </>
                            }
                          </span>
                    }
                  </Typography>
                </Box>
                :
                <Box
                  className={`${item?.status === 'expired' ? styles.statusPausedExp : styles.statusPaused}`}>
                  <Typography variant="body2" fontSize={'14px'} fontStyle={'italic'}
                    fontWeight={500}
                    className={styles.textColor}
                  >
                          Status
                  </Typography>
                  <Typography variant="body2" color="text.secondary" mt={'16px'}>
                          Running {'-> '} Paused
                  </Typography>
                </Box>
            }
          </Box>

        </CardContent>
      </Card>
    </>

  );
};

export default SuggestionCardItem;
