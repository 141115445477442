import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useForm from 'hooks/useForm';
import { validator } from 'helpers/validator';
import ROUTES from 'constants/routes';
import { errorTexts } from 'constants/errorTexts';
import { getSignInInfo, loginAction } from 'store/authentication/actions';
import { setLoginFailureStatus } from '../../../store/authentication/authenticationSlice';
import AuthenticationLayout from '../../../layout/Authentication/AuthenticationLayout';
import PasswordAdornment from '../../shared/components/general/Input/PasswordAdornment/PasswordAdornment';
import Loader from '../../shared/components/general/Loader/Loader';
import ErrorMessage from '../../shared/components/general/ErrorMessage/ErrorMessage';
import { useStyles, useStylesTextField } from '../authShared.styles';
import { TextField } from '@mui/material';

import '../styles.scss';
import './styles.scss';

const SignIn = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = useStylesTextField();
  const [passwordValue, setPasswordValue] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userNotExistError, setUserNotExistError] = useState(false);
  const {
    authentication: { loginLoading, loginFailureStatus, disableBtn },
  } = useSelector((state:any) => state);

  const submitForm = () => {};
  const {
    handleInputChange,
    handleSubmit,
    handleBlur,
    state = {},
    errors,
  } = useForm({
    callback: submitForm,
    validator,
  });

  const isValidForm =
        Object.values(errors).filter(error => typeof error !== 'undefined').length === 0;

  useEffect(() => {
    if (loginFailureStatus === 401) {
      setUserNotExistError(true);
    }
  }, [loginFailureStatus]);

  useEffect(() => {
    dispatch<any>(getSignInInfo({}));
    setUserNotExistError(false);
    dispatch(setLoginFailureStatus(''));
    // eslint-disable-next-line
    }, [state.email, passwordValue]);

  return (
    <AuthenticationLayout title="Sign in">
      <form className={classes.form} onSubmit={handleSubmit}>
        <TextField
          label="Email"
          defaultValue={state?.email}
          className={`${styles.root}`}
          onChange={handleInputChange}
          onBlur={handleBlur}
          name="email"
          variant="outlined"
          margin="normal"
          id="outlined-basic"
          fullWidth
          error={errors.email}
          helperText={errors.email}
          style={{ marginBottom: 0 }}
        />
        <div className="password-container">
          <TextField
            className={styles.root}
            label="Password"
            name="password"
            defaultValue={passwordValue}
            variant="outlined"
            margin="normal"
            fullWidth
            onChange={event => setPasswordValue(event.target.value)}
            onBlur={handleBlur}
            type={showPassword ? 'text' : 'password'}
            style={{ marginTop: '24px', marginBottom: '0' }}
          />
          <div className="eye-icon">
            <PasswordAdornment
              showPassword={showPassword}
              setShowPassword={setShowPassword}
            />
          </div>
        </div>
        <div
          className="forgot-pass"
          style={{
            justifyContent: userNotExistError ? 'space-between' : 'flex-end',
          }}
        >
          {userNotExistError && (
            <ErrorMessage text={errorTexts.userNotFound} margin="4px 0 0 8px" />
          )}
          <Link
            to={ROUTES.REQUEST_RESET_PASSWORD}
            style={{ color: '#49454F', fontSize: '11px' }}
          >
                        Forgot password?
          </Link>
        </div>
        <button
          className="auth-button"
          disabled={!isValidForm || !state?.email || !passwordValue || disableBtn}
          onClick={() => {
            dispatch<any>(loginAction(state?.email, passwordValue));
          }}
        >
          {loginLoading ? <Loader /> : 'Sign in'}
        </button>
      </form>
    </AuthenticationLayout>
  );
};

export default SignIn;
