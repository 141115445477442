import { createUseStyles } from "react-jss";

export const customSliderUseStyles = createUseStyles(() => ({
  arrowButtons: {
    position: "absolute",
    padding: "0",
    border: "none",
    height: "auto",
    top: "calc(50% - 12px)",
    cursor: "pointer",
    color: "#959595",
    "&>svg": {
      display: "block",
    },
  },
  arrowLeft: {
    left: "-32px",
  },
  arrowRight: {
    right: "-18px",
  },
}));
