import { createUseStyles } from "react-jss";

export const allEmailsUseStyles = createUseStyles(() => ({
  table: {
    width: "100%",
    borderCollapse: "collapse",
    background: "#FFFFFF",
    marginTop: "16px",
  },
  tableHeaderItem: {
    fontSize: "18px",
    fontWeight: "500",
    lineHeight: "27px",
    color: "#000000",
    padding: "24px",
    textAlign: "start",
  },
  tableBodyItem: {
    padding: "24px",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    color: "#444444",
  },
  tableHeaderRow: {
    borderBottom: "1px solid #D9D9D9",
  },
  tableBodyRow: {
    borderBottom: "1px solid #D9D9D9",
    "&:hover": {
      background: "#FAFAFA",
    },
    "&:hover $emailHoverIcon": {
      display: "block",
      cursor: "pointer",
    },
  },
  tableEmail: {
    maxWidth: "203px",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  emailHoverIcon: {
    display: "none",
  },
  reasonTooltip: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
    maxWidth: "272px",
    width: "max-content",
    display: "none",
    position: "absolute",
    top: "82px",
    left: "0",
    transform: "translateY(-50%)",
    background: "#fff",
    borderRadius: "8px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#444",
    padding: "12px 16px",
    boxShadow: "0px 0px 20px 0px rgba(116, 116, 116, 0.15)",
    zIndex: "999",
    "&:before": {
      content: "''",
      position: "absolute",
      top: "-10px",
      left: "32px",
      transform: "translate(-50%) rotate(45deg)",
      width: "20px",
      height: "20px",
      backgroundColor: "#fff",
      borderRadius: "1px",
    },
  },
  reasonContainer: {
    maxWidth: "203px",
    width: "100%",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  reasonTooltipWrapper: {
    position: "relative",
    "&:hover $reasonTooltip": {
      display: "block",
    },
  },
}));
