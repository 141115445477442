import { CSSProperties, useEffect, useRef } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import dateRangeIcon from 'assets/icons/dateRangeIcon.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { IDateRange } from './DateRangeProps.types';
import { dateRangeSelectUseStyles } from './DateRangeSelect.styles';

const DateRangeSelect = ({
  selectionRange,
  setSelectionRange,
  style,
  dateSelectValue,
  setDateSelectValue,
}: {
    selectionRange?: IDateRange;
    setSelectionRange: (range: IDateRange) => void;
    style?: CSSProperties;
    dateSelectValue: string;
    setDateSelectValue: (value: string) => void
}) => {
  const styles = dateRangeSelectUseStyles();
  const dateSelectRef = useRef<HTMLDivElement>(null);
  const maxDate = new Date();
  const minDate = new Date(2024, 0, 1);

  useEffect(() => {
    if (
      moment(selectionRange?.startDate).format('MM-DD-YYYY') ===
            moment(minDate).format('MM-DD-YYYY') &&
            moment(selectionRange?.endDate).format('MM-DD-YYYY') ===
            moment(maxDate).format('MM-DD-YYYY')
    ) {
      setDateSelectValue('All time');
    } else {
      if (!selectionRange?.endDate) {
        setDateSelectValue(
          `${selectionRange?.startDate?.toLocaleDateString()} - ${selectionRange?.startDate?.toLocaleDateString()}`,
        );
      } else {
        setDateSelectValue(
          `${selectionRange?.startDate?.toLocaleDateString()} - ${selectionRange?.endDate?.toLocaleDateString()}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionRange]);


  const handleDateChange = (dates: any) => {
    const [start, end] = dates;

    setSelectionRange({
      startDate: start,
      endDate: end,
    });
  };

  return (
    <div className={styles.selectWrapper} ref={dateSelectRef}>
      <input
        value={dateSelectValue}
        style={style}
      />
      <DatePicker
        showMonthDropdown
        showYearDropdown
        onChange={handleDateChange}
        startDate={selectionRange?.startDate}
        endDate={selectionRange?.endDate}
        selectsRange
        inline
        maxDate={maxDate}
        yearDropdownItemNumber={5}
      />
      <div className={styles.dateRangeIcon}>
        <img src={dateRangeIcon} alt="" />
      </div>
    </div>
  );
};

export default DateRangeSelect;
