const ROUTES: {[key:string]:string} = {
  SIGNIN: "/",
  DUPLICATION: "/duplication",
  ALL_DUPLICATIONS: "/all-duplications",
  SIGNUP: "/signup/:token",
  RESET_PASSWORD: "/reset-password",
  REQUEST_RESET_PASSWORD: "/request-reset-password",
  RESET_PASSWORD_CONFIRM: "/reset-password-confirm/:uid/:token",
  DUPLICATION_REQUEST: "/duplication-request/:request_id/preview",
  INVITATION: "/invitation",
  REPORTING_UPDATE: "/update",
  REPORTING: "/reporting",
  BRAND_REPORTS: "/brand-reports",
  TEAM_MANAGEMENT: "/team-management",
  PAGE_NOT_FOUND: "/404",
  SUGGESTIONS: "/suggestions",
  CLIENT_COMUNICATION: "/client-communication",
};

export default ROUTES;
