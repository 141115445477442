export const SettingsIcon = () => (
  <svg
    width="4"
    height="20"
    viewBox="0 0 4 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2.00002" cy="2.00002" r="2.00002" fill="#A2A2A2" />
    <circle cx="2.00002" cy="10" r="2.00002" fill="#A2A2A2" />
    <circle cx="2.00002" cy="18" r="2.00002" fill="#A2A2A2" />
  </svg>
);
