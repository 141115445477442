import React from "react";
import { Typography } from "@mui/material";

const EmailIsSent: React.FC = () => (
  <>
    <Typography
      component="h1"
      variant="h3"
      fontSize="32px"
      style={{
        marginTop: "24px",
        color: "#444444",
        fontWeight: "500",
        fontSize: "24px",
      }}
    >
            Email is sent!
    </Typography>
    <p className="description" style={{ margin: "16px 30px" }}>
            You will receive an email with a link to reset your password.
    </p>
  </>
);

export default EmailIsSent;
