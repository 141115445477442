
import AuthenticationLayout from "../../../layout/Authentication/AuthenticationLayout";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PasswordAdornment from "../../shared/components/general/Input/PasswordAdornment/PasswordAdornment";
import ErrorMessage from "../../shared/components/general/ErrorMessage/ErrorMessage";

import { useStyles, useStylesTextField } from '../authShared.styles';
import useForm from "hooks/useForm";
import { TextField } from '@mui/material';

import { validator } from "helpers/validator";
import {
  confirmResetPassword,
  checkIsResetLinkValid,
} from "store/passwordReset/actions";
import { setConfirmResetPassword } from "../../../store/passwordReset/passwordResetSlice";
import ROUTES from "constants/routes";
import Spinner from "../../shared/components/general/Spinner/Spinner";
import redirectResetPass from "assets/icons/redirectResetPass.svg";

import "./styles.scss";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const styles = useStylesTextField();
  const location = useLocation();
  const navigate = useNavigate();

  const uid = location.pathname.split("/")[2];
  const token = location.pathname.split("/")[3];

  const {
    passwordReset: {
      confirmPasswordReset: { sent, message },
      disableEnterBtn,
      loading,
    },
  } = useSelector((state:any) => state);

  const initialState = {
    email: "",
    password: "",
    confirmPassword: "",
    showPassword: "",
  };
  const uidAndToken = {
    uid,
    token,
  };
  const [showPassword, setShowPassword] = useState();

  const submitForm = () => {};
  const { handleInputChange, handleSubmit, handleBlur, state, errors } =
    useForm({
      initialState,
      callback:submitForm,
      validator,
    });

  const isValidForm =
    Object.values(errors).filter(error => typeof error !== "undefined")
      .length === 0;

  useEffect(() => {
    dispatch(setConfirmResetPassword({ sent: null, message: "" }));
    dispatch<any>(checkIsResetLinkValid(uidAndToken));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    sent && navigate(ROUTES.SIGNIN);
    // eslint-disable-next-line
  }, [sent]);

  const generateContent = () => (
    <>
      {!sent && message ? (
        <AuthenticationLayout>
          <h2 className="title">{message}</h2>
          <div className="redirect-reset-password">
            <a href={ROUTES.REQUEST_RESET_PASSWORD}>
                Redirect to reset password
              <span>
                <img
                  src={redirectResetPass}
                  alt="redirect to reset password"
                />
              </span>
            </a>
          </div>
        </AuthenticationLayout>
      ) : (
        <AuthenticationLayout title="Password reset">
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextField
              className={styles.root}
              name="password"
              label="Password"
              helperText={errors?.password}
              defaultValue={state?.password}
              variant="outlined"
              margin="normal"
              fullWidth
              onChange={handleInputChange}
              onBlur={handleBlur}
              error={errors.password}
              type={showPassword ? "text" : "password"}
            />
            <div className="reset-password-eye-icon password">
              <PasswordAdornment
                showPassword={showPassword}
                setShowPassword={setShowPassword}
              />
            </div>
            <div className="password-container">
              <TextField
                className={styles.root}
                label="Confirm Password"
                error={errors.confirmPassword}
                helperText={errors?.confirmPassword}
                name="confirmPassword"
                defaultValue={state?.confirmPassword}
                variant="outlined"
                margin="normal"
                fullWidth
                onChange={handleInputChange}
                onBlur={handleBlur}
                type={showPassword ? "text" : "password"}
                style={{ marginBottom: 0 }}
              />
              <div className="reset-password-eye-icon confirm-password">
                <PasswordAdornment
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </div>
            </div>
            {state.password &&
                state.confirmPassword &&
                state.password !== state.confirmPassword && (
              <ErrorMessage
                text="Passwords do not match."
                margin="4px 0 0 8px"
              />
            )}
            <button
              className="auth-button reset-password-button"
              disabled={
                !isValidForm ||
                  !state?.confirmPassword ||
                  !state?.password ||
                  state?.password !== state?.confirmPassword ||
                  disableEnterBtn
              }
              onClick={() =>
                dispatch<any>(
                  confirmResetPassword({
                    password: state?.password,
                    password2: state?.confirmPassword,
                    uid,
                    token,
                  }),
                )
              }
            >
                Confirm
            </button>
          </form>
        </AuthenticationLayout>
      )}
    </>
  );

  return loading ? (
    <div className="container-main-auth">
      <Spinner />
    </div>
  ) : (
    generateContent()
  );
};

export default ResetPassword;
