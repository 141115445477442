import React, { useEffect, useState } from 'react';
import Flex from '../../../shared/components/general/Flex/Flex';
import Switch from '../../../shared/components/general/Switch/Switch';
import { Button } from '../../../shared/components/general/Button/Button';
import { ButtonVariants } from '../../../shared/components/general/Button/Button.types';
import attentionBlue from '../../../../assets/icons/attentionBlue.svg';
import {
  InputField,
  InputFieldVariant,
} from '../../../shared/components/general/InputField/InputField';
import { useInviteOrEditStyles } from './InviteOrEdit.styles';
import { RegexConstants } from '../../../../constants/regex.constants';
import InviteOrEditSuccess from './InviteOrEditSuccess';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { QueryConstants } from 'constants/queryConstants';
import {
  getSingleUser,
  inviteNewUser,
  singleUserUpdate,
} from '../../../../services/users.services';
import Spinner from '../../../shared/components/general/Spinner/Spinner';

const InviteOrEdit = ({
  handleCloseModal,
  type,
  id,
}: {
    handleCloseModal: () => void;
    type: string;
    id?: number;
}) => {
  const queryClient = useQueryClient();
  const styles = useInviteOrEditStyles();
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [disableEditBtn, setDisableEditBtn] = useState<boolean>(true);
  const [disableInviteBtn, setDisableInviteBtn] = useState<boolean>(true);
  const [permissionsSwitch, setPermissionsSwitch] = useState<any>(['ad_management']);
  const [invitationIsSent, setInvitationIsSent] = useState(false);
  const [invitationFailedMessage, setInvitationFailedMessage] = useState<string>('');
  const [emailErrorText, setEmailErrorText] = useState<string>('');

  const [payload, setPayload] = useState<any>({
    access_details: {
      role: '',
      permissions: [],
    },
  });

  const handlePermissionSwitch = (type: string) => {
    if (permissionsSwitch?.includes(type)) {
      const modifiedPermissions = permissionsSwitch.filter((item: any) => item !== type);
      setPermissionsSwitch(modifiedPermissions);
    } else {
      permissionsSwitch
        ? setPermissionsSwitch([...permissionsSwitch, type])
        : setPermissionsSwitch([type]);
    }
  };

  const handleSwitch = () => {
    setIsSwitchOn(!isSwitchOn);
  };

  const handleEmailChange = (event: any) => {
    setEmailValue((event.target as HTMLTextAreaElement).value);

    let result = true;
    const emailRegex = RegexConstants.email;
    result = emailRegex.test(String(event.target.value).toLowerCase());

    setEmailError(result);
  };

  const { data, isLoading } = useQuery(
    [QueryConstants.getSingleUser, { id }],
    () => getSingleUser(id),
    {
      enabled: type === 'edit',
      onSuccess: () => {
        queryClient.invalidateQueries(QueryConstants.getTeamManagementUsers);
      },
    },
  );

  const { mutate: updateUser, isLoading: isLoadingEdit } = useMutation(
    () =>
      singleUserUpdate(
        id ?? 0,
        isSwitchOn
          ? {
            access_details: {
              role: 'admin',
            },
          }
          : {
            access_details: {
              role: 'employee',
              permissions: permissionsSwitch,
            },
          },
      ),
    {
      onSuccess: (response) => {
        handleCloseModal();
        queryClient.invalidateQueries(QueryConstants.getTeamManagementUsers);
        queryClient.invalidateQueries(QueryConstants.getSingleUser);
      },
    },
  );

  const { mutate: inviteUser, isLoading: isLoadingInvite } = useMutation(
    () =>
      inviteNewUser(
        emailValue,
        isSwitchOn
          ? {
            access_details: {
              role: 'admin',
            },
          }
          : {
            access_details: {
              role: 'employee',
              permissions: permissionsSwitch,
            },
          },
      ),
    {
      onSuccess: () => {
        setInvitationIsSent(true);
      },
      onError: (error: any) => {
        setInvitationFailedMessage(error?.response.data.email[0]);
        setInvitationIsSent(false);
      },
    },
  );

  useEffect(() => {
    isSwitchOn &&
            (payload.access_details.role === 'admin'
              ? setPermissionsSwitch(['ad_management'])
              : setPermissionsSwitch(payload.access_details.permissions));
    // eslint-disable-next-line
    }, [isSwitchOn, payload.access_details.role]);

  useEffect(() => {
    type === 'invite' && !isSwitchOn && setPermissionsSwitch(['ad_management']);

    // eslint-disable-next-line
    }, [isSwitchOn, type]);

  useEffect(() => {
    if (data) {
      data.data.role === 'admin' && setIsSwitchOn(true);

      setEmailValue(data.data.email);

      setPayload((prev: any) => ({
        access_details: {
          role: data.data.role,
          permissions: data.data.permissions || [],
        },
      }));

      if (data.data.role === 'employee') {
        setPermissionsSwitch(data.data.permissions);
      }

      let result = true;
      const emailRegex = RegexConstants.email;
      result = emailRegex.test(data.data.email);

      setEmailError(result);
    }
  }, [data]);

  useEffect(() => {
    if (payload.access_details.role === 'employee') {
      if (isSwitchOn) {
        setDisableEditBtn(false);
      } else if (!permissionsSwitch.length) {
        setDisableEditBtn(true);
      } else if (permissionsSwitch.length) {
        permissionsSwitch?.map((permission: any) => {
          if (
            !payload.access_details.permissions?.includes(permission) ||
                        payload.access_details.permissions.length !== permissionsSwitch.length
          ) {
            return setDisableEditBtn(false);
          } else {
            return setDisableEditBtn(true);
          }
        });
      }
    }

    if (payload.access_details.role === 'admin') {
      isSwitchOn
        ? setDisableEditBtn(true)
        : permissionsSwitch.length !== 0
          ? setDisableEditBtn(false)
          : setDisableEditBtn(true);
    }

    // eslint-disable-next-line
    }, [permissionsSwitch, isSwitchOn, isLoadingEdit]);

  useEffect(() => {
    if (emailError && (permissionsSwitch?.length !== 0 || isSwitchOn) && !isLoadingInvite) {
      setDisableInviteBtn(false);
    } else {
      setDisableInviteBtn(true);
    }
  }, [emailValue, permissionsSwitch, emailError, isSwitchOn, isLoadingInvite]);

  useEffect(() => {
    if (!invitationIsSent && invitationFailedMessage && emailError) {
      setEmailErrorText(invitationFailedMessage);
    } else {
      setEmailErrorText('Invalid email address.');
    }
  }, [invitationIsSent, invitationFailedMessage, emailError]);

  const [emailHasError, setEmailHasError] = useState<boolean>(false);

  useEffect(() => {
    if (
      (emailValue && !emailError) ||
            (!invitationIsSent && Boolean(invitationFailedMessage))
    ) {
      setEmailHasError(true);
    } else {
      setEmailHasError(false);
    }
  }, [emailValue, emailError, invitationIsSent, invitationFailedMessage]);

  return invitationIsSent ? (
    <InviteOrEditSuccess handleClose={handleCloseModal} />
  ) : (
    <Flex className={styles.wrapper} direction="column" style={{ width: '445px' }}>
      <h1 className={styles.title}>
        {type === 'edit' ? 'Edit permissions' : 'Invite a member'}
      </h1>
      {isLoading ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{ width: '100%', height: '100px' }}
        >
          <Spinner />
        </Flex>
      ) : (
        <>
          <Flex alignItems="center">
            <span className={styles.userRole} onClick={() => setIsSwitchOn(false)}>
                            Employee
            </span>
            <Switch
              isSwitchOn={isSwitchOn}
              handleToggle={handleSwitch}
              // handleTypeChange={() => setIsSwitchOn(!isSwitchOn)}
            />
            <span className={styles.userRole} onClick={() => setIsSwitchOn(true)}>
                            Admin
            </span>
          </Flex>
          <div className={styles.divider} />
          <InputField
            isReadonly={type === 'edit'}
            inputType="email"
            placeholder="Email"
            style={{ position: 'relative' }}
            label="Email"
            labelStyle={{
              fontSize: '18px',
              lineHeight: '1.5',
              marginBottom: '8px',
              fontWeight: '700',
            }}
            variant={InputFieldVariant.Input}
            hasError={emailHasError}
            handleChange={handleEmailChange}
            value={emailValue}
            errorText={emailErrorText}
            hintText={
              type !== 'edit'
                ? 'Only franchiseramp.com, gohacktech.com, aifrontdesk.com domains are allowed.'
                : null
            }
            handleFocus={() => setInvitationFailedMessage('')}
          />
          <div className={styles.divider} />
          <h2 className={styles.subTitle}>Permissions</h2>
          {isSwitchOn ? (
            <p className={styles.hintText}>
              <img
                width="15px"
                height="15px"
                style={{ marginRight: '6px' }}
                src={attentionBlue}
                alt="info"
              />
                            Admins have full access to all features.
            </p>
          ) : (
            <Flex
              flexWrap="wrap"
              style={{ width: '100%', gap: '16px' }}
              direction="column"
            >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <span className={styles.permissionItem}>Ad Management</span>
                <Switch
                  isSwitchOn={permissionsSwitch?.includes('ad_management')}
                  handleToggle={() => handlePermissionSwitch('ad_management')}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <span className={styles.permissionItem}>Reporting</span>
                <Switch
                  isSwitchOn={permissionsSwitch?.includes('reporting')}
                  handleToggle={() => handlePermissionSwitch('reporting')}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                style={{ width: '100%' }}
              >
                <span className={styles.permissionItem}>FSM</span>
                <Switch
                  isSwitchOn={permissionsSwitch?.includes('fsm')}
                  handleToggle={() => handlePermissionSwitch('fsm')}
                />
              </Flex>
            </Flex>
          )}
        </>
      )}
      <Flex justifyContent="flex-end" style={{ width: '100%', marginTop: '24px' }}>
        <Button
          variant={ButtonVariants.Secondary}
          style={{ marginRight: '16px' }}
          handleClick={() => handleCloseModal()}
        >
                    Cancel
        </Button>
        <Button
          style={{
            textAlign: 'left',
            width: '87px',
            margin: '0',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
          variant={ButtonVariants.Primary}
          handleClick={() => (type === 'edit' ? updateUser() : inviteUser())}
          disabled={type === 'edit' ? disableEditBtn || isLoadingEdit : disableInviteBtn}
        >
          {isLoadingInvite || isLoadingEdit ? (
            <Spinner
              white={true}
              style={{
                width: '16px',
                margin: '0',
                left: '50%',
                position: 'initial',
              }}
            />
          ) : (
            <span>{type === 'edit' ? 'Save' : 'Invite'}</span>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default InviteOrEdit;
