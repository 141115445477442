import { useSelector } from "react-redux";
import { SearchedCampaigns } from "../../../shared/components/general/Icons/SearchedCampaigns.icon";
import loadingCampaign from "assets/icons/loadingCampaign.svg";
import ChooseBrandAdAccounts from "../ChooseBrandAdAccounts/ChooseBrandAdAccounts";

import "./styles.scss";

const SearchedBrandAdAccountsById = () => {
  const {
    brandAdAccounts: { searchingCampaignLoading, brandAdAccounts , campaignIdSearchedValue },
  } = useSelector((state:any) => state);


  const generateCampaignContent = () => {
    if (searchingCampaignLoading && campaignIdSearchedValue) {
      return (
        <div className="spinner-wrapper">
          <img
            className="loading-campaign"
            src={loadingCampaign}
            alt="loadingCampaign"
          />
        </div>
      );
    }

    if (brandAdAccounts?.length) {
      return <ChooseBrandAdAccounts />;
    }

    return (
      <div className="searched-campaigns-container">
        <div className="no-duplicated-campaigns">
          <SearchedCampaigns />
          <h2>Campaign duplication has never been easier.</h2>
          <p>Let's start!</p>
        </div>
      </div>
    );
  };

  return generateCampaignContent();
};

export default SearchedBrandAdAccountsById;
