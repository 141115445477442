export const SortingIcon = () => (
  <svg
    width="8"
    height="12"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.640399 4.53169L3.6249 0.812687C3.8904 0.468688 4.3279 0.468688 4.5939 0.812687L7.5784 4.53169C7.8439 4.87569 7.7189 5.15669 7.2659 5.15669H0.953399C0.500399 5.15669 0.375399 4.87519 0.640899 4.53169H0.640399ZM7.5779 7.46919L4.5934 11.1882C4.3279 11.5322 3.8904 11.5322 3.6244 11.1882L0.639899 7.46919C0.374399 7.12519 0.499399 6.84419 0.952399 6.84419H7.2649C7.7179 6.84419 7.8429 7.12569 7.5774 7.46919H7.5779Z"
      fill="#444444"
    />
  </svg>
);
