import React from "react";
import { useSelector } from "react-redux";
import Checkbox from "modules/shared/components/general/Checkbox/Checkbox";
import Flex from "modules/shared/components/general/Flex/Flex";
import disableInfo from "assets/icons/Disable-info.svg";
import CustomTooltipForDuplication from "modules/shared/components/general/CustomTooltip/CustomTooltipForDuplication";
import { brandAdAccountUseStyles } from "./styles";

const DisabledBrandAdItem: React.FC<any> = ({
  accountName,
  status,
  fbAccountId,
}) => {
  const styles = brandAdAccountUseStyles();
  const {
    brandAdAccounts: { objectiveIsLead },
  }: any = useSelector(state => state);

  const generateDisabledTooltip = () => (
    <div className={styles.disableTooltipWrapper}>
      <img src={disableInfo} alt="disable Info" />
      {objectiveIsLead ? (
        <span className={styles.tooltipMessage}>
            The campaign can't be duplicated. Budget Allocation or/and Form ID
            is missing
        </span>
      ) : (
        <span className={styles.tooltipMessage}>
            The campaign can’t be duplicated. Budget <br />
            Allocation is missing.
        </span>
      )}
    </div>
  );

  const generateAccountName = () => {
    if (accountName.length > 44) {
      return <CustomTooltipForDuplication value={accountName} />;
    }
    return (
      <h3 className={styles.acountName} style={{ color: "#959595" }}>
        {accountName}
      </h3>
    );
  };

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      className={styles.disableCampaignInfoWrapper}
    >
      <Flex alignItems="center" style={{ gap: "23px" }}>
        <Checkbox
          handleCheck={() => {}}
          styles={{
            checkboxWidth: "4.67px",
            checkboxHeight: "2.67px",
            beforeWidth: "24px",
            beforeHeight: "24px",
            backgroundColor: "#F9F9F9",
            top: "4px",
            left: "5px",
          }}
          disabled={true}
        />
        <div
          style={{
            fontWeight: "400",
            fontSize: "18px",
            lineHeight: "1.5",
            color: "#959595",
          }}
        >
          {generateAccountName()}
          <div className={styles.acountId}>
            <span>ID:{fbAccountId}</span>
          </div>
        </div>
      </Flex>
      <Flex style={{ gap: "100px", padding: "2px 10px" }} alignItems="center">
        {generateDisabledTooltip()}
        <div style={{ width: "110px" }}>
          <div
            className={styles.status}
            style={{ color: "#959595", background: "#EAEAEA" }}
          >
            {status}
          </div>
        </div>
      </Flex>
    </Flex>
  );
};

export default DisabledBrandAdItem;
