import React, { useState } from 'react';
import { reportRequestUseStyles } from "modules/BrandsReporting/components/ReportRequest/ReportRequest.styles";
import Flex from "modules/shared/components/general/Flex/Flex";
import DateRangeSelect from './DateSelect/DateRangeSelect';

const DatePeriod = ({ isSwitchOn, setActiveTab, activeTab, setRanges, ranges, dayBefore }:
  { isSwitchOn: boolean, setActiveTab: any, activeTab: string, setRanges: any, ranges: any, dayBefore: any }) => {
  const styles = reportRequestUseStyles();

  const [dateSelectValue, setDateSelectValue] = useState<string>("");


  if (isSwitchOn) {
    return (
      <Flex className={styles.periodWrapper} direction="row" flexWrap="wrap">
        <div
          onClick={() => setActiveTab("last_7_days")}
          className={activeTab === "last_7_days" ? styles.activeTab : ""}
        >
          Last 7 days
        </div>
        <div
          onClick={() => setActiveTab("last_14_days")}
          className={activeTab === "last_14_days" ? styles.activeTab : ""}
        >
          Last 14 days
        </div>
        <div
          onClick={() => setActiveTab("last_30_days")}
          className={activeTab === "last_30_days" ? styles.activeTab : ""}
        >
          Last 30 days
        </div>
        <div
          onClick={() => setActiveTab("last_month")}
          className={activeTab === "last_month" ? styles.activeTab : ""}
        >
          Last month
        </div>
        <div
          onClick={() => setActiveTab("this_month")}
          className={activeTab === "this_month" ? styles.activeTab : ""}
        >
          This month
        </div>
      </Flex>
    );
  } else {
    return (
      <Flex>
        <DateRangeSelect
          selectionRange={ranges}
          setSelectionRange={setRanges}
          style={{ width: "330px" }}
          dateSelectValue={dateSelectValue}
          setDateSelectValue={setDateSelectValue}
        />
      </Flex>
    );
  }
};

export default DatePeriod;
