import { EmptyStateBySearchIcon } from "modules/shared/components/general/Icons/EmptyStateBySearch.icon";
import { ChooseBrandAdAccountsUseStyles } from "./ChooseBrandAdAccounts.styles";

const CampaignsEmptyStateBySearch = ({ message }: { message: string }) => {
  const styles = ChooseBrandAdAccountsUseStyles();

  return (
    <div style={{ textAlign: "center", paddingTop: "150px",background:"#f9f9f9" }}>
      <EmptyStateBySearchIcon />
      <h2 className={styles.emptyStateMessage}>{message}</h2>
      <p className={styles.emptyStateInfo}>No accounts to show!</p>
    </div>
  );
};
export default CampaignsEmptyStateBySearch;
