import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStyles = createUseStyles((theme: AppTheme) => ({
  modalTitle: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "48px",
    color: theme.primaryColor,
    textAlign: "center",
    margin: "0",
  },
  modalDescription: {
    color: theme.textColor,
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    margin: "8px 0 0 0",
    maxWidth: "420px",
  },
  succesToast:{
    display: "flex",
    alignItems: "center",
    borderTop: '4px solid #33850D',
    borderRadius: '8px',
    background: '#E4E4E4',
    width: '100%',
    padding: '12px 16px',
    gap: '8px',
  },
}));
