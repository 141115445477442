import { createUseStyles } from "react-jss";

export const DeleteReportUseStyles = createUseStyles(() => ({
  deleteReportWrapper: {
    "& > h1": {
      margin: "24px 0 16px",
      textAlign: "center",
    },
  },
  cancelDelete: {
    filter: "drop-shadow(0px 4px 4px rgba(232, 232, 232, 0.25))",
    " &:hover": {
      filter: "drop-shadow(0px 4px 4px rgba(214, 214, 214, 0.25))",
    },
  },
}));
