import React, { createContext, useContext, useState } from "react";
import { LocalStorage } from "../helpers/Storage";
import {
  useAllEmails,
  useSentDataQuery,
  useSkippedDataQuery,
  useToSendDataQuery,
} from "../modules/ClientCommunication/queryActions/queryActions";
import { statuses } from "../modules/ClientCommunication/constants/constants";
import { PropsContext } from "../types/styles.types";

const clientCommunication = createContext<any>(null);

const useClientCommunication = () => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);
  const [fsmsDefaultValue, setFsmsDefaultValue] = useState("");
  const [selectedFsmsIds, setSelectedFsmsIds] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [googleAuthCode, setGoogleAuthCode] = useState("");
  const [emailStatus, setEmailStatus] = useState({});
  const [sendModalForOtherUser, setSendModalForOtherUser] = useState(false);
  const [areYouSureSendModalOpened, setAreYouSureSendModalOpened] =
        useState(false);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([
    "sent",
    "skipped",
    "expired",
  ]);
  const [fsmsData, setFsmsData] = useState([]);
  const [fsmsOptions, setFsmsOptions] = useState([]);
  const [selectedFsms, setSelectedFsms] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState("all_time");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [toValue, setToValue] = useState("");
  const [toOptions, setToOptions] = useState([]);
  const [CCoptions, setCCoptions] = useState([]);
  const [showCCField, setShowCCField] = useState(false);
  const [activeTab, setActiveTab] = useState(
    () => LocalStorage.get("activeTab") || "weeklyBoard",
  );
  const [isFetchingFsms, setIsFetchingFsms] = useState(true);
  const [userEmailReport, setUserEmailReport] = useState(null);

  const handleSearch = (value: any) => setSearchValue(value);

  const {
    toSendData,
    refetchToSendData,
    toSendFetchNextPage,
    toSendHasNextPage,
    toSendIsFetching,
    toSendIsFetchingNextPage,
  } = useToSendDataQuery(selectedFsmsIds, fsmsData, activeTab, isSelectOpen);

  const {
    sentData,
    sentFetchNextPage,
    sentHasNextPage,
    refetchSentData,
    sentIsFetching,
    sentIsFetchingNextPage,
  } = useSentDataQuery(selectedFsmsIds, fsmsData, activeTab, isSelectOpen);

  const {
    skippedData,
    skippedFetchNextPage,
    skippedHasNextPage,
    refetchSkippedData,
    skippedIsFetching,
    skippedIsFetchingNextPage,
  } = useSkippedDataQuery(selectedFsmsIds, fsmsData, activeTab, isSelectOpen);

  const {
    allEmailsResponse,
    refetchAllEmails,
    fetchNextAllEmailsPage,
    allEmailsIsFetchingNextPage,
    allEmailsIsFetching,
    flattenedArrayAllEmailsResponse,
  } = useAllEmails(
    activeTab,
    selectedTimePeriod,
    debouncedSearchValue,
    selectedStatuses,
    selectedFsmsIds,
    fsmsData,
    statuses,
  );

  const toSendResult: any = toSendData?.pages.map(page => page.data?.results?.map((item: any) => item));

  const flattenedArrayToSendResult = [].concat.apply([], toSendResult);

  const sentDataResult: any = sentData?.pages.map(page => page.data?.results?.map((item: any) => item));

  const flattenedArraySentResult = [].concat.apply([], sentDataResult);

  const skippedDataResult:any = skippedData?.pages.map(page => page.data?.results?.map((item:any) => item));

  const flattenedArraySkippedResult = [].concat.apply([], skippedDataResult);

  return {
    isSelectOpen,
    setIsSelectOpen,
    fsmsDefaultValue,
    setFsmsDefaultValue,
    selectedFsmsIds,
    setSelectedFsmsIds,
    searchValue,
    handleSearch,
    googleAuthCode,
    setGoogleAuthCode,
    setEmailStatus,
    emailStatus,
    sendModalForOtherUser,
    setSendModalForOtherUser,
    areYouSureSendModalOpened,
    setAreYouSureSendModalOpened,
    selectedStatuses,
    setSelectedStatuses,
    selectedFsms,
    setSelectedFsms,
    selectedTimePeriod,
    setSelectedTimePeriod,
    fsmsData,
    setFsmsData,
    toSendFetchNextPage,
    setFsmsOptions,
    fsmsOptions,
    updatedStatus,
    setUpdatedStatus,
    toValue,
    setToValue,
    setToOptions,
    toOptions,
    setCCoptions,
    CCoptions,
    setShowCCField,
    showCCField,
    setActiveTab,
    activeTab,
    setDebouncedSearchValue,
    setUserEmailReport,
    userEmailReport,

    toSendData,
    refetchToSendData,
    toSendHasNextPage,
    toSendIsFetching,
    toSendIsFetchingNextPage,
    flattenedArrayToSendResult,

    sentData,
    sentFetchNextPage,
    sentHasNextPage,
    refetchSentData,
    sentIsFetching,
    sentIsFetchingNextPage,
    flattenedArraySentResult,

    skippedData,
    skippedFetchNextPage,
    skippedHasNextPage,
    refetchSkippedData,
    skippedIsFetching,
    skippedIsFetchingNextPage,
    flattenedArraySkippedResult,

    allEmailsResponse,
    refetchAllEmails,
    fetchNextAllEmailsPage,
    allEmailsIsFetchingNextPage,
    allEmailsIsFetching,
    flattenedArrayAllEmailsResponse,

    isFetchingFsms,
    setIsFetchingFsms,
  };
};

export const ClientCommunicationContext: React.FC<PropsContext> = ({ children }) => {
  const clientCommunicationData = useClientCommunication();
  return (
    <clientCommunication.Provider value={clientCommunicationData}>
      {children}
    </clientCommunication.Provider>
  );
};

export const useClientCommunicationContext = () =>
  useContext(clientCommunication);
