export const EmptyHistoryByIdIcon = (
  <svg
    width="600"
    height="400"
    viewBox="0 0 600 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M121.511 94.24C115.527 93.672 115.111 93.224 114.551 87.232C113.983 93.216 113.535 93.632 107.543 94.192C113.527 94.768 113.943 95.216 114.503 101.2C115.079 95.2 115.527 94.776 121.511 94.24Z"
      fill="#92E3A9"
    />
    <path
      d="M521.206 170.912C515.222 170.344 514.806 169.896 514.254 163.904C513.678 169.888 513.23 170.304 507.246 170.864C513.23 171.44 513.646 171.888 514.198 177.872C514.774 171.888 515.198 171.448 521.206 170.912Z"
      fill="#92E3A9"
    />
    <path
      d="M416.25 111.597C418.753 108.662 418.402 104.254 415.467 101.751C412.532 99.2488 408.123 99.5996 405.621 102.535C403.118 105.47 403.469 109.878 406.404 112.381C409.34 114.883 413.748 114.532 416.25 111.597Z"
      fill="#92E3A9"
    />
    <path
      d="M448.745 275.2C442.761 274.632 442.345 274.184 441.785 268.192C441.209 274.176 440.761 274.592 434.777 275.152C440.761 275.72 441.177 276.168 441.737 282.16C442.305 276.208 442.753 275.76 448.745 275.2Z"
      fill="#92E3A9"
    />
    <path
      d="M84.9289 271.88C78.9449 271.304 78.5289 270.856 77.9689 264.872C77.4009 270.856 76.9529 271.272 70.9609 271.824C76.9449 272.4 77.3609 272.848 77.9209 278.832C78.4889 272.848 78.9369 272.408 84.9289 271.88Z"
      fill="#92E3A9"
    />
    <path
      d="M150.401 117.36C146.401 116.976 146.057 116.672 145.705 112.624C145.313 116.664 145.017 116.968 140.969 117.328C145.009 117.712 145.313 118.016 145.673 122.056C146.041 118.016 146.345 117.72 150.401 117.36Z"
      fill="#92E3A9"
    />
    <path
      d="M89.7914 134.856C85.7914 134.472 85.4474 134.168 85.0954 130.128C84.7034 134.168 84.3994 134.464 80.3594 134.824C84.3594 135.208 84.7034 135.512 85.0554 139.56C85.4474 135.52 85.7434 135.2 89.7914 134.856Z"
      fill="#92E3A9"
    />
    <path
      d="M209.256 138.952C205.216 138.56 204.92 138.264 204.56 134.216C204.176 138.216 203.872 138.56 199.832 138.92C203.872 139.304 204.168 139.608 204.528 143.648C204.912 139.608 205.216 139.304 209.256 138.952Z"
      fill="#92E3A9"
    />
    <path
      d="M168.51 246.056C164.51 245.672 164.166 245.368 163.814 241.328C163.422 245.328 163.118 245.664 159.078 246.024C163.118 246.408 163.422 246.712 163.774 250.752C164.166 246.712 164.462 246.4 168.51 246.056Z"
      fill="#92E3A9"
    />
    <path
      d="M358.334 72.192C354.294 71.8 353.998 71.504 353.638 67.456C353.254 71.496 352.95 71.8 348.91 72.16C352.95 72.544 353.246 72.848 353.606 76.888C353.99 72.848 354.294 72.544 358.334 72.192Z"
      fill="#92E3A9"
    />
    <path
      d="M301.385 266.096C297.345 265.712 297.041 265.408 296.681 261.368C296.297 265.408 295.993 265.704 291.953 266.064C295.993 266.448 296.289 266.752 296.649 270.8C297.033 266.76 297.337 266.456 301.385 266.096Z"
      fill="#92E3A9"
    />
    <path
      d="M548.7 238.024C550.489 236.134 550.408 233.151 548.518 231.362C546.628 229.573 543.646 229.655 541.857 231.545C540.068 233.435 540.149 236.417 542.039 238.206C543.929 239.995 546.911 239.913 548.7 238.024Z"
      fill="#92E3A9"
    />
    <path
      d="M452.557 185.071C454.402 183.236 454.41 180.252 452.575 178.407C450.74 176.562 447.757 176.553 445.911 178.388C444.066 180.223 444.058 183.207 445.893 185.052C447.728 186.897 450.711 186.906 452.557 185.071Z"
      fill="#92E3A9"
    />
    <path
      d="M123.522 191.912C119.482 191.528 119.186 191.224 118.826 187.184C118.442 191.224 118.138 191.52 114.098 191.88C118.098 192.264 118.434 192.568 118.794 196.608C119.202 192.568 119.482 192.272 123.522 191.912Z"
      fill="#92E3A9"
    />
    <path
      d="M285.682 92.856C281.642 92.464 281.346 92.168 280.986 88.12C280.602 92.12 280.298 92.464 276.258 92.824C280.298 93.208 280.594 93.512 280.954 97.552C281.338 93.512 281.642 93.208 285.682 92.856Z"
      fill="#92E3A9"
    />
    <path
      d="M223.264 65.048C219.224 64.664 218.92 64.36 218.56 60.32C218.176 64.32 217.872 64.656 213.832 65.016C217.832 65.4 218.168 65.704 218.528 69.752C218.912 65.712 219.2 65.408 223.264 65.048Z"
      fill="#92E3A9"
    />
    <path
      d="M66.2638 207.312C62.2638 206.928 61.9278 206.624 61.5678 202.584C61.1838 206.584 60.8798 206.928 56.8398 207.28C60.8398 207.672 61.1758 207.968 61.5358 212.016C61.9198 208 62.2238 207.672 66.2638 207.312Z"
      fill="#92E3A9"
    />
    <path
      d="M223.729 315.2C219.689 314.816 219.385 314.512 219.033 310.472C218.641 314.512 218.337 314.808 214.297 315.168C218.337 315.552 218.641 315.856 218.993 319.896C219.385 315.824 219.689 315.528 223.729 315.2Z"
      fill="#92E3A9"
    />
    <path
      d="M490.401 317.328C486.401 316.944 486.057 316.64 485.705 312.6C485.313 316.64 485.017 316.944 480.969 317.296C484.969 317.688 485.313 317.984 485.673 322.032C486.073 317.992 486.401 317.688 490.401 317.328Z"
      fill="#92E3A9"
    />
    <path
      d="M515.502 84.624C511.502 84.24 511.166 83.936 510.806 79.896C510.422 83.896 510.118 84.232 506.07 84.592C510.07 84.976 510.414 85.28 510.774 89.328C511.198 85.288 511.462 84.984 515.502 84.624Z"
      fill="#92E3A9"
    />
    <g opacity="0.8">
      <path
        d="M121.511 94.24C115.527 93.672 115.111 93.224 114.551 87.232C113.983 93.216 113.535 93.632 107.543 94.192C113.527 94.768 113.943 95.216 114.503 101.2C115.079 95.2 115.527 94.776 121.511 94.24Z"
        fill="#E7E7E7"
      />
      <path
        d="M521.206 170.912C515.222 170.344 514.806 169.896 514.254 163.904C513.678 169.888 513.23 170.304 507.246 170.864C513.23 171.44 513.646 171.888 514.198 177.872C514.774 171.888 515.198 171.448 521.206 170.912Z"
        fill="#E7E7E7"
      />
      <path
        d="M416.25 111.597C418.753 108.662 418.402 104.254 415.467 101.751C412.532 99.2488 408.123 99.5996 405.621 102.535C403.118 105.47 403.469 109.878 406.404 112.381C409.34 114.883 413.748 114.532 416.25 111.597Z"
        fill="#E7E7E7"
      />
      <path
        d="M448.745 275.2C442.761 274.632 442.345 274.184 441.785 268.192C441.209 274.176 440.761 274.592 434.777 275.152C440.761 275.72 441.177 276.168 441.737 282.16C442.305 276.208 442.753 275.76 448.745 275.2Z"
        fill="#E7E7E7"
      />
      <path
        d="M84.9289 271.88C78.9449 271.304 78.5289 270.856 77.9689 264.872C77.4009 270.856 76.9529 271.272 70.9609 271.824C76.9449 272.4 77.3609 272.848 77.9209 278.832C78.4889 272.848 78.9369 272.408 84.9289 271.88Z"
        fill="#E7E7E7"
      />
      <path
        d="M150.401 117.36C146.401 116.976 146.057 116.672 145.705 112.624C145.313 116.664 145.017 116.968 140.969 117.328C145.009 117.712 145.313 118.016 145.673 122.056C146.041 118.016 146.345 117.72 150.401 117.36Z"
        fill="#E7E7E7"
      />
      <path
        d="M89.7914 134.856C85.7914 134.472 85.4474 134.168 85.0954 130.128C84.7034 134.168 84.3994 134.464 80.3594 134.824C84.3594 135.208 84.7034 135.512 85.0554 139.56C85.4474 135.52 85.7434 135.2 89.7914 134.856Z"
        fill="#E7E7E7"
      />
      <path
        d="M209.256 138.952C205.216 138.56 204.92 138.264 204.56 134.216C204.176 138.216 203.872 138.56 199.832 138.92C203.872 139.304 204.168 139.608 204.528 143.648C204.912 139.608 205.216 139.304 209.256 138.952Z"
        fill="#E7E7E7"
      />
      <path
        d="M168.51 246.056C164.51 245.672 164.166 245.368 163.814 241.328C163.422 245.328 163.118 245.664 159.078 246.024C163.118 246.408 163.422 246.712 163.774 250.752C164.166 246.712 164.462 246.4 168.51 246.056Z"
        fill="#E7E7E7"
      />
      <path
        d="M358.334 72.192C354.294 71.8 353.998 71.504 353.638 67.456C353.254 71.496 352.95 71.8 348.91 72.16C352.95 72.544 353.246 72.848 353.606 76.888C353.99 72.848 354.294 72.544 358.334 72.192Z"
        fill="#E7E7E7"
      />
      <path
        d="M301.385 266.096C297.345 265.712 297.041 265.408 296.681 261.368C296.297 265.408 295.993 265.704 291.953 266.064C295.993 266.448 296.289 266.752 296.649 270.8C297.033 266.76 297.337 266.456 301.385 266.096Z"
        fill="#E7E7E7"
      />
      <path
        d="M548.7 238.024C550.489 236.134 550.408 233.151 548.518 231.362C546.628 229.573 543.646 229.655 541.857 231.545C540.068 233.435 540.149 236.417 542.039 238.206C543.929 239.995 546.911 239.913 548.7 238.024Z"
        fill="#E7E7E7"
      />
      <path
        d="M452.557 185.071C454.402 183.236 454.41 180.252 452.575 178.407C450.74 176.562 447.757 176.553 445.911 178.388C444.066 180.223 444.058 183.207 445.893 185.052C447.728 186.897 450.711 186.906 452.557 185.071Z"
        fill="#E7E7E7"
      />
      <path
        d="M123.522 191.912C119.482 191.528 119.186 191.224 118.826 187.184C118.442 191.224 118.138 191.52 114.098 191.88C118.098 192.264 118.434 192.568 118.794 196.608C119.202 192.568 119.482 192.272 123.522 191.912Z"
        fill="#E7E7E7"
      />
      <path
        d="M285.682 92.856C281.642 92.464 281.346 92.168 280.986 88.12C280.602 92.12 280.298 92.464 276.258 92.824C280.298 93.208 280.594 93.512 280.954 97.552C281.338 93.512 281.642 93.208 285.682 92.856Z"
        fill="#E7E7E7"
      />
      <path
        d="M223.264 65.048C219.224 64.664 218.92 64.36 218.56 60.32C218.176 64.32 217.872 64.656 213.832 65.016C217.832 65.4 218.168 65.704 218.528 69.752C218.912 65.712 219.2 65.408 223.264 65.048Z"
        fill="#E7E7E7"
      />
      <path
        d="M66.2638 207.312C62.2638 206.928 61.9278 206.624 61.5678 202.584C61.1838 206.584 60.8798 206.928 56.8398 207.28C60.8398 207.672 61.1758 207.968 61.5358 212.016C61.9198 208 62.2238 207.672 66.2638 207.312Z"
        fill="#E7E7E7"
      />
      <path
        d="M223.729 315.2C219.689 314.816 219.385 314.512 219.033 310.472C218.641 314.512 218.337 314.808 214.297 315.168C218.337 315.552 218.641 315.856 218.993 319.896C219.385 315.824 219.689 315.528 223.729 315.2Z"
        fill="#E7E7E7"
      />
      <path
        d="M490.401 317.328C486.401 316.944 486.057 316.64 485.705 312.6C485.313 316.64 485.017 316.944 480.969 317.296C484.969 317.688 485.313 317.984 485.673 322.032C486.073 317.992 486.401 317.688 490.401 317.328Z"
        fill="#E7E7E7"
      />
      <path
        d="M515.502 84.624C511.502 84.24 511.166 83.936 510.806 79.896C510.422 83.896 510.118 84.232 506.07 84.592C510.07 84.976 510.414 85.28 510.774 89.328C511.198 85.288 511.462 84.984 515.502 84.624Z"
        fill="#E7E7E7"
      />
    </g>
    <path
      d="M265.265 338.688C212.985 338.688 164.145 330.784 124.057 315.544C97.9691 305.624 77.0171 293.144 61.7771 278.392C45.9851 263.128 37.0491 246.128 35.2011 227.864C33.3531 209.6 38.7611 191.16 51.2011 173.072C63.2011 155.616 81.2811 139.208 104.881 124.32C153.449 93.704 219.937 72.856 292.161 65.6C364.385 58.344 433.689 65.6 487.313 86.016C513.401 95.936 534.353 108.416 549.593 123.168C565.393 138.432 574.321 155.432 576.153 173.696C577.985 191.96 572.593 210.4 560.153 228.488C548.153 245.944 530.073 262.352 506.465 277.24C457.929 307.888 391.433 328.736 319.201 336C300.977 337.784 282.921 338.688 265.265 338.688ZM346.113 63.304C328.465 63.304 310.433 64.208 292.201 66.032C220.041 73.232 153.609 94.072 105.137 124.656C56.6651 155.24 32.0171 191.856 35.6171 227.856C39.2171 263.856 70.6811 294.816 124.201 315.2C177.721 335.584 247.017 342.784 319.201 335.56C391.385 328.336 457.801 307.52 506.265 276.936C554.729 246.352 579.385 209.736 575.785 173.736C572.185 137.736 540.697 106.776 487.201 86.4C447.137 71.2 398.337 63.304 346.113 63.304Z"
      fill="#92E3A9"
    />
    <path
      d="M272.201 312.904C227.401 312.904 185.569 305.896 151.241 292.384C129.105 283.672 111.321 272.728 98.3612 259.848C84.9372 246.504 77.3212 231.664 75.7292 215.728C74.1372 199.792 78.6572 183.728 89.1692 168C99.3212 152.8 114.585 138.56 134.553 125.6C175.601 99.032 231.905 81.024 293.089 74.904C354.273 68.784 413.033 75.272 458.529 93.176C480.665 101.888 498.457 112.84 511.409 125.712C524.833 139.056 532.449 153.904 534.041 169.832C535.633 185.76 531.113 201.832 520.601 217.568C510.457 232.8 495.201 247.04 475.201 260C434.153 286.568 377.849 304.568 316.665 310.696C301.657 312.168 286.769 312.904 272.201 312.904ZM337.601 73.088C323.033 73.088 308.169 73.832 293.153 75.336C232.033 81.448 175.793 99.432 134.753 125.968C93.7132 152.504 72.9772 184.368 76.1132 215.688C79.2492 247.008 105.977 274.144 151.369 292.016C196.761 309.888 255.497 316.384 316.625 310.264C377.753 304.144 433.993 286.168 475.025 259.632C516.057 233.096 536.809 201.232 533.665 169.904C530.521 138.576 503.777 111.448 458.401 93.6C424.105 80.096 382.289 73.088 337.601 73.088Z"
      fill="#92E3A9"
    />
    <path
      d="M277.256 291.592C239.04 291.592 203.28 285.512 173.976 273.792C135.256 258.312 112.432 234.848 109.72 207.72C107.008 180.592 124.72 153.064 159.608 130.216C194.496 107.368 242.288 92 294.272 86.76C346.256 81.52 396.2 87.208 434.88 102.672C473.56 118.136 496.424 141.616 499.144 168.752C501.864 195.888 484.136 223.4 449.248 246.248C414.36 269.096 366.568 284.504 314.584 289.704C301.976 290.968 289.488 291.592 277.256 291.592ZM331.6 85.272C319.384 85.272 306.912 85.896 294.312 87.16C242.392 92.352 194.632 107.768 159.832 130.552C125.032 153.336 107.416 180.704 110.112 207.68C112.808 234.656 135.544 258 174.112 273.424C212.68 288.848 262.6 294.504 314.528 289.312C366.456 284.12 414.216 268.704 449.008 245.912C483.8 223.12 501.44 195.76 498.744 168.8C496.048 141.84 473.312 118.472 434.744 103.048C405.472 91.344 369.768 85.272 331.6 85.272Z"
      fill="#92E3A9"
    />
    <path
      d="M281.6 270.352C249.408 270.352 219.28 265.144 194.6 255.152C162.104 241.96 142.936 222 140.632 198.952C138.328 175.904 153.16 152.552 182.392 133.184C211.624 113.816 251.672 100.784 295.256 96.424C338.84 92.064 380.712 96.912 413.16 110.088C445.608 123.264 464.8 143.2 467.112 166.256C469.424 189.312 454.592 212.656 425.352 232.032C396.112 251.408 356.072 264.424 312.488 268.8C302.056 269.832 291.712 270.352 281.6 270.352ZM325.904 95.256C315.936 95.256 305.704 95.76 295.296 96.8C251.776 101.152 211.76 114.184 182.616 133.488C153.472 152.792 138.736 176 141.016 198.888C145.752 246.2 222.616 277.376 312.448 268.392C402.28 259.408 471.448 213.6 466.712 166.296C462.528 124.472 401.928 95.256 325.888 95.256H325.904Z"
      fill="#92E3A9"
    />
    <path
      d="M285.378 250.216C258.77 250.216 233.866 245.848 213.466 237.416C186.706 226.392 170.914 209.744 168.994 190.536C167.074 171.328 179.25 151.88 203.298 135.776C227.346 119.672 260.274 108.856 296.098 105.272C331.922 101.688 366.426 105.768 393.138 116.776C419.85 127.784 435.69 144.448 437.618 163.656C439.546 182.864 427.354 202.312 403.314 218.416C379.274 234.52 346.338 245.328 310.514 248.92C302.026 249.8 293.602 250.216 285.378 250.216ZM321.05 104.448C312.946 104.448 304.626 104.856 296.17 105.704C260.378 109.28 227.474 120.104 203.522 136.104C179.57 152.104 167.482 171.44 169.386 190.504C171.29 209.568 186.986 226.088 213.618 237.056C240.25 248.024 274.674 252.112 310.466 248.528C384.314 241.136 441.194 203.088 437.25 163.728C433.762 128.848 383.738 104.448 321.05 104.448Z"
      fill="#92E3A9"
    />
    <path
      d="M289.824 233.44C239.88 233.44 199.856 213.24 197.024 184.512C193.824 152.216 238.72 121.176 297.176 115.328C355.632 109.48 405.816 130.992 409.048 163.328C410.648 179 401.048 194.824 382.104 207.896C363.16 220.968 337.192 229.688 308.896 232.52C302.4 233.128 296 233.44 289.824 233.44ZM316.224 114.768C310.016 114.768 303.664 115.08 297.208 115.72C238.968 121.552 194.208 152.392 197.416 184.472C200.624 216.552 250.608 237.912 308.848 232.088C337.08 229.264 363.008 220.544 381.864 207.536C400.72 194.528 410.192 178.856 408.64 163.336C405.752 134.816 365.92 114.768 316.192 114.768H316.224Z"
      fill="#92E3A9"
    />
    <path
      d="M293.031 218.568C254.407 218.568 223.375 202.568 221.111 179.896C219.871 167.552 227.223 155.096 241.807 144.848C256.391 134.6 276.367 127.776 298.159 125.592C343.199 121.08 381.895 138.112 384.447 163.552C385.679 175.888 378.327 188.352 363.751 198.6C349.175 208.848 329.183 215.664 307.391 217.848C302.519 218.336 297.719 218.568 293.031 218.568ZM312.535 125.272C307.853 125.272 303.074 125.512 298.199 125.992C276.471 128.168 256.527 134.976 242.031 145.192C227.535 155.408 220.287 167.688 221.503 179.88C224.031 205.096 262.543 221.96 307.351 217.48C329.079 215.312 349.023 208.496 363.519 198.28C378.015 188.064 385.263 175.792 384.047 163.592C381.799 141.112 350.951 125.272 312.535 125.272Z"
      fill="#92E3A9"
    />
    <path
      d="M295.944 204.672C268.792 204.672 246.984 193.416 245.384 177.472C244.512 168.8 249.68 160 259.936 152.8C270.192 145.6 284.216 140.8 299.528 139.272C331.176 136.072 358.384 148.072 360.168 165.968C361.04 174.656 355.872 183.416 345.616 190.632C335.36 197.848 321.336 202.632 306.024 204.16C302.6 204.504 299.2 204.672 295.944 204.672ZM309.6 139.2C306.32 139.2 302.952 139.368 299.536 139.704C284.336 141.232 270.304 146.008 260.136 153.16C249.968 160.312 244.936 168.92 245.736 177.456C247.504 195.128 274.536 206.944 305.936 203.8C321.136 202.272 335.176 197.496 345.336 190.344C355.496 183.192 360.536 174.584 359.736 166.048C358.2 150.272 336.568 139.2 309.6 139.2Z"
      fill="#92E3A9"
    />
    <g opacity="0.7">
      <path
        d="M265.265 338.688C212.985 338.688 164.145 330.784 124.057 315.544C97.9691 305.624 77.0171 293.144 61.7771 278.392C45.9851 263.128 37.0491 246.128 35.2011 227.864C33.3531 209.6 38.7611 191.16 51.2011 173.072C63.2011 155.616 81.2811 139.208 104.881 124.32C153.449 93.704 219.937 72.856 292.161 65.6C364.385 58.344 433.689 65.6 487.313 86.016C513.401 95.936 534.353 108.416 549.593 123.168C565.393 138.432 574.321 155.432 576.153 173.696C577.985 191.96 572.593 210.4 560.153 228.488C548.153 245.944 530.073 262.352 506.465 277.24C457.929 307.888 391.433 328.736 319.201 336C300.977 337.784 282.921 338.688 265.265 338.688ZM346.113 63.304C328.465 63.304 310.433 64.208 292.201 66.032C220.041 73.232 153.609 94.072 105.137 124.656C56.6651 155.24 32.0171 191.856 35.6171 227.856C39.2171 263.856 70.6811 294.816 124.201 315.2C177.721 335.584 247.017 342.784 319.201 335.56C391.385 328.336 457.801 307.52 506.265 276.936C554.729 246.352 579.385 209.736 575.785 173.736C572.185 137.736 540.697 106.776 487.201 86.4C447.137 71.2 398.337 63.304 346.113 63.304Z"
        fill="#E7E7E7"
      />
      <path
        d="M272.201 312.904C227.401 312.904 185.569 305.896 151.241 292.384C129.105 283.672 111.321 272.728 98.3612 259.848C84.9372 246.504 77.3212 231.664 75.7292 215.728C74.1372 199.792 78.6572 183.728 89.1692 168C99.3212 152.8 114.585 138.56 134.553 125.6C175.601 99.032 231.905 81.024 293.089 74.904C354.273 68.784 413.033 75.272 458.529 93.176C480.665 101.888 498.457 112.84 511.409 125.712C524.833 139.056 532.449 153.904 534.041 169.832C535.633 185.76 531.113 201.832 520.601 217.568C510.457 232.8 495.201 247.04 475.201 260C434.153 286.568 377.849 304.568 316.665 310.696C301.657 312.168 286.769 312.904 272.201 312.904ZM337.601 73.088C323.033 73.088 308.169 73.832 293.153 75.336C232.033 81.448 175.793 99.432 134.753 125.968C93.7132 152.504 72.9772 184.368 76.1132 215.688C79.2492 247.008 105.977 274.144 151.369 292.016C196.761 309.888 255.497 316.384 316.625 310.264C377.753 304.144 433.993 286.168 475.025 259.632C516.057 233.096 536.809 201.232 533.665 169.904C530.521 138.576 503.777 111.448 458.401 93.6C424.105 80.096 382.289 73.088 337.601 73.088Z"
        fill="#E7E7E7"
      />
      <path
        d="M277.256 291.592C239.04 291.592 203.28 285.512 173.976 273.792C135.256 258.312 112.432 234.848 109.72 207.72C107.008 180.592 124.72 153.064 159.608 130.216C194.496 107.368 242.288 92 294.272 86.76C346.256 81.52 396.2 87.208 434.88 102.672C473.56 118.136 496.424 141.616 499.144 168.752C501.864 195.888 484.136 223.4 449.248 246.248C414.36 269.096 366.568 284.504 314.584 289.704C301.976 290.968 289.488 291.592 277.256 291.592ZM331.6 85.272C319.384 85.272 306.912 85.896 294.312 87.16C242.392 92.352 194.632 107.768 159.832 130.552C125.032 153.336 107.416 180.704 110.112 207.68C112.808 234.656 135.544 258 174.112 273.424C212.68 288.848 262.6 294.504 314.528 289.312C366.456 284.12 414.216 268.704 449.008 245.912C483.8 223.12 501.44 195.76 498.744 168.8C496.048 141.84 473.312 118.472 434.744 103.048C405.472 91.344 369.768 85.272 331.6 85.272Z"
        fill="#E7E7E7"
      />
      <path
        d="M281.6 270.352C249.408 270.352 219.28 265.144 194.6 255.152C162.104 241.96 142.936 222 140.632 198.952C138.328 175.904 153.16 152.552 182.392 133.184C211.624 113.816 251.672 100.784 295.256 96.424C338.84 92.064 380.712 96.912 413.16 110.088C445.608 123.264 464.8 143.2 467.112 166.256C469.424 189.312 454.592 212.656 425.352 232.032C396.112 251.408 356.072 264.424 312.488 268.8C302.056 269.832 291.712 270.352 281.6 270.352ZM325.904 95.256C315.936 95.256 305.704 95.76 295.296 96.8C251.776 101.152 211.76 114.184 182.616 133.488C153.472 152.792 138.736 176 141.016 198.888C145.752 246.2 222.616 277.376 312.448 268.392C402.28 259.408 471.448 213.6 466.712 166.296C462.528 124.472 401.928 95.256 325.888 95.256H325.904Z"
        fill="#E7E7E7"
      />
      <path
        d="M285.378 250.216C258.77 250.216 233.866 245.848 213.466 237.416C186.706 226.392 170.914 209.744 168.994 190.536C167.074 171.328 179.25 151.88 203.298 135.776C227.346 119.672 260.274 108.856 296.098 105.272C331.922 101.688 366.426 105.768 393.138 116.776C419.85 127.784 435.69 144.448 437.618 163.656C439.546 182.864 427.354 202.312 403.314 218.416C379.274 234.52 346.338 245.328 310.514 248.92C302.026 249.8 293.602 250.216 285.378 250.216ZM321.05 104.448C312.946 104.448 304.626 104.856 296.17 105.704C260.378 109.28 227.474 120.104 203.522 136.104C179.57 152.104 167.482 171.44 169.386 190.504C171.29 209.568 186.986 226.088 213.618 237.056C240.25 248.024 274.674 252.112 310.466 248.528C384.314 241.136 441.194 203.088 437.25 163.728C433.762 128.848 383.738 104.448 321.05 104.448Z"
        fill="#E7E7E7"
      />
      <path
        d="M289.824 233.44C239.88 233.44 199.856 213.24 197.024 184.512C193.824 152.216 238.72 121.176 297.176 115.328C355.632 109.48 405.816 130.992 409.048 163.328C410.648 179 401.048 194.824 382.104 207.896C363.16 220.968 337.192 229.688 308.896 232.52C302.4 233.128 296 233.44 289.824 233.44ZM316.224 114.768C310.016 114.768 303.664 115.08 297.208 115.72C238.968 121.552 194.208 152.392 197.416 184.472C200.624 216.552 250.608 237.912 308.848 232.088C337.08 229.264 363.008 220.544 381.864 207.536C400.72 194.528 410.192 178.856 408.64 163.336C405.752 134.816 365.92 114.768 316.192 114.768H316.224Z"
        fill="#E7E7E7"
      />
      <path
        d="M293.031 218.568C254.407 218.568 223.375 202.568 221.111 179.896C219.871 167.552 227.223 155.096 241.807 144.848C256.391 134.6 276.367 127.776 298.159 125.592C343.199 121.08 381.895 138.112 384.447 163.552C385.679 175.888 378.327 188.352 363.751 198.6C349.175 208.848 329.183 215.664 307.391 217.848C302.519 218.336 297.719 218.568 293.031 218.568ZM312.535 125.272C307.853 125.272 303.074 125.512 298.199 125.992C276.471 128.168 256.527 134.976 242.031 145.192C227.535 155.408 220.287 167.688 221.503 179.88C224.031 205.096 262.543 221.96 307.351 217.48C329.079 215.312 349.023 208.496 363.519 198.28C378.015 188.064 385.263 175.792 384.047 163.592C381.799 141.112 350.951 125.272 312.535 125.272Z"
        fill="#E7E7E7"
      />
      <path
        d="M295.944 204.672C268.792 204.672 246.984 193.416 245.384 177.472C244.512 168.8 249.68 160 259.936 152.8C270.192 145.6 284.216 140.8 299.528 139.272C331.176 136.072 358.384 148.072 360.168 165.968C361.04 174.656 355.872 183.416 345.616 190.632C335.36 197.848 321.336 202.632 306.024 204.16C302.6 204.504 299.2 204.672 295.944 204.672ZM309.6 139.2C306.32 139.2 302.952 139.368 299.536 139.704C284.336 141.232 270.304 146.008 260.136 153.16C249.968 160.312 244.936 168.92 245.736 177.456C247.504 195.128 274.536 206.944 305.936 203.8C321.136 202.272 335.176 197.496 345.336 190.344C355.496 183.192 360.536 174.584 359.736 166.048C358.2 150.272 336.568 139.2 309.6 139.2Z"
        fill="#E7E7E7"
      />
    </g>
    <path
      d="M123.383 311.934C125.279 302.517 119.182 293.347 109.765 291.452C100.349 289.557 91.1785 295.654 89.2833 305.071C87.388 314.487 93.4851 323.657 102.902 325.552C112.318 327.448 121.488 321.35 123.383 311.934Z"
      fill="#455A64"
    />
    <path
      opacity="0.23"
      d="M117.818 296.44C117.818 296.44 115.322 292.976 112.322 294.256C109.322 295.536 109.578 298.048 109.922 299.384C110.266 300.72 111.522 301.96 110.658 302.824C109.794 303.688 107.674 304.296 107.81 305.656C107.964 306.756 108.205 307.842 108.53 308.904L108.378 311.264C108.378 311.264 108.506 312.616 106.61 313.2C104.714 313.784 103.674 313.104 102.45 314.592C101.226 316.08 100.442 315.968 100.77 317.304C101.098 318.64 101.194 319.608 103.906 321.304C106.618 323 112.306 323.2 115.978 320.88C119.65 318.56 122.17 316.352 122.754 310.432C123.338 304.512 120.338 298.144 117.818 296.44Z"
      fill="black"
    />
    <path
      opacity="0.23"
      d="M92.2582 300.8C92.2582 300.8 91.0742 302.672 91.0742 306.584C91.0742 310.496 94.7542 312.08 95.4742 309.464C96.1942 306.848 94.5622 302.328 97.2502 299.904C99.9382 297.48 105.026 295.608 103.13 294.24C101.234 292.872 93.3382 296 92.2582 300.8Z"
      fill="black"
    />
    <path
      opacity="0.23"
      d="M96.3054 315.792C96.3054 315.792 93.4414 314.52 93.4414 316.472C93.4414 318.424 95.9374 321.888 97.4414 319.2C98.9454 316.512 97.4814 315.872 96.3054 315.792Z"
      fill="black"
    />
    <path
      opacity="0.23"
      d="M105.803 301.36C105.803 301.36 99.1306 301.048 101.395 304.144C103.659 307.24 107.955 303.296 105.803 301.36Z"
      fill="black"
    />
    <path
      d="M475.007 85.88C477.32 87.0344 479.239 88.8487 480.521 91.0936C481.803 93.3384 482.391 95.913 482.21 98.4918C482.028 101.071 481.087 103.538 479.504 105.582C477.921 107.625 475.767 109.154 473.315 109.974C470.864 110.793 468.224 110.868 465.73 110.188C463.236 109.507 460.999 108.103 459.303 106.152C457.608 104.2 456.528 101.79 456.202 99.2257C455.876 96.6612 456.317 94.0576 457.471 91.744C459.02 88.642 461.737 86.2818 465.025 85.1822C468.313 84.0826 471.904 84.3336 475.007 85.88Z"
      fill="#455A64"
    />
    <g opacity="0.34">
      <path
        d="M472.727 94.04C474.791 93 478.631 92.488 481.111 92.248C481.199 92.448 481.271 92.664 481.351 92.872C477.647 94.272 470.247 97.096 469.175 97.584C468.103 98.072 462.575 103.064 459.735 106.608C459.303 106.163 458.905 105.687 458.543 105.184C460.015 103.728 463.031 100.776 463.807 100.064C466.586 97.782 469.572 95.7652 472.727 94.04Z"
        fill="black"
      />
      <path
        d="M468.534 108.248C470.006 106.992 471.262 106.608 473.846 105.048C476.43 103.488 479.766 101.288 480.886 100.984C481.214 100.896 481.542 100.808 481.854 100.744C481.628 101.665 481.301 102.559 480.878 103.408C480.006 103.696 478.638 104.208 476.63 105.008C472.63 106.68 470.446 108.928 469.086 109.744L467.774 110.544C467.43 110.504 467.086 110.456 466.742 110.392C467.236 109.597 467.839 108.875 468.534 108.248Z"
        fill="black"
      />
      <path
        d="M461.932 107.64C463.772 107.488 464.196 104.984 465.324 103.64C466.452 102.296 471.844 100.032 475.108 99.032C477.541 98.1517 479.887 97.0484 482.116 95.736C482.217 96.4861 482.255 97.2435 482.228 98C480.526 99.113 478.73 100.074 476.86 100.872C473.036 102.536 472.652 100.872 469.484 103.064C467.177 104.857 465.112 106.941 463.34 109.264C462.511 108.849 461.728 108.347 461.004 107.768C461.309 107.701 461.62 107.659 461.932 107.64Z"
        fill="black"
      />
      <path
        d="M475.597 88.12C473.285 88.552 472.293 90.112 470.597 92.12C468.901 94.128 467.053 94.256 463.933 95.496C461.925 96.296 458.573 98.832 456.437 100.544C456.198 99.4948 456.09 98.4198 456.117 97.344C456.977 96.6774 457.802 95.9671 458.589 95.216C458.993 94.7662 459.486 94.4058 460.037 94.1578C460.588 93.9098 461.185 93.7798 461.789 93.776C466.509 93.184 468.501 92.08 469.941 89.408C471.381 86.736 474.989 85.936 474.989 85.936C475.917 86.3999 476.785 86.9747 477.573 87.648C476.927 87.8544 476.267 88.0121 475.597 88.12Z"
        fill="black"
      />
      <path
        d="M457.125 92.536C457.237 92.272 457.341 92.008 457.469 91.736C458.696 89.2828 460.663 87.2771 463.092 86.0024C465.521 84.7276 468.289 84.2483 471.005 84.632C469.917 86.232 468.685 85.096 465.149 87.232C461.613 89.368 462.973 90.432 460.165 90.984C459.023 91.197 457.967 91.736 457.125 92.536Z"
        fill="black"
      />
      <path
        d="M468.676 88.456C468.676 88.456 467.876 91.16 466.364 91.712C465.048 92.0601 463.683 92.1925 462.324 92.104C462.324 92.104 465.596 88.632 468.676 88.456Z"
        fill="black"
      />
    </g>
    <g opacity="0.34">
      <path
        d="M471.605 85.976C471.069 86.24 470.517 86.456 469.869 86.704C468.2 87.3079 466.592 88.0714 465.069 88.984C463.907 89.7078 462.82 90.5438 461.821 91.48C461.049 92.3039 460.1 92.9413 459.045 93.344C458.142 93.6529 457.273 94.055 456.453 94.544C456.525 94.232 456.621 93.92 456.717 93.608C457.398 93.2316 458.113 92.9208 458.853 92.68C459.803 92.2948 460.657 91.7079 461.357 90.96C462.388 89.9937 463.514 89.1334 464.717 88.392C466.267 87.4571 467.904 86.6748 469.605 86.056C470.237 85.808 470.781 85.6 471.285 85.352C471.568 85.2116 471.836 85.0428 472.085 84.848C472.373 84.92 472.653 84.992 472.933 85.08C472.524 85.426 472.079 85.7264 471.605 85.976Z"
        fill="black"
      />
      <path
        d="M474.789 89.448C473.493 89.992 472.981 90.76 472.205 91.928C471.766 92.6066 471.29 93.2609 470.781 93.888C469.828 94.9753 468.576 95.7573 467.181 96.136C463.394 97.5177 459.95 99.7008 457.085 102.536C456.989 102.296 456.893 102.064 456.805 101.824C458.386 100.284 460.148 98.9406 462.053 97.824C463.597 96.8859 465.232 96.106 466.933 95.496C468.365 94.952 469.333 94.6 470.253 93.456C470.743 92.8452 471.203 92.2097 471.629 91.552C472.154 90.5886 472.903 89.7655 473.813 89.152C474.04 89.0176 474.275 88.8974 474.517 88.792C475.373 88.432 476.533 87.992 477.597 87.576C477.797 87.752 477.997 87.912 478.189 88.096C477.045 88.544 475.733 89.048 474.789 89.448Z"
        fill="black"
      />
      <path
        d="M469.911 97.848C470.606 97.4224 471.34 97.0634 472.103 96.776C475.055 95.68 480.599 94 481.359 93.776L481.599 93.52C481.679 93.776 481.751 94.04 481.815 94.32L481.735 94.408H481.647C481.591 94.408 475.487 96.264 472.343 97.432C469.199 98.6 466.511 101.36 464.919 103.032C463.396 104.467 461.743 105.757 459.983 106.888L459.735 106.624L459.551 106.336C461.285 105.223 462.915 103.957 464.423 102.552C466.039 100.751 467.884 99.1696 469.911 97.848Z"
        fill="black"
      />
      <path
        d="M463.812 109.488C465.048 107.625 466.638 106.024 468.492 104.776C468.87 104.547 469.266 104.349 469.676 104.184C471.108 103.632 472.316 103.272 473.38 102.96C474.778 102.603 476.123 102.065 477.38 101.36C478.282 100.785 479.114 100.109 479.86 99.344C480.486 98.6455 481.242 98.075 482.084 97.664L482.221 97.616C482.221 97.872 482.221 98.12 482.221 98.416C481.525 98.7978 480.895 99.2894 480.356 99.872C479.578 100.679 478.705 101.388 477.756 101.984C476.441 102.715 475.034 103.269 473.572 103.632C472.524 103.944 471.332 104.296 469.932 104.84C467.532 105.768 465.453 108.384 464.469 109.784C464.245 109.688 464.036 109.6 463.812 109.488Z"
        fill="black"
      />
    </g>
    <g opacity="0.45">
      <path
        d="M467.997 121.464C467.686 121.5 467.372 121.5 467.061 121.464C463.469 121.008 460.813 115.224 460.117 107.16L461.013 107.792C461.661 115.336 464.021 120.264 467.157 120.664C472.029 121.288 477.293 111.32 478.925 98.456C480.557 85.592 477.941 74.632 473.085 74.016C469.885 73.608 466.181 78.16 463.685 85.608L462.685 86.144C465.341 78.192 469.517 72.752 473.189 73.224C478.565 73.904 481.437 85.032 479.717 98.56C478.133 111.288 473.101 120.96 467.997 121.464Z"
        fill="#E7E7E7"
      />
    </g>
    <path
      d="M348.681 301.345C357.796 294.397 359.552 281.375 352.603 272.261C345.655 263.146 332.633 261.39 323.519 268.339C314.404 275.287 312.648 288.309 319.597 297.423C326.545 306.538 339.567 308.294 348.681 301.345Z"
      fill="#455A64"
    />
    <g opacity="0.28">
      <path
        d="M353.604 273.712L316.004 289.896C316.092 290.256 316.188 290.616 316.3 290.968L354.204 274.656C354.012 274.336 353.804 274.024 353.604 273.712Z"
        fill="black"
      />
      <path
        d="M343.371 265.392C342.843 265.192 342.315 265.024 341.771 264.872L317.739 275.2C317.683 275.312 317.611 275.416 317.555 275.528C317.363 275.912 317.203 276.328 317.035 276.696L343.371 265.392Z"
        fill="black"
      />
      <path
        d="M329.053 304.328L355.261 292.752C355.453 292.272 355.629 291.792 355.789 291.312L327.645 303.744C328.109 303.952 328.573 304.16 329.053 304.328Z"
        fill="black"
      />
      <path
        d="M349.603 269.104C349.299 268.84 348.987 268.576 348.659 268.304L315.379 283.304C315.379 283.712 315.379 284.104 315.379 284.528L349.603 269.104Z"
        fill="black"
      />
      <path
        d="M316.66 292.04C316.788 292.384 316.916 292.728 317.06 293.064L355.06 276.384C354.908 276.048 354.732 275.728 354.564 275.4L316.66 292.04Z"
        fill="black"
      />
      <path
        d="M354.117 295.072C354.293 294.76 354.485 294.464 354.645 294.144C354.805 293.824 354.821 293.72 354.917 293.512L329.805 304.576C330.365 304.752 330.925 304.912 331.485 305.04L354.117 295.072Z"
        fill="black"
      />
      <path
        d="M322.706 300.656L356.802 286.256C356.802 285.848 356.802 285.456 356.802 285.048L321.754 299.856C322.074 300.128 322.402 300.392 322.706 300.656Z"
        fill="black"
      />
    </g>
    <path
      d="M370.458 269.24C368.618 265.144 359.698 264.872 348.058 267.872C349.377 268.809 350.582 269.896 351.65 271.112C355.914 270.616 358.898 271.112 359.738 272.712C361.61 276.36 352.09 284.296 338.466 290.432C324.842 296.568 312.298 298.608 310.402 294.96C309.45 293.104 311.458 290.16 315.45 286.848C315.297 285.301 315.315 283.742 315.506 282.2C304.65 289.208 298.298 296.52 300.226 300.808C302.922 306.808 320.826 304.608 340.226 295.896C359.626 287.184 373.154 275.24 370.458 269.24Z"
      fill="#37474F"
      fill-opacity="0.5"
    />
    <path
      d="M163.469 183.766C170.51 173.956 168.266 160.296 158.456 153.254C148.646 146.213 134.986 148.458 127.945 158.267C120.903 168.077 123.148 181.737 132.957 188.779C142.767 195.82 156.427 193.576 163.469 183.766Z"
      fill="#455A64"
    />
    <g opacity="0.34">
      <path
        d="M154.019 172.184C157.819 171.504 163.467 168.352 167.027 166.176C166.947 165.816 166.835 165.456 166.731 165.104C160.331 166.936 147.627 170.584 145.707 171C143.651 171.448 131.379 170.264 124.027 168.368C123.896 169.4 123.84 170.44 123.859 171.48C127.283 171.944 134.259 172.88 136.035 173.08C142.045 173.347 148.066 173.047 154.019 172.184Z"
        fill="black"
      />
      <path
        d="M134.155 157.36C137.355 157.568 139.451 156.824 144.491 156.32C149.531 155.816 156.171 155.424 157.971 154.72C158.499 154.512 159.027 154.296 159.507 154.072C158.272 153.067 156.931 152.2 155.507 151.488C154.051 151.968 151.715 152.672 148.187 153.56C141.147 155.336 135.979 154.512 133.331 154.776L130.787 155.032C130.363 155.424 129.987 155.832 129.555 156.272C131.028 156.853 132.577 157.219 134.155 157.36Z"
        fill="black"
      />
      <path
        d="M125.938 164.8C128.546 163.152 131.626 166.088 134.466 166.728C137.306 167.368 146.81 165.032 152.186 163.104C155.906 161.768 161.674 160.896 164.866 160.48C164.251 159.378 163.544 158.329 162.754 157.344C159.36 157.551 155.992 158.065 152.69 158.88C145.898 160.48 147.09 163.152 140.618 163.336C134.146 163.52 126.17 161.192 126.17 161.192C125.488 162.578 124.952 164.031 124.57 165.528C125.044 165.321 125.502 165.078 125.938 164.8Z"
        fill="black"
      />
      <path
        d="M163.795 177.224C160.259 178.968 157.395 177.872 153.099 176.904C148.803 175.936 146.219 177.592 140.803 179.064C137.315 180.008 130.275 179.968 125.707 179.816C126.445 181.479 127.388 183.043 128.515 184.472C129.851 184.368 131.267 184.672 133.971 184.848C136.675 185.024 137.171 185 139.723 183.552C146.627 179.608 150.395 179.096 155.003 181.224C159.803 183.416 165.243 180.816 165.243 180.816C166.011 179.262 166.596 177.625 166.987 175.936C165.894 176.289 164.827 176.72 163.795 177.224Z"
        fill="black"
      />
      <path
        d="M134.637 189.816C135.053 190.064 135.437 190.312 135.893 190.528C139.991 192.587 144.634 193.304 149.162 192.578C153.691 191.851 157.876 189.718 161.125 186.48C158.101 185.48 157.557 188.192 150.725 188.88C143.893 189.568 144.629 186.816 140.285 188.88C138.536 189.742 136.57 190.068 134.637 189.816Z"
        fill="black"
      />
      <path
        d="M154.187 183.712C154.187 183.712 150.419 180.88 147.827 181.672C145.235 182.464 142.027 185.192 142.027 185.192C142.027 185.192 149.923 186.544 154.187 183.712Z"
        fill="black"
      />
    </g>
    <g opacity="0.34">
      <path
        d="M160.583 184.096C159.615 184.28 158.647 184.552 157.535 184.896C154.7 185.747 151.791 186.328 148.847 186.632C146.567 186.831 144.273 186.805 141.999 186.552C139.775 186.36 137.999 186.216 136.399 186.832C134.878 187.326 133.309 187.658 131.719 187.824C132.135 188.176 132.567 188.496 133.015 188.816C134.305 188.639 135.575 188.336 136.807 187.912C138.175 187.392 139.815 187.528 141.887 187.704C144.233 187.965 146.599 187.995 148.951 187.792C151.959 187.477 154.932 186.882 157.831 186.016C158.919 185.704 159.863 185.44 160.791 185.264C161.319 185.171 161.855 185.128 162.391 185.136C162.703 184.76 163.007 184.376 163.295 183.984C162.389 183.926 161.48 183.963 160.583 184.096Z"
        fill="black"
      />
      <path
        d="M161.374 176.264C159.086 176.824 157.63 176.312 155.422 175.528C154.157 175.057 152.867 174.657 151.558 174.328C148.862 173.664 147.022 174.2 144.478 174.928C138.034 176.888 131.242 177.434 124.566 176.528C124.67 176.944 124.774 177.328 124.91 177.76C128.567 178.239 132.269 178.274 135.934 177.864C138.943 177.558 141.912 176.949 144.798 176.048C147.254 175.336 148.878 174.864 151.278 175.456C152.551 175.771 153.804 176.161 155.03 176.624C156.87 177.272 158.382 177.808 160.334 177.616C160.777 177.57 161.215 177.492 161.646 177.384C163.15 177.016 165.15 176.456 166.974 175.928C167.07 175.504 167.182 175.072 167.254 174.64C165.294 175.2 163.038 175.848 161.374 176.264Z"
        fill="black"
      />
      <path
        d="M146.4 169.904C147.754 169.775 149.093 169.521 150.4 169.144C155.456 167.648 164.552 164.344 165.808 163.888L166.376 163.984C166.232 163.552 166.064 163.136 165.896 162.72H165.704L165.56 162.776C165.456 162.776 155.448 166.472 150.072 168.064C144.696 169.656 138.328 168.632 134.544 168.064C130.76 167.496 124.944 167.792 124.064 167.848C124.064 168.048 124.024 168.24 124 168.44L124.04 169.016C124.104 169.016 130.392 168.56 134.36 169.208C137.368 169.6 141.912 170.352 146.4 169.904Z"
        fill="black"
      />
      <path
        d="M126.602 160.424C128.93 161.224 133.458 162.464 137.586 162.024C138.324 161.956 139.055 161.823 139.77 161.624C142.234 160.936 144.21 160.208 145.954 159.56C148.179 158.615 150.519 157.97 152.914 157.64C154.692 157.506 156.48 157.578 158.242 157.856C159.782 158.156 161.365 158.156 162.906 157.856L163.138 157.792C162.882 157.456 162.634 157.104 162.338 156.776C161.009 156.968 159.657 156.938 158.338 156.688C156.486 156.396 154.606 156.323 152.738 156.472C150.242 156.812 147.803 157.479 145.482 158.456C143.762 159.096 141.818 159.816 139.394 160.496C135.25 161.648 129.842 160.232 127.13 159.352C126.978 159.72 126.794 160.08 126.602 160.424Z"
        fill="black"
      />
    </g>
    <path
      d="M235.809 246.64C236.826 244.617 237.22 242.338 236.942 240.091C236.663 237.844 235.725 235.729 234.245 234.016C232.765 232.302 230.81 231.066 228.627 230.463C226.445 229.861 224.132 229.919 221.983 230.631C219.833 231.342 217.943 232.675 216.551 234.461C215.159 236.247 214.328 238.406 214.163 240.664C213.998 242.923 214.507 245.179 215.624 247.148C216.742 249.118 218.418 250.711 220.441 251.728C223.154 253.089 226.297 253.317 229.178 252.363C232.059 251.409 234.444 249.351 235.809 246.64Z"
      fill="#455A64"
    />
    <g opacity="0.4">
      <path
        d="M232.484 238.552C232.948 237.984 233.892 239.04 233.284 237.216C232.676 235.392 232.948 233.904 230.884 233.272C230.884 233.272 228.532 232.776 228.204 233.648C227.876 234.52 228.788 234.32 228.276 235.416C227.764 236.512 228.004 236.8 227.076 236.8C226.148 236.8 225.292 233.6 224.092 236.264C222.892 238.928 222.14 239.704 222.9 240.04C223.66 240.376 225.564 239.464 226.1 240.352C226.636 241.24 226.756 242.696 226.148 242.856C225.54 243.016 224.548 241.656 223.804 242.464C223.06 243.272 223.444 245.12 224.124 245.664C224.804 246.208 227.732 248.224 230.012 246.952C232.292 245.68 233.428 245.568 233.132 243.712C232.836 241.856 232.164 241.312 231.94 240.168C231.716 239.024 232.004 239.12 232.484 238.552Z"
        fill="black"
      />
      <path
        d="M217.724 243.688C217.436 243.992 216.476 244.76 216.972 245.544C217.468 246.328 218.06 247.008 218.372 246.976C218.684 246.944 219.644 247.16 219.732 246C219.82 244.84 220.636 245.592 219.828 244.84C219.02 244.088 218.404 243 217.724 243.688Z"
        fill="black"
      />
      <path
        d="M226.196 248.912C224.86 249.144 223.868 249.664 225.076 250.272C226.284 250.88 228.924 250 228.924 250C228.924 250 229.724 248.968 229.3 248.6C228.876 248.232 227.532 248.672 226.196 248.912Z"
        fill="black"
      />
      <path
        d="M226.267 247.544C225.355 246.8 223.451 247.616 223.451 247.616C223.451 247.616 222.315 246.68 221.627 247.168C220.939 247.656 220.099 248.576 221.259 248.672C222.419 248.768 223.339 247.52 224.763 248.216C226.187 248.912 227.203 248.288 226.267 247.544Z"
        fill="black"
      />
      <path
        d="M217.043 236.856C216.699 238.672 218.243 237.36 218.011 238.224C217.779 239.088 216.627 239.024 216.579 240.56C216.531 242.096 218.379 242.896 218.699 241.92C219.019 240.944 219.499 239.52 219.499 239.52C219.736 239.243 219.907 238.915 219.999 238.561C220.09 238.207 220.1 237.838 220.027 237.48C219.891 236.048 217.379 235.048 217.043 236.856Z"
        fill="black"
      />
      <path
        d="M235.401 241.6C234.769 239.464 234.313 242.232 234.313 242.232C234.42 242.413 234.479 242.617 234.486 242.827C234.493 243.037 234.447 243.245 234.353 243.432V243.488C234.017 244.248 233.217 244.648 233.553 245.128C233.889 245.608 236.001 243.744 235.401 241.6Z"
        fill="black"
      />
    </g>
    <path
      d="M398.638 208.168C398.549 207.267 398.196 206.412 397.621 205.712C397.047 205.012 396.278 204.498 395.411 204.236C394.544 203.973 393.619 203.973 392.753 204.237C391.887 204.5 391.118 205.015 390.544 205.715C389.97 206.416 389.617 207.271 389.529 208.172C389.442 209.073 389.623 209.98 390.051 210.778C390.479 211.576 391.135 212.229 391.934 212.655C392.733 213.08 393.641 213.259 394.542 213.168C395.748 213.047 396.856 212.452 397.624 211.515C398.392 210.578 398.756 209.374 398.638 208.168Z"
      fill="#E7E7E7"
    />
    <path
      d="M383.327 220.917C387.688 215.426 386.771 207.44 381.279 203.079C375.788 198.719 367.802 199.636 363.442 205.128C359.081 210.619 359.998 218.605 365.49 222.965C370.981 227.325 378.967 226.408 383.327 220.917Z"
      fill="#455A64"
    />
    <path
      d="M380.751 209.488L382.775 210.96C383.639 209.2 383.767 208.352 383.087 207.8C382.407 207.248 380.543 205.344 380.543 205.344C380.238 204.944 379.839 204.625 379.381 204.416C378.923 204.207 378.421 204.113 377.919 204.144L375.239 204.416C374.611 204.132 373.941 203.951 373.255 203.88C374.471 202.728 375.447 202.864 374.055 202.656C372.359 202.408 371.311 202.312 370.711 202.576C370.111 202.84 369.303 203.136 368.503 203.424C367.734 203.584 367.008 203.904 366.372 204.363C365.735 204.822 365.202 205.41 364.807 206.088C364.807 206.088 362.671 208.376 362.607 209.392C362.559 210.112 363.783 209.088 364.495 208.44L364.335 209.68C364.335 209.68 364.127 211.784 365.199 212.08L366.271 212.384C366.959 212.943 367.791 213.295 368.671 213.4C368.671 213.4 369.311 213.544 369.231 214.8C369.193 215.771 369.22 216.744 369.311 217.712C369.311 217.712 368.695 217.776 370.111 219.312C371.047 220.316 372.046 221.259 373.103 222.136C373.103 222.136 374.319 223.896 374.607 222.616C374.895 221.336 375.559 219.6 375.287 219C375.015 218.4 376.567 219.288 375.023 218.4C374.047 217.831 373.139 217.152 372.319 216.376C371.119 214.832 370.871 216.52 371.119 214.832C371.367 213.144 371.471 212.088 371.471 212.088C370.597 211.404 369.538 211 368.431 210.928C367.631 208.928 367.111 208.144 367.695 207.664C367.695 207.664 368.119 208.832 368.639 208.952C369.159 209.072 369.167 207.552 369.167 207.552L370.767 205.064C370.767 205.064 372.063 205.424 372.607 204.672C372.728 205.114 372.737 205.579 372.636 206.025C372.534 206.472 372.323 206.886 372.023 207.232L372.215 209.088L375.287 208.576L374.031 210.576L373.447 213.032L373.991 214.336L375.991 215.592L378.903 219.68C379.674 219.493 380.363 219.057 380.863 218.44C381.462 217.599 381.864 216.634 382.039 215.616C382.143 214.56 382.719 214.088 382.039 213.32L381.343 212.52C381.343 212.52 380.015 211.816 380.159 211.176C380.315 210.6 380.513 210.036 380.751 209.488Z"
      fill="#263238"
    />
    <path
      d="M316.739 225.222C320.904 220.055 320.093 212.491 314.927 208.325C309.761 204.16 302.196 204.971 298.031 210.137C293.865 215.303 294.676 222.868 299.842 227.033C305.008 231.199 312.573 230.388 316.739 225.222Z"
      fill="#E7E7E7"
    />
    <g opacity="0.21">
      <path
        d="M313.929 208C314.236 208.03 314.537 208.1 314.825 208.208C313.367 207.047 311.658 206.242 309.833 205.856C308.961 206.72 306.281 209.408 304.593 211.456L303.338 212.984L300.938 215.904C303.484 214.253 305.946 212.474 308.314 210.576C310.194 208.904 313.929 208 313.929 208Z"
        fill="black"
      />
      <path
        d="M317.904 211.856C317.489 211.111 316.999 210.412 316.44 209.768C315.901 210.585 315.279 211.343 314.584 212.032C311.608 215.392 309.784 215.32 311.608 215.392C313.168 215.456 316.96 212.584 317.904 211.856Z"
        fill="black"
      />
      <path
        d="M319.336 216.456V218.472C319.392 217.801 319.392 217.127 319.336 216.456Z"
        fill="black"
      />
      <path
        d="M316.192 209.504C316.072 209.368 315.952 209.232 315.816 209.096C315.844 209.188 315.892 209.273 315.957 209.343C316.022 209.414 316.103 209.469 316.192 209.504Z"
        fill="black"
      />
      <path
        d="M319.322 218.976C317.827 219.764 316.396 220.668 315.042 221.68C313.146 223.144 307.946 227.232 307.946 227.232L304.57 229.104C304.458 229.146 304.349 229.194 304.242 229.248C305.651 229.63 307.118 229.752 308.57 229.608C310.082 229.128 312.626 224.392 318.242 222.76C318.806 221.566 319.171 220.288 319.322 218.976Z"
        fill="black"
      />
    </g>
    <g opacity="0.21">
      <path
        d="M310.898 218.52C310.898 218.52 300.994 223.792 297.746 224.824C298.361 225.648 299.076 226.392 299.874 227.04C301.298 226.288 302.914 225.36 304.594 224.256C309.138 221.248 310.194 221.4 312.994 219.08C315.794 216.76 310.898 218.52 310.898 218.52Z"
        fill="black"
      />
      <path
        d="M297.226 224.08C298.554 222.664 301.826 221.024 302.218 218.376C302.538 216.16 298.362 219.312 295.93 221.312C296.242 222.286 296.677 223.216 297.226 224.08Z"
        fill="black"
      />
    </g>
    <path
      d="M360.528 184.32C360.357 182.61 359.682 180.99 358.59 179.664C357.498 178.338 356.037 177.365 354.392 176.869C352.747 176.373 350.992 176.375 349.349 176.877C347.706 177.378 346.248 178.355 345.16 179.685C344.072 181.014 343.402 182.637 343.236 184.347C343.07 186.057 343.414 187.777 344.226 189.292C345.038 190.806 346.28 192.045 347.797 192.854C349.313 193.662 351.034 194.002 352.744 193.832C353.88 193.719 354.982 193.383 355.988 192.844C356.994 192.305 357.884 191.572 358.607 190.689C359.33 189.806 359.872 188.788 360.201 187.696C360.531 186.603 360.642 185.456 360.528 184.32Z"
      fill="#E7E7E7"
    />
    <path
      d="M346.807 182.568C346.813 182.633 346.8 182.698 346.769 182.755C346.738 182.813 346.69 182.859 346.633 182.889C346.575 182.92 346.51 182.932 346.445 182.925C346.38 182.918 346.319 182.892 346.269 182.85C346.219 182.808 346.183 182.752 346.165 182.69C346.147 182.627 346.148 182.561 346.168 182.499C346.188 182.437 346.226 182.382 346.277 182.342C346.328 182.301 346.39 182.277 346.455 182.272C346.498 182.268 346.541 182.272 346.582 182.284C346.623 182.297 346.661 182.318 346.694 182.345C346.726 182.373 346.753 182.407 346.773 182.445C346.792 182.483 346.804 182.525 346.807 182.568Z"
      fill="#263238"
    />
    <path
      d="M348.953 181.36C348.959 181.421 348.954 181.483 348.936 181.542C348.919 181.6 348.89 181.655 348.851 181.703C348.813 181.75 348.765 181.79 348.711 181.819C348.657 181.848 348.598 181.866 348.537 181.872C348.445 181.881 348.354 181.863 348.273 181.82C348.192 181.777 348.126 181.711 348.083 181.63C348.039 181.549 348.021 181.458 348.029 181.366C348.038 181.275 348.073 181.189 348.131 181.118C348.189 181.047 348.266 180.994 348.354 180.967C348.441 180.94 348.535 180.939 348.623 180.965C348.71 180.991 348.789 181.042 348.847 181.113C348.906 181.183 348.943 181.269 348.953 181.36Z"
      fill="#263238"
    />
    <path
      d="M348.719 184.688C348.72 184.7 348.719 184.713 348.715 184.724C348.711 184.736 348.705 184.746 348.696 184.755C348.688 184.764 348.678 184.772 348.667 184.777C348.656 184.782 348.644 184.784 348.631 184.784C348.62 184.785 348.608 184.784 348.597 184.78C348.586 184.777 348.576 184.771 348.567 184.764C348.558 184.757 348.55 184.747 348.545 184.737C348.54 184.727 348.536 184.716 348.535 184.704C348.531 184.69 348.53 184.675 348.533 184.66C348.535 184.645 348.541 184.631 348.55 184.619C348.559 184.607 348.571 184.597 348.584 184.591C348.598 184.584 348.612 184.581 348.627 184.581C348.642 184.581 348.657 184.584 348.67 184.591C348.684 184.597 348.695 184.607 348.704 184.619C348.713 184.631 348.719 184.645 348.722 184.66C348.724 184.675 348.724 184.69 348.719 184.704V184.688Z"
      fill="#263238"
    />
    <path
      d="M353.598 183.304C353.601 183.334 353.599 183.365 353.59 183.395C353.581 183.424 353.567 183.452 353.547 183.475C353.528 183.499 353.504 183.519 353.477 183.533C353.45 183.548 353.421 183.557 353.39 183.56C353.36 183.563 353.329 183.56 353.299 183.551C353.27 183.543 353.243 183.528 353.219 183.509C353.195 183.49 353.175 183.466 353.161 183.439C353.146 183.412 353.137 183.382 353.134 183.352C353.131 183.322 353.134 183.291 353.143 183.261C353.151 183.232 353.166 183.205 353.185 183.181C353.204 183.157 353.228 183.137 353.255 183.123C353.282 183.108 353.312 183.099 353.342 183.096C353.373 183.093 353.403 183.095 353.433 183.104C353.462 183.113 353.49 183.127 353.513 183.147C353.537 183.166 353.557 183.19 353.572 183.217C353.586 183.244 353.595 183.273 353.598 183.304Z"
      fill="#263238"
    />
    <path
      d="M352.302 180.976C352.312 181.021 352.313 181.068 352.304 181.113C352.294 181.158 352.276 181.201 352.249 181.238C352.222 181.276 352.188 181.307 352.148 181.331C352.108 181.354 352.064 181.369 352.018 181.374C351.972 181.379 351.926 181.374 351.882 181.36C351.838 181.346 351.798 181.323 351.763 181.292C351.729 181.262 351.701 181.224 351.683 181.182C351.664 181.14 351.654 181.094 351.654 181.048C351.65 181.005 351.654 180.961 351.666 180.92C351.679 180.878 351.699 180.84 351.727 180.806C351.754 180.773 351.788 180.745 351.827 180.725C351.865 180.704 351.907 180.692 351.95 180.688C351.992 180.684 352.035 180.688 352.075 180.7C352.116 180.712 352.153 180.732 352.186 180.759C352.219 180.785 352.246 180.818 352.266 180.856C352.286 180.893 352.298 180.934 352.302 180.976Z"
      fill="#263238"
    />
    <path
      d="M350.015 183.472C350.015 183.508 350.002 183.544 349.979 183.572C349.955 183.6 349.923 183.618 349.887 183.624C349.868 183.626 349.849 183.625 349.832 183.619C349.814 183.614 349.797 183.605 349.783 183.593C349.769 183.581 349.757 183.566 349.749 183.55C349.74 183.533 349.736 183.515 349.735 183.496C349.732 183.46 349.743 183.425 349.765 183.397C349.788 183.369 349.819 183.35 349.855 183.344C349.874 183.342 349.893 183.343 349.911 183.349C349.929 183.354 349.947 183.363 349.961 183.375C349.976 183.386 349.989 183.401 349.998 183.418C350.007 183.435 350.013 183.453 350.015 183.472Z"
      fill="#263238"
    />
    <path
      d="M352.072 185.2C352.074 185.224 352.072 185.249 352.064 185.272C352.057 185.295 352.045 185.316 352.03 185.335C352.014 185.354 351.995 185.369 351.973 185.38C351.952 185.391 351.928 185.398 351.904 185.4C351.879 185.403 351.854 185.402 351.83 185.395C351.806 185.388 351.783 185.377 351.764 185.361C351.744 185.345 351.728 185.326 351.717 185.303C351.705 185.281 351.698 185.257 351.696 185.232C351.694 185.208 351.696 185.183 351.704 185.16C351.711 185.137 351.723 185.116 351.738 185.097C351.754 185.078 351.773 185.063 351.795 185.052C351.816 185.041 351.84 185.034 351.864 185.032C351.914 185.027 351.963 185.042 352.002 185.074C352.041 185.105 352.066 185.15 352.072 185.2Z"
      fill="#263238"
    />
    <path
      d="M347.583 187.48C347.591 187.544 347.581 187.608 347.553 187.666C347.525 187.724 347.48 187.772 347.425 187.805C347.37 187.838 347.306 187.853 347.242 187.85C347.178 187.847 347.116 187.826 347.064 187.788C347.013 187.75 346.973 187.698 346.95 187.638C346.928 187.577 346.924 187.512 346.938 187.45C346.952 187.387 346.985 187.33 347.031 187.286C347.077 187.241 347.136 187.212 347.199 187.2C347.243 187.191 347.288 187.191 347.332 187.2C347.376 187.21 347.417 187.228 347.453 187.255C347.489 187.281 347.52 187.315 347.542 187.354C347.564 187.392 347.578 187.435 347.583 187.48Z"
      fill="#263238"
    />
    <path
      d="M351.626 188.304C351.654 188.365 351.667 188.432 351.664 188.499C351.661 188.566 351.642 188.631 351.609 188.69C351.576 188.748 351.53 188.798 351.474 188.835C351.418 188.872 351.354 188.895 351.287 188.902C351.22 188.91 351.153 188.901 351.09 188.877C351.027 188.853 350.971 188.815 350.926 188.765C350.881 188.715 350.848 188.655 350.831 188.591C350.813 188.526 350.812 188.458 350.826 188.392C350.817 188.28 350.852 188.169 350.924 188.082C350.995 187.995 351.098 187.94 351.21 187.928C351.314 187.928 351.414 187.966 351.491 188.035C351.568 188.105 351.616 188.201 351.626 188.304Z"
      fill="#263238"
    />
    <path
      d="M355.417 184.8C355.422 184.849 355.418 184.899 355.404 184.946C355.39 184.993 355.367 185.037 355.335 185.075C355.304 185.113 355.265 185.145 355.222 185.167C355.178 185.19 355.13 185.204 355.081 185.208C354.982 185.217 354.884 185.187 354.808 185.124C354.731 185.061 354.683 184.97 354.673 184.872C354.666 184.774 354.697 184.677 354.759 184.601C354.822 184.525 354.911 184.476 355.009 184.464C355.058 184.458 355.107 184.463 355.155 184.477C355.202 184.491 355.246 184.514 355.284 184.546C355.322 184.577 355.353 184.616 355.376 184.659C355.399 184.703 355.413 184.751 355.417 184.8Z"
      fill="#263238"
    />
    <path
      d="M355.569 179.664C355.573 179.7 355.57 179.737 355.56 179.772C355.55 179.808 355.533 179.84 355.51 179.869C355.487 179.897 355.458 179.921 355.425 179.938C355.393 179.955 355.357 179.965 355.321 179.968C355.284 179.972 355.247 179.97 355.211 179.959C355.175 179.949 355.142 179.932 355.113 179.909C355.084 179.886 355.06 179.858 355.042 179.825C355.024 179.793 355.013 179.757 355.009 179.72C355.002 179.645 355.025 179.57 355.073 179.512C355.121 179.453 355.19 179.416 355.265 179.408C355.339 179.403 355.412 179.427 355.468 179.475C355.525 179.523 355.561 179.59 355.569 179.664Z"
      fill="#263238"
    />
    <path
      d="M351.714 178.824C351.721 178.898 351.699 178.972 351.652 179.03C351.606 179.088 351.539 179.126 351.466 179.136C351.391 179.141 351.317 179.117 351.259 179.069C351.201 179.022 351.164 178.954 351.154 178.88C351.146 178.806 351.169 178.732 351.216 178.674C351.263 178.616 351.331 178.579 351.406 178.572C351.48 178.565 351.554 178.587 351.612 178.634C351.67 178.681 351.706 178.75 351.714 178.824Z"
      fill="#263238"
    />
    <path
      d="M357.97 182.912C357.967 182.957 357.948 182.999 357.916 183.032C357.885 183.064 357.843 183.085 357.799 183.09C357.754 183.094 357.709 183.083 357.671 183.058C357.634 183.033 357.606 182.995 357.594 182.952C357.597 182.907 357.616 182.864 357.647 182.832C357.678 182.8 357.72 182.779 357.765 182.774C357.81 182.77 357.855 182.781 357.892 182.806C357.93 182.831 357.957 182.869 357.97 182.912Z"
      fill="#263238"
    />
    <path
      d="M356.8 186.136C356.805 186.173 356.802 186.211 356.791 186.248C356.78 186.284 356.763 186.317 356.739 186.346C356.715 186.376 356.685 186.4 356.652 186.417C356.618 186.434 356.582 186.445 356.544 186.448C356.49 186.452 356.435 186.44 356.387 186.414C356.339 186.387 356.299 186.347 356.274 186.298C356.249 186.25 356.238 186.195 356.244 186.14C356.249 186.085 356.271 186.034 356.306 185.991C356.341 185.949 356.387 185.918 356.44 185.901C356.492 185.885 356.548 185.885 356.601 185.9C356.654 185.916 356.701 185.946 356.736 185.988C356.771 186.03 356.794 186.081 356.8 186.136Z"
      fill="#263238"
    />
    <path
      d="M354.624 188.144C354.628 188.181 354.625 188.218 354.615 188.254C354.605 188.289 354.588 188.323 354.565 188.352C354.542 188.381 354.513 188.405 354.481 188.423C354.448 188.441 354.413 188.452 354.376 188.456C354.339 188.46 354.302 188.456 354.267 188.445C354.231 188.435 354.198 188.417 354.17 188.394C354.141 188.37 354.117 188.342 354.1 188.309C354.082 188.276 354.071 188.241 354.068 188.204C354.064 188.167 354.068 188.13 354.078 188.095C354.089 188.059 354.107 188.026 354.13 187.998C354.153 187.969 354.182 187.945 354.215 187.928C354.247 187.911 354.283 187.9 354.32 187.896C354.393 187.889 354.466 187.911 354.523 187.958C354.579 188.004 354.616 188.071 354.624 188.144Z"
      fill="#263238"
    />
    <path
      d="M352.999 190.76C353.006 190.833 352.983 190.906 352.937 190.963C352.891 191.02 352.824 191.056 352.751 191.064C352.714 191.068 352.677 191.064 352.642 191.053C352.606 191.043 352.573 191.025 352.545 191.002C352.487 190.955 352.45 190.886 352.443 190.812C352.435 190.738 352.458 190.664 352.505 190.606C352.552 190.548 352.621 190.511 352.695 190.504C352.732 190.501 352.769 190.505 352.804 190.516C352.839 190.527 352.872 190.545 352.9 190.569C352.928 190.592 352.952 190.622 352.969 190.654C352.986 190.687 352.996 190.723 352.999 190.76Z"
      fill="#263238"
    />
    <path
      d="M347.08 190.928C347.091 190.973 347.091 191.02 347.082 191.066C347.072 191.111 347.053 191.154 347.026 191.192C346.999 191.229 346.964 191.261 346.924 191.284C346.883 191.307 346.839 191.321 346.793 191.326C346.746 191.331 346.7 191.325 346.656 191.31C346.612 191.296 346.571 191.272 346.537 191.24C346.503 191.208 346.476 191.17 346.458 191.127C346.44 191.084 346.431 191.038 346.432 190.992C346.427 190.949 346.43 190.906 346.442 190.864C346.453 190.823 346.473 190.784 346.5 190.751C346.527 190.717 346.56 190.689 346.598 190.669C346.636 190.648 346.677 190.636 346.72 190.632C346.763 190.628 346.807 190.632 346.849 190.644C346.89 190.657 346.929 190.677 346.962 190.705C346.996 190.732 347.023 190.766 347.044 190.805C347.064 190.843 347.076 190.885 347.08 190.928Z"
      fill="#263238"
    />
    <path
      d="M349.193 190.336C349.2 190.401 349.189 190.466 349.159 190.524C349.129 190.582 349.083 190.629 349.026 190.661C348.969 190.693 348.904 190.706 348.839 190.701C348.775 190.696 348.713 190.671 348.662 190.63C348.611 190.59 348.574 190.535 348.554 190.473C348.535 190.411 348.534 190.344 348.552 190.282C348.571 190.22 348.607 190.164 348.657 190.122C348.707 190.081 348.768 190.055 348.833 190.048C348.876 190.042 348.919 190.046 348.96 190.057C349.002 190.069 349.04 190.089 349.074 190.115C349.108 190.142 349.136 190.176 349.156 190.214C349.176 190.251 349.189 190.293 349.193 190.336Z"
      fill="#263238"
    />
    <path
      d="M345.775 184.464C345.781 184.519 345.775 184.574 345.759 184.626C345.743 184.679 345.717 184.728 345.682 184.77C345.647 184.813 345.604 184.848 345.556 184.874C345.507 184.899 345.454 184.915 345.399 184.92C345.293 184.922 345.191 184.883 345.111 184.813C345.032 184.743 344.982 184.646 344.971 184.54C344.96 184.435 344.989 184.329 345.052 184.244C345.115 184.159 345.207 184.1 345.311 184.08C345.423 184.071 345.534 184.106 345.621 184.178C345.708 184.25 345.763 184.352 345.775 184.464Z"
      fill="#263238"
    />
    <path
      d="M346.062 186.4C346.069 186.475 346.046 186.55 345.998 186.608C345.95 186.667 345.881 186.704 345.806 186.712C345.733 186.719 345.66 186.697 345.603 186.65C345.546 186.604 345.51 186.537 345.502 186.464C345.498 186.427 345.5 186.39 345.511 186.354C345.521 186.318 345.538 186.285 345.561 186.256C345.584 186.227 345.612 186.203 345.645 186.185C345.677 186.167 345.713 186.156 345.75 186.152C345.824 186.145 345.898 186.167 345.956 186.213C346.014 186.26 346.052 186.326 346.062 186.4Z"
      fill="#263238"
    />
    <path
      d="M357.095 189.264C357.099 189.307 357.095 189.351 357.083 189.392C357.07 189.434 357.05 189.472 357.022 189.506C356.995 189.539 356.961 189.567 356.922 189.587C356.884 189.607 356.842 189.62 356.799 189.624C356.756 189.628 356.712 189.624 356.671 189.612C356.629 189.599 356.591 189.579 356.557 189.551C356.524 189.524 356.496 189.49 356.476 189.451C356.456 189.413 356.443 189.371 356.439 189.328C356.431 189.241 356.457 189.154 356.513 189.087C356.568 189.019 356.648 188.976 356.735 188.968C356.822 188.96 356.909 188.986 356.976 189.041C357.044 189.097 357.087 189.177 357.095 189.264Z"
      fill="#263238"
    />
    <path
      d="M359.303 185.888C359.294 185.931 359.27 185.97 359.236 185.998C359.202 186.026 359.159 186.041 359.115 186.041C359.07 186.041 359.028 186.026 358.993 185.998C358.959 185.97 358.936 185.931 358.927 185.888C358.922 185.838 358.937 185.789 358.968 185.75C359 185.711 359.045 185.686 359.095 185.68C359.122 185.678 359.151 185.681 359.177 185.691C359.203 185.7 359.227 185.716 359.247 185.735C359.267 185.755 359.282 185.779 359.292 185.806C359.301 185.832 359.305 185.86 359.303 185.888Z"
      fill="#263238"
    />
    <path
      d="M350.504 185.544C350.51 185.593 350.506 185.643 350.491 185.69C350.477 185.737 350.454 185.781 350.423 185.819C350.391 185.857 350.353 185.889 350.309 185.911C350.265 185.934 350.218 185.948 350.168 185.952C350.094 185.96 350.019 185.945 349.954 185.91C349.888 185.875 349.834 185.821 349.799 185.756C349.763 185.69 349.748 185.615 349.756 185.541C349.763 185.467 349.792 185.396 349.84 185.339C349.887 185.281 349.95 185.239 350.022 185.218C350.093 185.196 350.17 185.197 350.241 185.219C350.312 185.241 350.375 185.283 350.422 185.341C350.469 185.399 350.498 185.47 350.504 185.544Z"
      fill="#263238"
    />
    <path
      d="M349.487 179.2C349.492 179.243 349.488 179.286 349.475 179.327C349.462 179.368 349.442 179.406 349.414 179.439C349.386 179.472 349.353 179.499 349.314 179.518C349.276 179.537 349.234 179.549 349.191 179.552C349.111 179.55 349.035 179.519 348.976 179.465C348.917 179.41 348.881 179.336 348.873 179.256C348.865 179.177 348.886 179.097 348.933 179.032C348.98 178.967 349.049 178.921 349.127 178.904C349.171 178.9 349.214 178.904 349.256 178.916C349.297 178.929 349.336 178.949 349.369 178.977C349.403 179.004 349.43 179.038 349.451 179.077C349.471 179.115 349.483 179.157 349.487 179.2Z"
      fill="#263238"
    />
    <path
      d="M346.734 179.936C346.737 179.954 346.735 179.973 346.73 179.991C346.724 180.009 346.715 180.026 346.703 180.04C346.691 180.054 346.677 180.066 346.66 180.074C346.643 180.082 346.625 180.087 346.606 180.088C346.575 180.08 346.547 180.062 346.527 180.037C346.507 180.012 346.496 179.98 346.496 179.948C346.496 179.916 346.507 179.884 346.527 179.859C346.547 179.833 346.575 179.816 346.606 179.808C346.64 179.81 346.671 179.824 346.695 179.848C346.718 179.871 346.732 179.903 346.734 179.936Z"
      fill="#263238"
    />
    <path
      d="M355.201 190.64C355.203 190.664 355.201 190.689 355.193 190.712C355.186 190.735 355.174 190.756 355.159 190.775C355.143 190.794 355.124 190.809 355.102 190.82C355.081 190.831 355.057 190.838 355.033 190.84C355.008 190.843 354.983 190.842 354.959 190.835C354.935 190.828 354.912 190.817 354.893 190.801C354.873 190.785 354.857 190.766 354.846 190.743C354.834 190.721 354.827 190.697 354.825 190.672C354.823 190.648 354.825 190.623 354.833 190.6C354.84 190.577 354.852 190.556 354.867 190.537C354.883 190.518 354.902 190.503 354.924 190.492C354.945 190.481 354.969 190.474 354.993 190.472C355.018 190.469 355.043 190.47 355.067 190.477C355.091 190.484 355.114 190.495 355.133 190.511C355.153 190.527 355.169 190.546 355.18 190.569C355.192 190.591 355.199 190.615 355.201 190.64Z"
      fill="#263238"
    />
    <path
      d="M351.008 191.568C351.012 191.599 351.009 191.629 351 191.659C350.991 191.688 350.977 191.716 350.958 191.739C350.938 191.763 350.914 191.783 350.887 191.797C350.86 191.812 350.831 191.821 350.8 191.824C350.77 191.827 350.739 191.824 350.71 191.816C350.68 191.807 350.653 191.792 350.629 191.773C350.605 191.754 350.586 191.73 350.571 191.703C350.556 191.676 350.547 191.646 350.544 191.616C350.541 191.586 350.544 191.555 350.553 191.525C350.561 191.496 350.576 191.469 350.595 191.445C350.614 191.421 350.638 191.401 350.665 191.387C350.692 191.372 350.722 191.363 350.752 191.36C350.783 191.357 350.814 191.359 350.843 191.368C350.872 191.377 350.9 191.391 350.924 191.411C350.947 191.43 350.967 191.454 350.982 191.481C350.996 191.508 351.005 191.537 351.008 191.568Z"
      fill="#263238"
    />
    <path
      d="M355.607 181.92C355.598 181.963 355.574 182.002 355.54 182.03C355.506 182.058 355.463 182.073 355.419 182.073C355.374 182.073 355.332 182.058 355.297 182.03C355.263 182.002 355.24 181.963 355.231 181.92C355.225 181.892 355.225 181.863 355.232 181.836C355.239 181.808 355.252 181.782 355.27 181.76C355.288 181.738 355.31 181.72 355.336 181.708C355.362 181.695 355.39 181.689 355.419 181.689C355.447 181.689 355.475 181.695 355.501 181.708C355.527 181.72 355.549 181.738 355.567 181.76C355.585 181.782 355.598 181.808 355.605 181.836C355.612 181.863 355.612 181.892 355.607 181.92Z"
      fill="#263238"
    />
    <path
      d="M354.153 178.72C354.156 178.75 354.153 178.781 354.145 178.811C354.136 178.84 354.122 178.868 354.102 178.891C354.083 178.915 354.059 178.935 354.032 178.949C354.005 178.964 353.975 178.973 353.945 178.976C353.914 178.979 353.883 178.976 353.854 178.968C353.825 178.959 353.797 178.944 353.774 178.925C353.75 178.906 353.73 178.882 353.716 178.855C353.701 178.828 353.692 178.798 353.689 178.768C353.686 178.738 353.688 178.707 353.697 178.677C353.706 178.648 353.72 178.621 353.74 178.597C353.759 178.573 353.783 178.553 353.81 178.539C353.837 178.524 353.866 178.515 353.897 178.512C353.927 178.509 353.958 178.511 353.988 178.52C354.017 178.529 354.044 178.543 354.068 178.563C354.092 178.582 354.112 178.606 354.126 178.633C354.141 178.66 354.15 178.689 354.153 178.72Z"
      fill="#263238"
    />
    <path
      d="M333.825 159.504C333.649 157.728 336.025 155.504 335.593 153.848C335.161 152.192 331.977 151.368 331.281 149.784C330.585 148.2 332.145 145.32 331.209 143.856C330.273 142.392 327.009 142.632 325.849 141.328C324.689 140.024 325.297 136.832 323.945 135.728C322.593 134.624 319.585 135.864 318.065 134.976C316.545 134.088 316.169 130.864 314.529 130.224C312.889 129.584 310.433 131.696 308.713 131.32C306.993 130.944 305.641 128.008 303.873 127.904C302.105 127.8 300.449 130.576 298.681 130.76C296.913 130.944 294.681 128.552 293.025 128.992C291.369 129.432 290.545 132.6 288.953 133.304C287.361 134.008 284.497 132.432 283.033 133.376C281.569 134.32 281.809 137.568 280.505 138.736C279.201 139.904 276.009 139.28 274.905 140.64C273.801 142 275.041 145 274.153 146.512C273.265 148.024 270.041 148.416 269.401 150.056C268.761 151.696 270.873 154.144 270.497 155.872C270.121 157.6 267.177 158.936 267.081 160.672C266.985 162.408 269.753 164.088 269.929 165.864C270.105 167.64 267.729 169.864 268.169 171.52C268.609 173.176 271.777 174 272.473 175.584C273.169 177.168 271.609 180.04 272.553 181.512C273.497 182.984 276.745 182.728 277.913 184.032C279.081 185.336 278.457 188.536 279.817 189.632C281.177 190.728 284.177 189.496 285.689 190.384C287.201 191.272 287.585 194.496 289.225 195.136C290.865 195.776 293.321 193.664 295.049 194.032C296.777 194.4 298.113 197.352 299.889 197.456C301.665 197.56 303.305 194.784 305.081 194.6C306.857 194.416 309.081 196.808 310.737 196.368C312.393 195.928 313.209 192.76 314.801 192.056C316.393 191.352 319.257 192.928 320.721 191.984C322.185 191.04 321.945 187.792 323.249 186.624C324.553 185.456 327.753 186.08 328.849 184.72C329.945 183.36 328.721 180.36 329.609 178.848C330.497 177.336 333.721 176.944 334.361 175.304C335.001 173.664 332.881 171.216 333.257 169.488C333.633 167.76 336.577 166.416 336.673 164.688C336.769 162.96 334.001 161.272 333.825 159.504Z"
      fill="#455A64"
    />
    <path
      d="M322.13 184.524C334.196 173.332 334.904 154.476 323.711 142.41C312.519 130.344 293.663 129.637 281.597 140.829C269.531 152.022 268.824 170.877 280.016 182.943C291.209 195.009 310.064 195.717 322.13 184.524Z"
      fill="#455A64"
    />
    <path
      opacity="0.23"
      d="M322.44 162.753C322.474 151.385 313.285 142.142 301.917 142.108C290.549 142.074 281.306 151.263 281.272 162.631C281.238 173.999 290.427 183.242 301.795 183.276C313.163 183.31 322.406 174.121 322.44 162.753Z"
      fill="#E7E7E7"
    />
    <path
      opacity="0.42"
      d="M313.866 164.154C314.671 157.526 309.95 151.502 303.323 150.697C296.696 149.893 290.671 154.613 289.867 161.241C289.062 167.868 293.783 173.893 300.41 174.697C307.037 175.501 313.062 170.781 313.866 164.154Z"
      fill="#E7E7E7"
    />
    <path
      opacity="0.11"
      d="M319.953 145.872C319.953 145.872 313.417 138.76 308.881 139.472C304.345 140.184 308.521 143.152 310.393 144.904C312.265 146.656 311.401 150.136 309.593 148.864C307.785 147.592 306.809 142.84 305.321 147.352C303.833 151.864 304.457 153.256 306.401 153.304C308.345 153.352 309.449 152.272 310.625 151.912C311.801 151.552 313.825 152.08 313.345 154.792C312.865 157.504 313.657 157.912 312.001 158.32C310.345 158.728 308.561 157.936 308.273 159.92C308.19 160.407 308.213 160.906 308.341 161.383C308.469 161.86 308.698 162.303 309.013 162.683C309.329 163.063 309.723 163.37 310.168 163.584C310.613 163.797 311.099 163.912 311.593 163.92C314.041 164.16 315.793 164.72 315.961 161.52C316.129 158.32 319.297 153.664 320.593 156.928C321.889 160.192 324.529 162.352 325.153 158.896C325.777 155.44 328.465 153.232 323.425 151.312C318.385 149.392 321.681 148.608 319.953 145.872Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M301.366 166.16C301.366 166.16 301.006 167.408 299.086 167.6C297.166 167.792 297.142 165.128 294.478 162.728C291.814 160.328 288.47 160.904 289.51 163.952C290.55 167 292.606 168.248 291.046 169.616C289.486 170.984 287.102 166.616 285.326 168.248C283.55 169.88 283.046 172.112 285.062 172.88C287.078 173.648 290.278 174.056 290.494 176.216C290.71 178.376 289.558 178.984 292.094 180.424C294.63 181.864 298.31 181.984 298.694 178.544C299.078 175.104 301.574 173.408 305.094 174.544C308.614 175.68 311.198 172.48 309.23 169.744C307.262 167.008 304.486 163.424 301.366 166.16Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M296.591 152.328C296.591 152.328 297.311 149.832 294.191 150.384C291.071 150.936 289.287 152.328 289.215 154.032C289.143 155.736 289.527 157.16 290.519 157.304C291.511 157.448 293.088 156.312 294.384 157.152C294.94 157.458 295.427 157.877 295.811 158.383C296.196 158.889 296.47 159.47 296.615 160.088C296.711 161.048 294.263 162.032 298.055 162.368C301.847 162.704 302.855 162.128 303.599 159.632C304.343 157.136 304.631 155.4 302.543 153.912C300.455 152.424 299.087 150.624 296.591 152.328Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M319.087 168.752C318.915 169.465 318.598 170.136 318.156 170.722C317.714 171.308 317.157 171.797 316.519 172.16C314.239 173.6 313.231 173.216 312.319 176.216C311.407 179.216 306.839 177.248 303.791 178.28C300.743 179.312 301.223 184.12 304.223 185.032C307.223 185.944 312.751 185.392 315.463 183.432C318.175 181.472 316.375 178 319.207 177.232C322.039 176.464 326.815 175.744 326.599 171.16C326.383 166.576 321.655 165.344 319.087 168.752Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M283.913 163.784C283.913 163.784 284.441 159.368 279.977 158.36C275.513 157.352 273.953 161.144 275.441 166.36C276.929 171.576 282.185 173.2 281.297 169.168C280.409 165.136 284.417 166.4 283.913 163.784Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M283.505 145.152C283.505 145.152 279.665 147.96 279.305 151.632C278.945 155.304 282.185 158.624 284.177 156.728C286.169 154.832 285.113 151.536 288.617 150.216C292.121 148.896 295.081 146.904 292.417 144.504C289.753 142.104 286.121 142.224 283.505 145.152Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M289.92 182.584C289.92 182.584 287.976 182.536 285.76 179.848C283.544 177.16 280.072 176.296 281.176 180.064C282.28 183.832 286.464 186.808 290.16 187.408C293.856 188.008 298.296 186.352 295.656 184.208C293.016 182.064 291.336 182.2 289.92 182.584Z"
      fill="black"
    />
    <path
      opacity="0.11"
      d="M300.55 136.168C300.55 136.168 294.79 136.744 294.046 139.008C293.302 141.272 298.894 141.408 297.862 143.232C296.83 145.056 292.078 145.992 294.662 147.912C297.246 149.832 303.422 150.672 302.918 148.056C302.414 145.44 300.518 143.208 302.318 142.056C304.118 140.904 303.038 139.56 304.79 137.68C306.542 135.8 303.406 135.64 300.55 136.168Z"
      fill="black"
    />
  </svg>
);
