import { createUseStyles } from "react-jss";

export const customMultiSelectAutoCompleteStyles = createUseStyles(() => ({
  placeholder:{
    position: 'absolute',
    left:'15px',
    top: '18px',
    color:'#B7B7B7',
    zIndex: 900,
    userSelect: 'none',
    pointerEvents: 'none',
  },
  optionStyles:{
    paddingLeft: '8px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color:'red',
  },
  dot:{
    backgroundColor: '#000',
    borderRadius: '10px',
    display: 'inline-block',
    height: '10px',
    width: '10px',
    margin: '0 8px',
  },
  emptyDot: {
    color: '#EAEAEA',
  },
  optionText:{
    color:  '#0F314D',
  },
  resetBtn:{
    borderRadius: '8px !important',
    border: '1px solid var(--Stroke, #D9D9D9) !important',
    background: '#FFF',
    color: '#0F314C !important',
    width: '100%',
    marginBottom: '8px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    textTransform: 'capitalize !important',
  },
  activeDateBtn:{
    backgroundColor: 'rgba(15, 49, 77, 0.05) !important',
    border: '1px solid #0F314D !important',
    background: '#FFF',
    color: '#0F314C !important',
    width: '100%',
    marginBottom: '8px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    maxWidth:'280px',
    textTransform: 'capitalize !important',
    borderRadius: '8px !important',
  },
  dateBtn: {
    borderRadius: '8px !important',
    border: '1px solid #D9D9D9 !important',
    background: '#FFF',
    color: '#0F314C !important',
    width: '100%',
    marginBottom: '8px !important',
    fontSize: '12px !important',
    fontWeight: '400 !important',
    maxWidth:'280px',
    textTransform: 'capitalize !important',
  },
  dateChipInvalid:{
    border: '1px solid #B3261E !important',
  },
  dateBtnWrap:{
    display: 'flex',
    gap: '8px',
  },
}));
