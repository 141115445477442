import { styled } from "@mui/system";
import Select from "@mui/material/Select";
import { AppThemeConstants } from "constants/style.constants";

import { createUseStyles } from "react-jss";
import { IAdAccount, ISelectionOption } from 'modules/BrandsReporting/components/ReportRequest/types';

interface IMultiselectWithSearch {
 selected:IAdAccount[] | ISelectionOption[]
}

export const MenuProps = {
  autoFocus: false,
  PaperProps: {
    style: {
      maxHeight: "314px",
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      marginTop: "8px",
      marginLeft: 0,
      borderRadius: "8px",
      padding: "8px",
      border: `1px solid ${AppThemeConstants.grey1}`,
      zIndex:"9999",
    },
  },
};

export const MultiSelectMenuItemStyles = {
  padding: "8px",
  backgroundColor: "transparent",

};
export const AutoCompleteItemsStyles:any = {
  backgroundColor: "transparent",
  "&:hover":{
    backgroundColor: "#F3F4F6 !important",
  },
};

export const CustomSelect = styled(Select)(() => ({
  width: 300,
  borderRadius: "8px",
  "& .MuiSelect-select": {
    padding: "8px 16px !important",
  },
  "& .MuiSelect-select:hover": {
    boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
  },
  "& .Mui-disabled": {
    background: "#F6F7F8",
    borderRadius: "8px",
  },
  "& .Mui-disabled:hover": {
    boxShadow: "none",
  },
  "& .MuiSvgIcon-root.Mui-disabled": {
    border: "none",
  },
}));

export const stylesSx = () => ({
  color: "#B7B7B7",
  backgroundColor: "#FFFFFF",
  fontWeight: "300",
  fontSize: "16px",
  outline: "none",
  borderRadius: "4px",
  marginTop: "16px",
  border: `1px solid ${AppThemeConstants.grey1}`,
  width: "364px",
  padding: "0 !important",
  "& fieldset": {
    border: 0,
    padding: "0 !important",
    "&:focus-visible": {
      borderColor: "transparent !important",
      borderWidth: "0 !important",
      outline: 0,
      border: 0,
    },
  },
  '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#0F314D !important',
        borderWidth: '1px !important',
      },
  '&.MuiSelect-multiple .MuiInputBase-input': {
    paddingLeft: '100px !important',
  },
  "& .MuiMenuItem-root.Mui-selected": {
    border: "none !important",
  },
  "& .MuiMenuItem-root:hover": {
    border: "none !important",
  },
  "& .MuiMenuItem-root.Mui-selected:hover": {
    border: "none !important",
  },
});

export const useStylesFiltersRenderValue = createUseStyles(() => ({
  renderValue: {
    color: "#B7B7B7",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.3px",
  },
  filterValue: {
    color: "#444",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "-0.3px",
  },
}));

export const multiSelectWithSearchUseStyles = createUseStyles(() => ({
  serchInput:{
    "&>.MuiAutocomplete-input::placeholder" : {
      color:({ selected }:IMultiselectWithSearch) => (selected ? "#0F314D" : "#B7B7B7"),
    },
    position:"relative",
    "&>input":{
      width: '391px',
      borderRadius: '8px',
      height: "40px",
      margin: "8px 0",
      border:"1px solid #D9D9D9",
      padding:"8px 16px",
      fontSize:"16px",
      fontWeight:"400",
      color:"#444",
      "&:hover":{
        boxShadow:"0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      },
      "&:focus":{
        border:"1px solid #0F314D",
      },
    },
  },
  labelStyle: {
    position: 'absolute',
    left:'15px',
    top: '18px',
    color:'#B7B7B7',
    pointerEvents: 'none',
  },
  autoCompleteInput:{
    "&>.MuiAutocomplete-input::placeholder" : {
      color:({ selected }:IMultiselectWithSearch) => (selected ? "#0F314D" : "#B7B7B7"),
    },
    position:"relative",
    "&>input":{
      width:'100%',
      borderRadius: '8px',
      height: "40px",
      margin: "8px 0",
      border:"1px solid #D9D9D9",
      padding:"8px 16px",
      fontSize:"16px",
      fontWeight:"400",
      color:"#444",
      "&:hover":{
        boxShadow:"0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
      },
      "&:focus":{
        border:"1px solid #0F314D",
      },
    },
  },
  optionName:{
    fontSize:"16px",
    fontWeight:"400",
    color:"#444444",
  },
  optionId:{
    fontSize:"16px",
    fontWeight:"400",
    color:"#959595",
  },
  arrowIcon:{
    position:"absolute",
    top:"15px",
    right:"20px",
  },
}));

interface IStatisticsSelectStylesProps {
  length:number
}

export const selectForStatisticsUseStyles = createUseStyles((length:IStatisticsSelectStylesProps) => ({
  wrapper:{
    position:"relative",
  },
  optionsContainer:{
    maxHeight: "314px",
    overflow:"auto",
    boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
    marginTop: "8px",
    marginLeft: 0,
    borderRadius: "8px",
    padding: "8px",
    border: `1px solid ${AppThemeConstants.grey1}`,
    maxWidth:"300px",   
    background:"#fff",
    position:"absolute",
    zIndex:"9999",
    width:"100%",
  },
  searchInput:{
    width:"300px",
    height:"42px",
    padding:"8px 16px",
    border: `1px solid ${AppThemeConstants.grey1}`,
    borderRadius: "8px",
    fontSize:"16px",
    fontWeight:"400",
    color:"#0F314D",
    margin:"8px 24px 0 0",
    "&:hover":{
      boxShadow: "0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
    },
  },
  renderValue:{
    color:"#444",
  },
  option:{
    padding:"8px",
    cursor:"pointer",
    "&:hover":{
      background:"#f3f4f6",
    },
  },
  optionName:{
    fontSize:"16px",
    fontWeight:"400",
    lineHeight:"24px",
    color:"#0F314D",
  },
  inputContainer:{
    position:"relative",
  },
  label:{
    position:"absolute",
    fontSize:"16px",
    fontWeight:"400",
    lineHeight:"20px",
    color:"#B7B7B7",
    top:"18px",
    left:"20px",
  },
  arrowIcon:{
    position:"absolute",
    top:"16px",
    right:"40px",
  },
}));
