import { createUseStyles } from "react-jss";


export const ReportSuccessUseStyles = createUseStyles(() => ({
  successWrapper: {
    // maxWidth:"617px",
    paddingRight: "32px",
    "& > p": {
      margin: "8px 0 16px",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "27px",
      width: "425px",
      textAlign: "center",
    },
    "& > button": {
      marginTop: "32px",
    },
  },
}));
