import React from "react";
import MemberList from "../../components/MemberList/MemberList";
import InviteNewMembers from "../../components/InviteNewMembers/InviteNewMembers";

const TeamMemberList = () => (
  <div style={{
    maxWidth: "1288px",
    margin: "auto",
    marginBottom: "64px",
    width: "100%",
    display: "block",
    // border: '1px solid red'
  }}>
    <InviteNewMembers />
    <MemberList />
  </div>
);
export default TeamMemberList;
