import React, { useEffect, useState } from 'react';
import Tabs from '../components/Tabs/Tabs';
import Filters from '../components/Filters/Filters';
import { useStylesSuggestions } from './suggestions.styles';
import { getSuggestions } from '../../../services/suggestions.services';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';
import { getPageFromUrl } from '../../../helpers/PageFromUrl';
import Spinner from '../../shared/components/general/Spinner/Spinner';
import SuggestionsData from '../components/SuggestionsData/SuggestionsData';
import { servicesNames } from '../../../constants/servicesNames.constants';
import EmptyStateSuggestions from '../components/EmptyStateSuggestions/EmptyStateSuggestions';
import './suggestions.scss';
import InfiniteScroll from '../../shared/components/general/InfiniteScroll/InfiniteScroll';
import { useDispatch, useSelector } from 'react-redux';

import useDebounce from 'hooks/useDebounce';
import WarningToast from '../../shared/components/general/WarningToast/WarningToast';
import { getWarnings, reSyncBrandAirTable } from '../../../services/addBrandModal.seervices';
import { setBrand, setItemCount, setManager } from '../../../store/suggestions/suggestionSlice';

interface ISuggestions {
    data: any;
    isFetchingManager: boolean;
    refetch: () => void;
    brandsData: any;
    isFetchingBrands: any;
    refetchBrands: any;
    logicSequence: any;
    isLogicSequenceFetching: any;
    refetchLogicSequence: any;
}

const Suggestions: React.FC<ISuggestions> = (
  {
    data,
    isFetchingManager,
    refetch,
    brandsData,
    isFetchingBrands,
    refetchBrands,
    logicSequence,
    isLogicSequenceFetching,
    refetchLogicSequence,
  }) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('suggestions');
  const queryClient = useQueryClient();
  const [isEmptyState, setIsEmptyState] = useState<boolean>(false);
  const [warnings, setWarnings] = useState<{ title: string, info: string } | null>(null);

  const styles = useStylesSuggestions();

  const {
    suggestion: {
      brands = [],
      managers,
      searchValueOfCampaign,
      isSelectOpen,
      date,
      type,
      itemCount,
      logicSequenceData = [],
    },
  } = useSelector((state: any) => state);

  const debouncedSearchValue = useDebounce(searchValueOfCampaign, 1000);

  useQuery(
    ['warnings'],
    () => getWarnings(undefined, 'suggestions'),
    {
      onSuccess: (resWar) => {
        if (!resWar.data?.warnings.length) {
          setWarnings(null);
          return;
        }
        setWarnings(resWar.data?.warnings[0]);
      },
    },
  );

  const { mutate: reSyncAirTable } =
        useMutation(
          () => reSyncBrandAirTable(undefined, 'suggestions'),
          {
            onSuccess: (res) => {
              if (!res.data?.warnings.length) {
                setWarnings(null);
                return;
              }
              setWarnings(res.data?.warnings[0]);
            },
          },
        );


  const handleReSync = () => reSyncAirTable(undefined);


  const {
    data: suggestionsResponse,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    refetch: refetchSuggestions,
  } = useInfiniteQuery(
    [
      servicesNames.getSuggestions,
      brands.length,
      logicSequenceData.length,
      {
        activeTab,
        date,
        type,
      },
    ],
    ({ pageParam = 1 }) =>
      getSuggestions({
        activeTab,
        brands,
        managers,
        page: pageParam,
        searchValueOfCampaign: debouncedSearchValue,
        date,
        type,
        logicSequenceData,
        logicSequenceDataLength: logicSequenceData.length,
      }),
    {
      getNextPageParam: ({ data: { next = '' } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
      onSuccess: (data: any) => {
        if (!data?.pages[0]?.data?.results?.length) {
          setIsEmptyState(true);
        } else {
          if (managers?.length && brands?.length) {
            setIsEmptyState(false);
          }
        }

        if (activeTab === 'suggestions') {
          dispatch(setItemCount(data?.pages[0]?.data?.count));
        }
      },
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    refetchSuggestions()
      .catch((err: Error) => {
        throw new Error(err.message);
      });
  }, [debouncedSearchValue, refetchSuggestions]);

  useEffect(() => {
    if (isSelectOpen) {
      setIsEmptyState(prev => prev);
    }
    // eslint-disable-next-line
    }, [isSelectOpen, managers, brands, searchValueOfCampaign]);

  useEffect(() => {
    queryClient.removeQueries([
      servicesNames.getSuggestions,
      {
        activeTab,
        debouncedSearchValue,
        date,
        type,
      },
    ]);
    return () => {
      dispatch(setManager([]));
      dispatch(setBrand([]));
      queryClient.invalidateQueries(servicesNames.getSuggestions);
    };

    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (!managers.length || !brands.length) {
      setIsEmptyState(true);
    } else {
      setIsEmptyState(false);
    }
    // eslint-disable-next-line
    }, [activeTab]);

  useEffect(() => {
    if (isEmptyState && activeTab === 'suggestions') {
      dispatch(setItemCount(0));
    }
    // eslint-disable-next-line
    }, [isEmptyState, activeTab]);


  const suggestionsData = suggestionsResponse?.pages.map((page: any) =>
    page.data?.results?.map((item: any) => item),
  );


  const flattenedArray = [].concat.apply([], suggestionsData as any);

  const generateContent = () => {
    if (isFetching && !isFetchingNextPage) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner />
        </div>
      );
    }

    if (isEmptyState) {
      return <EmptyStateSuggestions activeTab={activeTab} />;
    }

    if (flattenedArray?.length && !isEmptyState) {
      return (
        <SuggestionsData
          flattenedArray={flattenedArray}
          isFetchingNextPage={isFetchingNextPage}
          activeTab={activeTab}
        />
      );
    }
  };

  return (
    <div
      id="suggestions"
      style={{
        maxWidth: '1288px',
        margin: 'auto',
        marginBottom: '64px',
        width: '100%',
      }}
    >
      <h1 className={styles.title}>Ads suggestion optimization</h1>
      {
        warnings &&
                <WarningToast title={warnings?.title ?? ''} info={warnings?.info ?? ''} handleReSync={handleReSync} />
      }
      <Tabs
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        itemCount={isEmptyState && activeTab === 'suggestions' ? 0 : itemCount}
      />
      <Filters
        activeTab={activeTab}
        refetch={refetchSuggestions}
        isFetchingSuggestions={isFetching}
        managersData={data}
        refetchManagers={refetch}
        isFetchingManagers={isFetchingManager}
        brandsData={brandsData}
        isFetchingBrands={isFetchingBrands}
        refetchBrands={refetchBrands}
        logicSequence={logicSequence}
        isLogicSequenceFetching={isLogicSequenceFetching}
        refetchLogicSequence={refetchLogicSequence}
      />
      <InfiniteScroll
        scroll
        next={() => fetchNextPage()}
        maxHeight={'calc(100vh - 350px)'}
        hasMore={!isFetching}
      >
        {generateContent()}
      </InfiniteScroll>
    </div>
  );
};

export default Suggestions;
