import React from "react";
import { AlienIcon } from "../../../shared/components/general/Icons/Alien.icon";
import "./RequestConfirmCampaignDuplication.scss";

const RequestConfirmCampaignDuplication:React.FC<any> = () => (
  <div className="request-confirm-campaign-duplication-wrapper">
    <h1 className="header-title">Confirm campaign duplication</h1>
    <div className="request-confirm-campaign-duplication-description">
      {AlienIcon}
      <p>Looks like you don't have any duplicated campaigns</p>
      <p>Check your email if there are missed duplications</p>
    </div>
  </div>
);
export default RequestConfirmCampaignDuplication;
