import { createUseStyles } from "react-jss";

export const useStylesSuggestions: any = createUseStyles(() => ({
  title: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "48px",
    textAlign: "center",
    marginTop: "32px",
  },
  tabs: {
    padding: "8px 16px",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "1.5",
    color: "#B7B7B7",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    cursor: "pointer",
  },
}));
