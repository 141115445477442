import { CSSProperties, useEffect, useState } from "react";
import { imageLoaderUseStyles } from "./ImageLoader.styles";

export const enum ImgLoadState {
  Idle = "Idle",
  Loading = "Loading",
  Success = "Success",
}

export const ImageLoader = ({
  placeholderSrc,
  width,
  height,
  style,
  src,
  className,
  alt,
}: {
  width?: number | string;
  height?: number | string;
  className?: string;
  placeholderSrc?: string | null;
  src: any;
  style?: CSSProperties;
  alt: string;
}) => {
  const [imgData, setImgSrc] = useState({
    img: placeholderSrc || src,
    loadState: ImgLoadState.Idle,
  });

  const styles = imageLoaderUseStyles({
    loadState: imgData.loadState,
  });

  const allStyles = className ? `${styles.image} ${className}` : styles.image;

  useEffect(() => {
    if (src) {
      setImgSrc(prev => ({ ...prev, loadState: ImgLoadState.Loading }));
      const img = new Image();
      img.src = src;
      img.onload = () => {
        setImgSrc({ img: src, loadState: ImgLoadState.Success });
      };
      img.onerror = () => {
        if (placeholderSrc === null) {
          setImgSrc(prev => ({ ...prev, img: null }));
          return;
        }

        setImgSrc({
          img: placeholderSrc ?? null,
          loadState: ImgLoadState.Success,
        });
      };
    }
    // eslint-disable-next-line
  }, [src]);

  if (imgData.img === null) {
    return null;
  }

  return (
    <img
      width={width}
      height={height}
      style={style}
      className={allStyles}
      src={imgData.img}
      alt={alt}
    />
  );
};
