import React from 'react';
import { Box } from '@mui/material';
import { useStyles } from './WarningToast.styles';
import { ReactComponent as WarningIcon } from 'assets/icons/warningIcon.svg';


interface IWarningToast {
    title:string
    info: string
    handleReSync:() => void
}

const WarningToast:React.FC<IWarningToast> = ({ title, info, handleReSync }) => {
  const styles = useStyles();

  return  (
    <Box className={styles.toastWrap} >
      <WarningIcon  style={{ width:'32px', height:'32px' }}/>
      <Box>
        <strong className={styles.toastTitle}>{title}</strong>
        {
          info.includes('Resync.') ?
            <p className={styles.toastText}>{info.replace('Resync.' ,'')}
              <span
                className={styles.reSyncText}
                onClick={handleReSync}
              >
                  Resync.
              </span>
            </p>
            :
            <p className={styles.toastText}>{info}</p>
        }
      </Box>
    </Box>
  );
};

export default WarningToast;
