import React from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import { successFailedModalUseStyles } from "./SuccessFailedModal.styles";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import { useMutation } from "react-query";
import { regenerateEmail } from "../../../../services/clientCommunication";
import Spinner from "../../../shared/components/general/Spinner/Spinner";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";

const RegenerateEmailModal = ({ onClose, emailId }:any) => {
  const styles = successFailedModalUseStyles();
  const { setEmailStatus, setUpdatedStatus } = useClientCommunicationContext();

  const { mutate: mutateRegenerateEmail, isLoading } = useMutation(
    regenerateEmail,
    {
      onSuccess: (response) => {
        setUpdatedStatus(response.data.status);
        setEmailStatus({
          [emailId]: "regenerate",
        });
        onClose();
      },
    },
  );

  return (
    <Flex direction="column" style={{ gap: "16px" }}>
      <Flex
        direction="column"
        alignItems="center"
        style={{ gap: "8px", paddingTop: "24px" }}
      >
        <h3 className={styles.modalTitle}>
          Are you sure you want to regenerate <br /> the email?
        </h3>
        <span className={styles.modalMessage}>
          The email will be generated anew with updated metrics and text.
          <br />
          Regeneration will take some time, please refresh the page later to{" "}
          <br /> see the updated email.
        </span>
      </Flex>
      <Flex justifyContent="flex-end" style={{ gap: "16px", width: "100%" }}>
        <Button variant={ButtonVariants.Secondary} handleClick={onClose}>
          Cancel
        </Button>
        <Button
          handleClick={() => !isLoading && mutateRegenerateEmail(emailId)}
          style={{
            textAlign: "left",
            width: "107px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoading ? (
            <Spinner
              white={true}
              style={{
                width: "16px",
                margin: "0",
                left: "50%",
                position: "initial",
              }}
            />
          ) : (
            <span>Confirm</span>
          )}
        </Button>
      </Flex>
    </Flex>
  );
};

export default RegenerateEmailModal;
