
import axios from 'axios';
import { instance } from 'api/ApiRequest';
import {
  setInvitedUserEmail,
  setUserSignUpInfo,
  setInvitedUserEmailInfo,
  setDisableBtn,
  setLoading,
} from './userInvitationSlice';
import { serverUrls } from '../../variables.js';

export const getInvitedUserEmail = (token:any) => async (dispatch:any) => {
  try {
    dispatch(setLoading(true));
    const {
      data: { email },
    } = await instance.get(`${serverUrls.accounts}users/invitations/${token}/`);
    dispatch(setLoading(false));
    dispatch(setInvitedUserEmail(email));
    dispatch(
      setInvitedUserEmailInfo({
        emailGet: true,
        message: '',
      }),
    );
  } catch (error) {
    dispatch(
      setInvitedUserEmailInfo({
        emailGet: false,
        message: 'Your link has expired',
      }),
    );
    dispatch(setLoading(false));
  }
};

export const signUpUser = (payload:any) => async (dispatch:any) => {
  try {
    dispatch(setDisableBtn(true));
    await axios.post(`${serverUrls.accounts}users/invitations/accept/`, payload);
    dispatch(setDisableBtn(false));
    dispatch(setUserSignUpInfo({ isSent: true, message: '' }));
  } catch (error) {
    dispatch(setDisableBtn(true));
    dispatch(
      setUserSignUpInfo({
        isSent: false,
        message: 'User with this email already exists',
      }),
    );
    dispatch(setDisableBtn(false));
  }
};
