import React from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import Spinner from "../../../shared/components/general/Spinner/Spinner";

const LoaderForConfirmCampaignDuplication: React.FC<any> = () => (
  <div className="confirm-campaign-duplication-wrapper">
    <h1 className="header-title">Confirm campaign duplication</h1>
    <Flex justifyContent="center">
      <Spinner style={{ margin: "40px" }}/>
    </Flex>
  </div>
);

export default LoaderForConfirmCampaignDuplication;
