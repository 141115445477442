import loadingCampaign from "assets/icons/loadingCampaign.svg";
import spinnerWhite from "assets/icons/spinnerWhite.svg";
import { spinnerUseStyles } from "./Spinner.styles";
import { CSSProperties } from "react";

const Spinner = ({ white = false, style }: { white?: boolean, style?: CSSProperties }) => {
  const styles = spinnerUseStyles();
  return (
    <div className={styles.wrapper} style={style}>
      <img
        style={{ width: style?.width }}
        className="loading-campaign"
        src={white ? spinnerWhite : loadingCampaign}
        alt="loadingCampaign"
      />
    </div>
  );
};

export default Spinner;
