import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { IAdAccount } from '../../../../BrandsReporting/components/ReportRequest/types';
import { multiSelectWithSearchUseStyles } from '../MultiSelect/MultiSelect.styles';
import { ArrowUpIcon } from '../Icons/ArrowUp.icon';
import { ArrowDownIcon } from '../Icons/ArrowDown.icon';


interface ICustomAutoComplete {
    options: Array<IAdAccount>
    handleChange: (event: IAdAccount | null) => void
    selected:any
    label?:string
}

const CustomAutoComplete:React.FC<ICustomAutoComplete> = (
  {
    options,
    selected,
    handleChange,
    label,
  }) => {
  const [isSelectOpen,setIsSelectOpen] = useState<boolean>(false);

  const styles = multiSelectWithSearchUseStyles({ selected });

  return (
    <Autocomplete
      id="grouped-demo"
      options={options.sort((a:any, b:any) =>
        b.firstLetter.localeCompare(a.firstLetter),
      )}
      groupBy={option => (option.firstLetter ?  option.firstLetter  : '')}
      getOptionLabel={(option:any) => option.name}
      sx={{ width: 300 , margin: 0 }}
      value={selected}
      onOpen={() => setIsSelectOpen(true)}
      onClose={() => setIsSelectOpen(false)}
      onChange={ (_, values) => {
        handleChange(values);
      }}
      renderInput={
        params => (
          <div ref={params.InputProps.ref} className={styles.autoCompleteInput}>
            <span className={styles.labelStyle}>{label}</span>
            <input
              style={{ paddingLeft: selected?.name ?  '60px' : '120px', paddingRight: "30px" }}
              type="text"
              {... params.inputProps}
            />
            <div className={styles.arrowIcon}>
              {isSelectOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </div>
          </div>
        )
      }
    />
  );
};

export default CustomAutoComplete;
