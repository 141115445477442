import { instance } from "../api/ApiRequest";
import { serverUrls } from "../variables";

export const getWeeklyBoardEmailReports = async ({
  page = 1,
  status,
  fsm_ids,
  fsmsData,
}: {
  page: number;
  status: string;
  fsm_ids: string[];
  fsmsData: { id: number; full_name: string }[];
}) => {
  const queryParams = new URLSearchParams({
    page: page.toString(), // Convert the number to a string
    status,
    ...(fsm_ids.length === fsmsData?.length || !fsm_ids?.length
      ? {}
      : { fsm_ids: fsm_ids.join(",") }),
  });

  const response = await instance.get(
    `${serverUrls.clientCommunication}emails/weekly-board/?${queryParams}`,
  );

  return response;
};

export const getAllEmails = async ({
  page,
  status,
  fsm_ids,
  period,
  fsmsData,
  statusesData,
  ownerName,
}: {
  page: any;
  status: string[];
  fsm_ids: string[];
  period: any;
  fsmsData: { id: number; full_name: string }[];
  statusesData: string[];
  ownerName: string;
}) => {
  const queryParams = new URLSearchParams({
    page: page.toString(),
    ...(status.length === statusesData?.length || !status?.length
      ? {}
      : { status: status.join(",") }),
    ...(fsm_ids.length === fsmsData?.length || !fsm_ids?.length
      ? {}
      : { fsm_ids: fsm_ids.join(",") }),
    ...(period === "all_time" ? {} : { period }),
    ...(ownerName === "" ? {} : { owner: ownerName }),
  });

  const response = await instance.get(
    `${serverUrls.clientCommunication}emails/?${queryParams}`,
  );

  return response.data;
};

export const getFSMs = async () => {
  const response = await instance.get(`${serverUrls.clientCommunication}fsms/`);
  return response.data;
};

export const getEmailReportById = async (email_id: number  | null) => {
  const response = await instance.get(
    `${serverUrls.clientCommunication}emails/${email_id}/`,
  );

  return response.data;
};

export const skipEmail = async (email_id: number, payload: any) => {
  const response = await instance.post(
    `${serverUrls.clientCommunication}emails/${email_id}/skip/`,
    payload,
  );

  return response;
};

export const regenerateEmail = async (email_id: number) => {
  const response = await instance.post(
    `${serverUrls.clientCommunication}emails/${email_id}/regenerate/`,
  );

  return response;
};

export const sendEmail = async (email_id: number, payload: any) => {
  const response = await instance.post(
    `${serverUrls.clientCommunication}emails/${email_id}/send/`,
    payload,
  );

  return response;
};

export const getEmailsStatistics = async ({ period }: { period: any }) => {
  const queryParams = new URLSearchParams({
    ...(period === "all_time" ? {} : { period }),
  });
  const response = await instance.get(
    `${serverUrls.clientCommunication}emails/statistics/?${queryParams}`,
  );

  return response;
};

export const saveEditedEmail = async (email_id: number, payload: any) => {
  const response = await instance.patch(
    `${serverUrls.clientCommunication}emails/${email_id}/`,
    payload,
  );
  return response;
};

export const getSignature = async () => {
  const response = await instance.get(
    `${serverUrls.clientCommunication}my-signature/`,
  );

  return response;
};

export const refreshSignature = async (payload:any) => {
  const response = await instance.post(
    `${serverUrls.clientCommunication}my-signature/`,
    payload,
  );

  return response;
};
