import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';
import { useLocation, useRoutes } from 'react-router-dom';
import { LocaleStorageTokenList, LocalStorage } from './helpers/Storage';
import ROUTES from './constants/routes';
import useRoutesData from './hooks/useRoutesData';
import { setLoginSuccess, setRedirectionURL } from './store/authentication/authenticationSlice';
import { globalUseStyles } from './styles';
import { checkRefreshToken } from './store/authentication/actions';
import { PERMISSIONS } from 'constants/permissions';
import './App.scss';

const App = () => {
  const accessToken = LocalStorage.get(LocaleStorageTokenList.access_token);
  const { role: roleFromToken, permissions: permissionsFromToken } =
    accessToken && jwt_decode<any>(accessToken);
  const dispatch = useDispatch();
  const location = useLocation();
  const routesData = useRoutesData();
  globalUseStyles();

  const { authentication: { loggedIn } } = useSelector((state:any) => state);

  const checkRefreshTokenOnFocus = () => {
    LocalStorage.get(LocaleStorageTokenList.refresh_token) && dispatch<any>(checkRefreshToken());
  };

  useEffect(() => {
    loggedIn && window.addEventListener("focus", checkRefreshTokenOnFocus);
    return () => {
      loggedIn && window.removeEventListener("focus", checkRefreshTokenOnFocus);
    };
    // eslint-disable-next-line
  }, [location.pathname, loggedIn])

  useEffect(() => {
    accessToken && dispatch(setLoginSuccess(true));

    const sortedPermissions = Object.keys(PERMISSIONS)
      .map((item) => {
        if (permissionsFromToken?.includes(item)) {
          return item.toUpperCase();
        }
        return null;
      })
      .filter(item => item !== null);

    if (roleFromToken === "admin") {
      dispatch(setRedirectionURL(ROUTES.DUPLICATION));
    } else {
      if (sortedPermissions[0] === "AD_MANAGEMENT") {
        dispatch(setRedirectionURL(ROUTES.DUPLICATION));
      } else if (sortedPermissions[0] === "FSM") {
        dispatch(setRedirectionURL(ROUTES.CLIENT_COMUNICATION));
      } else {
        dispatch(setRedirectionURL(ROUTES[sortedPermissions[0] ?? '']));
      }
    }
    // eslint-disable-next-line
  }, [accessToken, loggedIn]);


  useEffect(() => {
    const { pathname } = window.location;

    const normalizedPathname = pathname.replace(/\/+$/, "");
    if (pathname !== normalizedPathname) {
      window.history.replaceState(null, '', normalizedPathname);
    }

    checkRefreshTokenOnFocus();

    // eslint-disable-next-line
  }, []);

  return <div className="app">{useRoutes(routesData)}</div>;
};

export default App;
