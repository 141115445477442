import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import close from 'assets/icons/close.svg';
import enterWhite from 'assets/icons/enterWhite.svg';
import ErrorMessage from 'modules/shared/components/general/ErrorMessage/ErrorMessage';
import Flex from 'modules/shared/components/general/Flex/Flex';
import search from 'assets/icons/search.svg';
import { RegexConstants } from '../../../../constants/regex.constants';
import { errorTexts } from '../../../../constants/errorTexts';
import ROUTES from '../../../../constants/routes';
import { IRootStore } from 'types/store.types';
import { allDuplicationSearchUseStyles } from './AllDuplicationSearch.styles';

interface IAllDuplicationsSearch {
    refetch: () => void;
    setcampaignIdValuePayload: (arg:string) => void
    campaignIdValuePayload: string
}

const AllDuplicationsSearch: React.FC<IAllDuplicationsSearch> = ({
  refetch,
  setcampaignIdValuePayload,
  campaignIdValuePayload,
}) => {
  const [campaignIdValue, setCampaignIdValue] = useState('');
  const styles = allDuplicationSearchUseStyles();
  const [inputIsActive, setInputIsActive] = useState(false);
  const navigate = useNavigate();

  const {
    allDuplications: { disableSearchHistory },
  } = useSelector((state:IRootStore) => state);


  const checkOnlyNumbers = RegexConstants.onlyNumbers.test(campaignIdValue);

  const errorMessageGenerator = () => {
    if (campaignIdValue && !checkOnlyNumbers) {
      return <ErrorMessage text={errorTexts.onlyNumbers} margin="8px 4px" />;
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      !disableSearchHistory && checkOnlyNumbers && campaignIdValue && refetch();
    }
  };

  const inputSearchStyle:any = {
    backgroundImage: inputIsActive || campaignIdValue ? 'none' : `url(${search})`,
    border: campaignIdValue && !checkOnlyNumbers && '1px solid rgb(179, 38, 30)',
    borderRight: campaignIdValue && !checkOnlyNumbers && 'none',
    padding: inputIsActive || campaignIdValue ? '15px 48px 15px 32px' : '15px 16px 15px 70px',
  };

  useEffect(
    () => () => {
      setCampaignIdValue('');
    },
    // eslint-disable-next-line
        []
  );

  return (
    <Flex alignItems="center" justifyContent="center" className={styles.allDuplicationWrapper}>
      <span className={styles.allDuplications}>All duplications</span>
      <div className={styles.searchCampaignWrapper}>
        <div className={styles.inputWrapper}>
          <div className={styles.inputContainer}>
            <input
              name="campaignId"
              value={campaignIdValue}
              placeholder="Search by source campaign id"
              onBlur={() => {
                setInputIsActive(false);
              }}
              onFocus={() => {
                setInputIsActive(true);
              }}
              onChange={event => setCampaignIdValue(event.target.value)}
              onKeyDown={handleKeyDown}
              style={inputSearchStyle}
            />
            {campaignIdValue && (
              <div
                className={styles.closeBtn}
                onClick={() => {
                  setCampaignIdValue('');
                }}
              >
                <img src={close} alt="close" />
              </div>
            )}
          </div>
          <div className={styles.errorMessage}>{errorMessageGenerator()}</div>
        </div>
        <button
          className={`auth-button ${styles.enter}`}
          onClick={() => {
            setcampaignIdValuePayload(campaignIdValue);
            campaignIdValuePayload && (!disableSearchHistory && checkOnlyNumbers && campaignIdValue && refetch());
          }}
        >
          <img src={enterWhite} alt="enter" />
        </button>
      </div>
      <div>
        <button
          className="auth-button"
          style={{
            width: 'unset',
            margin: 0,
            height: '59px',
            padding: '20px 24px',
            whiteSpace: 'nowrap',
          }}
          onClick={() => {
            navigate(ROUTES.DUPLICATION);
          }}
        >
            Start duplication
        </button>
      </div>
    </Flex>
  );
};

export default AllDuplicationsSearch;
