import { createUseStyles } from 'react-jss';


export const useStyles = createUseStyles({
  root: {
    overflow: "hidden",
    color: "#fff",
    "& .MuiAccordionSummary-content": {
      margin: "0 !important",
    },
    "& .MuiAccordionSummary-root": {
      paddingRight:"38px",
      background: "#0F314D",
      color: "#FFFFFF",
      fontWeight: "300",
      fontSize: "18px",
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
  },
});


