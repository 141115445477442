import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles(() => ({
  toastWrap:{
    backgroundColor:'rgba(246, 156, 44, 0.05)',
    width:'100%',
    display:'flex',
    padding:'12px 16px',
    borderLeft: '4px solid #F69C2C',
    borderRadius:'4px 8px 8px 4px',
    alignItems:'center',
    gap:'16px',
    marginTop:'16px',
  },
  toastTitle:{
    fontSize: '18px',
    fontWeight: 500,
    color: '#444',
  },
  reSyncText:{
    textDecoration:'underline',
    cursor:'pointer',
    color:'#0F314D',
  },
  toastText:{
    marginTop: '10px',
    color:'#6E6E6E',
    fontSize: '16px',
    fontWeight: 400,
    margin:0,
  },
}));
