export function compareArraysFbAccountIds(arrOne:Array<any>, arrTwo:Array<any>) {
  // Check if array lengths are equal
  if (arrOne.length !== arrTwo.length) {
    return false;
  }

  // Check if all identifiers in arrOne exist in arrTwo
  for (let i = 0; i < arrOne.length; i++) {
    let found = false;
    for (let j = 0; j < arrTwo.length; j++) {
      if (arrOne[i].fb_account_id === arrTwo[j].fb_account_id) {
        found = true;
        break;
      }
    }
    // If identifier not found in arrTwo, return false
    if (!found) {
      return false;
    }
  }

  // All identifiers match, return true
  return true;
}
