import { createUseStyles } from 'react-jss';


export const stylesSuggestionCardItem = createUseStyles(() => ({
  suggestionCardWrap: {
    marginBottom:'20px',
    borderRadius:'8px !important',
    boxShadow: '0px 1px 5px 0px rgba(173, 173, 173, 0.33) !important',
    display: 'flex',
    transition: "all .7s",
    justifyContent: 'space-between',
    overflow: 'hidden',
    position: 'relative',

    "&.hidden": {
      height: 0,
      border: 0,
      margin: "0",
      transition: "all .7s",
    },
  },
  suggestionCardWrapFailed:{
    border: '1px solid #B3261E',
  },
  textColor:{
    color: '#444444',
  },
  accountBudgetCount: {
    color: '#B3261E',
  },
  remainingIconWrapper:{
    display: 'flex',
    gap: '5px',
    fontSize: '14px',
    width:'240px',
    position: 'relative',
  },
  accountBudgetCountDaily: {
    position: 'absolute',
    width: '100px',
  },
  acceptWrapper: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    width: '1000px',
  },
  infoIconBdg:{
    position:'relative',
  },
  infoIconWrapper:{
    position: 'absolute',
    top: '2px',
    right: '15px',
    cursor:'pointer',
  },
  infoIconWrapperOver: {
    right: '45px !important',
  },
  fadeInOut: {
    animation: "fadeInOut 0.3s ease-in-out",
  },
  createdAt: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "1.5",
    color: "#959595",
    margin: "0",
    whiteSpace: "nowrap",
    maxWidth: '195px',
    overflow:'hidden',
    textOverflow: 'ellipsis',
  },
  expiredBy:{
    width: '195px',
    textAlign: 'center',
  },
  buttonsBox:{
    height:'55px' ,
    width: '173px',
  },
  statusAcceptWrap:{
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  statusAccept: {
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "15px",
    padding: "4px 8px",
    width:'84px',
    textAlign: 'center',
    color:'#33850D',
    backgroundColor: '#E0EDDB',
  },
  statusAcceptDismissed:{
    color:'#444444',
    backgroundColor: '#EAEAEA',
  },
  statusAcceptExpired: {
    background: "rgba(179, 38, 30, 0.15)",
    color: "#B3261E",
  },
  cardTitle: {
    display: 'flex',
    gap: '12px',
    maxWidth: '500px',
    position: 'relative',
  },
  cardTitleWdtSplit: {
    maxWidth: '230px !important',
  },
  cardTitleWdtPauseAd: {
    maxWidth: '190px !important',
  },
  statusInfoWrap:{
    padding: '24px !important',
    width:'1036px',
  },
  tooltipWidth: {
    maxWidth:'600px',
  },
  campaignLinkWrap: {
    display: 'flex',
    gap: '8px',
  },
  campaignLink:{
    maxWidth: '400px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace:'nowrap',
  },
  closeIcon:{
    padding: '14px',
    display: 'flex',
    alignItems: 'center',
    height: 'auto',
    marginLeft: '12px',
  },
  icon: {
    '& path': {
      stroke:'#0F314D !important',
    },
  },
  losingCamp:{
    color: '#B3261E',
  },
  winingCamp:{
    color: '#33850D',
  },
  acceptBtn: {
    padding: "12px 32px",
    borderRadius: "4px",
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 'auto',
  },
  borderStyle: {
    marginTop: '20px !important',
    marginBottom: '20px !important',
  },
  cardButtonsWrap: {
    display:'flex',
    flexDirection:'column',
    marginTop: '60px',
    padding: '0 35px !important',
  },
  cardButtons :{
    display: 'flex',
  },
  cardButtonsPaused :{
    display: 'flex',
    marginBottom: '55px',
  },
  statusPaused:{
    marginLeft:'20px',
    marginTop: '50px',
  },
  statusPausedExp:{
    marginLeft:'20px',
    marginTop: '50px !important',
  },
  adPdExpired :{
    padding: '0 60px !important',
  },
  pauseAdPdExpired :{
    padding: '0 60px !important',
  },
  dailyBdgExpired :{
    marginTop: '65px',
  },
  dailyBdg :{
    marginTop:'63px',
  },
  dailyBdgBigReason :{
    marginTop:'83px',
  },
}));

