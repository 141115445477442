import { Button } from "modules/shared/components/general/Button/Button";
import { ButtonVariants } from "modules/shared/components/general/Button/Button.types";
import Flex from "modules/shared/components/general/Flex/Flex";
import { useClientCommunicationContext } from "../../../../context/clientCommunication.context";
import ButtonWithGoogleAuth from "../../pages/ButtonWithGoogleAuth";

const UserEmailButtons = ({
  isEditModeOn,
  isSaveDisabled,
  setIsEditModeOn,
  cancelBtnHandler,
  saveMutate,
  setIsSkipOpen,
  setIsRegenerateModalOpened,
  editRequired,
}:any) => {
  const { userEmailReport } = useClientCommunicationContext();
  return isEditModeOn ? (
    <Flex
      style={{ padding: "24px 0 0 0", width: "100%" }}
      justifyContent="flex-end"
    >
      <Button
        variant={ButtonVariants.Outlined}
        style={{ height: "auto", margin: "0 16px" }}
        handleClick={() => cancelBtnHandler()}
      >
        Cancel
      </Button>
      <Button
        variant={ButtonVariants.Primary}
        style={{ height: "auto", margin: "0 0" }}
        handleClick={() => {
          setIsEditModeOn && setIsEditModeOn(false);
          saveMutate();
        }}
        disabled={isSaveDisabled}
      >
        Save
      </Button>
    </Flex >
  ) : (
    <Flex
      style={{ padding: "24px 0 0 0", width: "100%" }}
      justifyContent="flex-end"
    >
      <Button
        variant={ButtonVariants.Danger}
        style={{
          padding: "16px 25px",
        }}
        handleClick={() => {
          setIsSkipOpen(true);
        }}
      >
        Skip
      </Button>
      <Button
        variant={ButtonVariants.Outlined}
        style={{ height: "auto", margin: "0 16px" }}
        handleClick={() => setIsRegenerateModalOpened(true)}
      >
        Regenerate
      </Button>
      <ButtonWithGoogleAuth
        fsmEmail={(userEmailReport as any)?.fsm_email}
        editRequired={editRequired}
      />
    </Flex>
  );
};

export default UserEmailButtons;
