import React, { createContext, useContext, useRef, useState } from "react";
import { PropsContext } from "../types/styles.types";

const updatePreview = createContext<any>(null);

export const UpdateContext:React.FC<PropsContext> = ({ children }) => {
  const modalRef = useRef(null);
  const tooltipRef = useRef(null);
  const [recordId, setRecordId] = useState("");
  const [leadRecordId, setLeadRecordId] = useState("");

  const [adSetId, setAdSetId] = useState("");
  const [showSuccessErrorContent, setShowSuccessErrorContent] = useState("");
  const [backToPreview, setBackToPreview] = useState(null);
  const [backToUpdate, setBackToUpdate] = useState(null);

  return (
    <updatePreview.Provider
      value={{
        recordId,
        setRecordId,
        setShowSuccessErrorContent,
        showSuccessErrorContent,
        backToPreview,
        setBackToPreview,
        backToUpdate,
        setBackToUpdate,
        adSetId,
        setAdSetId,
        modalRef,
        tooltipRef,
        leadRecordId,
        setLeadRecordId,
      }}
    >
      {children}
    </updatePreview.Provider>
  );
};

export const useUpdatePreview = () => useContext(updatePreview);
