import { instance } from 'api/ApiRequest';
import {
  setEmailForResetWasSent,
  setConfirmResetPassword,
  setDisableEnterBtn,
  setLoading,
} from './passwordResetSlice';
import { serverUrls } from '../../variables.js';

export const sendEmailForeResetPassword = (email:any) => async (dispatch:any) => {
  try {
    dispatch(setDisableEnterBtn(true));
    await instance.post(`${serverUrls.accounts}reset-password/`, {
      email,
    });
    dispatch(setEmailForResetWasSent({ sent: true, message: '' }));
    dispatch(setDisableEnterBtn(false));
  } catch (err) {
    dispatch(setDisableEnterBtn(false));
    dispatch(setEmailForResetWasSent({ sent: false, message: 'User does not exist.' }));
  }
};

export const confirmResetPassword = (payload:any) => async (dispatch:any) => {
  try {
    dispatch(setDisableEnterBtn(true));
    await instance.post(`${serverUrls.accounts}reset-password-confirm/`, payload);
    dispatch(setConfirmResetPassword({ sent: true, message: '' }));
    dispatch(setDisableEnterBtn(false));
  } catch (error) {
    dispatch(setDisableEnterBtn(false));
    dispatch(
      setConfirmResetPassword({
        sent: false,
        message: 'Link has expired',
      }),
    );
  }
};

export const checkIsResetLinkValid = (uidAndToken:any) => async (dispatch:any) => {
  try {
    dispatch(setLoading(true));
    await instance.post(`${serverUrls.accounts}reset-password-link/validate`, uidAndToken);
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(
      setConfirmResetPassword({
        sent: false,
        message: 'Link has expired',
      }),
    );
  }
};
