import React from "react";
import jwt_decode from "jwt-decode";
import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../shared/components/general/Button/Button.types";
import { LocaleStorageTokenList, LocalStorage } from 'helpers/Storage';
import { useClientCommunicationContext } from "../../../context/clientCommunication.context";
import sendWithGoogleIcon from "assets/icons/sendWithGoogleIcon.svg";
import { ImageLoader } from "../../shared/components/general/ImageLoader/ImageLoader";
import errorIcon from "assets/icons/errorIcon.svg";
import { useStylesClientCommunication } from "modules/ClientCommunication/pages/ClientCommunication.styles";

const SCOPES = [
  "https://www.googleapis.com/auth/gmail.send",
  "https://www.googleapis.com/auth/gmail.readonly",
  "https://www.googleapis.com/auth/gmail.settings.basic",
  "https://www.googleapis.com/auth/userinfo.profile",
];

const ButtonWithGoogleAuth: React.FC<any> = ({ fsmEmail, editRequired }) => {
  const styles = useStylesClientCommunication();

  const { email } =
    LocalStorage.get(LocaleStorageTokenList.access_token) &&
    jwt_decode<any>(LocalStorage.get(LocaleStorageTokenList.access_token));

  const {
    setGoogleAuthCode,
    setAreYouSureSendModalOpened,
    setSendModalForOtherUser,
  } = useClientCommunicationContext();
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      setGoogleAuthCode(tokenResponse.code);
      email === fsmEmail
        ? setAreYouSureSendModalOpened(true)
        : setSendModalForOtherUser(true);
    },
    flow: "auth-code",
    scope: SCOPES.join(" "),
  });

  const handleLoginClick = () => {
    LocalStorage.get(LocaleStorageTokenList.googleAuthAccessToken)
      ? email === fsmEmail
        ? setAreYouSureSendModalOpened(true)
        : setSendModalForOtherUser(true)
      : login();
  };

  return (
    <div className={styles.googleAuthContainer}>
      <Button
        variant={ButtonVariants.Primary}
        handleClick={() => handleLoginClick()}
        style={{ display: "flex", gap: "8px" }}
        disabled={editRequired}
      >
        <span>Send with Google</span>
        <ImageLoader src={sendWithGoogleIcon} alt="" />
      </Button>

      {editRequired && (
        <div className={styles.tooltipText}>
          <img src={errorIcon} alt="" />
          <span>Replace the placeholder before sending</span>
        </div>
      )}
    </div>
  );
};

export default ButtonWithGoogleAuth;
