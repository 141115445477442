import { useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import Spinner from '../../shared/components/general/Spinner/Spinner';
import { getAllDuplications } from '../../../services/allDuplications.services';
import { getPageFromUrl } from '../../../helpers/PageFromUrl';
import EmptyHistory from '../components/EmptyHistory/EmptyHistory';
import EmptyHistoryById from '../components/EmptyHistory/EmptyHistoryById';
import AllDuplicationsSearch from '../components/AllDuplicationsSearch/AllDuplicationsSearch';
import AllDuplicationsData from '../components/AllDuplicationsData/AllDuplicationsData';
import { useScrollTop } from '../../../hooks/useScrollTop';
import InfiniteScroll from '../../shared/components/general/InfiniteScroll/InfiniteScroll';

const AllDuplications = () => {
  const [campaignIdValuePayload, setcampaignIdValuePayload] = useState('');
  const scrollTopComponent = useScrollTop();

  const {
    data: allDuplicationsResponse,
    fetchNextPage,
    refetch,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['allDuplications'],
    ({ pageParam = 1 }) => getAllDuplications({
      page: pageParam,
      search: campaignIdValuePayload || "",
    }),
    {
      getNextPageParam: ({ data: { next = '' } = {} }) => {
        if (next) {
          const res = getPageFromUrl(next);
          return res ? Number(res) : undefined;
        }
      },
    },
  );


  useEffect(() => {
    if (!campaignIdValuePayload) {
      refetch();
    }
    // eslint-disable-next-line
    }, [campaignIdValuePayload]);

  const allDuplicationsData: any = allDuplicationsResponse?.pages
    .map(page => page.data?.results?.map((duplicationItem: any) => duplicationItem));

  const flattenedArray = [].concat.apply([], allDuplicationsData);


  const generateContent = () => {
    if (isFetching && !isFetchingNextPage) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Spinner />
        </div>
      );
    }

    if (flattenedArray.length) {
      return (
        <AllDuplicationsData
          flattenedArray={flattenedArray}
          isFetchingNextPage={isFetchingNextPage}
        />
      );
    }

    if (campaignIdValuePayload) {
      return <EmptyHistoryById />;
    }

    return <EmptyHistory />;
  };


  return (
    <>
      <InfiniteScroll
        scroll
        next={() => fetchNextPage()}
        maxHeight={'calc(100vh - 100px)'}
        hasMore={!isFetching}
      >
        <div style={{ maxWidth: '1288px', margin: 'auto', marginBottom: '64px' }}>
          <AllDuplicationsSearch
            refetch={refetch}
            setcampaignIdValuePayload={setcampaignIdValuePayload}
            campaignIdValuePayload={campaignIdValuePayload}
          />
          {generateContent()}
        </div>
      </InfiniteScroll>

      {scrollTopComponent}
    </>
  );
};

export default AllDuplications;
