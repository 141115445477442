import BasicModal from "modules/shared/components/general/BasicModal/BasicModal";
import { ImageLoader } from "modules/shared/components/general/ImageLoader/ImageLoader";
import Flex from "modules/shared/components/general/Flex/Flex";
import dismissImg from "assets/icons/dismissImg.svg";
import { Button } from "modules/shared/components/general/Button/Button";
import { ButtonVariants } from "modules/shared/components/general/Button/Button.types";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import { useStyles } from "./PauseAdModal.styles";
import { useEffect } from "react";

const DismissAdModal = ({
  isDismissModalOpen,
  setIsDismissModalOpen,
  isLoading,
  setLoadingItems,
  handleDissmisClick,
  title,
  info,
}:any) => {
  const styles = useStyles();

  useEffect(() => {
    setLoadingItems({});
    // eslint-disable-next-line
  }, [isDismissModalOpen])

  return (
    <BasicModal
      open={isDismissModalOpen}
      handleClose={() => setIsDismissModalOpen(false)}
    >
      <Flex direction="column" alignItems="center" style={{ width: "553px" }}>
        <Flex direction="column" style={{ padding: "24px 0 0 0" }} alignItems='center'>
          <h3 className={styles.modalTitle}>
            {title}
          </h3>
          <p className={styles.modalDescription}>
            {info}
          </p>
          <ImageLoader
            src={dismissImg}
            alt="mail box"
            style={{ width: "300px", height: "300px", textAlign: "center", marginTop: "16px" }}
          />
        </Flex>
        <Flex
          justifyContent="flex-end"
          style={{ gap: "16px", width: "100%", marginTop: "16px" }}
        >
          <Button variant={ButtonVariants.Secondary} handleClick={() => setIsDismissModalOpen(false)}>
            Cancel
          </Button>
          <Button
            handleClick={() => handleDissmisClick()}
            style={{
              textAlign: "left",
              width: "188px",
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {isLoading ? (
              <Spinner
                white={true}
                style={{
                  width: "16px",
                  margin: "0",
                  left: "50%",
                  position: "initial",
                }}
              />
            ) : (
              <span>Dismiss suggestion</span>
            )}
          </Button>
        </Flex>
      </Flex>
    </BasicModal >
  );
};

export default DismissAdModal;
