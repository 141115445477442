import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStyles = createUseStyles((theme: AppTheme) => ({
  drawer: {
    position: "fixed",
    top: 0,
    right: "-2400px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(68, 68, 68, 0.25)",
    zIndex: 99999,
  },
  open: {
    display: "block",
    right: 0,
  },
  drawerHeader: {
    display: "fixes",
    backgroundColor: theme.whiteColor,
    padding: "10px 24px",
    "& .close": {
      lineHeight: 0,
      borderRadius: "100%",
      padding: "4px",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.04)",
      },
    },
    "& h3": {
      color: theme.textColor,
      fontSize: "24px",
      fontWeight: "400",
      lineHeight: "36px",
      margin: 0,
    },
  },
  drawerBody: {
    margin: "24px",
    backgroundColor: theme.whiteColor,
    borderRadius: "8px",
    position: "relative",
  },
  drawerContent: {
    position: "absolute",
    top: 0,
    right: "-2000px",
    width: "1111px",
    height: "100%",
    backgroundColor: theme.grey5,
    boxSizing: "border-box",
    overflowY: "auto",
    transition: "right 0.5s linear",
  },
  openContent: {
    right: 0,
  },
  closeButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    fontSize: "20px",
  },
}));
