import { createUseStyles } from "react-jss";

export const allDuplicationSearchUseStyles = createUseStyles(() => ({
  allDuplicationWrapper: {
    margin: "32px 0 50px",
    gap: "32px",
  },
  allDuplications: {
    fontWeight: "700",
    fontSize: "24px",
    lineHeight: "1.5",
    color: "#0f314d",
    whiteSpace: "nowrap",
  },
  searchCampaignWrapper: {
    width: "100%",
    maxWidth: "900px",
    display: "flex",
  },
  inputWrapper: {
    position: "relative",
    width: "100%",
  },
  inputContainer: {
    position: "relative",
    "&>input": {
      backgroundColor: "#ffffff",
      boxShadow: "-1px 0px 4px #e8e8e8",
      borderRadius: "4px 0 0px 4px",
      border: "1px solid transparent",
      width: "100%",
      outline: " none",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "1.5",
      color: "#444444",
      display: "block",
      padding: "19px 32px 19px 32px",
      backgroundPosition: "32px center",
      backgroundRepeat: "no-repeat",
      "&:focus-visible": {
        outline: "none",
      },
      "&:hover": {
        border: "1px solid #dbdbdb",
      },
      "&::placeholder": {
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "1.5",
        color: "#b7b7b7",
        marginLeft: "10px",
      },
      "&:focus": {
        backgroundImage: "none",
        border: " 1px solid #dbdbdb",
      },
      "&:focus::placeholder": {
        color: "transparent",
      },
    },
  },
  errorMessage: {
    position: "absolute",
    whiteSpace: "nowrap",
    top: "58px",
  },
  enter: {
    borderRadius: " 0px 4px 4px 0px",
    width: "unset",
    height: "59px",
    marginTop: "0",
    "&:hover": {
      background: " #0f314d",
    },
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute ",
    right: "8px",
    top: "9px",
    padding: "8px",
    cursor: "pointer",
    borderRadius: "100%",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
}));
