import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

export const useStyles = createUseStyles((theme: AppTheme) => ({
  title: {
    color: theme.deepBlueColor,
    textAlign: "center",
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "48px",
    marginTop: "37px",
    marginBottom: "8px",
  },
  description: {
    color: theme.deepBlueColor,
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "427px",
  },
}));
