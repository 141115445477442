import React from "react";
import { useMutation, useQueryClient } from "react-query";
import Flex from "../../../shared/components/general/Flex/Flex";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import { useActivateOrDeactivateStyles } from "./ActivateOrDeactivate.styles";
import deactivateImg from "../../../../assets/icons/deactivateImg.svg";
import reactivateImg from "../../../../assets/icons/reactivateImg.svg";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import { singleUserUpdate } from "../../../../services/users.services";
import { QueryConstants } from "constants/queryConstants";
import Spinner from "../../../shared/components/general/Spinner/Spinner";

const ActivateOrDeactivate = ({
  handleCloseModal,
  id,
  isActive,
}: {
  handleCloseModal: () => void;
  id?: number;
  isActive: boolean;
}) => {
  const styles = useActivateOrDeactivateStyles();
  const queryClient = useQueryClient();

  const { mutate: updateUser, isLoading } = useMutation(
    () => singleUserUpdate(id, { is_active: !isActive }),
    {
      onSuccess: (response) => {
        handleCloseModal();
        queryClient.invalidateQueries(QueryConstants.getTeamManagementUsers);
      },
    },
  );

  return (
    <Flex className={styles.wrapper} direction="column" alignItems="center">
      <h1>
        Are you sure you want to <br /> {isActive ? "deactivate" : "reactivate"}{" "}
        this account?
      </h1>
      <ImageLoader
        src={isActive ? deactivateImg : reactivateImg}
        alt="Deactivate"
        style={{
          height: "300px",
          width: "330px",
          marginTop: "16px",
        }}
      />
      <Flex
        justifyContent="center"
        style={{ width: "100%", marginTop: "16px" }}
      >
        <Button
          variant={ButtonVariants.Secondary}
          style={{ marginRight: "16px" }}
          handleClick={() => handleCloseModal()}
        >
          Cancel
        </Button>
        <Button
          variant={isActive ? ButtonVariants.Warning : ButtonVariants.Primary}
          handleClick={() => updateUser()}
          style={{
            textAlign: "left",
            width: isActive
              ? "124px" : "114px",
            position: "relative",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {isLoading ?
            <Spinner
              white={true}
              style={{ width: "16px", margin: "0", left: "50%", position: "initial" }}
            /> : <span> {isActive ? "Deactivate" : "Reactivate"}</span>}
        </Button>
      </Flex>
    </Flex >
  );
};

export default ActivateOrDeactivate;
