import { createUseStyles } from 'react-jss';
import { AppTheme } from 'types/styles.types';

export const reportRequestUseStyles = createUseStyles((theme: AppTheme) => ({
  wrapper: {
    maxHeight: '600px',
    overflowY: 'scroll',
    marginTop: '32px',
    paddingRight: '32px',
  },
  infoToastTest:{
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    color: '#0F314D',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: '16px',
    background:'rgba(63, 90, 113, 0.05)',
    padding: '8px 16px',
    position: 'relative',
    borderRadius: ' 0 8px 8px 0',

    '&::before' : {
      content: '""',
      width: '4px',
      height: '100%',
      backgroundColor: '#0F314D',
      position: 'absolute',
      left: 0,
      top: 0,
      borderRadius: '4px',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: theme.secondaryColor,
    margin: '24px 0',
  },
  typeTitle: {
    fontWeight: 500,
    fontSize: '1.25rem',
    color: theme.textColor,
    marginBottom: '24px',
  },
  typeTitleReport: {
    fontWeight: 500,
    fontSize: '1.25rem',
    color: theme.textColor,
    margin: 0,
    marginTop: '32px',
  },
  checkWrapper: {
    marginRight: '32px',
  },
  brandNameWrapper: {
    maxWidth: '570px',
    width: '100%',
    borderRadius: '8px',
    backgroundColor: theme.whiteColor,
    borderLeft: `8px solid ${theme.primaryColor}`,
    padding: '16px 24px',
    marginBottom: '16px',
    '&:last-child': {
      marginBottom: '0',
    },
  },
  brandNameDivider: {
    height: '43px',
    width: '1px',
    margin: '0 16px',
    backgroundColor: theme.grey1,
  },
  brandName: {
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '27px',
    color: theme.textColor,
  },
  periodWrapper: {
    marginTop: '16px',
    gap: '16px',
    '& > div': {
      padding: '16px 16px',
      border: '1.5px solid #0F314C',
      borderRadius: '8px',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '8px',
      color: '#0F314C',
      cursor: 'pointer',
      textAlign: 'center',
    },
  },
  activeTab: {
    background: '#FFFFFF',
  },
  inputLabel: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: '1.5',
    color: '#444444',
  },
  keywordInput: {
    width: '391px',
    borderRadius: '8px',
    height: '40px',
    margin: '8px 0',
    border: '1px solid #D9D9D9',
    padding: '8px 16px',
    fontSize: '16px',
    fontWeight: '400',
    color: '#444',
    '&::placeholder': {
      color: '#B7B7B7',
    },
    '&:disabled': {
      background: 'rgba(15, 49, 77, 0.04)',
    },
    "&:hover":{
      boxShadow:"0px 1px 5px 0px rgba(173, 173, 173, 0.33)",
    },
    "&:focus":{
      border:"1px solid #0F314D",
    },
  },
  infoMessageContainer: {
    border: '1px solid rgba(15, 49, 77, 0.30)',
    borderLeft: '3px solid #0F314D',
    background: 'rgba(63, 90, 113, 0.05)',
    padding: '15px 24px 15px 16px',
  },
  infoMessage: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#444444',
  },
  closeBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute ",
    right: "12px",
    top: "21%",
    padding: "8px",
    cursor: "pointer",
    borderRadius: "100%",
    width: "32px",
    height: "32px",
    "&:hover": {
      background: "rgba(0, 0, 0, 0.04)",
    },
  },
}));
