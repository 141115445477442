import { variables } from "../variables";

const {
  REACT_APP_UNLEASH_URL,
  REACT_APP_UNLEASH_CLIENT_KEY,
  REACT_APP_UNLEASH_APP_NAME,
} = variables;

export const unleashProxyConfig = {
  url: REACT_APP_UNLEASH_URL,
  clientKey: REACT_APP_UNLEASH_CLIENT_KEY,
  refreshInterval: 3600,
  appName: REACT_APP_UNLEASH_APP_NAME,
};
