import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { duplicationRequest } from 'store/requestedDuplications/actions';
import LoaderForConfirmCampaignDuplication from '../components/LoaderForConfirmCampaignDuplication/LoaderForConfirmCampaignDuplication';

import DuplicationPreviewData from 'modules/RequestDuplicationPreview/components/DuplicationPreviewData/DuplicationPreviewData';
import RequestConfirmCampaignDuplication from '../components/RequestConfirmCampaignDuplication/RequestConfirmCampaignDuplication';
import DuplicationIsConfirmed from '../components/DuplicationIsConfirmed/DuplicationIsConfirmed';
import { serverUrls } from '../../../variables.js';

const RequestDuplicationPreview = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const requestId = location.pathname.split('/')[2];
  const [requestPreviewStatus, setRequestPreviewStatus] = useState("pending");
  const [campaignDuplication, setCampaignDuplication] = useState([]);
  const [nextRequestUrl, setNextRequestUrl] = useState('');
  const [isFirstRender, setIsFirstRender] = useState(true);

  const removeById = (event:any, id:any) => {
    setIsFirstRender(false);
    event.stopPropagation();
    setCampaignDuplication(campaignDuplication?.filter((result:any) => result.id !== id));
  };

  const handleCampaignDuplicationConfirmRequest = () => {
    dispatch<any>(
      duplicationRequest(
        `${nextRequestUrl || `${serverUrls.campaigns}duplication-request/${requestId}/`}`,
        ({ data: { results, next } }:any) => {
          results?.length
            ? setRequestPreviewStatus('duplication with full result')
            : setRequestPreviewStatus('duplication with empty result');

          setNextRequestUrl(next);
          setCampaignDuplication(campaignDuplication?.concat(results));
        },
      ),
    );
  };

  useEffect(() => {
    if (!campaignDuplication.length && !isFirstRender) {
      setRequestPreviewStatus('duplication with empty result');
    }
    // eslint-disable-next-line
  }, [campaignDuplication.length, isFirstRender]);

  useEffect(() => {
    handleCampaignDuplicationConfirmRequest();
    return () => {
      setCampaignDuplication([]);
    };
    // eslint-disable-next-line
  }, []);

  const generateContent = () => {
    switch (requestPreviewStatus) {
      case 'duplication is confirmed':
        return <DuplicationIsConfirmed />;
      case 'pending':
        return <LoaderForConfirmCampaignDuplication />;
      case 'duplication with full result':
        return (
          <DuplicationPreviewData
            requestId={requestId}
            campaignDuplication={campaignDuplication}
            handleCampaignDuplicationConfirmRequest={
              handleCampaignDuplicationConfirmRequest
            }
            next={nextRequestUrl}
            removeById={removeById}
            setRequestPreviewStatus={setRequestPreviewStatus}
          />
        );
      case 'duplication with empty result':
        return <RequestConfirmCampaignDuplication />;
      default:
        return '';
    }
  };

  return <div id="confirm-duplication-page">{generateContent()}</div>;
};

export default RequestDuplicationPreview;
