import { useEffect, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { IRootStore } from 'types/store.types';
import {
  setShowFailedModal,
  setShowSuccessModal,
} from 'store/brandsReporting/brandsReportingSlice';
import ReportRequestModalContent from 'modules/BrandsReporting/components/ReportRequest/ReportRequestModalContent';
import ReportSuccess from '../ReportSuccess/ReportSuccess';
import { SomethingWentWrong } from '../SomethingWentWrong/SomethingWentWrong';
import { ReportTypes, Brand } from './types';
import { useMutation } from 'react-query';
import { createReport } from 'services/reporting.services';
import ROUTES from '../../../../constants/routes';
import './styles.scss';

const ReportRequest = ({
  handleClose,
  brandsLogosData,
}: {
    handleClose: any;
    brandsLogosData?: any;
}) => {
  const location: any = useLocation();
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isCheckedFB, setCheckedFB] = useState(false);
  const [isCheckedTKT, setCheckedTKT] = useState(false);
  const [isLoadingMemberList, setIsLoadingMemberList] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<string | Blob>('');
  const [disabelConfirmBtn, setDisabelConfirmBtn] = useState(true);

  const {
    brandsReporting: { showSuccessModal, showFailedModal },
  } = useSelector((state: IRootStore) => state);
  const dispatch = useDispatch();
  const [mappingsValues, setMappingsValues] = useState({
    mappings: { member_since: '', email: '', revenue: '' },
  });
  const [reportTypes, setReportTypes] = useState<ReportTypes[]>([
    {
      name: 'Campaign',
      checked: false,
    },
    {
      name: 'Copy',
      checked: false,
    },
    {
      name: 'Creative',
      checked: false,
    },
    {
      name: 'Leads',
      checked: false,
    },
  ]);
  const [selectedAdAccounts, setSelectedAdAccounts] = useState<{ name: string; id: number }[]>(
    [],
  );
  const [keyword, setKeyword] = useState<string>('');

  const dayBefore = new Date();
  dayBefore.setDate(dayBefore.getDate() - 2);

  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(false);
  const [ranges, setRanges] = useState<any>({
    startDate: dayBefore,
    endDate: dayBefore,
    key: 'selection',
  });


  const [activeTab, setActiveTab] = useState('last_7_days');

  const [brands, setBrands] = useState<any>(
    brandsLogosData?.map((item: Brand) => ({
      ...item,
      checked: false,
    })),
  );

  const newBrandsId: any = [];
  const newReports: any = [];

  const reports = reportTypes?.filter(item => item.checked);
  const brandsData = brands?.filter((item: Brand) => item.checked);

  brandsData?.map((item: Brand) => newBrandsId.push(item.id));
  reports.map((item: any) => newReports.push(item.name));

  const baseData = {
    brands: location.pathname === ROUTES.BRAND_REPORTS ? [location?.state?.id] : newBrandsId,
    types: newReports,
    ...mappingsValues,
  };

  const data = isSwitchOn
    ? {
      predefined_period: activeTab,
      ...baseData,
    }
    : {
      period_start: moment(ranges.startDate).format('YYYY-MM-DD'),
      period_end: moment(ranges.endDate).format('YYYY-MM-DD'),
      ...baseData,
    };

  const formData = new FormData();
  uploadedFile && formData.append('member_list', uploadedFile);
  isCheckedFB && formData.append('platforms', 'Facebook');
  isCheckedTKT && formData.append('platforms', 'TikTok');
  isSwitchOn && formData.append('predefined_period', activeTab);
  !isSwitchOn && formData.append('period_start', moment(ranges.startDate).format('YYYY-MM-DD'));
  !isSwitchOn && formData.append('period_end', moment(ranges.endDate).format('YYYY-MM-DD'));

  location.pathname === ROUTES.BRAND_REPORTS
    ? formData.append('brands', location?.state?.id)
    : newBrandsId.map((newBrand: any) => formData.append('brands', newBrand));

  newReports.map((report: any) => formData.append('types', report));

  location.pathname === ROUTES.BRAND_REPORTS &&
    selectedAdAccounts.length > 0 &&
    selectedAdAccounts.map((item: any) => formData.append('ad_account_ids', item.id));
  location.pathname === ROUTES.BRAND_REPORTS && keyword && formData.append('keyword', keyword);

  uploadedFile && formData.append('mappings', JSON.stringify(mappingsValues.mappings));
  const { mutate, isLoading } = useMutation(createReport);

  const handleConfirmClick = () => {
    !isLoading &&
        mutate(formData, {
          onSuccess: () => {
            dispatch(setShowSuccessModal(true));
            // queryClient.invalidateQueries(servicesNames.getBrandsReports);
          },
          onError: () => {
            dispatch(setShowFailedModal(true));
          },
        });
    setUploadSuccess(false);


    setRanges({
      startDate: dayBefore,
      endDate: dayBefore,
      key: 'selection',
    });
  };


  useEffect(() => {
    const isCheckedLead = reportTypes?.find(itm => itm.name === 'Leads')?.checked ?? false;
    const isCheckEachOne = reportTypes.filter(itm => itm.name !== 'Leads')
      .some(item => item.checked) || isCheckedFB;
      
    // validation TikTok select with Leads
    const disableConfirmTkT: boolean = isCheckEachOne ? false : (isCheckedLead && isCheckedTKT);
    if (uploadSuccess || isLoadingMemberList) {
      const isDisableConfirmBtn =
                !data.types?.length ||
                !data.brands?.length ||
                !data?.mappings?.member_since ||
                !data.mappings.email ||
                !ranges.startDate ||
                !ranges.endDate;
      setDisabelConfirmBtn(isDisableConfirmBtn || disableConfirmTkT || (!isCheckedFB && !isCheckedTKT));
    } else {
      const isDisableConfirmBtn =
                !data.types?.length || !data.brands?.length || !ranges.startDate || !ranges.endDate;
      setDisabelConfirmBtn(isDisableConfirmBtn || disableConfirmTkT || (!isCheckedFB && !isCheckedTKT));
    }
  }, [
    uploadSuccess,
    data.types?.length,
    data.brands?.length,
    data?.mappings?.member_since,
    data.mappings.email,
    ranges.startDate,
    ranges.endDate,
    isLoadingMemberList,
    isCheckedTKT,
    reportTypes,
    isCheckedFB,
  ]);

  const generateContent = () => {
    if (showSuccessModal) {
      return (
        <ReportSuccess
          handleClose={handleClose}
          text={
            location.pathname === ROUTES.BRAND_REPORTS
              ? 'Please find the report(s) under the Brand they were generated for.'
              : 'Please find the reports under each Brand they were generated for.'
          }
        />
      );
    } else if (showFailedModal) {
      return <SomethingWentWrong />;
    } else {
      return (
        <ReportRequestModalContent
          setCheckedTKT={setCheckedTKT}
          setCheckedFB={setCheckedFB}
          location={location}
          isLoading={isLoading}
          brands={brands}
          setIsLoadingMemberList={setIsLoadingMemberList}
          isSwitchOn={isSwitchOn}
          setIsSwitchOn={setIsSwitchOn}
          setReportTypes={setReportTypes}
          reportTypes={reportTypes}
          handleClose={handleClose}
          disabelConfirmBtn={disabelConfirmBtn}
          setBrands={setBrands}
          setActiveTab={setActiveTab}
          setMappingsValues={setMappingsValues}
          mappingsValues={mappingsValues}
          uploadSuccess={uploadSuccess}
          setUploadSuccess={setUploadSuccess}
          setUploadedFile={setUploadedFile}
          setRanges={setRanges}
          ranges={ranges}
          activeTab={activeTab}
          dayBefore={dayBefore}
          handleConfirmClick={handleConfirmClick}
          selectedAdAccounts={selectedAdAccounts}
          setSelectedAdAccounts={setSelectedAdAccounts}
          keyword={keyword}
          setKeyword={setKeyword}
        />
      );
    }
  };

  return generateContent();
};

export default ReportRequest;
