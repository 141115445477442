import { createUseStyles } from 'react-jss';
import { AppTheme } from 'types/styles.types';

export const useStyles = createUseStyles((theme: AppTheme) => ({
  modalTitle: {
    fontSize: "32px",
    fontWeight: "700",
    lineHeight: "48px",
    color: theme.primaryColor,
    margin: "0",
    width: "100%",
  },
  infoToastTest:{
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    color: '#0F314D',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: 0,
    background:'rgba(63, 90, 113, 0.05)',
    padding: '8px 16px',
    position: 'relative',
    borderRadius: ' 0 8px 8px 0',

    '&::before' : {
      content: '""',
      width: '4px',
      height: '100%',
      backgroundColor: '#0F314D',
      position: 'absolute',
      left: 0,
      top: 0,
      borderRadius: '4px',
    },
  },
  typeDropBoxTitle: {
    fontWeight: 700,
    fontSize: '18px',
    color: '#444',
  },
  modalDescription: {
    color: theme.textColor,
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    margin: "8px 0 0 0",
  },
}));
