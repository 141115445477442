import React, { useEffect, useState } from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import suggestionsEmptyState from "../../../../assets/icons/suggestionsEmptyState.svg";
import { emptyStateUseStyles } from "./EmptyStateSuggestions.styles";

const EmptyStateSuggestions = ({ activeTab }: { activeTab: string }) => {
  const styles = emptyStateUseStyles();
  const [emptyStateInfo, setEmptyStateInfo] = useState<string>(
    "You don’t have any ad optimization suggestions with this filter.",
  );
  const [message, setMessage] = useState<string>("Try another filter!");

  useEffect(() => {
    switch (activeTab) {
      case "suggestions":
        setEmptyStateInfo(
          "You don’t have any ad optimization suggestions with this filter.",
        );
        setMessage("Try another filter!");
        break;
      case "changeHistory":
        setEmptyStateInfo(
          "You don’t have any ad optimization suggestions accepted!",
        );
        setMessage("Let’s start!");
        break;
      default:
        break;
    }
  }, [activeTab]);

  return (
    <Flex
      direction="column"
      justifyContent="center"
      alignItems="center"
      style={{ gap: "16px", margin: "239px auto 0" }}
    >
      <div style={{ width: "300px", height: "300px" }}>
        <ImageLoader src={suggestionsEmptyState} alt="empty state" />
      </div>
      <h4 className={styles.emptyStateInfo}>{emptyStateInfo}</h4>
      <p className={styles.message}>{message}</p>
    </Flex>
  );
};
export default EmptyStateSuggestions;
