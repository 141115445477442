import React, { CSSProperties, useEffect, useState } from 'react';
import { equalArrays } from '../../../../../helpers/equalArrays';
import { ListItemIcon, ListItemText, MenuItem, FormControl, Checkbox } from '@mui/material';
import {
  CustomSelect,
  MenuProps,
  stylesSx,
  MultiSelectMenuItemStyles,
  useStylesFiltersRenderValue,
} from './MultiSelect.styles';
import { capitalizeFirstLetter } from '../../../../../helpers/capitalizeFirstLetter';

const MultiSelect = ({
  style,
  label,
  handleChange,
  selected,
  options,
  dataLength,
  selectedLenght,
  isLoading,
  onCloseSelect = () => {},
  refetch,
  isSelectOpen,
  disabled,
  selectedOption,
}: {
    style: CSSProperties;
    label: string;
    handleChange: (a: any) => void;
    selected: string[];
    options: string[];
    dataLength?: number;
    selectedLenght: number;
    isLoading?: boolean;
    onCloseSelect?: (isOpen: boolean) => void;
    refetch?: () => void;
    isSelectOpen?: boolean;
    disabled?: boolean;
    selectedOption?: string[] | React.ReactNode;
}) => {
  const checkboxStyles = { color: '#0F314D', height: '24px', width: '24px' };
  const isAllSelected = options?.length > 0 && selected?.length === options?.length;
  const filtersRenderValue = useStylesFiltersRenderValue();
  const [oldselections, setOldSelections] = useState<any>([]);
  const [newSelections, setNewSelections] = useState<any>([]);

  useEffect(() => {
    if (!isSelectOpen && selectedLenght === 0) {
      handleChange(options);
    }

    // eslint-disable-next-line
    }, [isSelectOpen]);

  const handleSelectChange = (event: any) => {
    const { value } = event.target;
    if (value[value.length - 1] === 'all') {
      handleChange(selected.length === options.length ? [] : options);
      return;
    }
    handleChange(value);
  };

  const generateRenderValue = () => {
    switch (selectedLenght) {
      case dataLength:
        return (
          <div className={filtersRenderValue.renderValue}>
            {label}
            <span className={filtersRenderValue.filterValue}>All selected</span>
          </div>
        );
      case 1:
        return (
          <div className={filtersRenderValue.renderValue}>
            {label}
            {selectedOption ? (
              selectedOption
            ) : (
              <span className={filtersRenderValue.filterValue}>
                {capitalizeFirstLetter(selected[0])}
              </span>
            )}
          </div>
        );
      case 0:
        return (
          <div className={filtersRenderValue.renderValue}>
            {label}
            <span className={filtersRenderValue.filterValue}>All selected</span>
          </div>
        );
      default:
        return (
          <div className={filtersRenderValue.renderValue}>
            {label}
            <span className={filtersRenderValue.filterValue}>
              {selectedLenght} selected
            </span>
          </div>
        );
    }
  };

  useEffect(() => {
    const check = equalArrays(oldselections, newSelections);
    if (!check && !isSelectOpen) {
      refetch && refetch();
    }
    // eslint-disable-next-line
    }, [oldselections, newSelections]);

  return (
    <FormControl>
      <CustomSelect
        labelId="mutiple-select-label"
        displayEmpty={true}
        disabled={disabled}
        style={style}
        multiple
        value={selected}
        onChange={handleSelectChange}
        renderValue={() =>
          (isLoading ? (
            <div className={filtersRenderValue.renderValue}>{label}</div>
          ) : (
            generateRenderValue()
          ))
        }
        MenuProps={MenuProps}
        sx={stylesSx()}
        onOpen={() => {
          onCloseSelect(true);
          setOldSelections(selected);
        }}
        onClose={() => {
          onCloseSelect(false);
          setNewSelections(selected);
        }}
      >
        {options?.length && (
          <MenuItem
            value="all"
            onKeyDown={event => event.stopPropagation()}
            style={MultiSelectMenuItemStyles}
          >
            <ListItemIcon>
              <Checkbox style={checkboxStyles} checked={isAllSelected} />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>
        )}

        {(() => options?.map(option => (
          <MenuItem
            key={option}
            value={option}
            onKeyDown={event => event.stopPropagation()}
            style={MultiSelectMenuItemStyles}
          >
            <ListItemIcon>
              <Checkbox
                style={checkboxStyles}
                checked={selected?.indexOf(option) > -1}
              />
            </ListItemIcon>
            <ListItemText primary={capitalizeFirstLetter(option)} />
          </MenuItem>
        )))()}
      </CustomSelect>
    </FormControl>
  );
};

export default MultiSelect;
