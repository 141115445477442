import React from "react";
import Flex from "../../../shared/components/general/Flex/Flex";
import saveEditchanges from "../../../../assets/icons/saveEditchanges.svg";
import { ImageLoader } from "../../../shared/components/general/ImageLoader/ImageLoader";
import { Button } from "../../../shared/components/general/Button/Button";
import { ButtonVariants } from "../../../shared/components/general/Button/Button.types";
import Spinner from "../../../shared/components/general/Spinner/Spinner";

const SaveEditChangesModal = ({
  handleCancel,
  handleClose,
  isLoadingSave,
}: {
  handleCancel: any;
  handleClose: any;
  isLoadingSave: boolean;
}) => (
  <Flex direction="column" alignItems="center" style={{ gap: "16px" }}>
    <h1 style={{ textAlign: "center" }}>
        There are unsaved changes. Are you sure you want to close?
    </h1>
    <ImageLoader
      src={saveEditchanges}
      alt=""
      style={{ width: "450px", height: "300px" }}
    />
    <Flex style={{ width: "100%", gap: "16px" }} justifyContent="flex-end">
      <Button variant={ButtonVariants.Secondary} handleClick={handleClose}>
        {isLoadingSave}
          Close without changes
      </Button>
      <Button
        handleClick={handleCancel}
        style={{
          textAlign: "left",
          minWidth: "85px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {isLoadingSave ? (
          <Spinner
            white={true}
            style={{
              width: "16px",
              margin: "0",
              left: "50%",
              position: "initial",
            }}
          />
        ) : (
          <span>Keep editing</span>
        )}
      </Button>
    </Flex>
  </Flex>
);
export default SaveEditChangesModal;
