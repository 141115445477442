import { useEffect, useState } from "react";
import Spinner from "modules/shared/components/general/Spinner/Spinner";
import AllDuplicationsDataSingleItem from "./AllDuplicationsSingleItem";
import "./styles.scss";

const AllDuplicationsData = ({
  flattenedArray,
  isFetchingNextPage,
}: {
  flattenedArray: any;
  isFetchingNextPage: boolean;
}) => {
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    let copyTimer: any;
    if (copied) {
      copyTimer = setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
    return () => {
      clearTimeout(copyTimer);
    };
  }, [copied]);


  return (
    <>
      {flattenedArray?.map(
        (
          {
            campaigns,
            created_at,
            created_by,
            id,
          }: {
            campaigns: any;
            created_at: any;
            created_by: any;
            id: number;
          },
          index: any,
        ) => (
          <AllDuplicationsDataSingleItem
            key={id}
            index={index}
            campaigns={campaigns}
            createdAt={created_at}
            createdBy={created_by}
          />
        ),
      )}

      {isFetchingNextPage && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "90px",
          }}
        >
          <Spinner style={{ margin: "0" }} />
        </div>
      )}
     
    </>
  );
};

export default AllDuplicationsData;
