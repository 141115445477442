export const EditIconBlue = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4444 5.6875L5.44599 14.6859C4.78734 15.3446 4.26731 16.1441 4.109 17.062C3.94915 17.9888 3.89595 19.139 4.44444 19.6875C4.99293 20.236 6.14311 20.1828 7.06992 20.0229C7.98784 19.8646 8.78734 19.3446 9.44599 18.6859L18.4444 9.6875M14.4444 5.6875C14.4444 5.6875 17.4444 2.6875 19.4444 4.6875C21.4444 6.6875 18.4444 9.6875 18.4444 9.6875M14.4444 5.6875L18.4444 9.6875"
      stroke="#0F314D"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
