import React from "react";
import duplicationIsInProgress from "assets/icons/duplicationIsInProgress.png";
import { duplicationIsInProgressUseStyles } from "./DuplicationIsInProgress.styles";
import ROUTES from "../../../../constants/routes";
import { Link } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
  resetAllDuplicationData,
  setStartDuplicationSuccess,
} from '../../../../store/brandAdAccounts/brandAdAccountsSlice';

const DataPreparation = () => {
  const dispatch = useDispatch();
  const styles = duplicationIsInProgressUseStyles();

  const goBackToDuplication = () => dispatch(setStartDuplicationSuccess(false));

  return (
    <div className={styles.duplicationInProgressWrapper}>
      <div className={styles.duplicationImgContainer}>
        <img src={duplicationIsInProgress} alt="duplication in progress" />
      </div>
      <div>
        <h1>Duplication is in progress!</h1>
        <p>
          You will receive an email with a link to the FranchiseRamp preview
          <br /> page to confirm the duplication.
        </p>
        <Link
          className="auth-button"
          style={{ width: "fit-content" }}
          onClick={() => {
            goBackToDuplication();
            dispatch(resetAllDuplicationData());
          }}
          to={ROUTES.DUPLICATION}
        >
          Back to duplication
        </Link>
      </div>
    </div>
  );
};

export default DataPreparation;
