import { useEffect } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import { IRootStore } from 'types/store.types';
import { setActiveTab } from 'store/brandsReporting/brandsReportingSlice';

const ReportTabs = () => {
  const {
    brandsReporting: { activeTab },
  } = useSelector((state: IRootStore) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedActiveTab = sessionStorage.getItem('activeTab');
    if (storedActiveTab) {
      dispatch(setActiveTab(storedActiveTab));
    } else {
      dispatch(setActiveTab('Campaign'));
    }
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    sessionStorage.setItem('activeTab', activeTab);

    return () => {
      sessionStorage.setItem('activeTab', 'Campaign');
    };
  }, [activeTab]);

  return (
    <div className="report-types">
      {['Campaign', 'Copy', 'Creative', 'Leads'].map(tab => (
        <div
          key={tab}
          className={activeTab === tab ? 'active-tab' : ''}
          onClick={() => {
            dispatch(setActiveTab(tab));
          }}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default ReportTabs;
