import { createUseStyles } from "react-jss";
import { AppTheme } from "types/styles.types";

interface UserEmailDrawerStyleProps {
  readOnly: boolean;
}

export const useStyles = createUseStyles((theme: AppTheme) => ({
  inputContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    marginBottom: "16px",
  },
  input: (props: UserEmailDrawerStyleProps) => ({
    border: `1px solid ${theme.grey1}`,
    padding: "16px",
    borderRadius: "8px",
    width: "100%",
    maxWidth: "1015px",
    paddingLeft: "88px",
    color: theme.textColor,
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "8px",
    cursor: "initial",
    "&:focus": {
      border: props?.readOnly
        ? `1px solid ${theme.grey1}`
        : `1px solid ${theme.deepBlueColor}`,
    },
  }),
  defaultValue: {
    color: "#F69C2C",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    position: "absolute",
    top: "44%",
    transform: "translateY(-50%)",
    left: "16px",
  },
  isInProgressTitle: {
    color: theme.textColor,
    textAlign: "center",
    fontSize: "24px",
    fontWeight: "500",
    lineHeight: "36px",
    marginBottom: "8px",
  },
  isInProgressDescription: {
    color: theme.textColor,
    textAlign: "center",
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "27px",
    marginTop: "0px",
    marginBottom: "16px",
  },

}));
