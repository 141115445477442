import { instance } from '../api/ApiRequest';
import { serverUrls } from '../variables';

export const getSuggestions = async ({
  activeTab,
  brands = [],
  managers = [],
  page,
  searchValueOfCampaign,
  date,
  type,
  logicSequenceData = [],
}: {
  activeTab: string;
  brands: Array<any>;
  managers: [];
  page: string | number;
  searchValueOfCampaign: string;
  date: string;
  type: string;
  logicSequenceData: Array<any>;
  logicSequenceDataLength: number;
}) => {
  const periodCheck =
    date === "all time" || date === ""
      ? {}
      : { period: date };

  const typeCheck =
    type === "all" || type === "" ? {} : { type };

  const paramsObj = {
    ...(brands.length === 0
      ? {}
      : { brands }),
    ...(managers.length === 0
      ? {}
      : { managers }),
    page,
    ...(searchValueOfCampaign
      ? { ad_account_name_contains: searchValueOfCampaign }
      : {}),
    ...((activeTab === "changeHistory" || activeTab === "archived") &&
      periodCheck),
    ...(activeTab === "archived" && typeCheck),
    ...(
      logicSequenceData.length === 0
        ? {}
        : { reason_types: logicSequenceData }),
  } as any ;

  const queryParams = new URLSearchParams(paramsObj);
  const queryString = queryParams.toString();

  const endpoint =
    activeTab === "suggestions"
      ? `active/?${queryString}`
      : activeTab === "changeHistory"
        ? `accepted/?${queryString}`
        : `archived/?${queryString}`;

  return  await instance.get(`${serverUrls.suggestions}${endpoint}`);
};

export const acceptSuggestionById = async ({ id }:any) => await instance.post(
  `${serverUrls.suggestions}${id}/accept/`,
);

export const dismissSuggestionById = async ({ id }:any) => await instance.post(
  `${serverUrls.suggestions}${id}/dismiss/`,
);

export const getManagers = async () =>
  await instance.get(`${serverUrls.suggestions}managers/`);

export const getDates = async () => await instance.get(
  `${serverUrls.suggestions}period-choices/`,
);

export const 
  getTypes = async () => await instance.get(
    `${serverUrls.suggestions}archived-choices/`,
  );

export const getBrandNames = async () =>
  await instance.get(`${serverUrls.suggestions}brands/`);

export const getPauseData = async (suggestion_id: number) =>
  await instance.get(`${serverUrls.suggestions}${suggestion_id}/is-suggestion-valid/`);


export const getLogicSequenceData = async () => await instance.get(
  `${serverUrls.suggestions}logic-sequence-choices/`,
);
