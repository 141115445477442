import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: '',
  showSuccessModal: false,
  showFailedModal: false,
};

export const brandsReportingSlice = createSlice({
  name: 'brandsReporting',
  initialState,
  reducers: {
    setActiveTab: (state, action) => ({
      ...state,
      activeTab: action.payload,
    }),
    setShowSuccessModal: (state, action) => ({
      ...state,
      showSuccessModal: action.payload,
    }),
    setShowFailedModal: (state, action) => ({
      ...state,
      showFailedModal: action.payload,
    }),

  },
});

export const { setActiveTab, setShowSuccessModal, setShowFailedModal } =
    brandsReportingSlice.actions;

export default brandsReportingSlice.reducer;
