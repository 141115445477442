import { createUseStyles } from "react-jss";

interface ButtonStyleProps {
  disabled?: boolean;
  color?: string;
}

export const buttonUseStyles = createUseStyles(() => ({
  primaryButton: {
    position: "relative",
    padding: "16px 24px",
    background: ({ disabled }: ButtonStyleProps) =>
      (disabled ? "#657B8D" : "#0F314D"),
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    border: "none",
    cursor: ({ disabled }: ButtonStyleProps) =>
      (disabled ? "initial" : "pointer"),
    whiteSpace: "nowrap",
    "&:hover": {
      background: ({ disabled }: ButtonStyleProps) =>
        (disabled ? "#657B8D" : "#3F5A71"),
    },
  },
  secondaryButton: {
    padding: "16px 24px",
    background: "#FFFFFF",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F314D",
    border: "none",
    cursor: "pointer",
    filter: "drop-shadow(0px 4px 4px #e8e8e8)",
    "&:hover": {
      filter: "drop-shadow(0px 4px 4px #d6d6d6)",
    },
  },
  warningButton: {
    padding: "16px 24px",
    background: "#B3261E",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    // filter: "drop-shadow(0px 4px 4px #e8e8e8)",
    "&:hover": {
      backgroundColor: "#CF0B00",
    },
  },
  disabledButton: {
    padding: "16px 24px",
    background: "#fff",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#EAEAEA",
    border: "1px solid #EAEAEA",
    cursor: "initial",
  },
  outlinedButton: {
    position: "relative",
    padding: "16px 24px",
    backgroundColor: "#FFF",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#0F314D",
    border: "1px solid #0F314D",
    height: "32px",
    cursor: ({ disabled }: ButtonStyleProps) =>
      (disabled ? "initial" : "pointer"),
    whiteSpace: "nowrap",
    "&:hover": {
      border: "1px solid #3F5A71",
      color: "#3F5A71",
    },
  },
  dangerButton: {
    padding: "16px 24px",
    background: "#fff",
    borderRadius: "4px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#B3261E",
    border: "1px solid #B3261E",
    cursor: "pointer",
    "&:hover": {
      border: "1px solid #CF0B00",
      color: "#CF0B00",
    },
  },
}));
