import { ReactComponent as FBIcon } from 'assets/icons/FBIcon.svg';
import { ReactComponent as TikTokIcon } from 'assets/icons/TikTokIcon.svg';

export const platformIcon = {
  Facebook: <div style={{ width: '80px' }}><FBIcon /></div>,
  TikTok: <div style={{ width: '80px' }}><TikTokIcon /></div>,
};

export const columns = [
  {
    Header: 'Report name',
    accessor: 'reportName',
  },
  {
    Header: 'Platform',
    accessor: 'platform',
  },
  {
    Header: 'Time period',
    accessor: 'timePeriod',
  },
  {
    Header: 'Date created',
    accessor: 'dateCreated',
  },
  {
    Header: 'Created by',
    accessor: 'createdBy',
  },
  {
    Header: 'State',
    accessor: 'state',
  },
];

export const platforms = ['Tiktok', 'Facebook'];
