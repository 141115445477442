import { AppTheme } from "types/styles.types";

export const AppThemeConstants: AppTheme = {
  textColor: "#444444",
  primaryColor: "#0F314C",
  secondaryColor: "#D9D9D9",
  whiteColor: "#ffffff",
  deepBlueColor: "#0F314D",
  grey1: "#D9D9D9",
  grey2: "#EAEAEA",
  grey3: "#F3F3F3",
  grey4: "#B7B7B7",
  grey5: "#F9F9F9",
  inputBackground: "#FFFFFF",
  primaryRadius: "0.5rem",
  dangerColor: "#B3261E",
  inputBoxShadow: "0px 0px 4px #E8E8E8",
  inputPlaceholder: "#B7B7B7",
  red: "#B3261E",
  orange: "#F69C2C",
  green: "#33850D",
  statusDisabled: "#657B8D",
};

